import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import PricingPpcGrid from './pricingPpcGrid'
import PricingFluorGrid from './pricingFluorGrid'
import { TextInput, RadioGroup, FormRow, ErrorIcon } from '@molecules'
import { Tabs } from '@templates'
import { Button } from '@atoms'
import { Fieldset } from '@organisms'
import { useOrderStore } from '@stores'
import PreviewGrid from './previewGrid'

function OrderPricingRange({
  priceRange,
  colours,
  clarities,
  fluorescences,
  showCosts,
  onChange,
  disabled,
  fluorGridRef,
  validationText,
  handleApplyAllMargin
}) {
  const {
    setActiveRange,
    activePricingTabIndex,
    setActivePricingTabIndex,
    setPainterValue,
    setIsPainterEnabled,
    pmColours
  } = useOrderStore(state => state)
  const tabsName = 'orderPricingRange'

  useEffect(() => {
    if (priceRange && !('pricingMethod' in priceRange)) {
      if (priceRange.discountTable) {
        handleChange({ currentTarget: { name: 'pricingMethod', value: 'discount' } })
      } else if (priceRange.priceTable) {
        handleChange({ currentTarget: { name: 'pricingMethod', value: 'ppc' } })
      }
    }

    setActiveRange({ priceRange, colours, clarities })
  }, [priceRange, colours, clarities])

  function getTitle(key) {
    let label = ''
    if (key === 'priceTable' || key === 'discountTable') label = 'Pricing'
    if (key === 'additionalDiscounts') label = 'Fluor Discount'
    if (key === 'ppcPreview') label = 'PPC Preview'
    if (key === 'discountPreview') label = 'Discount Preview'
    return (
      <div className="d-flex">
        {label}
        <ErrorIcon warning={validationText?.getWarning(key)} error={validationText?.getError(key)}/>
      </div>
    )
  }

  const items = useMemo(() => {
    const arr = []
    const noFlu = (fluorescences || []).length === 0 || (fluorescences.length === 1 && fluorescences[0].value === 'N')
    const pricingMethod = priceRange?.pricingMethod
    if (priceRange) {
      if (pricingMethod === 'discount') {
        arr.push({
          title: getTitle('discountTable'),
          Component: PricingPpcGrid,
          props: {
            key: 'discount',
            priceTable: priceRange.discountTable,
            priceVar: 'percentage',
            colours,
            clarities,
            pmColours,
            disabled,
            validationText: validationText?.parseStep('discountTable'),
            onChange: (discountTable) => onChange({ discountTable })
          }
        })
      } else {
        arr.push({
          title: getTitle('priceTable'),
          Component: PricingPpcGrid,
          props: {
            key: 'ppc',
            priceTable: priceRange.priceTable,
            priceVar: 'ppc',
            colours,
            clarities,
            pmColours,
            disabled,
            validationText: validationText?.parseStep('priceTable'),
            onChange: (priceTable) => onChange({ priceTable })
          }
        })
      }
      arr.push({
        title: getTitle('additionalDiscounts'),
        Component: PricingFluorGrid,
        disabled: noFlu,
        props: {
          key: 'fluor',
          priceTable: priceRange.additionalDiscounts?.fluorescence,
          pricingType: priceRange.pricingMethod,
          colours,
          clarities,
          fluorescences,
          pmColours,
          validationText: validationText?.parseStep(['additionalDiscounts', 'fluorescence']),
          onChange: (newValue) => {
            if (!priceRange.additionalDiscounts) priceRange.additionalDiscounts = {}
            priceRange.additionalDiscounts.fluorescence = newValue
          },
          disabled,
          ref: fluorGridRef
        }
      })

      arr.push({
        title: getTitle(`${pricingMethod}Preview`),
        Component: PreviewGrid,
        disabled: noFlu,
        props: {
          key: `${pricingMethod}Preview`,
          priceTable: priceRange.additionalDiscounts?.fluorescence,
          ppcTable: priceRange?.[(pricingMethod === 'discount' ? 'discountTable' : 'priceTable')],
          pricingType: pricingMethod,
          priceVar: pricingMethod === 'discount' ? 'percentage' : 'ppc',
          colours,
          clarities,
          fluorescences,
          pmColours,
          disabled
        }
      })
    }
    return arr
  }, [priceRange, colours, clarities, pmColours, disabled, validationText, fluorGridRef])

  function handleChange(e) {
    const target = e.currentTarget || e.target
    if ('name' in target && 'value' in target) onChange({ [target.name]: target.value })
  }

  function onTabChange(index) {
    setPainterValue(null)
    setIsPainterEnabled(false)
    setActivePricingTabIndex(index)
  }

  if (!priceRange) return null

  return (
    <div className='order-pricing-range'>
      <div className="order-pricing-range__settings">
        <FormRow validationText={validationText?.parseStep('weight')}>
          <TextInput
            name='minWeight'
            label='Min Weight'
            value={priceRange.minWeight}
            onChange={handleChange}
            type='number'
            disabled={disabled}
            allowNegative={false}
            validationText={validationText?.parseStep('minWeight')}
          />
          <TextInput
            name='maxWeight'
            label='Max Weight'
            value={priceRange.maxWeight}
            onChange={handleChange}
            type='number'
            disabled={disabled}
            autoFocus={true}
            allowNegative={false}
            validationText={validationText?.parseStep('maxWeight')}
          />
        </FormRow>
        <Fieldset
          legend={'Pricing Method'}
          validationText={validationText?.parseStep('pricingMethod')}
        >
          <RadioGroup
            name='pricingMethod'
            options={[
              { name: 'discount', value: 'discount', label: 'Discount' },
              { name: 'ppc', value: 'ppc', label: 'Price per Carat' }
            ]}
            value={priceRange.pricingMethod}
            onChange={handleChange}
            disabled={disabled}
          />
        </Fieldset>
        {priceRange?.pricingMethod && (showCosts || priceRange.margin)
          ? <div className="order-pricing-range__settings-margin">
            <div className="order-pricing-range__settings-margin-input">
              <TextInput
                name='margin'
                label='Margin'
                value={priceRange.margin}
                onChange={handleChange}
                type='number'
                disabled={disabled}
                allowNegative={false}
                validationText={validationText?.parseStep('margin')}
                numberFormat={true}
                formatSuffix={'%'}
              />
            </div>
            <div className="order-pricing-range__settings-margin-button">
              <Button
                typeVariant='action'
                size='sm'
                disabled={disabled || priceRange.margin == null}
                onClick={() => handleApplyAllMargin(priceRange.margin)}
              >Apply to Existing Weight Ranges</Button>
            </div>
          </div>
          : null
        }
      </div>
      {priceRange?.pricingMethod
      && <div>
        <Tabs
          items={items}
          name={tabsName}
          onTabChange={onTabChange}
          initialTabIndex={activePricingTabIndex}
        />
      </div>
      }

    </div>
  )
}

OrderPricingRange.propTypes = {
  priceRange: PropTypes.object,
  colours: PropTypes.array,
  clarities: PropTypes.array,
  fluorescences: PropTypes.array,
  showCosts: PropTypes.bool,
  handleApplyAllMargin: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  validationText: PropTypes.object
}

export default OrderPricingRange
