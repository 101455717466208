import React, { useState, useEffect } from 'react'
import { useGlobalsStore, useAuthStore } from '@stores'
import { Table } from '@organisms'
import { useToast } from '@hooks'
import { cutGradeActions, shapeGroupActions } from '@actions'
import { useHistory, NavLink } from 'react-router-dom'

function ShapeList() {
  const [shapeList, setShapeList] = useState([])
  const {
    orgsList: { all: orgsList },
    getOrgsList
  } = useGlobalsStore()
  const { hasAdmin, permissionsAdminCache } = useAuthStore()
  const [columns, setColumns] = useState([])
  const [shapeGroups, setShapeGroups] = useState([])
  const history = useHistory()
  const { showSuccessToast } = useToast()

  useEffect(() => {
    getShapeList('ACTIVE')
  }, [])

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    if (hasAdmin(shapeGroupActions.getShapeGroupsList)) {
      shapeGroupActions.getShapeGroupsList()
      .then(response => setShapeGroups(response.data.data))
    }
  }, [])

  useEffect(() => {
    setColumns([
      {
        Header: 'ID',
        accessor: 'id',
        dataType: 'string',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/buyer-settings/shapes/${cellInfo.row.original.id}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value.slice(0, 4)}
            </NavLink>
          )
        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        dataType: 'string'
      },
      {
        Header: 'Owner',
        accessor: (row) => row.buyerId == null ? '* Preloaded *' : (orgsList?.find(org => org.orgId === row.buyerId)?.commonName || ''),
        dataType: 'string',
        id: 'owner'
      },
      ...(hasAdmin(shapeGroupActions.getShapeGroupsList) ? [{
        Header: 'Group',
        id: 'shapeGroupId',
        accessor: (row) => row.shapeGroupId == null ? 'None' : shapeGroups?.find(grp => grp.id === row.shapeGroupId)?.description || 'None',
        dataType: 'string',
        filterType: 'checkbox'
      }] : []),
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [orgsList, shapeGroups, permissionsAdminCache])

  function getShapeList(condition) {
    cutGradeActions.getShapeList({ condition })
    .then(response => setShapeList(response.data.data))
    .catch(err => console.error(err))
  }

  function handleArchive(row) {
    return cutGradeActions.setShapeCondition(row.id, 'ARCHIVED')
    .then(() => {
      showSuccessToast('Shape has been archived.')
    })
  }

  function handleUnarchive(row) {
    return cutGradeActions.setShapeCondition(row.id, 'ACTIVE')
    .then(() => {
      showSuccessToast('Shape has been unarchived.')
    })
  }

  function handleRemove(row) {
    return cutGradeActions.setShapeCondition(row.id, 'DELETED')
    .then(() => {
      showSuccessToast('Shape has been removed.')
    })
  }

  const rowActions = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.buyerId && row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.buyerId && row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      callback: handleRemove,
      shouldConfirm: true,
      shouldDisplay: (row) => row.buyerId && row.condition !== 'DELETED'
    }
  ]

  const tableTabs = [{
    label: 'Active',
    params: 'ACTIVE'
  },
  {
    label: 'Archived',
    params: 'ARCHIVED'
  }]

  const topBarActions = [{
    label: 'Create Shape',
    callback: () => history.push('/buyer-settings/shapes/create')
  }]

  const initialSort = [{
    id: 'updatedAt',
    desc: true
  },
  {
    id: 'owner',
    desc: true
  },
  {
    id: 'name',
    desc: false
  }]

  return (
    <Table
      title='Shapes'
      data={shapeList}
      columns={columns}
      tableTabs={tableTabs}
      getDataCallback={getShapeList}
      rowActions={rowActions}
      initialSort={initialSort}
      topBarActions={topBarActions}
      isMultiSelect={false}
    />
  )
}

export default ShapeList
