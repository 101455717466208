import React, { useState } from 'react'
import { useSigninStore } from '@stores'
import { useAuth } from '@hooks'
import { Button } from '@atoms'
import { TextInput, DisplayMessage } from '@molecules'
import { Form } from '@organisms'

const SignInForm = function () {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isSigningIn, setSigningIn] = useState(false)
  const [signinError, setSigninError] = useState(null)
  const { setDisplayMode } = useSigninStore(state => state)
  const { signIn } = useAuth()

  function handleSignin() {
    setSigninError(null)
    setSigningIn(true)
    signIn(username, password)
    .catch(err => {
      setSigningIn(false)
      setSigninError(err)
    })
  }

  return (
    <div className="signin__form">
      <div className="signin__form-title">
        <span>Sign in to Clara</span>
      </div>
      <Form
        onSubmit={handleSignin}
        hasOutline={false}
      >
        <TextInput
          name="username"
          label="Username"
          required={true}
          value={username}
          onChange={e => setUsername(e.target.value)}
          labelStyle={{ color: 'white' }}
        />
        <TextInput
          type="password"
          name="password"
          label="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          required={true}
          labelStyle={{ color: 'white' }}
        />
        <div className="signin__form-signin-buttons">
          <Button
            onClick={() => setDisplayMode('forgotPassword')}
            typeVariant="action"
            size='sm'
          >
            <span className='signin__form-action-button-text signin__form-forgot-password-button '>
              Forgot password?
            </span>
          </Button>
          <Button
            size='sm'
            type='submit'
            typeVariant='secondary'
            loading={isSigningIn}
          >
            Sign In
          </Button>
        </div>
        {
          signinError
            ? <DisplayMessage
              type={'error'}
              message={
                <span>{signinError.message}</span>
              }
            />
            : null
        }
      </Form>
    </div>
  )
}

export default SignInForm
