import React, { useState, useEffect, useMemo } from 'react'
import { Table } from '@organisms'
import { saleActions, planningActions, reportActions } from '@actions'
import { Checkbox } from '@atoms'
import { useGlobalsStore, useModalStore, usePlanningStore, useRoughStoneStore } from '@stores'
import { fileUtils, textUtils } from '@utils'
import { useHistory, NavLink } from 'react-router-dom'
import { useToast, usePageSettings } from '@hooks'
import moment from 'moment'
import { Dropdown } from '@molecules'
import { ConfirmationModal } from '@templates'
import PlanningDetailsModal from './inc/planningDetailsModal'

const fixedSales = [{ id: 1, name: 'No Sale (6 months)', plannedStoneParams: { saleId: 'none', earliestCreatedAt: moment().subtract(6, 'months').toISOString() } }, { id: 2, name: 'No Sale (All)', plannedStoneParams: { saleId: 'none' } }]

function PlanningList() {
  const [sales, setSales] = useState([])
  const [selectedSale, setSelectedSale] = useState()

  const {
    orgsList: { all: orgsList }, getOrgsList,
    spsRequestStatusesMap, getSpsRequestStatuses,
    saleStatusesMap, getSaleStatuses,
    spsPlanningSpeedsMap, getSpsPlanningSpeeds
  } = useGlobalsStore()
  const {
    assortmentsList: { [JSON.stringify({ condition: 'ACTIVE', columns: '[id, name]' })]: assortmentsList },
    getAssortmentsList
  } = useRoughStoneStore(store => store)
  const { setModal } = useModalStore()

  const {
    addPageSettingValue,
    getPageSettingValue
  } = usePageSettings('planning')

  useEffect(() => {
    const storeSelectedSale = getPageSettingValue('selectedSale')
    if (storeSelectedSale) setSelectedSale(storeSelectedSale)
  }, [])

  useEffect(() => {
    addPageSettingValue({ selectedSale })
  }, [selectedSale])

  useEffect(() => {
    getOrgsList()
    getSpsRequestStatuses()
    getSaleStatuses()
    getSpsPlanningSpeeds()
    getAssortmentsList({ condition: 'ACTIVE', columns: '[id, name]' })
  }, [])

  useEffect(() => {
    saleActions.getSales({ columns: '[id, name, status, updatedAt]' })
    .then(result => {
      setSales(fixedSales.slice().concat(result.data.data.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))))
    })
    .catch(console.error)
  }, [])

  useEffect(() => {
    if (selectedSale) {
      fetchRequests()
    }
  }, [selectedSale])

  const plannedStoneParams = useMemo(() => ({ saleId: selectedSale, columns: '[Rough]', ...fixedSales.find(({ id }) => id === selectedSale)?.plannedStoneParams }), [selectedSale])

  const {
    plannedStonesList: { [plannedStoneParams ? JSON.stringify(plannedStoneParams) : 'all']: spsRequests },
    getPlannedStonesList
  } = usePlanningStore(store => store)

  function fetchRequests() {
    getPlannedStonesList(plannedStoneParams)
  }

  const [columns, setColumns] = useState([])
  const [spsSummary, setSpsSummary] = useState({})

  useEffect(() => {
    setColumns([
      {
        Header: 'Request ID',
        accessor: 'id',
        dataType: 'string',
        filterType: 'textarea',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/planning/${cellInfo.row.original.id}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value}
            </NavLink>
          )
        }
      },
      {
        Header: 'Clara ID',
        accessor: 'roughId',
        dataType: 'string',
        filterType: 'textarea'
      },
      {
        Header: 'Assortment',
        id: 'assortment',
        accessor: row => assortmentsList?.find(a => a.id === row.Rough.assortmentId)?.name,
        dataType: 'string',
        filterType: 'checkbox'
      },
      {
        Header: 'Weight',
        accessor: 'Rough.weight',
        dataType: 'string',
        filterType: 'numberRange'
      },
      {
        Header: 'Buyers',
        id: 'buyer',
        accessor: row => orgsList?.find(org => org.orgId === row.buyerId)?.commonName,
        dataType: 'string'
      },
      {
        Header: 'SPS Start Time',
        id: 'spsStartTime',
        accessor: 'spsStartTime',
        dataType: 'dateTime',
        filterType: 'date'
      },
      {
        Header: 'SPS Message',
        id: 'spsMessage',
        accessor: 'spsMessage',
        dataType: 'string'
      },
      {
        Header: 'Version',
        accessor: 'version',
        dataType: 'string',
        filterType: 'checkbox'
      },
      {
        Header: 'Queue',
        id: 'queue',
        accessor: row => row.priorityQueue ? 'Priority' : 'Regular',
        dataType: 'string',
        filterType: 'checkbox',
        enums: ['Priority', 'Regular']
      },
      {
        Header: 'SPS Status',
        id: 'status',
        accessor: (row) => spsRequestStatusesMap?.[row.status],
        dataType: 'string',
        filterType: 'checkbox'
      },
      {
        Header: 'Planning Accuracy',
        id: 'speed',
        accessor: (row) => spsPlanningSpeedsMap?.[row.speed],
        dataType: 'string',
        filterType: 'checkbox'
      },
      {
        Header: 'Condition',
        id: 'condition',
        accessor: row => textUtils.formatDescription(row.condition),
        dataType: 'string',
        filterType: 'checkbox'
      },
      {
        Header: 'Last Updated',
        id: 'updatedAt',
        accessor: 'updatedAt',
        dataType: 'dateTime',
        filterType: 'date'
      }
    ])
  }, [assortmentsList, orgsList, spsRequestStatusesMap])

  useEffect(() => {
    if (spsRequests) {
      setSpsSummary(
        spsRequests.data.reduce((summary, req) => {
          if (req.condition === 'ACTIVE') {
            if (!summary[req.status]) summary[req.status] = { normalQCount: 0, priorityQCount: 0, lastUpdate: null, status: req.status }

            if (req.priorityQueue) summary[req.status].priorityQCount++
            else summary[req.status].normalQCount++

            if (!summary[req.status].lastUpdate || moment(req.updatedAt) > moment(summary[req.status].lastUpdate)) summary[req.status].lastUpdate = req.updatedAt
          }
          return summary
        }, {})
      )
    }
  }, [spsRequests])

  const summaryColumns = useMemo(() => ([
    {
      Header: 'Status',
      id: 'status',
      accessor: (row) => spsRequestStatusesMap?.[row.status],
      dataType: 'string'
    },
    {
      Header: 'Regular Queue Count',
      accessor: 'normalQCount',
      dataType: 'string'
    },
    {
      Header: 'Priority Queue Count',
      accessor: 'priorityQCount',
      dataType: 'string'
    },
    {
      Header: 'Last Updated',
      accessor: 'lastUpdate',
      dataType: 'dateTime',
      filterType: 'date'
    }
  ]), [spsRequestStatusesMap])

  const history = useHistory()
  function navigateTo(path) {
    history.push(path)
  }
  const { showSuccessToast } = useToast()

  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [resendRequestModalOpen, setResendRequestModalOpen] = useState(false)
  const [processResultsModalOpen, setProcessResultsModalOpen] = useState(false)

  function cancelRequests(requests) {
    if (!requests?.length) throw new Error('No requests selected.')
    return planningActions.cancelSpsRequests({ requestIds: requests.map(r => r.id) })
    .then(() => {
      showSuccessToast(`${requests.length} Requests were cancelled`)
      fetchRequests()
    })
  }

  function resendSPSRequest(requests, { highPriority, priceOverride }) {
    if (!requests?.length) throw new Error('No requests selected.')
    return planningActions.resendSpsRequests({ requestIds: requests.map(r => r.id), highPriority, priceOverride })
    .then(() => {
      showSuccessToast(`${requests.length} Requests were resubmitted`)
      fetchRequests()
    })
  }

  function processSPSResults(requests) {
    const now = moment().toISOString()
    if (!requests?.length) throw new Error('No requests selected.')
    return Promise.all(requests.map(req => {
      return planningActions.processSpsResults({ RequestID: req.id, Errors: {}, Time: now })
    }))
    .then(() => {
      showSuccessToast(`${requests.length} Requests were sent for processing`)
      fetchRequests()
    })
  }

  async function downloadPlanningDetails([opts]) {
    return reportActions.getPlanningDetails('xlsx', { ...opts, splitVersions: true })
    .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
  }

  const [highPriority, setHighPriority] = useState(false)
  const [priceOverride, setPriceOverride] = useState(false)

  return (
    <div className='PlannedStones'>
      <div className="dashboard__input-cards">
        <Dropdown
          label= 'Sale cycle'
          options={sales.map(sale => ({ value: sale.id, label: !textUtils.isUUID(selectedSale) ? sale.name : `${sale.name} | ${saleStatusesMap?.[sale.status] ?? ''}` }))}
          value={selectedSale}
          onChange={e => setSelectedSale(e.currentTarget.value)}
        />
      </div>
      { Object.values(spsSummary).length
        ? <>
          <Table
            title='SPS Summary'
            data={Object.values(spsSummary)}
            columns={summaryColumns}
            isMultiSelect={false}
            showFooter={false}
          /><br/>
        </> : null
      }
      <Table
        title='SPS Requests'
        data={spsRequests?.data}
        columns={columns}
        initialPageSize={100}
        initialSort={[
          { id: 'updatedAt', desc: true }
        ]}
        topBarActions={[
          {
            componentName: 'dropdown',
            enableOnSelect: true,
            options: [
              {
                callback: requests => setCancelModalOpen(requests),
                label: 'Cancel Requests',
                value: 'cancel',
                isDisabled: requests => requests.some(r => ['CANCELLED', 'COMPLETE', 'MATCHED', 'ERROR'].includes(r.original.status))
              },
              {
                callback: requests => setResendRequestModalOpen(requests),
                label: 'Resend SPS Requests',
                value: 'resend',
                isDisabled: requests => requests.some(r => r.original.condition !== 'ACTIVE')
              },
              {
                callback: requests => setProcessResultsModalOpen(requests),
                label: 'Process SPS Results',
                value: 'finalize',
                isDisabled: requests => requests.some(r => r.original.condition !== 'ACTIVE')
              }
            ]
          },
          {
            callback: () => setModal({
              id: 'downloadPlanningDetails',
              title: 'Planning Details',
              className: 'planning-details-modal',
              customMessageRenderer: (messageProps) => <PlanningDetailsModal
                initialValues={{ status: 'submitted', saleId: textUtils.isUUID(selectedSale) ? selectedSale : null, includeTops: true, maxOptsPerBuyer: 2, maxOpts: 5 }}
                {...messageProps}
              />,
              onSubmit: downloadPlanningDetails,
              customButtonsRenderer: () => null,
              closeOnFail: false
            }),
            label: 'Planning Details',
            enableOnSelect: false
          },
          {
            callback: () => fetchRequests(),
            label: 'Refresh',
            enableOnSelect: false
          },
          {
            callback: () => navigateTo('/planning/sales'),
            label: 'Sales',
            enableOnSelect: false
          },
          {
            callback: () => navigateTo('/planning/create'),
            label: 'Create SPS requests',
            enableOnSelect: false
          }
        ]}
      />
      <ConfirmationModal
        open={Boolean(cancelModalOpen)}
        title='Cancel SPS Requests'
        message={`Are you sure you want to cancel ${cancelModalOpen?.length} request${cancelModalOpen?.length > 1 ? 's' : ''}?`}
        onClose={() => setCancelModalOpen(false)}
        onSubmit={() => cancelRequests(cancelModalOpen)}
      />
      <ConfirmationModal
        open={Boolean(resendRequestModalOpen)}
        title='Resubmit SPS Requests'
        message={`Are you sure you want to resend ${resendRequestModalOpen?.length} request${resendRequestModalOpen?.length > 1 ? 's' : ''} for planning?`}
        onClose={() => setResendRequestModalOpen(false)}
        onSubmit={async () => {
          resendSPSRequest(resendRequestModalOpen, { highPriority, priceOverride })
        }}
      >
        <div className="user-list__change-password-modal">
          <div>
            <Checkbox
              name="highPriority"
              id="highPriority"
              label="Use High Priority Queue?"
              checked={highPriority}
              onChange={e => setHighPriority(e?.currentTarget?.checked)}
            />
          </div>
          <div>
            <Checkbox
              name="priceOverride"
              id="priceOverride"
              label="Override Previously saved prices?"
              checked={priceOverride}
              onChange={e => setPriceOverride(e?.currentTarget?.checked)}
            />
          </div>
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        open={Boolean(processResultsModalOpen)}
        title='Process SPS Results'
        message={`Are you sure you want to reprocess ${processResultsModalOpen?.length} request${processResultsModalOpen?.length > 1 ? 's' : ''}?`}
        onClose={() => setProcessResultsModalOpen(false)}
        onSubmit={async () => {
          processSPSResults(processResultsModalOpen)
        }}
      />
    </div>
  )
}

export default PlanningList
