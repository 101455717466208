import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { organizationActions, reportActions, transactionActions } from '@actions'
import { useAuthStore, useModalStore, useTransactionStore, useGlobalsStore } from '@stores'
import { Button } from '@atoms'
import { InfoTip, TextInput, RadioGroup } from '@molecules'
import OrderFilterList from './orderFilterList'
import CurrentOrderChart from './charts/currentOrdersChart'
import OrderBreakdownChart from './charts/orderBreakdownChart'
import OrderFeedbackChart from './charts/orderFeedbackChart'
import SaleHistoryChart from './charts/saleHistoryChart'
import { textUtils } from '@utils'
import dottie from 'dottie'

function DashboardCharts({ orgsList, organizationId, saleId }) {
  const defaultSelectedOrders = 3
  const chartColours = ['#1f3e9e', '#6e8e3b', '#530c99', '#528e8c', '#144242', '#2580fe', '#672144', '#23980d', '#fb2d4c', '#6e3901', '#9a53df', '#e5419f', '#9a76af', '#bc1cfa', '#c0710c']
  const tableInstanceRef = useRef()
  const [orders, setOrders] = useState()
  const [transactions, setTransactions] = useState()
  const [transactionsType, setTransactionsType] = useState('buyer')
  const [buyerTransactionParams, setBuyerTransactionParams] = useState()
  const [sellerTransactionParams, setSellerTransactionParams] = useState()
  const [adminTransactionParams, setAdminTransactionParams] = useState()
  const [selectedOrders, setSelectedOrders] = useState()
  const [clickedLine, setClickedLine] = useState(null)
  const [chartHoveredLine, setChartHoveredLine] = useState(null)
  const [dateRange, setDateRange] = useState({ startDate: textUtils.formatDate(moment().subtract(7, 'days')), endDate: textUtils.formatDate(moment()) })
  const [orderDetailType, setOrderDetailType] = useState('colour')
  const { setModal } = useModalStore(state => state)
  const { hasPermission, hasAdmin, permissionsAdminCache } = useAuthStore(state => state)
  const { polishedColoursMap: coloursMap, claritiesMap, getPolishedColours, getClarities } = useGlobalsStore()
  useEffect(() => {
    getPolishedColours()
    getClarities()
  }, [])

  const lines = useMemo(() => {
    if (!orders) return []
    return orders
      .reduce((data, order) => data.concat(order.OrderLines.map(ol => ({
        ...ol,
        orderName: order.name,
        quantityUnfilled: Number(ol.quantity) - Number(ol.quantityFilled)
      }))), [])
  }, [orders])

  const filteredLines = useMemo(() => {
    if (!lines || !selectedOrders) return lines
    return lines.filter(({ orderId }) => selectedOrders.includes(orderId))
  }, [lines, selectedOrders])

  const clickedLineLabel = useMemo(() => {
    if (!clickedLine) return ''
    const activeLine = lines.find(({ id }) => id === clickedLine)
    return activeLine
      ? textUtils.breakLines(`${activeLine.orderId}: ${activeLine.orderName}<br/>${getLineDescription(activeLine)}`)
      : ''
  }, [clickedLine])

  const {
    buyerTransactionsList: { [buyerTransactionParams ? JSON.stringify(buyerTransactionParams) : 'all']: buyerTransactionsList },
    getBuyerTransactionsList,
    sellerTransactionsList: { [sellerTransactionParams ? JSON.stringify(sellerTransactionParams) : 'all']: sellerTransactionsList },
    getSellerTransactionsList,
    adminTransactionsList: { [adminTransactionParams ? JSON.stringify(adminTransactionParams) : 'all']: adminTransactionsList },
    getAdminTransactionsList
  } = useTransactionStore(store => store)
  // Wait for both buyer and seller to be ready to set both
  const [buyerTransactions, sellerTransactions] = useMemo(() => {
    if (!buyerTransactionsList || !sellerTransactionsList) return [[], []]
    return [buyerTransactionsList.data, sellerTransactionsList.data]
  }, [buyerTransactionsList, sellerTransactionsList, organizationId])
  const adminTransactions = useMemo(() => {
    if (!adminTransactionsList) return []
    return adminTransactionsList.data
  }, [adminTransactionsList, organizationId])
  const adminTransactionsByRole = useMemo(() => {
    if (!adminTransactions) return []
    return adminTransactions.reduce((txns, txn) => {
      if (txn.buyerId === organizationId && txn.cancelledDate == null) txns.buyer.push(txn)
      if (txn.sellerId === organizationId && (txn.cancelledDate == null || txn.cancelForSeller === false)) txns.seller.push(txn)
      return txns
    }, { buyer: [], seller: [] })
  }, [adminTransactions, organizationId])
  const transactionsTypes = useMemo(() => {
    if (!permissionsAdminCache.size) return
    return ([
      {
        value: 'buyer',
        label: 'Buyer',
        dataKey: 'buyerPrice',
        disabled: !dottie.get(transactions, [
          ...(hasAdmin(transactionActions.getAdminTransactions) ? ['adminByRole'] : []),
          'buyer'
        ])?.length
      },
      {
        value: 'seller',
        label: 'Seller',
        dataKey: 'sellerPrice',
        disabled: !dottie.get(transactions, [
          ...(hasAdmin(transactionActions.getAdminTransactions) ? ['adminByRole'] : []),
          'seller'
        ])?.length
      }
    ])
  }, [transactions, organizationId, permissionsAdminCache])
  const salesHistoryTransactions = useMemo(() => {
    if (!transactions || !permissionsAdminCache.size) return []
    return dottie.get(transactions, [
      ...(hasAdmin(transactionActions.getAdminTransactions) ? ['adminByRole'] : []),
      transactionsType
    ]) ?? []
  }, [transactionsType, transactions, permissionsAdminCache])

  function getBuyerTransactionParams(buyerId) {
    return {
      buyerId,
      isCancelled: false,
      columns: '[Rough]'
    }
  }

  function getSellerTransactionParams(sellerId) {
    return {
      sellerId,
      isCancelled: false,
      columns: '[Rough]'
    }
  }

  function getAdminTransactionParams(orgId) {
    return {
      orgId,
      columns: '[Rough]'
    }
  }

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    refreshOrders(!organizationId || !saleId || !hasPermission(reportActions.getBuyerFeedback))
  }, [organizationId, saleId, permissionsAdminCache])
  useEffect(() => {
    if (!permissionsAdminCache.size) return
    refreshTransactions(!organizationId)
  }, [organizationId, permissionsAdminCache])
  useEffect(() => {
    resetChartSelections()
  }, [organizationId, saleId])

  useEffect(() => {
    if (transactionsTypes.some(({ value, disabled }) => value === 'buyer' && !disabled)) setTransactionsType('buyer')
    else if (transactionsTypes.some(({ value, disabled }) => value === 'seller' && !disabled)) setTransactionsType('seller')
  }, [transactionsTypes])
  useEffect(() => {
    setTransactions({ buyer: buyerTransactions, seller: sellerTransactions, admin: adminTransactions, adminByRole: adminTransactionsByRole })
  }, [buyerTransactions, sellerTransactions, adminTransactions, adminTransactionsByRole])

  function refreshOrders(reset = false) {
    if (reset) {
      setOrders()
      setSelectedOrders()
      return
    }

    return reportActions.getBuyerFeedback('json', { buyerId: organizationId, saleId })
    .then((result) => {
      if (!result?.data?.data) return refreshOrders(true)
      const orders = result.data.data.sort((a, b) => b.updatedAt !== a.updatedAt
        ? moment(b.updatedAt) - moment(a.updatedAt) : b.id - a.id
      )
      setOrders(orders)
      setSelectedOrders(orders.slice(0, defaultSelectedOrders).map(({ id }) => id))
    })
    .catch(console.error)
  }
  function refreshTransactions(reset = false) {
    if (reset) return setTransactions()

    if (hasAdmin(transactionActions.getAdminTransactions)) {
      setAdminTransactionParams(getAdminTransactionParams(organizationId))
      getAdminTransactionsList(getAdminTransactionParams(organizationId))
    } else {
      setBuyerTransactionParams(getBuyerTransactionParams(organizationId))
      setSellerTransactionParams(getSellerTransactionParams(organizationId))
      getBuyerTransactionsList(getBuyerTransactionParams(organizationId))
      getSellerTransactionsList(getSellerTransactionParams(organizationId))
    }
  }

  function resetChartSelections() {
    setClickedLine()
    setChartHoveredLine()
  }

  function handleDateRangeChange(e) {
    const target = e.target || e.currentTarget
    if (target) setDateRange(prevDate => ({ ...prevDate, [target.name]: target.value }))
  }

  function handleFilterClick() {
    setModal({
      id: 'dashboardChartsOrderFilter',
      title: 'Select Orders',
      customMessageRenderer: () => <OrderFilterList
        orders={orders}
        selectedOrders={selectedOrders}
        orgsList={orgsList}
        isAdmin={hasAdmin(organizationActions.getOrganizationList)}
        tableInstanceRef={tableInstanceRef}
        handleSubmit={() =>
          tableInstanceRef?.current?.tableInstance?.selectedFlatRows
          && tableInstanceRef?.current?.tableInstance?.selectedFlatRows.length
          && setSelectedOrders(tableInstanceRef.current.tableInstance.selectedFlatRows.map(({ original: { id } }) => id))
        }
      />,
      customButtonsRenderer: () => null,
      className: 'dashboard-charts__order-filter-modal'
    })
  }

  function getLineDescription(line) {
    const maxColour = coloursMap?.[line.maxColour] ?? ''
    const minColour = coloursMap?.[line.minColour] ?? ''
    const maxClarity = claritiesMap?.[line.maxClarity] ?? ''
    const minClarity = claritiesMap?.[line.minClarity] ?? ''
    return `${line.minWeight} - ${line.maxWeight}, ${maxColour} - ${minColour}, ${maxClarity} - ${minClarity}, ${line.minFluorescence} - ${line.maxFluorescence}`
  }

  function getLineQuantityDescription(line) {
    if (line.quantityType === 'TOPS' && !line.quantityFilled) return '(Tops)'
    else if (line.quantityType === 'TOPS') return `${line.quantityFilled} filled (Tops)`
    else if (line.quantityType === 'PER_SALE' && !line.quantityFilled) return `${line.quantity || 0}/sale`
    else if (line.quantityType === 'PER_SALE') return `${line.quantityFilled} filled - ${line.quantity}/sale`
    else if (!line.quantityFilled) return `${line.quantity || 0} requested`
    else return `${line.quantityFilled} / ${line.quantity} filled`
  }

  return (
    <div className="dashboard-charts">
      {
        transactions && (
          <div className="dashboard-charts__row">
            <div className="dashboard-charts__container">
              <div className="dashboard-charts__header">
                <div className="dashboard-charts__label">
                  Sales
                </div>
                <div className="dashboard-charts__label-right">
                  {transactionsTypes.length > 1 && (
                    <div className="sale-history-chart__radio">
                      <RadioGroup
                        name='transactionsType'
                        options={transactionsTypes.reduce((tabs, type) => tabs.concat({
                          label: type.label,
                          name: type.value,
                          value: type.value,
                          disabled: type.disabled
                        }), [])}
                        value={transactionsType}
                        onChange={(e) => setTransactionsType((e.target || e.currentTarget)?.value)}
                      />
                    </div>
                  )}
                  <div className="sale-history-chart__dates">
                    <TextInput
                      name='startDate'
                      type='date'
                      value={dateRange?.startDate}
                      onChange={handleDateRangeChange}
                      classNames={['sale-history-chart__dates-input']}
                    /> -
                    <TextInput
                      name='endDate'
                      type='date'
                      value={dateRange?.endDate}
                      onChange={handleDateRangeChange}
                      classNames={['sale-history-chart__dates-input']}
                    />
                  </div>
                </div>
              </div>
              <div className="dashboard-charts__chart">
                <SaleHistoryChart
                  transactions={salesHistoryTransactions}
                  chartColours={chartColours}
                  dataKey={transactionsTypes.find(type => type.value === transactionsType)?.dataKey}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              </div>
            </div>
          </div>
        )
      }
      {
        orders && (
          <>
            <hr/>
            <div className="dashboard-charts__row">
              <div className="dashboard-charts__container">
                <div className="dashboard-charts__header">
                  <div className="dashboard-charts__label">
                    Orders
                    <InfoTip name="dashboardChartsOrder" />
                    <Button iconName="filter" typeVariant="icon" noOutline={true} onClick={handleFilterClick}/>
                  </div>
                </div>
                <div className="dashboard-charts__group">
                  <div className="dashboard-charts__chart">
                    <CurrentOrderChart
                      lines={filteredLines}
                      getLineDescription={getLineDescription}
                      getLineQuantityDescription={getLineQuantityDescription}
                      chartColours={chartColours}
                      onClick={setClickedLine}
                      onHover={setChartHoveredLine}
                    />
                  </div>
                  <div className="dashboard-charts__chart">
                    <OrderFeedbackChart
                      lines={filteredLines}
                      getLineDescription={getLineDescription}
                      getLineQuantityDescription={getLineQuantityDescription}
                      chartColours={chartColours}
                      activeLine={chartHoveredLine}
                      onClick={setClickedLine}
                      onHover={setChartHoveredLine}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div className="dashboard-charts__row">
              <div className="dashboard-charts__container">
                <div className="dashboard-charts__header">
                  <div className="dashboard-charts__label">
                    Color &#038; Clarity Breakdown
                    <div className="dashboard-charts__label-description">Click on any order line above to see color and clarity breakdowns</div>
                  </div>
                  <div className="dashboard-charts__label-right">
                    <div className="order-breakdown-chart__radio">
                      <RadioGroup
                        name='orderDetailType'
                        options={[
                          { name: 'colour', value: 'colour', label: 'Color' },
                          { name: 'clarity', value: 'clarity', label: 'Clarity' },
                          { name: 'groups', value: 'groups', label: 'Grouped' }
                        ]}
                        value={orderDetailType}
                        onChange={(e) => setOrderDetailType((e.target || e.currentTarget)?.value)}
                        disabled={!clickedLine}
                      />
                    </div>
                  </div>
                </div>
                <div className="dashboard-charts__chart">
                  <div className="dashboard-charts__chart-title">{clickedLineLabel}</div>
                  <OrderBreakdownChart
                    lines={filteredLines}
                    activeLine={clickedLine}
                    chartColours={chartColours}
                    orderDetailType={orderDetailType}
                    coloursMap={coloursMap}
                    claritiesMap={claritiesMap}
                  />
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  )
}

DashboardCharts.propTypes = {
  orgsList: PropTypes.arrayOf(PropTypes.object),
  organizationId: PropTypes.string,
  saleId: PropTypes.string
}

export default DashboardCharts
