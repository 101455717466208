import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@organisms'
import { RoughCommentIcon, RoughCommentModal } from '@molecules'
import { ConfirmationModal } from '@templates'
import PriceUploadModal from '../../rough_stone/inc/priceUploadModal'
import { useToast } from '@hooks'
import { roughStoneActions } from '@actions'
import { useHistory, NavLink } from 'react-router-dom'
import Excel from 'exceljs/dist/es5/exceljs.browser'
import { useAuthStore, useGlobalsStore, useRoughStoneStore, usePlanningStore, useModalStore } from '@stores'
import UploadAdvFilesModal from '../../rough_stone/uploadAdvFilesModal'
import MultiRoughsCommentsModal from '../../rough_stone/inc/multiRoughsCommentsModal'
import MultiRoughsUpdateModal from '../../rough_stone/inc/multiRoughsUpdateModal'
import { arrayUtils, errorUtils, fileUtils, numberUtils, textUtils } from '@utils'
import { COMMON } from '@constants'
import PricingAdjustmentModal from '../../rough_stone/pricingAdjustmentModal'
import CloneRoughStonesModal from '../../rough_stone/cloneRoughStonesModal'

const FILTERS = {
  UNSOLD: { condition: 'ACTIVE', 'status.min': 'ROUGH_INCOMPLETE', 'status.max': 'ROUGH_SUBMITTED' },
  SOLD: { condition: 'ACTIVE', 'status.min': 'ROUGH_SOLD', 'status.max': 'ROUGH_ACCEPTED' },
  ARCHIVED: { condition: 'ARCHIVED' }
}
const TABS = {
  UNSOLD: 'unsold',
  SOLD: 'sold',
  ARCHIVED: 'archived'
}

function AssortmentRoughList({ assortmentId, roughStoneParams, refreshAssortmentDetails }) {
  const [openModal, setOpenModal] = useState({ name: false, selectedRows: [] })
  const [openCommentsModal, setOpenCommentModal] = useState({ open: false, rough: null })
  const [columns, setColumns] = useState([])
  const [activeTab, setActiveTab] = useState({ id: TABS.UNSOLD })
  const { hasAdmin, hasPermission, permissionsAdminCache } = useAuthStore()
  const {
    roughTingesMap,
    roughTinges,
    roughStatusesMap,
    roughStatuses,
    roughColoursMap,
    roughColours,
    roughQCApprovedMap,
    getRoughTinges,
    getRoughStatuses,
    getRoughColours,
    getRoughQCStatuses
  } = useGlobalsStore()
  const initialFilter = FILTERS.UNSOLD
  const uploadAdvFilesModalParams = getRoughsParams(initialFilter)
  const [localRoughsParams, setLocalRoughsParams] = useState(initialFilter)
  const { setModal } = useModalStore(state => state)
  const {
    roughStonesList: {
      [JSON.stringify(localRoughsParams)]: roughStonesList,
      [JSON.stringify(uploadAdvFilesModalParams)]: uploadAdvRoughStonesList
    },
    getRoughStonesList,
    removeAllRoughStonesListItems
  } = useRoughStoneStore(store => store)
  const {
    removeAllPlannedStonesListItems
  } = usePlanningStore(store => store)

  const allowedStatuses = useMemo(() => getRoughAllowedStatuses(), [roughStatusesMap])

  useEffect(() => {
    getRoughTinges()
    getRoughStatuses()
    getRoughColours()
    getRoughQCStatuses()
  }, [])

  function getRoughsParams(filters = initialFilter) {
    return { ...filters, ...roughStoneParams }
  }

  function refreshRoughList(newParams) {
    const params = getRoughsParams(newParams)
    setLocalRoughsParams(params)
    getRoughStonesList(params)
  }

  useEffect(() => {
    refreshRoughList(localRoughsParams)
  }, [roughStoneParams])
  useEffect(() => {
    if (roughStonesList?.cacheInvalid) refreshRoughList(localRoughsParams)
  }, [roughStonesList?.cacheInvalid])

  useEffect(() => {
    setColumns([
      {
        Header: 'Clara ID',
        accessor: 'id',
        dataType: 'number',
        Cell: cellInfo => {
          return <NavLink
            className='link'
            to={`/roughstones/${cellInfo.row.original.id}`}
            id={cellInfo.row.original.id}>
            {cellInfo.row.values.id}
          </NavLink>
        }
      },
      {
        Header: 'Seller Stone Name',
        accessor: 'sellerStoneName',
        dataType: 'string'
      },
      {
        Header: 'Weight',
        accessor: 'weight',
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Color',
        id: 'colour',
        accessor: row => roughColoursMap?.[row.colour] ?? null,
        dataType: 'string',
        filterType: 'checkbox',
        enums: roughColours?.map(rc => rc.description).concat([null])
      },
      {
        Header: 'Fluor',
        accessor: 'fluorescence',
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Tinge',
        id: 'tingeDescription',
        accessor: row => roughTingesMap?.[row.tinge] ?? null,
        dataType: 'string',
        filterType: 'checkbox',
        enums: roughTinges?.map(rt => rt.description).concat([null])
      },
      {
        Header: 'Country',
        id: 'country',
        accessor: row => row?.Country?.name,
        dataType: 'string'
      },
      {
        Header: 'Status',
        id: 'statusDescription',
        accessor: row => roughStatusesMap?.[row.status] ?? '',
        dataType: 'string',
        filterType: 'checkbox',
        enums: roughStatuses?.map(rs => rs.description)
      },
      {
        Header: 'Price Point',
        accessor: 'pricePoint',
        dataType: 'string'
      },
      {
        Header: 'Reserve ($/ct)',
        accessor: 'reservePpcOriginal',
        dataType: 'currency',
        filterType: 'numberRange',
        decimalScale: 2,
        fixedDecimalScale: true
      },
      {
        Header: 'Override Reserve ($/ct)',
        accessor: 'reservePpcOverride',
        dataType: 'currency',
        filterType: 'numberRange',
        decimalScale: 2,
        fixedDecimalScale: true
      },
      ...(hasAdmin(roughStoneActions.getRoughStoneList) ? [{
        Header: 'Location',
        accessor: 'location',
        dataType: 'string',
        filterType: 'checkbox'
      }] : []),
      ...(hasAdmin(roughStoneActions.getRoughStoneList) ? [{
        Header: 'QC Approved',
        id: 'qcStatus',
        accessor: row => roughQCApprovedMap?.[row.qcStatus] ?? '',
        dataType: 'string',
        filterType: 'checkbox',
        enums: roughQCApprovedMap ? Object.keys(roughQCApprovedMap)?.map(rqca => roughQCApprovedMap[rqca]) : []
      }] : []),
      {
        Header: 'Created At',
        accessor: 'createdAt',
        dataType: 'date',
        filterType: 'date'
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      },
      {
        Header: '',
        accessor: 'comments',
        dataType: '',
        disableSortBy: true,
        Cell: cellInfo => {
          return (
            <RoughCommentIcon
              comments={cellInfo?.row?.values?.comments}
              onClick={() => setOpenCommentModal({ open: true, rough: cellInfo?.row?.values })}
            />
          )
        }
      }

    ])
  }, [
    roughStatusesMap,
    roughStatuses,
    roughTinges,
    roughTingesMap,
    roughColours,
    roughColoursMap,
    roughQCApprovedMap,
    permissionsAdminCache
  ])

  const history = useHistory()
  function navigateTo(path, state) {
    history.push(path, state)
  }

  const { showSuccessToast, showInfoToast, showErrorToast } = useToast()
  function updateSucceeded(message = 'Stones updated', ids = []) {
    return function () {
      showSuccessToast(message, ids)
      removeStones(ids)
    }
  }

  function removeStones(ids) {
    removeAllRoughStonesListItems(ids)
    removeAllPlannedStonesListItems(ids, (plannedStone) => plannedStone?.Rough?.id)
  }

  const unsubmittedStatuses = ['ROUGH_NO_ADV', 'ROUGH_INCOMPLETE', 'ROUGH_NOT_SUBMITTED']
  const priceableStatuses = unsubmittedStatuses.concat(['ROUGH_SUBMITTED'])
  const archiveableStatuses = unsubmittedStatuses.concat(['ROUGH_ACCEPTED'])

  async function handleArchive(ids) {
    return roughStoneActions.setRoughCondition({ roughIds: ids, condition: 'ARCHIVED' })
    .then(refreshAssortmentDetails)
    .then(updateSucceeded(`Stone${ids.length > 1 ? 's have' : ' has'} been archived.`, ids))
  }

  async function handleUnarchive(ids) {
    return roughStoneActions.setRoughCondition({ roughIds: ids, condition: 'ACTIVE' })
    .then(refreshAssortmentDetails)
    .then(updateSucceeded(`Stone${ids.length > 1 ? 's have' : ' has'} been unarchived.`, ids))
  }

  async function handleRemove(ids) {
    return roughStoneActions.setRoughCondition({ roughIds: ids, condition: 'DELETED' })
    .then(refreshAssortmentDetails)
    .then(updateSucceeded(`Stone${ids.length > 1 ? 's have' : ' has'} been removed.`, ids))
  }

  async function handleUpdateMultipleStones([upds, opts]) {
    if (!upds.roughIds.length) return showInfoToast('No changes were recorded.')
    return roughStoneActions.updateMultipleRoughs(upds, opts).then(updateSucceeded(`Stone${upds.roughIds.length > 1 ? 's have' : ' has'} been updated.`, upds.roughIds))
  }

  const rowActions = [
    {
      actionName: 'archive',
      callback: (row) => handleArchive([row.id]),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE' && archiveableStatuses.includes(row.status)
    },
    {
      actionName: 'unarchive',
      callback: (row) => handleUnarchive([row.id]),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      callback: (row) => handleRemove([row.id]),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition !== 'DELETED' && unsubmittedStatuses.includes(row.status)
    }
  ]

  const tableTabs = [
    {
      id: TABS.UNSOLD,
      label: 'Unsold',
      params: getRoughsParams(FILTERS.UNSOLD)
    },
    {
      id: TABS.SOLD,
      label: 'Sold',
      params: getRoughsParams(FILTERS.SOLD)
    },
    {
      id: TABS.ARCHIVED,
      label: 'Archived',
      params: getRoughsParams(FILTERS.ARCHIVED)
    }
  ]

  function getRoughAllowedStatuses() {
    if (Object.keys(roughStatusesMap).length) {
      const roughStatuses = Object.keys(roughStatusesMap)
      const findStatusIndex = hasAdmin(roughStoneActions.editRoughStone) ? 'ROUGH_SOLD' : 'ROUGH_SUBMITTED'
      const submittedIndex = roughStatuses.findIndex(status => status === findStatusIndex)
      return roughStatuses.slice(0, submittedIndex)
    }
  }

  function topBarSelectedInfo(rows) {
    const totalWeight = rows.reduce((tot, row) => tot + Number(row?.values?.weight ?? 0), 0)
    return rows.length ? <div>{numberUtils.numFmt(totalWeight, 3)} ct</div> : null
  }

  async function generatePrintingLabelExcel(data) {
    try {
      const workbook = new Excel.Workbook()
      workbook.creator = 'Clara'
      workbook.created = new Date()
      const sheet = workbook.addWorksheet('Clara Labels')
      const columns = [
        { header: 'Clara ID', key: 'id', width: 12, transform: val => Number(val) },
        { header: 'Stone Count', key: 'stoneCount', width: 12, transform: () => 1 },
        { header: 'Size', key: 'weightCategory' },
        { header: 'Carats', key: 'weight', width: 12, transform: val => Number(val) },
        { header: 'Color', key: 'colour', width: 12 },
        { header: 'Fluorescence', key: 'fluorescence', width: 12 },
        { header: 'Tinge', key: 'tinge', width: 12, transform: val => String(val).toLowerCase() === 'none' ? '' : val }
      ]
      const rows = fileUtils.getExcelRows(data, columns)
      sheet.columns = columns
      sheet.addRows(rows)
      return workbook.xlsx.writeBuffer().then(buffer => fileUtils.saveBufferExcel(buffer, `Clara Labels - ${textUtils.formatDate(new Date(), COMMON.DATE_FMT.REPORT)}.xlsx`))
    } catch (err) {
      console.error(err)
      showErrorToast(err?.message || 'There was an error writing the excel.')
    }
  }

  return (
    <div className='assortment-details__rough-list'>
      <Table
        title='Rough Stones'
        data={roughStonesList?.data}
        columns={columns}
        rowActions={rowActions}
        tableTabs={tableTabs}
        onTabChange={setActiveTab}
        getDataCallback={refreshRoughList}
        topBarSelectedInfo={topBarSelectedInfo}
        topBarActions={[
          {
            componentName: 'dropdown',
            enableOnSelect: true,
            options: [
              {
                label: 'Submit',
                isDisabled: rows => rows.some(row => row.original.status !== 'ROUGH_NOT_SUBMITTED'),
                callback: selectedRows => setOpenModal({ name: 'submit', selectedRows }),
                value: 'submit'
              },
              {
                label: 'Suspend Matching',
                isDisabled: rows => rows.some(row => row.original.status !== 'ROUGH_SUBMITTED'),
                callback: selectedRows => setOpenModal({ name: 'unsubmit', selectedRows }),
                value: 'unsubmit'
              },
              {
                label: 'Single Property Update',
                callback: (selectedRows) => setModal({
                  id: 'multiRoughsUpdate',
                  title: 'Single Property Update',
                  className: 'update-selected-stones-modal',
                  customMessageRenderer: (messageProps) => <MultiRoughsUpdateModal
                    roughStonesList={selectedRows}
                    {...messageProps}
                  />,
                  onSubmit: handleUpdateMultipleStones,
                  customButtonsRenderer: () => null
                }),
                value: 'multiRoughsUpdate',
                isDisabled: rows => rows.some(row => row.original.condition !== 'ACTIVE'),
                roles: hasPermission(roughStoneActions.updateMultipleRoughs)
              },
              {
                label: 'Excel Update',
                callback: (selectedRows) => navigateTo(`/assortments/${assortmentId}/roughstones/upload`, { roughId: arrayUtils.pickBy(selectedRows, 'id') }),
                isDisabled: rows => rows.some(row => row.original.condition !== 'ACTIVE'),
                value: 'multiRoughUpload'
              },
              {
                label: 'Pricing Adjustments',
                callback: selectedRows => setOpenModal({ name: 'pricingAdjustment', selectedRows, acceptedKeys: ['smooth', 'generic', 'adjust', 'reset'] }),
                value: 'pricingAdjustment'
              },
              {
                label: 'Add New Comment',
                isDisabled: rows => rows.some(row => row.original.condition !== 'ACTIVE'),
                callback: selectedRows => setOpenModal({ name: 'multiRoughsComments', selectedRows }),
                value: 'multiRoughsComments'
              },
              {
                label: 'Generate Printing Label',
                isDisabled: false,
                callback: generatePrintingLabelExcel,
                value: 'printLabel'
              },
              {
                label: 'Clone Stones',
                shouldDisplay: hasAdmin(roughStoneActions.cloneRoughStones),
                callback: selectedRows => setOpenModal({ name: 'cloneRoughStones', selectedRows }),
                value: 'cloneRoughStones'
              },
              {
                label: 'Archive',
                isDisabled: rows => rows.some(row => !archiveableStatuses.includes(row.original.status) || row.original.condition !== 'ACTIVE'),
                callback: selectedRows => setOpenModal({ name: 'archive', selectedRows }),
                value: 'archive'
              },
              {
                label: 'Unarchive',
                isDisabled: rows => rows.some(row => row.original.condition !== 'ARCHIVED'),
                callback: selectedRows => setOpenModal({ name: 'unarchive', selectedRows }),
                value: 'unarchive'
              },
              {
                label: 'Delete',
                isDisabled: rows => rows.some(row => !unsubmittedStatuses.includes(row.original.status) || row.original.condition === 'DELETED'),
                callback: selectedRows => setOpenModal({ name: 'delete', selectedRows }),
                value: 'delete'
              }
            ]
          },
          {
            callback: () => navigateTo(`/assortments/${assortmentId}/roughstones/create`),
            label: 'Create Rough Stone',
            enableOnSelect: false
          },
          ...(activeTab?.id === TABS.UNSOLD ? [{
            callback: () => navigateTo(`/assortments/${assortmentId}/roughstones/upload`),
            label: 'Excel Update',
            enableOnSelect: false
          }] : []),
          ...(activeTab?.id === TABS.UNSOLD ? [{
            label: 'Upload Multiple ADV',
            callback: () => setModal({
              id: 'assortmentRoughStoneListAdvFileUpload',
              title: 'Upload Multiple ADV',
              customMessageRenderer: ({ handleCancel, handleClose }) => <UploadAdvFilesModal
                progressListName='assortmentRoughStoneListAdvFileUpload'
                roughStonesList={uploadAdvRoughStonesList?.data}
                allowedStatuses={allowedStatuses}
                onUploadCallback={(rough) => removeStones([rough.id])}
                handleCancel={handleCancel}
                handleClose={handleClose}
                required={true}
              />,
              customButtonsRenderer: () => <></>
            })
          }] : [])
        ]}
      />
      <PricingAdjustmentModal openModal={openModal} setOpenModal={setOpenModal} updateSucceeded={updateSucceeded} priceableStatuses={priceableStatuses} />
      <ConfirmationModal
        open={openModal.name === 'submit'}
        title='Submit Stones'
        message={`Submit ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''} to be planned and matched?`}
        onClose={() => setOpenModal({ name: false, selectedRows: [] })}
        onSubmit={async () => {
          return roughStoneActions.updateMultipleRoughs(
            {
              roughIds: openModal.selectedRows.map(row => row.id),
              status: 'ROUGH_SUBMITTED'
            },
            { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
          )
          .then(updateSucceeded('Stones submitted', openModal.selectedRows.map(row => row.id)))
        }}
      />
      <ConfirmationModal
        open={openModal.name === 'unsubmit'}
        title='Pause Stones'
        message={`Pause ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''} from sales until further notice?`}
        onClose={() => setOpenModal({ name: false, selectedRows: [] })}
        onSubmit={async () => {
          return roughStoneActions.updateMultipleRoughs(
            {
              roughIds: openModal.selectedRows.map(row => row.id),
              status: 'ROUGH_NOT_SUBMITTED'
            },
            { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
          )
          .then(updateSucceeded('Stones paused', openModal.selectedRows.map(row => row.id)))
        }}
      />
      <ConfirmationModal
        open={openModal.name === 'archive'}
        title='Archive Stones'
        message={`Archive ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''}?`}
        onClose={() => setOpenModal({ name: false, selectedRows: [] })}
        onSubmit={() => handleArchive(openModal.selectedRows.map(row => row.id))}
        closeOnFail={false}
      />
      <ConfirmationModal
        open={openModal.name === 'unarchive'}
        title='Unarchive Stones'
        message={`Unarchive ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''}?`}
        onClose={() => setOpenModal({ name: false, selectedRows: [] })}
        onSubmit={() => handleUnarchive(openModal.selectedRows.map(row => row.id))}
        closeOnFail={false}
      />
      <ConfirmationModal
        open={openModal.name === 'delete'}
        title='Delete Stones'
        message={`Permanently delete ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''}?`}
        onClose={() => setOpenModal({ name: false, selectedRows: [] })}
        onSubmit={() => handleRemove(openModal.selectedRows.map(row => row.id))}
        closeOnFail={false}
      />
      <PriceUploadModal
        open={openModal.name === 'uploadPrices'}
        onClose={() => setOpenModal({ name: false, selectedRows: [] })}
        onSubmit={async newPrices => {
          return roughStoneActions.updateMultipleRoughs(
            {
              roughIds: Object.keys(newPrices),
              reservePpcOverride: { list: newPrices }
            },
            { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
          )
          .then(updateSucceeded('Prices set', openModal.selectedRows.map(row => row.id)))
        }}
      />
      <RoughCommentModal
        open={openCommentsModal.open}
        onClose={() => {
          setOpenCommentModal({ open: false, rough: null })
          refreshRoughList(localRoughsParams)
        }}
        rough={openCommentsModal.rough}
      />
      <CloneRoughStonesModal
        open={openModal.name === 'cloneRoughStones'}
        onClose={() => {
          setOpenModal({ open: false, selectedRows: [] })
          refreshRoughList(localRoughsParams)
        }}
        roughStones={openModal.selectedRows}
      />
      <MultiRoughsCommentsModal open={openModal?.name === 'multiRoughsComments'} onClose={() => setOpenModal({ name: false, selectedRows: [] })} dataSet={openModal?.selectedRows} refreshStoneList={() => refreshRoughList(localRoughsParams)} />
    </div>
  )
}

AssortmentRoughList.propTypes = {
  assortmentId: PropTypes.string,
  roughStoneParams: PropTypes.object,
  refreshAssortmentDetails: PropTypes.func
}

AssortmentRoughList.defaultProps = {
  assortmentId: null,
  roughStoneParams: {}
}

export default AssortmentRoughList
