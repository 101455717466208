import React, { useEffect, useState, useMemo } from 'react'
import { Icon } from '@atoms'
import { Table } from '@organisms'
import { RoughCommentIcon } from '@molecules'
import { useHistory, NavLink } from 'react-router-dom'
import { useGlobalsStore, useAuthStore, useRoughStoneStore, usePlanningStore, useModalStore } from '@stores'
import { useToast } from '@hooks'
import { roughStoneActions, organizationActions, bidActions, saleActions } from '@actions'
import { arrayUtils, numberUtils, objectUtils } from '@utils'
import UploadAdvFilesModal from './uploadAdvFilesModal'
import moment from 'moment'
import RoughStoneListModals from './roughStoneListModals'
import useRoughStoneListReports from './roughStoneListReports'
import MultiRoughsUpdateModal from './inc/multiRoughsUpdateModal'

const STONE_KEYS = {
  ROUGH: 'rough',
  BIDS: 'bids'
}
const FILTERS = {
  UNSOLD: { key: 'unsold', stoneKey: STONE_KEYS.ROUGH, condition: 'ACTIVE', 'status.min': 'ROUGH_INCOMPLETE', 'status.max': 'ROUGH_SUBMITTED' },
  SOLD: { key: 'sold', stoneKey: STONE_KEYS.ROUGH, condition: 'ACTIVE', 'status.min': 'ROUGH_SOLD', 'status.max': 'ROUGH_ACCEPTED', adminColumns: ['Transaction'] },
  ARCHIVED: { key: 'archived', stoneKey: STONE_KEYS.ROUGH, condition: 'ARCHIVED' },
  BIDS: { key: 'bids', stoneKey: STONE_KEYS.BIDS, condition: 'ACTIVE', 'status.min': 'ROUGH_INCOMPLETE', 'status.max': 'ROUGH_SUBMITTED' }
}

function RoughStoneList() {
  const initialFilter = FILTERS.UNSOLD
  const { hasAdmin, hasPermission, permissionsAdminCache, orgId } = useAuthStore(state => state)
  const [tabRequestParams, setTabRequestParams] = useState(initialFilter)
  const [roughStoneParams, setRoughStoneParams] = useState()
  const [localRoughList, setLocalRoughList] = useState([])
  const [openModal, setOpenModal] = useState({ name: false, selectedRows: [] })
  const [isReportsModalOpen, setIsReportsModalOpen] = useState(false)
  const [bidsList, setBidsList] = useState([])
  const [allSales, setAllSales] = useState({})
  const [activeSaleId, setActiveSaleId] = useState(null)
  const [galaxyFees, setGalaxyFees] = useState([])
  const [openCommentsModal, setOpenCommentModal] = useState({ open: false, rough: null })
  const unsubmittedStatuses = ['ROUGH_NO_ADV', 'ROUGH_INCOMPLETE', 'ROUGH_NOT_SUBMITTED']
  const priceableStatuses = unsubmittedStatuses.concat(['ROUGH_SUBMITTED'])
  const archiveableStatuses = unsubmittedStatuses.concat(['ROUGH_ACCEPTED'])
  const uploadAdvFilesModalParams = getRoughParams(initialFilter)
  const stoneKey = tabRequestParams?.stoneKey
  const { setModal } = useModalStore()
  const { showSuccessToast, showInfoToast } = useToast()
  const history = useHistory()

  const { generateRoughBidsExcel, generatePrintingLabelExcel } = useRoughStoneListReports()

  const {
    orgsList: { all: orgsList },
    orgsMap: { all: orgsMap },
    roughSellersList: { all: roughSellersList },
    roughStatuses,
    roughStatusesMap,
    roughTingesMap,
    roughTinges,
    roughColoursMap,
    roughColours,
    roughQCApprovedMap,
    getOrgsList,
    getRoughSellersList,
    getRoughStatuses,
    getRoughTinges,
    getRoughColours,
    bidStatuses,
    getBidStatuses,
    getRoughQCStatuses
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getRoughStatuses()
    getBidStatuses()
    getRoughTinges()
    getOrgsList()
    getRoughColours()
    getRoughQCStatuses()
    roughStoneActions.getGalaxyFees().then(res => setGalaxyFees(res.data.data))
  }, [])

  useEffect(() => {
    refreshBids()
  }, [activeSaleId])

  function refreshBids() {
    if (!activeSaleId) return
    return bidActions.getBids(activeSaleId).then(response => setBidsList(response.data.data))
  }

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    if (hasPermission(roughStoneActions.getRoughSellers)) getRoughSellersList()
  }, [permissionsAdminCache])

  useEffect(() => {
    saleActions.getSales({ columns: '[id, name, status, updatedAt]' })
      .then(result => {
        setAllSales(arrayUtils.toMap(result.data.data, (x) => x.id, 'name'))

        const activeSales = result.data.data
        .filter(sale => sale.status === 'MATCHING')
        .sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
        setActiveSaleId(activeSales[0]?.id)
      })
  }, [])

  const allowedStatuses = useMemo(() => getRoughAllowedStatuses(), [roughStatusesMap])
  const bidsMapByRoughId = useMemo(() => arrayUtils.toMap(bidsList || [], (b) => b.roughId), [bidsList])

  function getRoughParams(reqFilters = initialFilter) {
    const filters = objectUtils.omit(reqFilters, ['key', 'stoneKey', 'columns', 'adminColumns'])
    return {
      ...filters,
      columns: [
        'id',
        'sellerStoneName',
        'assortmentId',
        'Assortment',
        'Country',
        'Mine',
        'Pipe',
        'Batch',
        'File',
        'condition',
        'status',
        'createdAt',
        'updatedAt',
        'sellerId',
        'weight',
        'weightCategory',
        'colour',
        'fluorescence',
        'tinge',
        'pricePoint',
        'reservePpc',
        'reservePpcOriginal',
        'reservePpcOverride',
        'genericPrice',
        'comments',
        'qcStatus',
        'location',
        'type'
      ]
      .concat(reqFilters.columns || [])
      .concat(hasAdmin(roughStoneActions.getRoughStoneList) ? reqFilters.adminColumns || [] : [])
    }
  }

  const {
    roughStonesList: {
      [roughStoneParams ? JSON.stringify(roughStoneParams) : 'all']: roughStonesList,
      [uploadAdvFilesModalParams ? JSON.stringify(uploadAdvFilesModalParams) : 'all']: uploadAdvRoughStonesList
    },
    getRoughStonesList,
    removeAllRoughStonesListItems
  } = useRoughStoneStore(store => store)
  const { removeAllPlannedStonesListItems } = usePlanningStore(store => store)

  useEffect(() => {
    if (hasStoneKey([STONE_KEYS.BIDS])) {
      setLocalRoughList(getRoughBids())
    } else {
      setLocalRoughList(roughStonesList?.data)
    }
  }, [tabRequestParams, roughStonesList, bidsList])

  useEffect(() => {
    if (!permissionsAdminCache?.size) return
    refreshStoneList()
  }, [tabRequestParams, permissionsAdminCache])
  useEffect(() => {
    if (roughStonesList?.cacheInvalid) refreshStoneList()
  }, [roughStonesList?.cacheInvalid])

  function navigateTo(path, state) {
    history.push(path, state)
  }

  function hasFilterKey(acceptedKeys = []) {
    return acceptedKeys.map(({ key }) => key).includes(tabRequestParams.key)
  }
  function hasStoneKey(acceptedKeys = []) {
    return acceptedKeys.includes(tabRequestParams.stoneKey)
  }
  function filterBy({ tabs = true, roles = true }) {
    const display = { tabs: true, roles: true }
    if (Array.isArray(tabs)) display.tabs = hasFilterKey(tabs)
    else display.tabs = tabs
    display.roles = roles

    return display.tabs && display.roles
  }

  const topBarActions = useMemo(() => ([
    {
      componentName: 'dropdown',
      enableOnSelect: true,
      options: [
        {
          label: 'Submit',
          isDisabled: rows => rows.some(row => row.original.status !== 'ROUGH_NOT_SUBMITTED'),
          callback: selectedRows => setOpenModal({ name: 'submit', selectedRows }),
          value: 'submit'
        },
        {
          label: 'Suspend Matching',
          isDisabled: rows => rows.some(row => row.original.status !== 'ROUGH_SUBMITTED'),
          callback: selectedRows => setOpenModal({ name: 'unsubmit', selectedRows }),
          value: 'unsubmit'
        },
        {
          label: 'Single Property Update',
          callback: (selectedRows) => setModal({
            id: 'multiRoughsUpdate',
            title: 'Single Property Update',
            className: 'update-selected-stones-modal',
            customMessageRenderer: (messageProps) => <MultiRoughsUpdateModal
              roughStonesList={selectedRows}
              {...messageProps}
            />,
            onSubmit: handleUpdateMultipleStones,
            customButtonsRenderer: () => null
          }),
          value: 'multiRoughsUpdate',
          isDisabled: rows => rows.some(row => row.original.condition !== 'ACTIVE'),
          roles: hasPermission(roughStoneActions.updateMultipleRoughs)
        },
        {
          label: 'Excel Update',
          callback: (selectedRows) => navigateTo('/roughstones/upload', { roughId: arrayUtils.pickBy(selectedRows, 'id') }),
          isDisabled: rows => rows.some(row => row.original.condition !== 'ACTIVE'),
          value: 'multiRoughUpload'
        },
        {
          label: 'Pricing Adjustments',
          callback: selectedRows => setOpenModal({ name: 'pricingAdjustment', selectedRows, acceptedKeys: ['smooth', 'smooth2', 'generic', 'adjust', 'reset'] }),
          value: 'pricingAdjustment'
        },
        {
          label: 'Add New Comment',
          isDisabled: rows => rows.some(row => row.original.condition !== 'ACTIVE'),
          callback: selectedRows => setOpenModal({ name: 'multiRoughsComments', selectedRows }),
          value: 'multiRoughsComments'
        },
        {
          label: 'Generate Printing Label',
          isDisabled: false,
          callback: generatePrintingLabelExcel,
          value: 'printLabel'
        },
        {
          label: 'Clone Stones',
          callback: selectedRows => setOpenModal({ name: 'cloneRoughStones', selectedRows }),
          value: 'cloneRoughStones',
          roles: hasAdmin(roughStoneActions.cloneRoughStones)
        },
        {
          label: 'Archive',
          isDisabled: rows => rows.some(row => !archiveableStatuses.includes(row.original.status) || row.original.condition !== 'ACTIVE'),
          callback: selectedRows => setOpenModal({ name: 'archive', selectedRows }),
          value: 'archive'
        },
        {
          label: 'Unarchive',
          isDisabled: rows => rows.some(row => row.original.condition !== 'ARCHIVED'),
          callback: selectedRows => setOpenModal({ name: 'unarchive', selectedRows }),
          value: 'unarchive'
        },
        {
          label: 'Delete',
          isDisabled: rows => rows.some(row => !unsubmittedStatuses.includes(row.original.status) || row.original.condition === 'DELETED'),
          callback: selectedRows => setOpenModal({ name: 'delete', selectedRows }),
          value: 'delete'
        }
      ]?.filter(filterBy),
      tabs: [FILTERS.UNSOLD, FILTERS.SOLD, FILTERS.ARCHIVED]
    },
    {
      componentName: 'dropdown',
      enableOnSelect: true,
      options: [
        {
          label: 'Approve',
          callback: selectedRows => changeBidStatus(selectedRows, 'BID_ACCEPTED'),
          value: 'approveBid'
        },
        {
          label: 'Reject',
          callback: selectedRows => changeBidStatus(selectedRows, 'BID_REJECTED'),
          value: 'rejectBid'
        },
        {
          label: 'Reset',
          callback: selectedRows => changeBidStatus(selectedRows, 'BID_OFFERED'),
          value: 'resetBid'
        },
        {
          label: 'Export',
          callback: (data) => generateRoughBidsExcel(data, { orgsList, galaxyFees }),
          value: 'exportActiveBids'
        }
      ],
      tabs: [FILTERS.BIDS]
    },
    {
      label: 'Upload Prices',
      callback: () => setOpenModal({ name: 'uploadPrices', selectedRows: [] }),
      tabs: [FILTERS.UNSOLD]
    },
    {
      label: 'Upload Multiple ADV',
      callback: () => setModal({
        id: 'uploadMultiAdvModal',
        title: 'Upload Multiple ADV',
        customMessageRenderer: ({ handleClose, handleCancel }) => <UploadAdvFilesModal
          progressListName='roughStoneListAdvFileUpload'
          roughStonesList={uploadAdvRoughStonesList?.data}
          allowedStatuses={allowedStatuses}
          onUploadCallback={(rough) => removeStones([rough.id])}
          handleClose={handleClose}
          handleCancel={handleCancel}
          required={true}
        />,
        customButtonsRenderer: () => <></>
      }),
      tabs: [FILTERS.UNSOLD]
    },
    {
      label: 'Excel Update',
      callback: () => navigateTo('/roughstones/upload'),
      tabs: [FILTERS.UNSOLD]
    },
    {
      label: 'Reports',
      callback: () => setIsReportsModalOpen(true),
      tabs: [FILTERS.UNSOLD, FILTERS.SOLD, FILTERS.ARCHIVED]
    },
    {
      label: 'Upload Bids Responses',
      callback: () => setOpenModal({ name: 'uploadBidsResponse', selectedRows: [] }),
      typeVariant: 'secondary',
      tabs: [FILTERS.BIDS]
    },
    {
      label: 'Confirm Bids',
      callback: () => {
        const totals = localRoughList.reduce((tot, row) => {
          if (row.bid.confirmed || row.bid.status === 'BID_OFFERED') return tot
          if (row.bid.status === 'BID_REJECTED') {
            return ({
              ...tot,
              rejected_count: tot.rejected_count + 1,
              rejected_weight: tot.rejected_weight + Number(row?.weight ?? 0),
              rejected_price: tot.rejected_price + Number(row?.bid?.offerPrice ?? 0)
            })
          } else {
            return ({
              ...tot,
              approved_count: tot.approved_count + 1,
              approved_weight: tot.approved_weight + Number(row?.weight ?? 0),
              approved_price: tot.approved_price + Number(row?.bid?.offerPrice ?? 0)
            })
          }
        }, { approved_weight: 0, approved_count: 0, approved_price: 0, rejected_weight: 0, rejected_count: 0, rejected_price: 0 })
        setModal({
          id: 'confirmBids',
          title: 'Confirm Bids',
          message: <>You are about to approve the following bids:<br/><br/>
            <b>Sell:</b><br/>
            Total stones: {numberUtils.numFmt(totals.approved_count)}<br/>
            Total weight: {numberUtils.numFmt(totals.approved_weight, 3)} ct<br/>
            Total selling price: {numberUtils.numFmt(totals.approved_price, 2, { prefix: '$', thousandSeperator: true })}<br/>
            <br/>
            <b>Do not Sell:</b><br/>
            Total stones: {numberUtils.numFmt(totals.rejected_count)}<br/>
            Total weight: {numberUtils.numFmt(totals.rejected_weight, 3)} ct<br/>
            Total selling price: {numberUtils.numFmt(totals.rejected_price, 2, { prefix: '$', thousandSeperator: true })}<br/>
            <br/>
            Are you sure you want to Confirm your selections?</>,
          buttonOptions: {
            submitText: 'Yes'
          },
          onSubmit: onConfirmBids
        })
      },
      typeVariant: 'primary',
      disabled: bidsList?.some(bid => bid.status === 'BID_OFFERED') || bidsList?.every(bid => bid.confirmed),
      tabs: [FILTERS.BIDS]
    }
  ]?.filter(filterBy)), [permissionsAdminCache, tabRequestParams, localRoughList, bidsList, priceableStatuses, archiveableStatuses, unsubmittedStatuses, allowedStatuses, uploadAdvRoughStonesList])

  const rowActions = useMemo(() => ([{
    actionName: 'approve',
    iconName: 'check',
    callback: (row) => changeBidStatus(row, 'BID_ACCEPTED'),
    shouldRefresh: false,
    shouldConfirm: false,
    tabs: [FILTERS.BIDS]
  },
  {
    actionName: 'reject',
    iconName: 'closeCircle',
    callback: (row) => changeBidStatus(row, 'BID_REJECTED'),
    shouldRefresh: false,
    shouldConfirm: false,
    tabs: [FILTERS.BIDS]
  },
  {
    actionName: 'reset',
    iconName: 'undo',
    callback: (row) => changeBidStatus(row, 'BID_OFFERED'),
    shouldDisplay: (row) => row?.bid?.status !== 'BID_OFFERED',
    shouldRefresh: false,
    shouldConfirm: false,
    tabs: [FILTERS.BIDS]
  },
  {
    actionName: 'archive',
    callback: (row) => handleArchive([row.id]),
    shouldConfirm: true,
    shouldDisplay: (row) => row.condition === 'ACTIVE' && archiveableStatuses.includes(row.status),
    tabs: [FILTERS.UNSOLD, FILTERS.SOLD]
  },
  {
    actionName: 'unarchive',
    callback: (row) => handleUnarchive([row.id]),
    shouldConfirm: true,
    shouldDisplay: (row) => row.condition === 'ARCHIVED',
    tabs: [FILTERS.ARCHIVED]
  },
  {
    actionName: 'remove',
    callback: (row) => handleRemove([row.id]),
    shouldConfirm: true,
    shouldDisplay: (row) => row.condition !== 'DELETED' && unsubmittedStatuses.includes(row.status),
    tabs: [FILTERS.UNSOLD, FILTERS.SOLD, FILTERS.ARCHIVED]
  }
  ]?.filter(filterBy)), [permissionsAdminCache, tabRequestParams, archiveableStatuses, unsubmittedStatuses])

  const columns = useMemo(() => {
    const cols = {
      [STONE_KEYS.ROUGH]: ['id', 'sellerStoneName', 'assortment', 'sellerName', 'weight', 'statusDescription', 'colour', 'fluorescence', 'tingeDescription', 'country', 'reservePpcOriginal', 'reservePpcOverride', 'location', 'qcStatus', 'buyerId', 'saleId', 'createdAt', 'updatedAt', 'comments'],
      [STONE_KEYS.BIDS]: ['id', 'sellerStoneName', 'assortment', 'sellerName', 'weight', 'colour', 'fluorescence', 'tingeDescription', 'bidStatus', 'bidConfirmed', 'offerPrice', 'reservePpc']
    }
    const allColumns = {
      id: {
        Header: 'Clara ID',
        accessor: 'id',
        id: 'id',
        dataType: 'string',
        Cell: cellInfo => {
          return <NavLink
            className='link'
            to={`/roughstones/${cellInfo.row.original.id}`}
            id={cellInfo.row.original.id}>
            {cellInfo.row.values.id}
          </NavLink>
        },
        filterType: 'textarea'
      },
      sellerStoneName: {
        Header: 'Seller Stone Name',
        id: 'sellerStoneName',
        accessor: 'sellerStoneName',
        dataType: 'string'
      },
      assortment: {
        Header: 'Assortment Name',
        id: 'assortment',
        accessor: 'Assortment.name',
        dataType: 'string'
      },
      sellerName: {
        Header: 'Seller',
        id: 'sellerName',
        accessor: row => orgsMap?.[row.sellerId],
        dataType: 'string',
        hidden: !hasAdmin(organizationActions.getOrganizationList)
      },
      weight: {
        Header: 'Weight',
        accessor: 'weight',
        id: 'weight',
        dateType: 'number',
        filterType: 'numberRange'
      },
      statusDescription: {
        Header: 'Status',
        id: 'statusDescription',
        accessor: row => roughStatusesMap?.[row.status] ?? '',
        filterType: 'checkbox',
        enums: roughStatuses?.map(status => status.description)
      },
      colour: {
        Header: 'Color',
        id: 'colour',
        accessor: row => roughColoursMap?.[row.colour] ?? null,
        dataType: 'string',
        filterType: 'checkbox',
        enums: roughColours?.map(rc => rc.description).concat([null])
      },
      fluorescence: {
        Header: 'Flu',
        id: 'fluorescence',
        accessor: 'fluorescence',
        dataType: 'numberRange',
        filterType: 'numberRange'
      },
      tingeDescription: {
        Header: 'Tinge',
        id: 'tingeDescription',
        accessor: row => roughTingesMap?.[row.tinge] ?? null,
        dataType: 'string',
        filterType: 'checkbox',
        enums: roughTinges?.map(rt => rt.description).concat([null])
      },
      country: {
        Header: 'Country',
        id: 'country',
        accessor: row => row?.Country?.name,
        dataType: 'string'
      },
      bidStatus: {
        Header: 'Bid Status',
        id: 'bidStatus',
        accessor: row => row?.bid?.status ? bidStatuses.find(b => b.value === row.bid.status).description : '',
        dataType: 'string',
        filterType: 'checkbox',
        enums: bidStatuses?.map(bs => bs.description)
      },
      bidConfirmed: {
        Header: 'Bid Confirmed',
        id: 'bidConfirmed',
        accessor: row => row?.bid?.confirmed,
        dataType: 'boolean',
        filterType: 'checkbox'
      },
      offerPrice: {
        Header: 'Offer Price',
        id: 'offerPrice',
        accessor: 'bid.offerPrice',
        dataType: 'currency',
        filterType: 'numberRange',
        decimalScale: 2,
        fixedDecimalScale: true
      },
      reservePpc: {
        Header: 'Reserve Price',
        id: 'reservePpc',
        accessor: row => row?.reservePpc != null ? numberUtils.numFmt(row?.weight * row?.reservePpc, 2) : 'N/A',
        dataType: 'currency',
        filterType: 'numberRange',
        decimalScale: 2,
        fixedDecimalScale: true
      },
      reservePpcOriginal: {
        Header: 'Reserve ($/ct)',
        id: 'reservePpcOriginal',
        accessor: 'reservePpcOriginal',
        dataType: 'currency',
        filterType: 'numberRange',
        decimalScale: 2,
        fixedDecimalScale: true
      },
      reservePpcOverride: {
        Header: 'Override Reserve ($/ct)',
        id: 'reservePpcOverride',
        accessor: 'reservePpcOverride',
        dataType: 'currency',
        filterType: 'numberRange',
        decimalScale: 2,
        fixedDecimalScale: true
      },
      location: {
        Header: 'Location',
        id: 'location',
        accessor: 'location',
        dataType: 'string',
        filterType: 'checkbox',
        hidden: !hasAdmin(roughStoneActions.getRoughStoneList)
      },
      qcStatus: {
        Header: 'QC Approved',
        id: 'qcStatus',
        accessor: row => roughQCApprovedMap?.[row.qcStatus] ?? '',
        dataType: 'string',
        filterType: 'checkbox',
        enums: roughQCApprovedMap ? Object.keys(roughQCApprovedMap)?.map(rqca => roughQCApprovedMap[rqca]) : [],
        hidden: !hasAdmin(roughStoneActions.getRoughStoneList)
      },
      buyerId: {
        Header: 'Buyer',
        id: 'buyerId',
        accessor: row => row?.Transactions?.[0]?.buyerId ? orgsMap?.[row.Transactions[0].buyerId] : '',
        dataType: 'string',
        hidden: !hasFilterKey([FILTERS.SOLD]) || !hasAdmin(roughStoneActions.getRoughStoneList)
      },
      saleId: {
        Header: 'Sale',
        id: 'saleId',
        accessor: row => row?.Transactions?.[0]?.saleId ? allSales[row.Transactions[0].saleId] : '',
        dataType: 'string',
        hidden: !hasFilterKey([FILTERS.SOLD]) || !hasAdmin(roughStoneActions.getRoughStoneList)
      },
      createdAt: {
        Header: 'Created At',
        id: 'createdAt',
        accessor: 'createdAt',
        dataType: 'date',
        filterType: 'date'
      },
      updatedAt: {
        Header: 'Last Updated',
        id: 'updatedAt',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      },
      comments: {
        Header: '',
        id: 'comments',
        accessor: 'comments',
        dataType: '',
        disableSortBy: true,
        Cell: cellInfo => {
          return (
            <RoughCommentIcon
              comments={cellInfo?.row?.values?.comments}
              onClick={() => setOpenCommentModal({ open: true, rough: cellInfo?.row?.values })}
            />
          )
        }
      }
    }
    return cols[stoneKey].reduce((arr, col) => {
      const { hidden, ...restCol } = allColumns[col]
      if (!hidden) arr.push(restCol)
      return arr
    }, [])
  }, [orgsMap,
    roughStatusesMap,
    roughTinges,
    roughTingesMap,
    roughColours,
    roughColoursMap,
    roughQCApprovedMap,
    permissionsAdminCache,
    tabRequestParams,
    stoneKey,
    allSales])

  function getRoughBids() {
    return roughStonesList?.data?.reduce((roughList, rough) => {
      const bid = bidsMapByRoughId?.[rough.id]
      if (!bid || bid.condition !== 'ACTIVE' || bid.saleId !== activeSaleId) return roughList
      return roughList.concat({ ...rough, bid })
    }, [])
  }

  function updateSucceeded(message = 'Stones updated', ids = []) {
    return function () {
      showSuccessToast(message)
      removeStones(ids)
    }
  }

  function removeStones(ids) {
    removeAllRoughStonesListItems(ids)
    removeAllPlannedStonesListItems(ids, (plannedStone) => plannedStone?.Rough?.id)
  }

  async function handleArchive(ids) {
    return roughStoneActions.setRoughCondition({ roughIds: ids, condition: 'ARCHIVED' })
    .then(updateSucceeded(`Stone${ids.length > 1 ? 's have' : ' has'} been archived.`, ids))
  }

  async function handleUnarchive(ids) {
    return roughStoneActions.setRoughCondition({ roughIds: ids, condition: 'ACTIVE' })
    .then(updateSucceeded(`Stone${ids.length > 1 ? 's have' : ' has'} been unarchived.`, ids))
  }

  async function handleRemove(ids) {
    return roughStoneActions.setRoughCondition({ roughIds: ids, condition: 'DELETED' })
    .then(updateSucceeded(`Stone${ids.length > 1 ? 's have' : ' has'} been removed.`, ids))
  }

  async function handleUpdateMultipleStones([upds, opts]) {
    if (!upds.roughIds.length) return showInfoToast('No changes were recorded.')
    return roughStoneActions.updateMultipleRoughs(upds, opts).then(updateSucceeded(`Stone${upds.roughIds.length > 1 ? 's have' : ' has'} been updated.`, upds.roughIds))
  }

  const tableTabs = [
    {
      id: FILTERS.UNSOLD.key,
      label: 'Unsold',
      params: FILTERS.UNSOLD
    },
    {
      id: FILTERS.SOLD.key,
      label: 'Sold',
      params: FILTERS.SOLD
    },
    {
      id: FILTERS.ARCHIVED.key,
      label: 'Archived',
      params: FILTERS.ARCHIVED
    },
    {
      id: FILTERS.BIDS.key,
      label: <div className='rough-stone-list__bids-tab-icon'>
        <span>Active Bids</span>
        {bidsList.some(bid => !bid.confirmed) && <Icon name='exclamation' />}
      </div>,
      params: FILTERS.BIDS,
      hide: !bidsList?.length
    }
  ]

  const rowColourScheme = [
    {
      condition: (row) => hasStoneKey([STONE_KEYS.BIDS]) && row?.bid?.status === 'BID_ACCEPTED',
      scheme: 'success'
    },
    {
      condition: (row) => hasStoneKey([STONE_KEYS.BIDS]) && row?.bid?.status === 'BID_REJECTED',
      scheme: 'error'
    },
    {
      condition: (row) => hasStoneKey([STONE_KEYS.BIDS]) && row?.bid?.confirmed,
      scheme: 'disabled'
    }
  ]

  async function changeBidStatus(rows, status) {
    const _rows = Array.isArray(rows) ? rows : [rows]
    const confirmedRows = _rows.filter(row => row.bid.confirmed)
    if (confirmedRows?.length) await bidActions.setBidsConfirmed(confirmedRows.map(row => row.bid.id), false)
    await bidActions.setBidsStatus(_rows.map(row => row?.bid?.id), status)
    refreshBids()
  }

  function onConfirmBids() {
    const bidIds = bidsList
    .filter(bid => !bid.confirmed && bid.status !== 'BID_OFFERED')
    .map(bid => bid.id)

    bidActions.setBidsConfirmed(bidIds, true)
    .then(() => {
      refreshBids()
      showSuccessToast('Bids confirmed.')
    })
  }

  function refreshStoneList() {
    const reqParams = { ...tabRequestParams }
    const stoneParams = getRoughParams(reqParams)
    setRoughStoneParams(stoneParams)
    getRoughStonesList(stoneParams)
  }

  function getRoughAllowedStatuses() {
    if (Object.keys(roughStatusesMap).length) {
      const roughStatuses = Object.keys(roughStatusesMap)
      const findStatusIndex = hasAdmin(roughStoneActions.editRoughStone) ? 'ROUGH_SOLD' : 'ROUGH_SUBMITTED'
      const submittedIndex = roughStatuses.findIndex(status => status === findStatusIndex)
      return roughStatuses.slice(0, submittedIndex)
    }
  }

  function topBarSelectedInfo(rows) {
    const totalWeight = rows.reduce((tot, row) => tot + Number(row?.values?.weight ?? 0), 0)
    return rows.length ? <div>{numberUtils.numFmt(totalWeight, 3)} ct</div> : null
  }

  return (
    <>
      <Table
        title="Rough Stones"
        data={localRoughList}
        columns={columns}
        tableTabs={tableTabs}
        rowActions={rowActions}
        initialSort={hasStoneKey([STONE_KEYS.BIDS]) ? [{ id: 'assortment', desc: false }] : undefined}
        rowColourScheme={rowColourScheme}
        getDataCallback={(params) => setTabRequestParams({ ...params })}
        topBarActions={topBarActions}
        topBarSelectedInfo={topBarSelectedInfo}
      />
      <RoughStoneListModals
        openModal={openModal}
        setOpenModal={setOpenModal}
        updateSucceeded={updateSucceeded}
        isReportsModalOpen={isReportsModalOpen}
        setIsReportsModalOpen={setIsReportsModalOpen}
        openCommentsModal={openCommentsModal}
        setOpenCommentModal={setOpenCommentModal}
        refreshStoneList={() => refreshStoneList(roughStoneParams)}
        refreshBids={refreshBids}
        orgsList={orgsList}
        orgsMap={orgsMap}
        orgId={orgId}
        roughSellersList={roughSellersList}
        localRoughList={localRoughList}
        priceableStatuses={priceableStatuses}
      />
    </>
  )
}

export default RoughStoneList
