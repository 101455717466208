import React, { useEffect, useMemo } from 'react'
import { FormComponents, Table } from '@organisms'
import { roleAndPermissionAction } from '@actions'
import { useToast } from '@hooks'
import { useGlobalsStore, useModalStore } from '@stores'

const { SimpleForm, TextInput, Button } = FormComponents

function RoleList() {
  const { setModal } = useModalStore()
  const { showSuccessToast, showErrorToast } = useToast()
  const { getRolesList, setRolesList, rolesList } = useGlobalsStore(state => state)
  useEffect(() => {
    getRolesList()
  }, [])

  const roles = useMemo(() => rolesList
    ?.sort()
    ?.map(role => ({
      role: role.role,
      createdAt: role.createdAt && JSON.parse(role.createdAt)
    }))
  , [rolesList])

  const columns = useMemo(() => ([
    {
      Header: 'Role',
      accessor: 'role',
      dataType: 'string'
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      dataType: 'date',
      filterType: 'date'
    }
  ]), [roles])

  const topBarActions = [
    {
      label: 'Create Role',
      callback: () => setModal({
        id: 'createRole',
        title: 'Create Role',
        customMessageRenderer: ({ handleSubmit, handleCancel }) => (
          <SimpleForm
            onSubmit={handleSubmit}
            name="createRole"
          >
            <TextInput size="lg" name="role" label="Role" required={true} />
            <div key='row-buttons' className='modal__buttons'>
              <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
              <Button type='submit' typeVariant='primary' size='sm'>Create</Button>
            </div>
          </SimpleForm>
        ),
        customButtonsRenderer: () => null,
        onSubmit: async (res) => await roleAndPermissionAction.createRole(res[0]).then(() => showSuccessToast('Role successfully created.')).then(setRolesList).catch(showErrorToast)
      })
    }
  ]

  return (
    <Table
      title='Roles'
      data={roles}
      columns={columns}
      isMultiSelect={false}
      topBarActions={topBarActions}
    />
  )
}

export default RoleList
