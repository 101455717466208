import React, { useState, useEffect, useMemo } from 'react'
import { FormComponents, Table } from '@organisms'
import { NavLink } from 'react-router-dom'
import { logisticsActions, saleActions, reportActions } from '@actions'
import { useGlobalsStore, useModalStore } from '@stores'
import { textUtils, arrayUtils, fileUtils } from '@utils'
import moment from 'moment'
import EditPaymentsModal from './editPaymentsModal'
import InvoicePaymentConditionModal from './invoicePaymentConditionModal'
import PaymentReportModal from './paymentsReportModal'
import { useProgressList, useToast } from '@hooks'

const { SimpleForm, Checkbox, Button } = FormComponents

const CONDITIONS = {
  active: {
    id: 'active',
    label: 'Active',
    params: 'ACTIVE'
  },
  archived: {
    id: 'archived',
    label: 'Archived',
    params: 'ARCHIVED'
  }
}

function InvoiceList() {
  const {
    orgsMap: { all: orgsMap },
    getOrgsList
  } = useGlobalsStore(store => store)
  const { showSuccessToast } = useToast()
  const {
    createProgressList
  } = useProgressList()
  const { setModal } = useModalStore()
  const [invoiceList, setInvoiceList] = useState([])
  const [openModal, setOpenModal] = useState(null)
  const [activeTab, setActiveTab] = useState(CONDITIONS.active)
  const [salesMap, setSalesMap] = useState({})
  useEffect(() => {
    getOrgsList()
    getInvoiceList('ACTIVE')
    saleActions.getSales({ status: 'COMPLETED', columns: '[id, name, eventGroupId, status, updatedAt, buyers, sellers, events]' })
    .then(result => {
      setSalesMap(arrayUtils.toMap(
        result.data.data.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt)),
        sale => sale.id,
        sale => `${sale.name}${sale.Events?.length && sale.Events[0].endTime ? ` | ${textUtils.formatDate(sale.Events[0].endTime, 'MMM DD, YYYY')}` : ''}`
      ))
    })
    .catch(console.error)
  }, [])

  function getInvoiceList (condition) {
    return logisticsActions.getInvoices({ condition, columns: '[Payments]' })
    .then(results => setInvoiceList(results.data.data))
  }

  async function getReleaseNotes(selectedRows) {
    const releaseNotesParams = await new Promise((resolve, reject) => {
      setModal({
        id: 'releaseNotes',
        title: 'Confirm Release Stones',
        type: 'form',
        customMessageRenderer: ({ handleSubmit, handleCancel }) => (
          <SimpleForm
            onSubmit={handleSubmit}
            name="releaseNotes"
            initialValues={{ notifyRecipients: true }}
          >
            <Checkbox size="lg" name="notifyRecipients" label="Notify recipient(s)" />
            <div key='row-buttons' className='modal__buttons'>
              <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
              <Button type='submit' typeVariant='primary' size='sm'>Confirm</Button>
            </div>
          </SimpleForm>
        ),
        onSubmit: (res) => resolve(res[0]),
        onCancel: reject
      })
    })
    const progressList = createProgressList({
      name: 'getReleaseNotes',
      title: 'Release Stones',
      concurrency: 2
    })
    for (const selectedRow of selectedRows) {
      const task = progressList.newTask()
      task.id = selectedRow.id
      task.name = `Invoice #${selectedRow.invoiceNumber}`
      task.callback = async () => reportActions.getReleaseNotes({
        format: 'xlsx',
        releaseType: 'SHIPMENT_RELEASE',
        invoiceIds: `[${task.id}]`,
        ...releaseNotesParams
      })
      .then(response => fileUtils.saveBase64Excel(response.data.data.report, fileUtils.getFileName(response.data.data)))
      progressList.addTask(task)
    }
  }

  function handleArchive({ id }) {
    logisticsActions.setInvoiceCondition({ invoiceIds: [id], condition: CONDITIONS.archived.params })
    .then(() => {
      showSuccessToast('Invoice has been archived')
      getInvoiceList(CONDITIONS.active.params)
    })
  }

  function handleUnarchive({ id }) {
    logisticsActions.setInvoiceCondition({ invoiceIds: [id], condition: CONDITIONS.active.params })
    .then(() => {
      showSuccessToast('Invoice has been unarchived')
      getInvoiceList(CONDITIONS.archived.params)
    })
  }

  const columns = useMemo(() => (
    [
      {
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
        dataType: 'string',
        Cell: cellInfo => {
          return <NavLink
            className='link'
            to={`/finance/invoices/${cellInfo.row.original.id}`}
            id={cellInfo.row.original.id}>
            {cellInfo.value}
          </NavLink>
        }
      },
      {
        Header: 'Sale',
        id: 'saleName',
        accessor: row => salesMap?.[row.saleId],
        dataType: 'string'
      },
      {
        Header: 'Customer',
        id: 'orgId',
        accessor: row => orgsMap?.[row.orgId],
        dataType: 'string'
      },
      {
        Header: 'Paid',
        accessor: row => !!(row?.Payments?.length && row.Payments.every(pay => pay?.paymentDate)),
        dataType: 'boolean',
        filterType: 'checkbox'
      },
      {
        Header: 'Invoice Date',
        accessor: 'invoiceDate',
        dataType: 'date',
        filterType: 'date'
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        dataType: 'date',
        filterType: 'date'
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      }
    ]
  ), [orgsMap, salesMap])

  const topBarActions = [
    {
      componentName: 'dropdown',
      enableOnSelect: true,
      options: [
        {
          label: 'Edit payments',
          callback: selectedRows => setOpenModal({
            name: 'editPayments',
            selectedRows,
            onClose: () => getInvoiceList(String(activeTab?.id).toLocaleUpperCase())
          }),
          value: 'editPayments',
          isDisabled: activeTab?.id === CONDITIONS.archived.id
        },
        ...(
            activeTab?.id === CONDITIONS.active.id ? [{
              label: 'Archive invoices',
              callback: selectedRows => setOpenModal({
                name: 'setInvoiceCondition',
                selectedRows,
                operation: 'archive',
                action: () => logisticsActions.setInvoiceCondition({ invoiceIds: selectedRows?.map(row => row.id), condition: CONDITIONS.archived.params })
              .then(() => setOpenModal(null)),
                onClose: () => getInvoiceList(CONDITIONS.active.params).then(() => setOpenModal(null))
              }),
              value: 'setInvoiceCondition'
            }] : []
        ),
        ...(
          activeTab?.id === CONDITIONS.archived.id ? [{
            label: 'Unarchive invoices',
            callback: selectedRows => setOpenModal({
              name: 'setInvoiceCondition',
              selectedRows,
              operation: 'unarchive',
              action: () => logisticsActions.setInvoiceCondition({ invoiceIds: selectedRows?.map(row => row.id), condition: CONDITIONS.active.params })
              .then(() => setOpenModal(null)),
              onClose: () => getInvoiceList(CONDITIONS.archived.params).then(() => setOpenModal(null))
            }),
            value: 'setInvoiceCondition'
          }] : []
        ),
        {
          label: 'Release Stones',
          callback: getReleaseNotes,
          value: 'getReleaseNotes'
        }
      ]
    },
    {
      label: 'Payments Report',
      callback: () => setOpenModal(prev => ({ ...prev, name: 'generatePaymentReport' }))
    }
  ]

  const rowActions = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === CONDITIONS.active.params
    },
    {
      actionName: 'unarchive',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === CONDITIONS.archived.params
    }
  ]

  const tableTabs = useMemo(() => Object.values(CONDITIONS), [])

  return (
    <>
      <Table
        title="Invoices"
        data={invoiceList}
        columns={columns}
        onTabChange={setActiveTab}
        getDataCallback={getInvoiceList}
        tableTabs={tableTabs}
        topBarActions={topBarActions}
        rowActions={rowActions}
      />
      <EditPaymentsModal
        open={openModal?.name === 'editPayments'}
        payments={openModal?.selectedRows?.reduce((payments, row) => payments.concat(row?.Payments?.map(p => ({ ...p, Invoice: { invoiceNumber: row.invoiceNumber } }))), [])}
        orgsMap={orgsMap}
        salesMap={salesMap}
        onClose={async (shouldFetch) => {
          if (shouldFetch) await openModal.onClose()
          setOpenModal(prev => ({ ...prev, name: '' }))
        }}
      />
      <InvoicePaymentConditionModal
        open={openModal?.name === 'setInvoiceCondition'}
        invoices={openModal?.selectedRows?.map(row => row.id)}
        operation={openModal?.operation}
        action={openModal?.action}
        onClose={async (shouldFetch) => {
          if (shouldFetch) await openModal.onClose()
          setOpenModal(prev => ({ ...prev, name: '' }))
        }}
      />
      <PaymentReportModal
        open={openModal?.name === 'generatePaymentReport'}
        onClose={() => setOpenModal(prev => ({ ...prev, name: '' }))}
      />
    </>
  )
}

export default InvoiceList
