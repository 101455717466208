import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import routes from '../../../routes/routes'
import { useAuthStore } from '@stores'
import { useWindowSize } from '@hooks'
import SidebarSection from './sidebarSection'
import SidebarLogo from './sidebarLogo'
import { Icon } from '@atoms'

function Sidebar({
  isCollapsed,
  setIsCollapsed
}) {
  const [width] = useWindowSize()
  const collapsedClassName = isCollapsed ? '--collapsed' : ''

  const {
    isAuthenticated,
    hasPermission,
    hasAdmin,
    getPermissions
  } = useAuthStore(state => state)
  const [showScrollArrows, setShowScrollArrows] = useState(false)
  const scrollArrowsClassName = showScrollArrows ? '--up' : '--hidden'
  const _id = 'sidebar'
  let scrollInterval = null
  const menuDiv = document.getElementById(_id)

  useEffect(() => {
    // if the scrollbar height is bigger than the div height
    // it shows the scroll arrows (up and down arrows).
    if (menuDiv) setShowScrollArrows(menuDiv.scrollHeight > menuDiv.clientHeight)
  }, [menuDiv?.scrollHeight, menuDiv?.clientHeight])

  useEffect(() => {
    if (width < 768 && !isCollapsed) setIsCollapsed(true)
    if (width >= 768 && isCollapsed) setIsCollapsed(false)
  }, [width])

  useEffect(() => {
    if (isAuthenticated) getPermissions()
  }, [isAuthenticated])
  if (!isAuthenticated) return null

  const startScroll = function (direction) {
    const element = document.getElementById(_id)
    scroll(element, direction, 35, element.clientHeight)
  }

  const stopScroll = function () {
    if (scrollInterval) {
      window.clearInterval(scrollInterval)
    }
  }

  const scroll = function (element, direction, speed = 45, distance = 500, step = 10) {
    let scrollAmount = 0
    const slideTimer = setInterval(function () {
      if (direction === 'up') { element.scrollTop -= step } else { element.scrollTop += step }

      scrollAmount += step
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer)
        scrollInterval = null
      }
    }, speed)

    scrollInterval = slideTimer
  }

  return (
    <div className={`sidebar${collapsedClassName}`}>
      <SidebarLogo
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      <div
        className={`sidebar__scroll-arrow${scrollArrowsClassName}`}
        onMouseOver={() => startScroll('up')}
        onMouseOut={() => stopScroll()}
      >
        <Icon name='chevronUp' />
      </div>
      <div
        className="sidebar__menu"
        id={_id}
      >
        <SidebarSection
          label='Overview'
          id='overview'
          isCollapsed={isCollapsed}
          routes={routes?.navigation?.overview?.filter(r => r.displayFor?.some(endpoint => hasPermission(endpoint)) || r.displayForAdmin?.some(endpoint => hasAdmin(endpoint)))}
        />
        <SidebarSection
          label='Main'
          id='main'
          isCollapsed={isCollapsed}
          routes={routes?.navigation?.main?.filter(r => r.displayFor?.some(endpoint => hasPermission(endpoint)) || r.displayForAdmin?.some(endpoint => hasAdmin(endpoint)))}
        />
        <SidebarSection
          label='Admin'
          id='admin'
          isCollapsed={isCollapsed}
          routes={routes?.navigation?.admin?.filter(r => r.displayFor?.some(endpoint => hasPermission(endpoint)) || r.displayForAdmin?.some(endpoint => hasAdmin(endpoint)))}
        />
        <SidebarSection
          label='Account'
          id='account'
          isFooter={true}
          isCollapsed={isCollapsed}
          routes={routes?.navigation?.account?.filter(r => r.displayFor?.some(endpoint => hasPermission(endpoint)) && !r.displayFor?.some(endpoint => hasAdmin(endpoint)))}
        />
      </div>
      <div
        className={`sidebar__scroll-arrow${scrollArrowsClassName}`}
        onMouseOver={() => startScroll('down')}
        onMouseOut={() => stopScroll()}
      >
        <Icon name='chevronDown' />
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func
}

Sidebar.defaultProps = {
  isCollapsed: false
}

export default Sidebar
