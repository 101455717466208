import React, { useEffect, useState, useMemo } from 'react'
import DashboardInfoCard from './dashboardInfoCard'
import DashboardInputCard from './dasboardInputCard'
import { eventActions, reportActions, saleActions, organizationActions, transactionActions, logisticsActions } from '@actions'
import { useGlobalsStore, useAuthStore, useTransactionStore } from '@stores'
import { useToast, usePageSettings } from '@hooks'
import moment from 'moment'
import { arrayUtils, fileUtils, textUtils } from '@utils'
import DashboardTable from './dashboardTable'
import { Modal, Tabs } from '@templates'
import DashboardCharts from './dashboardCharts'
import FileSaver from 'file-saver'
import CreateShipmentForm from '../shipments/createShipmentForm'

function Dashboard() {
  const [sales, setSales] = useState([])
  const [selectedSale, setSelectedSale] = useState()
  const [organizationId, setOrganizationId] = useState()
  const [buyerTransactionParams, setBuyerTransactionParams] = useState()
  const [sellerTransactionParams, setSellerTransactionParams] = useState()
  const [adminTransactionParams, setAdminTransactionParams] = useState()
  const [savedReports, setSavedReports] = useState()
  const [newShipmentModalOpen, setNewShipmentModalOpen] = useState(false)
  const {
    hasAdmin,
    hasPermission,
    permissionsAdminCache,
    orgId: userOrgId
  } = useAuthStore(state => state)
  const {
    orgsList: { all: orgsList },
    eventTypesList,
    getOrgsList,
    getEventTypesList
  } = useGlobalsStore()
  const { showErrorToast } = useToast()
  const {
    addPageSettingValue,
    getPageSettingValue
  } = usePageSettings('dashboard')

  useEffect(() => {
    if (selectedSale == null) return
    addPageSettingValue({ selectedSale })
  }, [selectedSale])

  useEffect(() => {
    if (organizationId == null) return
    addPageSettingValue({ organizationId })
  }, [organizationId])

  useEffect(() => {
    saleActions.getSales({ status: 'COMPLETED', columns: '[id, name, eventGroupId, status, updatedAt, buyers, sellers, events]' })
    .then(result => {
      setSales(result.data.data
        .sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
        .map(sale => ({
          ...sale,
          eventName: `${sale.name}${sale.Events?.length && sale.Events[0].endTime
            // events are unsorted, but in reality there will only be one
            ? ` | ${textUtils.formatDate(sale.Events[0].endTime)}`
            : ''}`
        }))
      )

      const storeSelectedSale = getPageSettingValue('selectedSale')
      if (storeSelectedSale == null) setSelectedSale(result.data.data[0]?.id)
      else setSelectedSale(storeSelectedSale)
    })
    .catch(console.error)
  }, [])

  useEffect(() => {
    const storeOrgId = getPageSettingValue('organizationId')
    if (organizationId != null) setOrganizationId(storeOrgId)
  }, [])

  useEffect(() => {
    getOrgsList()
    getEventTypesList()
  }, [])

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    refreshSavedReports()
  }, [organizationId, permissionsAdminCache])

  function getBuyerTransactionParams(buyerId, saleId) {
    return {
      ...(saleId ? { saleId } : {}),
      buyerId,
      isCancelled: false,
      columns: '[Rough, SPSRequest]'
    }
  }

  function getSellerTransactionParams(sellerId, saleId) {
    return {
      ...(saleId ? { saleId } : {}),
      sellerId,
      isCancelled: false, // TODO: CD-1702, this may change in the future
      columns: '[Rough]'
    }
  }

  function getAdminTransactionParams(saleId) {
    return {
      ...(saleId ? { saleId } : {}),
      columns: '[Rough, SPSRequest]'
    }
  }
  const {
    buyerTransactionsList: { [buyerTransactionParams ? JSON.stringify(buyerTransactionParams) : 'all']: buyerTransactionsList },
    getBuyerTransactionsList,
    sellerTransactionsList: { [sellerTransactionParams ? JSON.stringify(sellerTransactionParams) : 'all']: sellerTransactionsList },
    getSellerTransactionsList,
    adminTransactionsList: { [adminTransactionParams ? JSON.stringify(adminTransactionParams) : 'all']: adminTransactionsList },
    getAdminTransactionsList
  } = useTransactionStore(store => store)
  const buyerTransactions = useMemo(() => {
    if (!buyerTransactionsList) return
    return buyerTransactionsList.data
  }, [buyerTransactionsList, organizationId, selectedSale])
  const sellerTransactions = useMemo(() => {
    if (!sellerTransactionsList) return
    return sellerTransactionsList.data
  }, [sellerTransactionsList, organizationId, selectedSale])
  const adminTransactions = useMemo(() => {
    if (!adminTransactionsList) return
    return adminTransactionsList.data
  }, [adminTransactionsList, organizationId, selectedSale])
  const fullTransactions = useMemo(() => ({
    buyer: buyerTransactions, seller: sellerTransactions, admin: adminTransactions
  }), [buyerTransactions, sellerTransactions, adminTransactions])

  const orgsMap = useMemo(() => arrayUtils.toMap(orgsList, x => x.orgId), [orgsList])
  function getReportBySale(reports, reportType) {
    return arrayUtils.groupBy((reports || []).filter((report) => reportType === report.reportType), (x) => x.saleId)
  }
  const buyerFeedbackReportsBySale = useMemo(() => getReportBySale(savedReports, 'BUYER_FEEDBACK'), [savedReports, selectedSale])
  const invoiceReportsBySale = useMemo(() => getReportBySale(savedReports, 'INVOICE'), [savedReports, selectedSale])
  // const salesById = useMemo(() =>arrayUtils.groupBy(sales || [], (x) => x.id), [sales])
  const latestSale = useMemo(() => sales?.[0]?.id, [sales])

  useEffect(() => {
    const storeOrgId = getPageSettingValue('organizationId')
    if (permissionsAdminCache.size) {
      setOrganizationId(hasPermission(transactionActions.getAdminTransactions)
        ? storeOrgId || ''
        : userOrgId)
    } else {
      let _orgId = ''
      _orgId = storeOrgId || null
      if (_orgId != null) setOrganizationId(_orgId)
    }
  }, [permissionsAdminCache, userOrgId])

  useEffect(() => {
    if (!sales?.length) return
    refreshList()
  }, [selectedSale, organizationId])

  function refreshList() {
    if (selectedSale == null || organizationId == null) return
    if (hasPermission(transactionActions.getAdminTransactions)) {
      setAdminTransactionParams(getAdminTransactionParams(selectedSale))
      getAdminTransactionsList(getAdminTransactionParams(selectedSale))
    } else if (hasPermission(transactionActions.getBuyerTransactions) && hasPermission(transactionActions.getSellerTransactions)) {
      setBuyerTransactionParams(getBuyerTransactionParams(organizationId, selectedSale))
      setSellerTransactionParams(getSellerTransactionParams(organizationId, selectedSale))
      getBuyerTransactionsList(getBuyerTransactionParams(organizationId, selectedSale))
      getSellerTransactionsList(getSellerTransactionParams(organizationId, selectedSale))
    }
  }

  async function getSavedReports() {
    return (hasPermission(reportActions.listReports) ? reportActions.listReports : reportActions.listUserReports)({ condition: 'ACTIVE', status: 'AVAILABLE', orgId: organizationId })
  }
  async function refreshSavedReports() {
    if (!organizationId) { return setSavedReports() }
    const result = await getSavedReports()
    setSavedReports(result.data.data)
    return result.data.data
  }

  const { winners: saleWinners, losers: saleLosers } = useMemo(() => {
    const saleResults = { winners: [], losers: [] }
    if (!adminTransactions || !hasPermission(transactionActions.getAdminTransactions)) return saleResults
    const sale = sales.find(({ id }) => selectedSale === id)
    const { transactionWinners, transactionLosers } = adminTransactions.reduce(({ transactionWinners, transactionLosers }, { buyerId }) => {
      transactionLosers.delete(buyerId)
      transactionWinners.add(buyerId)
      return { transactionWinners, transactionLosers }
    }, { transactionWinners: new Set(), transactionLosers: new Set([...(sale?.buyers || [])]) })
    const sellers = new Set([...(sale?.sellers || [])])
    saleResults.winners = Array.from(transactionWinners, (id) => orgsMap[id]).filter(x => x).sort((a, b) => textUtils.compareIgnoreCase(a?.commonName, b?.commonName))
    saleResults.losers = Array.from([...transactionLosers, ...sellers], (id) => orgsMap[id]).filter(x => x).sort((a, b) => textUtils.compareIgnoreCase(a?.commonName, b?.commonName))
    return saleResults
  }, [buyerTransactions, adminTransactions, orgsMap, selectedSale])

  const [nextSale, setNextSale] = useState(null)
  useEffect(() => {
    if (eventTypesList?.length) {
      const saleTypeId = eventTypesList.find(t => t.name === 'Sale')?.id
      if (!saleTypeId) return
      eventActions.getEvents({ typeId: saleTypeId })
      .then(result => {
        const events = result.data.data
        const now = moment()
        setNextSale(events.reduce((next, sale) => {
          if (moment(sale.startTime) < now) return next
          if (!next || moment(sale.startTime) < moment(next.startTime)) return sale
          return next
        }, null))
      })
      .catch(console.error)
    }
  }, [eventTypesList])

  const items = [
    {
      title: 'Transactions',
      Component: DashboardTable,
      props: { transactions: fullTransactions, refreshList, orgsList, selectedSale, organizationId }
    },
    {
      title: 'Summary',
      Component: DashboardCharts,
      props: { orgsList, organizationId, saleId: selectedSale },
      disabled: !organizationId // Maybe if we are going to stick to the tabs approach we could add a infotip/infohover here
    }
  ]

  function downloadFile(e) {
    const reportName = e.currentTarget.value
    if (reportName === 'packingList') {
      reportActions.getPackingList('xlsx', { ...(selectedSale ? { saleId: selectedSale } : {}), ...(organizationId ? { buyerId: organizationId } : {}) })
      .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
    } else if (reportName === 'sellerPackingList') {
      reportActions.getSellerPackingList('xlsx', { ...(selectedSale ? { saleId: selectedSale } : {}), ...(organizationId ? { sellerId: organizationId } : {}) })
      .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
    } else if (reportName === 'buyerFeedback') {
      if (buyerFeedbackReportsBySale?.[selectedSale]?.[0]) downloadSavedFile(buyerFeedbackReportsBySale?.[selectedSale]?.[0])
      // Generate new report if the report has not yet been generated and the latest sale is selected
      else if (latestSale === selectedSale && hasAdmin(reportActions.getBuyerFeedback)) {
        reportActions.getBuyerFeedback('xlsx', { saleId: selectedSale, buyerId: organizationId })
        .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
        .then(refreshSavedReports)
      }
    } else if (reportName === 'invoice') {
      // I suppose we do not need to check if the user has permission to download this report since we are not displaying the option
      // to download in the dropdown for non-admin
      if (invoiceReportsBySale?.[selectedSale]?.[0]) downloadSavedFile(invoiceReportsBySale?.[selectedSale]?.[0])
      // Generate new report if the report has not yet been generated
      else {
        logisticsActions.generateFullInvoice({ saleId: selectedSale, buyerId: organizationId })
        .then(refreshSavedReports)
        .then((reports) => getReportBySale(reports, 'INVOICE'))
        .then((reportBySale) => downloadSavedFile(reportBySale?.[selectedSale]?.[0]))
      }
    } else if (reportName === 'proFormaInvoice') {
      logisticsActions.getShipments({ fromOrgId: organizationId, type: 'FROM_SELLER', condition: 'ACTIVE', columns: '[ShipmentFiles]' })
      .then(async response => {
        let shipmentFound = null
        let someRoughsShipped = false
        const someRoughsShippedStatus = soldTxns.some(txn => txn.shippedDate != null || ['ROUGH_SHIPPED', 'ROUGH_ACCEPTED'].includes(txn.Rough?.status))
        for (const shp of response.data.data) {
          const { any, every } = soldTxns.reduce(({ any, every }, { roughId }) => {
            const inBoth = shp.roughIds.includes(roughId)
            return { any: any || inBoth, every: every && inBoth }
          }, { any: false, every: true })
          if (shp.roughIds?.length === soldTxns.length && every) shipmentFound = shp
          else if (!shipmentFound && any) someRoughsShipped = true
        }
        if (shipmentFound) {
          for (const doc of shipmentFound.ShipmentFiles) {
            if (doc.condition === 'ACTIVE' && doc.type === 'COMMERCIAL_INVOICE') {
              const response = await reportActions.getDownloadUrl([doc.fileId], { shipmentId: doc.shipmentId })
              const file = response?.data?.data?.[0]
              const downloaded = await reportActions.getBinaryFile(file.url)
              FileSaver.saveAs(downloaded.data, fileUtils.getFileName(file))
              return
            }
          }
          reportActions.createProForma({ format: 'xlsx', shipmentId: shipmentFound.id })
          .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
        } else if (someRoughsShippedStatus) {
          showErrorToast("Some stones or transactions are already in shipped status. Pro Forma invoice can't be created automatically.")
        } else if (someRoughsShipped) {
          showErrorToast('Some stones were already shipped separately. Please create a shipment manually.')
        } else {
          setNewShipmentModalOpen(true)
        }
      })
    }
  }

  async function downloadSavedFile(savedFile) {
    const response = await reportActions.getDownloadUrl([savedFile.fileId], { savedReportId: savedFile.id })
    const file = response?.data?.data?.[0]
    // FileSaver.saveAs(file.url, fileUtils.getFileName(file))
    const downloaded = await reportActions.getBinaryFile(file.url)
    FileSaver.saveAs(downloaded.data, fileUtils.getFileName(file))
  }

  const soldTxns = useMemo(() => {
    if (!adminTransactions && !sellerTransactions) return []
    return (hasPermission(transactionActions.getAdminTransactions) ? adminTransactions : sellerTransactions)
    .filter(t =>
      (!t.cancelledDate || t.cancelForSeller === false) && (!selectedSale || t.saleId === selectedSale) && (!organizationId || t.buyerId === organizationId || t.sellerId === organizationId)
    )
    .filter(t =>
      !organizationId || t.sellerId === organizationId
    )
  }, [sellerTransactions, adminTransactions, selectedSale, organizationId, permissionsAdminCache])
  const purchasedTxns = useMemo(() => {
    if (!adminTransactions && !buyerTransactions) return []
    return (hasPermission(transactionActions.getAdminTransactions) ? adminTransactions : buyerTransactions)
    .filter(t =>
      (!t.cancelledDate) && (!selectedSale || t.saleId === selectedSale) && (!organizationId || t.buyerId === organizationId || t.sellerId === organizationId)
    )
    .filter(t =>
      !organizationId || t.buyerId === organizationId
    )
  }, [buyerTransactions, adminTransactions, selectedSale, organizationId, permissionsAdminCache])

  const totalPurchased = purchasedTxns.reduce((sum, t) => sum + Number(t.buyerPrice), 0)
  const totalPurchasedWeight = purchasedTxns.reduce((sum, t) => sum + Number(t.Rough?.weight ?? 0), 0)
  const totalSold = soldTxns.reduce((sum, t) => sum + Number(t.sellerPrice), 0)
  const totalSoldWeight = soldTxns.reduce((sum, t) => sum + Number(t.Rough?.weight ?? 0), 0)

  return (
    <div className='dashboard'>
      <div className="dashboard__input-cards">
        <DashboardInputCard
          label= 'Sale Cycle'
          options={(sales?.length ? sales.concat({ id: '', name: 'All Sales', eventName: 'All Sales' }) : []).map(sale => ({ value: sale.id, label: sale.eventName }))}
          value={selectedSale}
          onChange={e => setSelectedSale(e.currentTarget.value)}
          isClearable={false}
        />
        <DashboardInputCard
          name='organization'
          isVisible={hasAdmin(organizationActions.getOrganizationList)}
          value={organizationId}
          label= 'Organization'
          onChange={e => setOrganizationId(e.currentTarget.value)}
          options={[
            { label: 'All Organizations', value: '' },
            { label: 'Winners', options: saleWinners.map(org => ({ label: org.commonName, value: org.orgId })) },
            { label: 'Participants', options: saleLosers.map(org => ({ label: org.commonName, value: org.orgId })) }
          ]}
          isClearable={false}
        />
        <DashboardInputCard
          label= 'Download'
          value={''}
          name='downloadFile'
          onChange={downloadFile}
          options={[
            { value: 'packingList', label: 'Buyer Packing List', isDisabled: !purchasedTxns.length },
            { value: 'sellerPackingList', label: 'Seller Packing List', isDisabled: !soldTxns.length },
            {
              value: 'buyerFeedback',
              label: 'Buyer Feedback',
              // Disable if organization is not selected or
              // if the selected sale is not the latest sale and this report has not already been previously generated
              isDisabled: !organizationId || (!buyerFeedbackReportsBySale?.[selectedSale] && (selectedSale !== latestSale || !hasAdmin(reportActions.getBuyerFeedback)))
            },
            { value: 'invoice', label: 'Invoice', isDisabled: !selectedSale || !organizationId || !purchasedTxns.length || !hasAdmin(reportActions.getInvoice) },
            { value: 'proFormaInvoice', label: 'Pro Forma', isDisabled: !selectedSale || !organizationId || !soldTxns.length || !hasPermission(logisticsActions.getShipments) }
          ].reduce((downloadOptions, { isDisabled, ...option }) =>
            hasAdmin(transactionActions.getAdminTransactions) || !isDisabled
              ? downloadOptions.concat({ isDisabled, ...option })
              : downloadOptions,
          [])}
          isClearable={false}
        />
      </div>
      <div className="dashboard__info-cards">
        <DashboardInfoCard
          title='Next Sale'
          value={nextSale?.startTime}
          iconName='calendar3'
          type='date'
          style='admin'
        />
        <DashboardInfoCard
          title='Total Stones Sold'
          value={soldTxns.length}
          secondaryValue={totalSoldWeight}
          iconName='roughStone'
          isVisible={soldTxns.length > 0}
          type='number'
          secondaryType='weight'
          style='seller'
        />
        <DashboardInfoCard
          title='Total Value Sold'
          value={totalSold}
          iconName='dollars'
          isVisible={soldTxns.length > 0}
          type='currency'
          style='seller'
        />
        <DashboardInfoCard
          title='Total Stones Purchased'
          value={purchasedTxns.length}
          secondaryValue={totalPurchasedWeight}
          iconName='roughStone'
          isVisible={purchasedTxns.length > 0}
          type='number'
          secondaryType='weight'
          style='buyer'
        />
        <DashboardInfoCard
          title='Total Value Purchased'
          value={totalPurchased}
          iconName='dollars'
          isVisible={purchasedTxns.length > 0}
          type='currency'
          style='buyer'
        />
        { hasPermission(transactionActions.getAdminTransactions) && !organizationId
          ? <DashboardInfoCard
            title="Clara Margin"
            value={totalPurchased - totalSold}
            iconName='dollars'
            isVisible={purchasedTxns.length > 0}
            type='currency'
            style='admin'
          /> : null }
      </div>
      {
        (
          (hasPermission(transactionActions.getSellerTransactions) && hasPermission(transactionActions.getBuyerTransactions))
          || (hasPermission(transactionActions.getAdminTransactions))
        )
        && (
          <Tabs
            name="dashboard"
            items={items}
          />
        )
      }
      <Modal open={newShipmentModalOpen} onClose={() => setNewShipmentModalOpen(false)} title=''>
        <CreateShipmentForm
          selectedRoughStones={soldTxns.map(txn => txn.Rough)}
          onCancel={() => setNewShipmentModalOpen(false)}
          onCreated={(shipmentId) => {
            setNewShipmentModalOpen(false)
            reportActions.createProForma({ format: 'xlsx', shipmentId: shipmentId })
            .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
          }}
          fixedFromOrgId={organizationId}
          fixedType='FROM_SELLER'
        />
      </Modal>
    </div>
  )
}

export default Dashboard
