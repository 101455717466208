import React, { useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import clone from 'just-clone'
import { useGlobalsStore, useAuthStore, useModalStore } from '@stores'
import { usePageTitle, useToast } from '@hooks'
import EditAddressForm from './editForms/editAddressForm'
import { DetailsPage } from '@templates'
import { ORG } from '@constants'
import {
  organizationActions,
  provenanceActions,
  orderActions,
  roughStoneActions,
  userActions
} from '@actions'
import { InfoTip } from '@molecules'
import { arrayUtils, numberUtils, objectUtils, textUtils, validationUtils } from '@utils'
import AcceptStonesModal from './acceptStonesModal'

function OrganizationDetails({ match, title }) {
  let { orgId, operation } = match?.params
  const { showSuccessToast, showErrorToast } = useToast()
  const addressRef = useRef()
  const { hasAdmin, hasPermission, orgId: currentOrgId, permissionsAdminCache } = useAuthStore(state => state)
  const isAdmin = useMemo(() => hasAdmin(organizationActions.editOrganization), [permissionsAdminCache])
  const isEditPermitted = useMemo(() => hasPermission(organizationActions.editOrganization), [permissionsAdminCache])
  const { setModal } = useModalStore(state => state)
  const appSettingsParams = { groupKey: 'INVOICE_PAYMENT_DETAILS' }

  // org settings page goes to /organizations/me
  if (orgId === 'me') orgId = currentOrgId

  const {
    getOrgsList,
    resetOrgsList,
    getProvenanceTypesList,
    getCountriesList,
    getMinesList,
    getSawPlane,
    getAppSettings,
    resetProvenanceTypesList,
    resetCountriesList,
    resetMinesList,
    countriesList: { all: countriesList },
    minesList: { all: minesList },
    provenanceTypesList: { all: provTypes },
    complianceStatuses,
    licenceStatuses,
    sawPlane,
    appSettings: { [JSON.stringify(appSettingsParams)]: invoicePaymentDetails },
    orgsList: { all: orgsList }
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getOrgsList()
  }, [])
  const organization = useMemo(() => orgsList?.find(org => org.orgId === orgId), [orgsList])
  usePageTitle(title, orgId, organization?.commonName)

  const [fields, setFields] = useState([])

  // Get the current organization's Provenance Types, Counties and Mines
  //    as well as the complete lists
  const [orgProvTypes, setOrgProvTypes] = useState([])
  const [orgCountries, setOrgCountries] = useState([])
  const [orgMines, setOrgMines] = useState([])

  const countries = useMemo(() => {
    if (!countriesList || !orgCountries) return []
    const [activeCountries, inactiveCountries] = arrayUtils.partitionBy(countriesList, x => x.condition === 'ACTIVE')
    return [...activeCountries, ...arrayUtils.intersectBy(inactiveCountries, orgCountries, (x) => objectUtils.isObject(x) ? x.id : x)]
  }, [countriesList, orgCountries])

  const mines = useMemo(() => {
    if (!minesList || !orgMines) return []
    const [activeMines, inactiveMines] = arrayUtils.partitionBy(minesList, x => x.condition === 'ACTIVE')
    return [...activeMines, ...arrayUtils.intersectBy(inactiveMines, orgMines, (x) => objectUtils.isObject(x) ? x.id : x)]
  }, [minesList, orgMines])

  const orgProvParams = useMemo(() => {
    if (!organization) return undefined
    return {
      sellerId: organization.orgId
    }
  }, [organization])
  const orgCountriesParams = useMemo(() => {
    if (!organization) return undefined
    return {
      sellerId: organization.orgId
    }
  }, [organization])
  const orgMinesParams = useMemo(() => {
    if (!organization) return undefined
    return {
      sellerId: organization.orgId
    }
  }, [organization])

  useEffect(() => {
    Promise.all([
      getProvenanceTypesList(),
      getCountriesList(),
      getMinesList(),
      getSawPlane(),
      getAppSettings(appSettingsParams)
    ])
  }, [])

  useEffect(() => {
    if (orgProvParams && orgCountriesParams && orgMinesParams) {
      Promise.all([
        getProvenanceTypesList(orgProvParams),
        getCountriesList(orgCountriesParams),
        getMinesList(orgMinesParams)
      ]).then(([provs, countries, mines]) => {
        if (provs) setOrgProvTypes(provs.map(p => p.id))
        if (countries) setOrgCountries(countries.map(c => c.id))
        if (mines) setOrgMines(mines.map(m => m.id))
      })
    }
  }, [orgProvParams, orgCountriesParams, orgMinesParams])

  useEffect(() => {
    if (organization) {
      setFields([
        {
          label: 'Common Name',
          value: organization.commonName,
          name: 'commonName',
          componentName: 'textInput',
          canEdit: isAdmin
        },
        {
          label: 'Legal Name',
          value: organization.legalName,
          name: 'legalName',
          componentName: 'textInput',
          canEdit: isAdmin
        },
        {
          label: 'Corporate Number',
          value: organization.corporateNumber,
          name: 'corporateNumber',
          componentName: 'textInput',
          canEdit: isAdmin
        },
        {
          label: 'Tax Number',
          value: organization.taxNumber,
          name: 'taxNumber',
          componentName: 'textInput',
          canEdit: isAdmin
        },
        {
          label: 'Organization Type',
          value: organization.orgType,
          name: 'orgType',
          componentName: 'dropdown',
          options: [
            { value: 'ADMIN', label: 'Admin' },
            { value: 'DEMO', label: 'Demo' },
            { value: 'GENERIC', label: 'Generic' },
            { value: 'REGULAR', label: 'Regular' }
          ],
          canEdit: isAdmin
        },
        {
          label: 'Condition',
          value: organization.condition,
          name: 'condition',
          componentName: 'dropdown',
          options: [
            { value: 'ACTIVE', label: 'Active' },
            { value: 'ARCHIVED', label: 'Archived' },
            { value: 'DELETED', label: 'Deleted' }
          ],
          canEdit: isAdmin
        },
        {
          label: 'Seller Type',
          componentName: 'fieldset',
          span: true,
          shouldDisplay: isAdmin,
          canEdit: isAdmin,
          children: [
            {
              label: 'Clara Seller',
              value: organization.isClaraSeller,
              name: 'isClaraSeller',
              componentName: 'checkbox',
              canEdit: true
            },
            {
              label: 'Lucara Seller',
              value: organization.isLucaraSeller,
              name: 'isLucaraSeller',
              componentName: 'checkbox',
              canEdit: true
            }
          ]
        },
        {
          label: 'Email CCs',
          value: organization.emailCcs?.join('\r\n'),
          name: 'emailCcs',
          componentName: 'textArea',
          canEdit: true,
          rows: 5
        },
        {
          label: 'Email BCCs',
          value: organization.emailBccs?.join('\r\n'),
          name: 'emailBccs',
          componentName: 'textArea',
          canEdit: true,
          rows: 5
        },
        {
          label: 'Compliance',
          componentName: 'fieldset',
          children: [
            {
              label: 'Status',
              componentName: 'dropdown',
              value: organization.compliance?.status,
              name: 'compliance.status',
              options: complianceStatuses?.map(s => ({ value: s.value, label: s.description })),
              canEdit: false,
              isClearable: false
            },
            {
              label: 'Expiry',
              value: organization.compliance?.expires,
              name: 'compliance.expires',
              componentName: 'textinput',
              type: 'date',
              canEdit: false
            }
          ]
        },
        ...(
          Object.entries(organization.licences).map(([licenceType, licence]) => ({
            label: licenceType,
            componentName: 'fieldset',
            key: `licence-${licenceType}`,
            children: [
              {
                label: 'Status',
                componentName: 'dropdown',
                value: licence?.status,
                name: `licences.${licenceType}.status`,
                options: licenceStatuses?.map(s => ({ value: s.value, label: s.description })),
                canEdit: false,
                isClearable: false
              },
              {
                label: 'Expiry',
                value: licence?.expires,
                name: `licences.${licenceType}.expires`,
                componentName: 'textinput',
                type: 'date',
                canEdit: false
              }
            ]
          }))
        ),
        {
          label: 'Addresses',
          customComponent: EditAddressForm,
          formData: organization.address,
          value: organization.address,
          name: 'address',
          span: true,
          canEdit: true,
          addressRef
        },
        {
          legend: 'Buyer Settings',
          componentName: 'fieldset',
          name: 'buyerSettings',
          span: false,
          canEdit: true,
          children: [
            {
              legend: 'Sale Limits',
              componentName: 'fieldset',
              name: 'limits',
              span: false,
              canEdit: true,
              children: [
                {
                  label: 'Purchase Limit',
                  value: organization.purchaseLimit,
                  componentName: 'textinput',
                  name: 'purchaseLimit',
                  type: 'currency',
                  infoTip: true,
                  canEdit: true
                },
                {
                  label: 'Purchase Minimum',
                  value: organization.purchaseMinimum,
                  componentName: 'textinput',
                  name: 'purchaseMinimum',
                  type: 'currency',
                  infoTip: true,
                  canEdit: true
                },
                {
                  label: 'Shipping Minimum',
                  value: organization.shippingMinimum,
                  componentName: 'textinput',
                  name: 'shippingMinimum',
                  type: 'currency',
                  infoTip: true,
                  canEdit: true
                }
              ]
            },
            {
              legend: 'Advanced Pricing',
              componentName: 'fieldset',
              name: 'advancedPricing',
              span: false,
              canEdit: true,
              children: [
                {
                  label: 'Show Color Calc Baselines',
                  value: organization.showComputedBaselines,
                  name: 'showComputedBaselines',
                  componentName: 'checkbox',
                  infoTip: true,
                  canEdit: true,
                  handleChange: async (e, doChange) => {
                    if (e?.currentTarget?.value || e?.target?.value) {
                      return setModal({
                        id: 'advancedPricingShowComputedBaselines',
                        title: 'Confirm Advanced Pricing Computed Baselines Change',
                        message: <>
                            This will lead to changes in the layout of Buyer Settings and Order sections.
                          <br/>
                          <b>Your saved and submitted orders will also be affected, please review their pricing accordingly.</b>
                          <br/>
                        </>,
                        checkbox: {
                          name: 'advancedPricingShowComputedBaselinesCheckbox',
                          message: 'By checking this box you hereby confirm you are aware of the effects this change will have on Buyer Settings and Orders.'
                        },
                        onSubmit: () => doChange()
                      })
                    }
                    return doChange()
                  }
                },
                {
                  label: 'Show Costs',
                  value: organization.showCosts,
                  name: 'showCosts',
                  componentName: 'checkbox',
                  infoTip: true,
                  canEdit: true,
                  handleChange: async (e, doChange) => {
                    if (e?.currentTarget?.value || e?.target?.value) {
                      return setModal({
                        id: 'advancedPricingShowCosts',
                        title: 'Confirm Advanced Pricing Costs Change',
                        message: <>
                            This will lead to changes in the layout of Buyer Settings and Order sections.
                          <br/>
                          <b>Your saved and submitted orders will also be affected, please review their pricing accordingly.</b>
                          <br/>
                        </>,
                        checkbox: {
                          name: 'advancedPricingShowCostsCheckbox',
                          message: 'By checking this box you hereby confirm you are aware of the effects this change will have on Buyer Settings and Orders.'
                        },
                        onSubmit: () => doChange()
                      })
                    }
                    return doChange()
                  }
                }
              ]
            },
            {
              legend: 'Saw Plane Thickness',
              componentName: 'fieldset',
              name: 'sawPlane',
              span: false,
              canEdit: true,
              children: [
                {
                  label: 'Thickness',
                  name: 'sawPlane.thickness',
                  componentName: 'textinput',
                  value: organization.sawPlane ? organization.sawPlane.thickness : sawPlane?.thickness,
                  canEdit: true,
                  type: 'number'
                },
                {
                  id: 'units',
                  name: 'sawPlane.units',
                  label: 'Units',
                  componentName: 'radioGroup',
                  value: organization.sawPlane ? organization.sawPlane.units : sawPlane?.units,
                  options: ORG.SAW_THICKNESS_UNITS,
                  canEdit: true
                }
              ]
            },
            {
              legend: 'Stone Types',
              componentName: 'fieldset',
              span: false,
              canEdit: true,
              children: [
                {
                  label: 'High Tension',
                  name: 'highTensionMaxParts',
                  componentName: 'dropdown',
                  value: String(organization.highTensionMaxParts ?? 1),
                  isClearable: false,
                  options: ORG.ACCEPT_HIGH_TENSION,
                  canEdit: true,
                  infoTip: true
                },
                {
                  label: 'Yellow UV',
                  name: 'rejectYellowFluor',
                  componentName: 'dropdown',
                  value: organization.rejectYellowFluor ? 'true' : 'false',
                  isClearable: false,
                  options: ORG.ACCEPT_YELLOW_FLU,
                  canEdit: true
                },
                {
                  label: 'Windowed Stones',
                  name: 'acceptWindowedStones',
                  componentName: 'dropdown',
                  infoTip: true,
                  value: String(organization.acceptWindowedStones ?? ''),
                  isClearable: false,
                  options: ORG.ACCEPT_WINDOWED_STONES,
                  canEdit: hasPermission(orderActions.editMultipleOrders)
                },
                {
                  label: 'Blocked Stones',
                  name: 'acceptBlockedStones',
                  componentName: 'dropdown',
                  infoTip: true,
                  value: String(organization.acceptBlockedStones ?? ''),
                  isClearable: false,
                  options: ORG.ACCEPT_BLOCKED_STONES,
                  canEdit: hasPermission(orderActions.editMultipleOrders)
                }
              ]
            }
          ]
        },
        {
          legend: 'Seller Settings',
          componentName: 'fieldset',
          name: 'sellerSettings',
          span: false,
          canEdit: isAdmin,
          children: [
            {
              label: 'Allowed Provenance Types',
              value: orgProvTypes,
              name: 'orgProvTypes',
              componentName: 'dropdown',
              options: provTypes?.map(i => ({ label: i.description, value: i.id })),
              canEdit: isAdmin,
              isMulti: true
            },
            {
              label: 'Allowed Countries',
              value: orgCountries,
              name: 'orgCountries',
              componentName: 'dropdown',
              options: countries?.map(i => {
                const isDisabled = ['ARCHIVED', 'DELETED'].includes(i.condition)
                return { value: i.id, label: `${i.name}${isDisabled ? ' (Inactive)' : ''}`, isDisabled }
              }),
              canEdit: isAdmin,
              isMulti: true
            },
            {
              label: 'Allowed Mines',
              value: orgMines,
              name: 'orgMines',
              componentName: 'dropdown',
              options: mines?.map(i => {
                const isDisabled = ['ARCHIVED', 'DELETED'].includes(i.condition)
                return { value: i.id, label: `${i.name}${isDisabled ? ' (Inactive)' : ''}`, isDisabled }
              }),
              canEdit: isAdmin,
              isMulti: true
            },
            // {
            //   label: 'Allowed Pipes',
            //   value: orgPipes,
            //   name: 'orgPipes',
            //   componentName: 'dropdown',
            //   options: [],
            //   canEdit: isAdmin,
            //   isMulti: true
            // },

            {
              label: 'Anonymous on Blockchain',
              value: organization.bcAnonymous || false,
              name: 'bcAnonymous',
              id: 'bcAnonymous',
              componentName: 'toggleSwitch',
              canEdit: isAdmin
            },
            {
              label: 'Minimum Matching Margin %',
              value: organization.minMatchingMargin * 100,
              type: 'number',
              name: 'minMatchingMargin',
              componentName: 'textInput',
              canEdit: isAdmin,
              visible: isAdmin,
              infoTip: InfoTip.messages.minMatchingMargin
            },
            {
              label: 'Minimum Clara Margin %',
              value: organization.minClaraMargin * 100,
              type: 'number',
              name: 'minClaraMargin',
              componentName: 'textInput',
              canEdit: isAdmin,
              visible: isAdmin,
              infoTip: InfoTip.messages.minClaraMargin
            },
            {
              label: 'Profit Split %',
              value: organization.profitSplit * 100,
              type: 'number',
              name: 'profitSplit',
              componentName: 'textInput',
              canEdit: isAdmin,
              visible: isAdmin,
              infoTip: InfoTip.messages.profitSplit
            }
          ]
        },
        {
          label: 'Invoice Notes',
          value: organization.invoiceNotes,
          name: 'invoiceNotes',
          componentName: 'textArea',
          canEdit: isAdmin
        },
        {
          label: 'Compliance Comments',
          value: organization.comments,
          name: 'comments',
          componentName: 'textArea',
          canEdit: isAdmin,
          visible: isAdmin
        },
        {
          label: 'Preferred Shipping Method',
          value: organization.preferredShipping,
          name: 'preferredShipping',
          componentName: 'dropdown',
          options: ORG.PREFERRED_SHIPPING,
          canEdit: true,
          isClearable: false
        },
        {
          label: 'Risk Level',
          value: organization.riskLevel || '',
          name: 'riskLevel',
          componentName: 'dropdown',
          options: ORG.RISK_LEVEL,
          canEdit: isAdmin,
          visible: isAdmin,
          isClearable: false
        },
        {
          label: 'Created At',
          value: organization.createdAt ? moment(organization.createdAt).toLocaleString() : ''
        },
        {
          label: 'Last Updated',
          value: organization.updatedAt ? moment(organization.updatedAt).toLocaleString() : ''
        }
      ])
    }
  }, [organization, orgProvTypes, provTypes, orgCountries, countries, orgMines, mines, complianceStatuses, licenceStatuses, invoicePaymentDetails, isAdmin])

  async function handleOnSanitize(formValues) {
    const organizationData = clone(formValues)
    if (organizationData.address) {
      organizationData.address = organizationData.address.map((addr) => ({
        ...addr,
        compliance: objectUtils.removeEmptyStrings(addr.compliance),
        licences: objectUtils.removeEmptyStrings(addr.licences, { deep: true }),
        userDocuments: arrayUtils.pickBy(addr.userDocuments, ['name', 'documentType', 'expires']).map(doc => objectUtils.removeEmptyStrings(doc, { replacement: undefined })).filter(doc => !objectUtils.isEmpty(objectUtils.filterNullish(doc)))
      }))
    }
    if (organizationData.emailCcs !== undefined) organizationData.emailCcs = organizationData.emailCcs ? organizationData.emailCcs.split('\n') : null
    if (organizationData.emailBccs !== undefined) organizationData.emailBccs = organizationData.emailBccs ? organizationData.emailBccs.split('\n') : null
    if (organizationData.minMatchingMargin !== undefined) organizationData.minMatchingMargin = numberUtils.isNumber(organizationData.minMatchingMargin) ? organizationData.minMatchingMargin / 100 : null
    if (organizationData.minClaraMargin !== undefined) organizationData.minClaraMargin = numberUtils.isNumber(organizationData.minClaraMargin) ? organizationData.minClaraMargin / 100 : null
    if (organizationData.profitSplit !== undefined) organizationData.profitSplit = numberUtils.isNumber(organizationData.profitSplit) ? organizationData.profitSplit / 100 : null
    if (organizationData.sawPlane !== undefined) {
      organizationData.sawPlane = {
        thickness: organizationData.sawPlane.thickness !== undefined ? organizationData.sawPlane.thickness
        // fallback
          : (organization.sawPlane?.thickness ?? sawPlane.thickness),
        units: organizationData.sawPlane.units
        // fallback
        ?? (organization.sawPlane?.units ?? sawPlane.units)
      }
    }
    if (organizationData.riskLevel !== undefined) organizationData.riskLevel = !textUtils.isEmpty(organizationData.riskLevel) ? organizationData.riskLevel : null

    if (organizationData.acceptWindowedStones !== undefined) organizationData.acceptWindowedStones = textUtils.isEmpty(organizationData.acceptWindowedStones) ? null : organizationData.acceptWindowedStones === 'true'
    if (organizationData.acceptBlockedStones !== undefined) organizationData.acceptBlockedStones = textUtils.isEmpty(organizationData.acceptBlockedStones) ? null : organizationData.acceptBlockedStones === 'true'
    return organizationData
  }

  function handleHasValuesChanged(editedValues, originalValues) {
    const hasChanged = {}
    const skipFields = []

    if (editedValues?.address) {
      const addressField = originalValues?.find(field => field.name === 'address')
      const filteredAddress = objectUtils.filterNullish(editedValues.address, true)
      hasChanged.address = validationUtils.diffValues(Object.values(filteredAddress).map(a => a), addressField.value)
      skipFields.push('address')
    }

    Object.keys(editedValues).forEach(fieldName => {
      if (skipFields.includes(fieldName)) return
      const originalValue = originalValues.find(v => v.name === fieldName)
      if (originalValue != null) {
        hasChanged[fieldName] = validationUtils.diffValues(originalValue?.value, editedValues[fieldName])
      } else hasChanged[fieldName] = true
    })

    return Object.values(hasChanged).some(v => v)
  }

  async function validateAndSubmit(organizationValues) {
    const { address = organization.address, sawPlane, isClaraSeller, isLucaraSeller } = organizationValues
    const errors = []
    if (address.length) {
      const { s: totalShipping, b: totalBilling, isValid } = address.reduce((total, { shipping: s, billing: b, condition, ...restAddress }, idx) => Object.assign(
        ...(
          !['ARCHIVED', 'DELETED'].includes(condition)
            ? [
              { s: total.s + Number(s ?? false) },
              { b: total.b + Number(b ?? false) }
            ]
            : [{ s: total.s, b: total.b }]
        ),
        {
          isValid: total.isValid && addressRef.current?.validateForm?.({
            shipping: s,
            billing: b,
            condition,
            ...restAddress
          }, idx)?.isValid
        }
      ), { s: 0, b: 0, isValid: true })
      if (totalShipping !== 1 || totalBilling !== 1) errors.push('Your Organization profile should have only one billing address and only one shipping address selected at any point in time')
      if (!isValid) errors.push('Please clear the highlighted address errors before saving.')

      const entityNames = address.map(addr => addr.name)
      if (entityNames.length !== new Set(entityNames).size) {
        errors.push('Entity name must be unique.')
      }

      if (address.every(addr => addr.condition === 'ARCHIVED')) errors.push('At least one active address is required.')
    }
    if ((sawPlane?.thickness === null || sawPlane?.thickness < 0)) errors.push('Saw Plane Thickness must be a positive number.')

    if (isLucaraSeller && isClaraSeller) errors.push('Clara Seller and Lucara Seller cannot both be selected simultaneously.')

    if (errors.length) {
      errors.forEach(error => showErrorToast(error))
      throw new Error(errors)
    } else {
      return handleOnSubmit(organizationValues)
    }
  }

  async function handleOnSubmit({
    orgProvTypes,
    orgCountries,
    orgMines,
    condition,
    addresses,
    acceptWindowedStones,
    acceptBlockedStones,
    ...editedValues
  }) {
    try {
      // Confirm Submit
      const postSubmitOptions = {
        ordersRoughs: { suspend: false, archive: false },
        users: { suspend: false },
        acceptStones: {}
      }
      if (condition && condition !== organization.condition && condition !== 'ACTIVE') {
        await confirmSuspendModal('condition')
        postSubmitOptions.ordersRoughs.suspend = true
        postSubmitOptions.ordersRoughs.archive = true
        postSubmitOptions.users.suspend = true
      } else if (editedValues.address?.filter(addr => addr.condition !== 'ARCHIVED')?.some(addr => addr?.compliance?.status === 'INCOMPLETE')) {
        await confirmSuspendModal('compliance')
        postSubmitOptions.ordersRoughs.suspend = true
      }
      if (
        (acceptWindowedStones !== undefined && acceptWindowedStones !== organization.acceptWindowedStones)
        || (acceptBlockedStones !== undefined && acceptBlockedStones !== organization.acceptBlockedStones)
      ) {
        const keys = [
          ...(acceptWindowedStones !== undefined ? ['windowed'] : []),
          ...(acceptBlockedStones !== undefined ? ['blocked'] : [])
        ]
        const override = await confirmAcceptStonesModal(keys)
        if (override) {
          if (keys.includes('windowed')) postSubmitOptions.acceptStones.acceptWindowedStones = acceptWindowedStones
          if (keys.includes('blocked')) postSubmitOptions.acceptStones.acceptBlockedStones = acceptBlockedStones
        }
      }

      // Seller Settings
      await Promise.all([
        orgProvTypes ? provenanceActions
          .setProvenanceTypes({ orgId: organization.orgId, provenanceTypeIds: orgProvTypes })
          .then(() => resetProvenanceTypesList(orgProvParams)) : Promise.resolve(),
        orgCountries ? provenanceActions
          .setCountries({ orgId: organization.orgId, countryIds: orgCountries })
          .then(() => resetCountriesList(orgCountriesParams)) : Promise.resolve(),
        orgMines ? provenanceActions
          .setMines({ orgId: organization.orgId, mineIds: orgMines })
          .then(() => resetMinesList(orgMinesParams)) : Promise.resolve()
      ])

      // Condition
      if (condition) {
        await organizationActions
        .setOrgCondition({ orgId: organization.orgId, condition })
        .then(() => { delete editedValues.condition })
      }

      // Rest Updates
      if (Object.keys(editedValues).length) await saveOrganization(organization.orgId, { ...editedValues, acceptWindowedStones, acceptBlockedStones })

      // Suspend Orders/Roughs/Users
      await Promise.all([
        postSubmitOptions.ordersRoughs.suspend ? suspendOrders(organization.orgId, postSubmitOptions.ordersRoughs.archive) : Promise.resolve(),
        postSubmitOptions.ordersRoughs.suspend ? suspendRoughs(organization.orgId, postSubmitOptions.ordersRoughs.archive)
          : Promise.resolve(),
        postSubmitOptions.users.suspend ? suspendUsers(organization.orgId) : Promise.resolve(),
        Object.keys(postSubmitOptions.acceptStones).length ? overrideOrdersAcceptStones(organization.orgId, postSubmitOptions.acceptStones) : Promise.resolve()
      ])
      declareVictory()
    } catch (err) {
      console.error(err)
      // Keep the org in edit mode
      throw new Error(err)
    }
  }

  function saveOrganization(orgId, editedValues) {
    return organizationActions
    .editOrganization({ orgId, ...editedValues })
  }

  function declareVictory() {
    showSuccessToast('Organization saved.')
    resetOrgsList()
    getOrgsList()
  }

  async function confirmSuspendModal(type) {
    const messages = {
      condition: (
        <>
            Changing the organization condition will suspend and archive any users, orders and roughs stones
            for this organization. Are you sure you want to continue?
        </>
      ),
      compliance: (<>
      Changing the compliance status to incomplete will suspend any submitted orders and roughs stones
      for this organization as well. Are you sure you want to continue?
      </>)
    }
    return await new Promise((resolve, reject) => {
      setModal({
        id: 'organizationConfirmSuspend',
        title: 'Confirm Suspend',
        message: messages[type],
        buttonOptions: {
          submitText: 'Yes',
          cancelText: 'No'
        },
        onSubmit: resolve,
        onCancel: reject
      })
    })
  }

  async function confirmAcceptStonesModal(keys) {
    return await new Promise((resolve, reject) => {
      setModal({
        id: 'editOrganizationAcceptStones',
        title: 'Accept Stones',
        customMessageRenderer: (messageProps) => <AcceptStonesModal keys={keys} {...messageProps} />,
        onSubmit: (res) => resolve(res[0]?.overrideOrders),
        onCancel: reject,
        type: 'form'
      })
    })
  }

  async function suspendOrders(buyerId, archive = false) {
    return orderActions.suspendBuyersOrders({ buyerId: orgId })
    .then(() => { if (archive) orderActions.setOrderCondition({ buyerId, condition: 'ARCHIVED' }) })
    .then(() => showSuccessToast('Orders suspended.'))
  }
  async function suspendRoughs(sellerId, archive = false) {
    return roughStoneActions.suspendSellerRoughs({ sellerId })
    .then(() => { if (archive) roughStoneActions.setRoughCondition({ sellerId, condition: 'ARCHIVED' }) })
    .then(() => showSuccessToast('Roughs suspended.'))
  }

  async function suspendUsers(orgId) {
    return userActions.setUserCondition({ orgId, enabled: false })
    .then(() => showSuccessToast('Users suspended.'))
  }
  async function overrideOrdersAcceptStones(orgId, opts) {
    return orderActions.editMultipleOrders({ buyerId: orgId, additionalFilters: { ...opts } })
    .then(() => showSuccessToast('Orders updated.'))
  }
  const canEdit = isEditPermitted && (organization?.compliance?.status === 'COMPLETE' || isAdmin)

  return (
    <DetailsPage
      canEdit={canEdit}
      isEdit={operation === 'edit'}
      fields={fields}
      onSanitize={handleOnSanitize}
      onHasValuesChanged={handleHasValuesChanged}
      onSubmit={validateAndSubmit}
      title={{
        label: 'Clara ID',
        value: organization?.orgId || ''
      }}
      propsByRef={{ addressRef }}
    />
  )
}

OrganizationDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}
export default OrganizationDetails
