import * as Yup from 'yup'
import { required, oneOf, requiredToSubmit } from './messages'
import { order } from './models/models'

// schema extensions
// consider moving some reusable schema chains to extensions here ex. string is required when id is null for maxColour, minColour, maxClarity, etc.

// schema
const orderDetailsErrorValidationSchema = Yup.object({
  name: Yup.string().nullable(true).transform(v => v ?? '').min(1, required('Name'))
})
const orderDetailsWarningValidationSchema = (constants) => Yup.object({
  shapeId: Yup.string().uuid().nullable(true).transform(v => v ?? '').min(1, requiredToSubmit('Shape')),
  cutGradeId: Yup.string().uuid().nullable(true).transform(v => v ?? '').min(1, requiredToSubmit('Cut Grade')),
  priceBaselineId: Yup.string().uuid().nullable(true)
  .test('discountPricingBaselineRequired', requiredToSubmit('Baseline'), (val, { options: { context: { form } } }) => {
    const existingVal = form?.priceBaselineId
    if (val !== null && existingVal) return true
    return !form?.priceScheme?.find((range) => (range?.discountTable?.length && range?.pricingMethod !== 'ppc'))
  }),
  additionalFilters: Yup.object({
    countryIds: Yup.array(Yup.string().oneOf(constants?.COUNTRIES, oneOf('Country'))).nullable(true).min(1),
    mineIds: Yup.array(Yup.string().oneOf(constants?.MINES, oneOf('Mine'))).nullable(true).min(1)
  }),
  priceScheme: order.priceScheme(constants),
  lines: order.orderLine(constants)
})

const createOrderNameShapeCutGradeErrorValidationSchema = Yup.object({
  name: Yup.string().nullable().required(required('Order Name'))
})

const createOrderNameShapeCutGradeWarningValidationSchema = Yup.object({
  shapeId: Yup.string().nullable().required(requiredToSubmit('Shape')),
  cutGradeId: Yup.string().nullable().required(requiredToSubmit('Cut Grade'))
})

const createOrderPricingStepWarningValidationSchema = (constants) => Yup.object({
  priceScheme: order.priceScheme(constants, {
    priceTable: Yup.mixed().when('pricingMethod', {
      is: (method) => method === 'ppc',
      then: () => order.priceTable().required(),
      otherwise: () => Yup.array().nullable(true)
    }),
    discountTable: Yup.mixed().when('pricingMethod', {
      is: (method) => method === 'discount',
      then: () => order.discountTable().required(),
      otherwise: () => Yup.array().nullable(true)
    })
  })
})

const createOrderCostsAndBaselinesWarningValidationSchema = Yup.object({
  priceBaselineId: Yup.string().uuid().nullable(true)
  .test('discountPricingBaselineRequired', requiredToSubmit('Baseline'), (val, { options: { context: { form } } }) => {
    const existingVal = form?.priceBaselineId
    if (val !== null && existingVal) return true
    return !form?.priceScheme?.find((range) => (range?.discountTable?.length && range?.pricingMethod !== 'ppc'))
  })
})
const createOrderOrderLinesWarningValidationSchema = (constants) => Yup.object({
  lines: order.orderLine(constants)
})

export const orderSchema = {
  orderDetailsErrorValidationSchema,
  orderDetailsWarningValidationSchema,
  createOrderNameShapeCutGradeErrorValidationSchema,
  createOrderNameShapeCutGradeWarningValidationSchema,
  createOrderPricingStepWarningValidationSchema,
  createOrderCostsAndBaselinesWarningValidationSchema,
  createOrderOrderLinesWarningValidationSchema
}
