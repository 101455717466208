/* global FileReader, atob, Blob */

import FileSaver from 'file-saver'
import text from './text'
import md5 from 'js-md5'
import arrayUtils from './array'

const promiseReadBinaryFile = function(f) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onloadend = () => resolve(fr.result)
    fr.onabort = reject
    fr.onerror = reject
    fr.readAsArrayBuffer(f)
  })
}

const promiseReadFile = function(f) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onloadend = () => resolve(fr.result)
    fr.onabort = reject
    fr.onerror = reject
    fr.readAsText(f)
  })
}

const promiseReadBase64File = function(f) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onloadend = () => {
      const match = fr.result.match(/^data:[\w-]+\/[\w.-]+;base64,([a-zA-Z0-9+/=]+)$/)
      if (!match) reject(new Error('Data is not in expected base64 format.'))
      else resolve(match[1])
    }
    fr.onabort = reject
    fr.onerror = reject
    fr.readAsDataURL(f)
  })
}

// This function came from stack overflow
function base64ToBlob(base64String, contentType = 'application/octet-stream') {
  const sliceSize = 1024
  const byteCharacters = atob(base64String)
  const bytesLength = byteCharacters.length
  const slicesCount = Math.ceil(bytesLength / sliceSize)
  const byteArrays = new Array(slicesCount)

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize
    const end = Math.min(begin + sliceSize, bytesLength)

    const bytes = new Array(end - begin)
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return new Blob(byteArrays, { type: contentType })
}

const saveBase64Excel = function(base64String, fileName) {
  const blob = base64ToBlob(base64String, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
  FileSaver.saveAs(blob, fileName)
}

const saveBufferExcel = function(buffer, fileName) {
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  FileSaver.saveAs(blob, fileName)
}

const saveTextFile = function(fileString, fileName, { mimeType = 'text/plain', charSet = 'utf-8' } = {}) {
  FileSaver.saveAs(new Blob([fileString], { type: `${mimeType};charset=${charSet}` }), fileName)
}

// Safely read the contents of Excel cell
// TODO: expand this function to handle string types better
const parseExcelCell = function(cell) {
  let val
  if (!cell) return val

  // Handle formulas
  if (cell.result != null) val = Number(cell.result)
  // Handle numbers
  else if (cell.text !== '') val = Number(cell.text)

  // Convert percentages to decimal
  if (text.isPercentFmt(cell.numFmt)) val *= 100
  return val
}

const getFileName = (f, options = {}) => {
  const extractName = () => {
    if (!f) return null
    if (f.downloadName) return f.downloadName
    if (f.originalName) return f.originalName
    if (f.key) {
      const idx = f.key.lastIndexOf('/') + 1
      if (idx > 0 && idx < f.key.length) return f.key.slice(idx)
    }
    if (f.url) {
      const idx = f.url.lastIndexOf('/') + 1
      if (idx > 0 && idx < f.url.length) return f.url.slice(idx)
    }
    if (f.key) return f.key
    if (f.url) return f.url
    return null
  }
  const name = extractName()
  if (name && options.shorten) return shortenFileName(name)
  return name
}

const shortenFileName = (fileName, maxLength = 40) => {
  if (!fileName) return fileName
  if (fileName.length < maxLength) return fileName

  const idx = fileName.lastIndexOf('.')
  const prefix = idx < 0 ? fileName : fileName.slice(0, idx)
  const suffix = idx < 0 ? '' : fileName.slice(idx)
  // return fileName.slice(0, maxLength - suffix.length - 1) + '\u2026' + suffix
  return prefix.slice(0, Math.floor((maxLength - suffix.length) * 3 / 4) - 1) + '\u2026' + prefix.slice(-Math.ceil((maxLength - suffix.length) / 4)) + suffix
}

const getFileHash = async (file) => {
  if (file) {
    try {
      const fileBuffer = await file.arrayBuffer()
      return md5(new Uint8Array(fileBuffer))
    } catch (error) {
      return new Error(error)
    }
  }
}

const getExcelRows = (data, columns) => data.map((item, idx) => {
  const changedFields = columns.reduce((chObj, col) => col.transform ? { ...chObj, [col.key]: col.transform(item[col.key], item) } : chObj, {})
  return { ...item, ...changedFields }
})

// Provide a xlsx sheet, header row values as an array, and percent to match. The first row that meets the match percentage will be returned.
// sheet, [header0, header1, header2, ...] => headerRowIdx OR -1
const findHeaderRowIdx = (sheet, headers, pct = 0.5) =>
  Array.from({ length: sheet.rowCount + 1 }, (_, i) => i).findIndex(idx => {
    const row = sheet.getRow(idx)
    const matches = arrayUtils.intersectBy(row.values, headers, x => String(x)?.toLowerCase()?.trim()).length
    const matchPct = matches / headers.length
    return matchPct >= pct
  })

const addRow = (sheet, row) => {
  const r = sheet.addRow([])
  if (Array.isArray(row)) fillRow(r, row)
  else if (typeof row === 'object') fillRowColumns(r, row)
}

const spliceRows = (sheet, start, count, ...rows) => {
  sheet.spliceRows(start, count)
  for (let i = 0; i < rows.length; i++) {
    const r = sheet.insertRow(start + i, [])
    fillRow(r, rows[i])
  }
}

const fillRow = (r, row, startCol = 1) => {
  row.forEach((cell, i) => {
    if (cell == null) return
    const c = r.getCell(i + startCol)
    if (typeof cell === 'object') {
      const { result, formula, ...restCell } = cell
      for (const key in restCell) {
        c[key] = cell[key]
      }
      // Override value property if formula is present
      if ('formula' in cell) c.value = { result, formula }
    } else {
      c.value = cell
    }
  })
}

const setRowCells = fillRow

const fillRowColumns = (r, row) => {
  for (const [colName, cell] of Object.entries(row)) {
    if (cell == null) return
    const c = r.getCell(colName)
    if (typeof cell === 'object') {
      const { result, formula, ...restCell } = cell
      for (const key in restCell) {
        c[key] = cell[key]
      }
      // Override value property if formula is present
      if ('formula' in cell) c.value = { result, formula }
    } else {
      c.value = cell
    }
  }
}

const setCellStyle = (cell, style) => {
  if (!style) return
  for (const key in style) {
    cell[key] = style[key]
  }
}

export default {
  getFileHash,
  promiseReadBinaryFile,
  promiseReadBase64File,
  promiseReadFile,
  saveBase64Excel,
  saveBufferExcel,
  saveTextFile,
  parseExcelCell,
  getFileName,
  shortenFileName,
  getExcelRows,
  findHeaderRowIdx,
  addRow,
  spliceRows,
  setRowCells,
  setCellStyle
}
