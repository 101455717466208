import React, { useState, useEffect } from 'react'
import { useGlobalsStore, useAuthStore } from '@stores'
import { Table } from '@organisms'
import { costActions, organizationActions } from '@actions'
import { useHistory, NavLink } from 'react-router-dom'
import { InfoTip } from '@molecules'
import { useToast } from '@hooks'

function CostList({ costType, ...props }) {
  const costTypeLower = costType.toLowerCase()
  const costTypeCap = costTypeLower.charAt(0).toUpperCase() + costTypeLower.slice(1)
  const [costList, setCostList] = useState([])
  const { showSuccessToast } = useToast()
  const { hasAdmin, permissionsAdminCache } = useAuthStore(state => state)
  const { orgsList: { all: orgsList }, getOrgsList } = useGlobalsStore()
  const [columns, setColumns] = useState([])
  const history = useHistory()
  const costAction = costTypeLower === 'mfg'
    ? costActions.setMfgCostCondition
    : costActions.setCertCostCondition

  useEffect(() => {
    getCostsList('ACTIVE')
  }, [])

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    setColumns([
      {
        Header: 'Name',
        accessor: 'name',
        dataType: 'string',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/buyer-settings/${costTypeLower}costs/${cellInfo.row.original.id}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value}
            </NavLink>
          )
        }
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      },
      ...(hasAdmin(organizationActions.getOrganizationList) || costTypeLower === 'cert' ? [{
        Header: 'Owner',
        accessor: (row) => row.buyerId == null ? '* Preloaded *' : (orgsList?.find(org => org.orgId === row.buyerId)?.commonName || ''),
        dataType: 'string',
        id: 'owner'
      }] : [])
    ])
  }, [orgsList, permissionsAdminCache])

  function getCostsList(condition) {
    costActions[`get${costTypeCap}CostList`]({ condition })
    .then(response => setCostList(response.data.data))
    .catch(err => console.error(err))
  }

  function handleArchive(row) {
    return costAction([row.id], 'ARCHIVED')
    .then(() => {
      showSuccessToast('Cost has been archived.')
    })
  }

  function handleUnarchive(row) {
    return costAction([row.id], 'ACTIVE')
    .then(() => {
      showSuccessToast('Cost has been unarchived.')
    })
  }

  function handleRemove(row) {
    return costAction([row.id], 'DELETED')
    .then(() => {
      showSuccessToast('Cost has been removed.')
    })
  }

  const rowActions = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.buyerId && row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.buyerId && row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      callback: handleRemove,
      shouldConfirm: true,
      shouldDisplay: (row) => row.buyerId && row.condition !== 'DELETED'
    }
  ]

  const topBarActions = [{
    label: `Create ${costTypeCap} Cost`,
    callback: () => history.push(`/buyer-settings/${costTypeLower}costs/create`)
  }]

  const tableTabs = [{
    label: 'Active',
    params: 'ACTIVE'
  },
  {
    label: 'Archived',
    params: 'ARCHIVED'
  }]

  const initialSort = [{
    id: 'owner',
    desc: true
  },
  {
    id: 'updatedAt',
    desc: true
  }
  // {
  //   id: 'name',
  //   desc: false
  // }
  ]

  return (
    <Table
      id={costTypeLower}
      title={<>{costTypeLower === 'mfg' ? 'Manufacturing' : 'Certificate'} Costs <InfoTip name={`${costTypeLower}Cost`} /></>}
      data={costList}
      columns={columns}
      tableTabs={tableTabs}
      rowActions={rowActions}
      initialSort={initialSort}
      getDataCallback={getCostsList}
      topBarActions={topBarActions}
      isMultiSelect={false}
    />
  )
}

export default CostList
