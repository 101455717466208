import React from 'react'
import PropTypes from 'prop-types'
import FileSaver from 'file-saver'
import { useProgressList } from '@hooks'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

function DownloadLink({
  name,
  label,
  text,
  url,
  onClick,
  fileName,
  addToProgressList
}) {
  const {
    createProgressList,
    getProgressList
  } = useProgressList()

  function handleOnClick() {
    if (onClick && typeof onClick === 'function') {
      // custom onClick function
      onClick()
      return
    }

    if (!fileName) FileSaver.saveAs(url)
    else {
      const downloadLinkAxios = axios.create()
      if (!addToProgressList) {
        downloadLinkAxios.get(url, {
          responseType: 'blob'
        })
        .then(res => FileSaver.saveAs(res.data, fileName))
        return
      }

      const listName = 'downloadLink'
      const list = getProgressList(listName)
        ? getProgressList(listName)
        : createProgressList({
          name: listName,
          title: 'Download Files',
          concurrency: 1,
          autoStart: true
        })

      const task = list.newTask()
      task.id = uuidv4()
      task.name = fileName || text || url
      task.callback = async () => downloadLinkAxios.get(url, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          list.updateTaskProgressPercentage(task.id, percentage)
        }
      })
      .then(res => FileSaver.saveAs(res.data, fileName))

      list.addTask(task)
    }
  }

  return (
    <div className='link-component'>
      <span className="label">{label}</span>
      <span className="link-component__link" onClick={handleOnClick}>{text || url}</span>
    </div>
  )
}

DownloadLink.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  fileName: PropTypes.string,
  addToProgressList: PropTypes.bool
}

DownloadLink.defaultProps = {
  url: '',
  name: '',
  text: '',
  label: '',
  fileName: '',
  addToProgressList: false
}

export default DownloadLink
