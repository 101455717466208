import React, { useState, useEffect, useMemo } from 'react'
import { Table } from '@organisms'
import { cutGradeActions, orderActions, organizationActions, reportActions } from '@actions'
import { useAuthStore, useGlobalsStore, useOrderStore, useModalStore } from '@stores'
import { useHistory, NavLink } from 'react-router-dom'
import Excel from 'exceljs/dist/es5/exceljs.browser'
import { useToast } from '@hooks'
import SubmitOrderConfirmationModal from './submitOrderConfirmationModal'
import PriceAdjustOrderModal from './priceAdjustOrderModal'
import ExportPriceTemplate from './exportPriceTemplate'
import PolishedPriceBookModal from './polishedPriceBookModal'
import { arrayUtils, errorUtils, fileUtils, textUtils } from '@utils'
import { COMMON } from '@constants'

function OrderList() {
  const [openModal, setOpenModal] = useState({ name: false, selectedRows: [] })
  const [ordersParams, setOrdersParams] = useState(getOrdersParams('ACTIVE'))
  const { setModal } = useModalStore(state => state)

  const {
    ordersList: { [ordersParams ? JSON.stringify(ordersParams) : 'all']: storeOrdersList },
    getOrdersList,
    removeAllOrdersListItems
  } = useOrderStore(store => store)
  const ordersList = useMemo(() => {
    if (!storeOrdersList || !storeOrdersList.data) return []
    return storeOrdersList.data.map((o) => ({
      ...o,
      totalQty: o.OrderLines.reduce((total, line) => (total + line.quantity), 0),
      filledQty: o.OrderLines.reduce((total, line) => (total + line.quantityFilled), 0)
    }))
  }, [storeOrdersList, ordersParams])

  useEffect(() => {
    refreshOrdersList('ACTIVE')
  }, [])
  useEffect(() => {
    if (storeOrdersList?.cacheInvalid) refreshOrdersList(ordersParams?.condition)
  }, [storeOrdersList?.cacheInvalid])

  function getOrdersParams(condition) {
    return {
      condition,
      columns: '[id, buyerId, shapeId, cutGradeId, name, expiryDate, priceSchemeType, priceBaselineId, mfgCostId, certCostId, tingeMapId, additionalFilters, status, replanDate, condition, createdAt, updatedAt, OrderLines]'
    }
  }

  function refreshOrdersList(condition = 'ACTIVE') {
    setOrdersParams(getOrdersParams(condition))
    getOrdersList(getOrdersParams(condition))
  }

  const { hasAdmin, permissionsAdminCache, orgId } = useAuthStore()
  const {
    orderStatusesMap,
    orderStatuses,
    getOrderStatuses,
    orgsList: { all: orgsList },
    getOrgsList,
    provenanceTypesList: { all: provenanceList },
    getProvenanceTypesList,
    countriesList: { all: countriesList },
    getCountriesList,
    minesList: { [JSON.stringify({ condition: 'ACTIVE' })]: minesList },
    getMinesList,
    polishedColours: colours,
    getPolishedColours,
    clarities,
    getClarities,
    polishedFluorescences,
    getPolishedFluorescences
  } = useGlobalsStore()
  useEffect(() => {
    getOrgsList()
    getOrderStatuses()
    getProvenanceTypesList()
    getCountriesList()
    getMinesList({ condition: 'ACTIVE' })
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
  }, [])
  const initialGridSize = useMemo(() => {
    if (!colours || !clarities || !polishedFluorescences) return
    const maxColIdx = 0
    const minColIdx = colours.length - 1
    const maxClrIdx = 0
    const minClrIdx = clarities.length - 1
    const minFluIdx = 0
    const maxFluIdx = polishedFluorescences.length - 1
    return {
      maxColour: colours[maxColIdx].value,
      minColour: colours[minColIdx].value,
      maxClarity: clarities[maxClrIdx].value,
      minClarity: clarities[minClrIdx].value,
      minFluorescence: polishedFluorescences[minFluIdx].value,
      maxFluorescence: polishedFluorescences[maxFluIdx].value
    }
  }, [colours, clarities, polishedFluorescences])
  const showCosts = useMemo(() => hasAdmin(orderActions.getOrderList) || orgsList?.find(x => x.orgId === orgId)?.showCosts, [orgsList, permissionsAdminCache])

  const [shapesList, setShapesList] = useState([])
  const [cutGradesList, setCutGradesList] = useState([])
  useEffect(() => {
    cutGradeActions.getShapeList({ condition: 'ACTIVE', columns: '[id, name]' })
    .then(result => setShapesList(result.data.data))
    .catch(console.error)
    cutGradeActions.getCutGradeList({ condition: 'ACTIVE', columns: '[id, institute, name]' })
    .then(result => setCutGradesList(result.data.data))
    .catch(console.error)
  }, [])

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'Order ID',
        accessor: 'id',
        id: 'id',
        dataType: 'number',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/orders/${cellInfo.row.original.id}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value}
            </NavLink>
          )
        },
        filterType: 'textarea'
      },
      ...(hasAdmin(organizationActions.getOrganizationList) ? [{
        Header: 'Owner',
        accessor: row => orgsList?.find(org => org.orgId === row.buyerId)?.commonName,
        dataType: 'string'
      }] : []),
      {
        Header: 'Order Name',
        accessor: 'name',
        dataType: 'string'
      },
      {
        Header: 'Shape',
        id: 'shape',
        accessor: row => shapesList.find(s => s.id === row.shapeId)?.name,
        dataType: 'string'
      },
      {
        Header: 'Cut Grade',
        id: 'cutGrade',
        accessor: row => cutGradesList.find(g => g.id === row.cutGradeId)?.name,
        dataType: 'string'
      },
      {
        Header: 'Qty',
        accessor: 'totalQty',
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Filled',
        accessor: 'filledQty',
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: row => orderStatusesMap?.[row.status] ?? '',
        dataType: 'string',
        filterType: 'checkbox'
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        dataType: 'date',
        filterType: 'date'
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [orderStatusesMap, orderStatuses, shapesList, cutGradesList, orgsList, permissionsAdminCache])

  const history = useHistory()
  function navigateTo(path) {
    history.push(path)
  }

  const { showSuccessToast, showErrorToast } = useToast()

  async function handleArchive(ids) {
    return orderActions.setOrderCondition({ orderIds: ids, condition: 'ARCHIVED' })
     .then(() => removeAllOrdersListItems(ids))
    .then(() => showSuccessToast(`Order${ids.length > 1 ? 's have' : ' has'} been archived.`))
  }

  async function handleRemove(ids) {
    return orderActions.setOrderCondition({ orderIds: ids, condition: 'DELETED' })
    .then(() => removeAllOrdersListItems(ids))
    .then(() => showSuccessToast(`Order${ids.length > 1 ? 's have' : ' has'} been removed.`))
  }

  async function handleUnarchive(ids) {
    return orderActions.setOrderCondition({ orderIds: ids, condition: 'ACTIVE' })
    .then(() => removeAllOrdersListItems(ids))
    .then(() => showSuccessToast(`Order${ids.length > 1 ? 's have' : ' has'} been unarchived.`))
  }

  async function handlePause(ids) {
    return orderActions.editMultipleOrders(
      {
        orderIds: ids,
        status: 'ORDER_NOT_SUBMITTED'
      },
      { errorSummary: errorUtils.getErrorSummary({ field: 'orders' }) }
    )
    .then(() => removeAllOrdersListItems(ids))
    .then(() => showSuccessToast(`Order${ids.length > 1 ? 's have' : ' has'} been paused.`))
  }

  async function handlePriceAdjust(ids, priceAdjust) {
    return orderActions.editMultipleOrders(
      {
        orderIds: ids,
        priceAdjust
      },
      { errorSummary: errorUtils.getErrorSummary({ field: 'orders' }) }
    )
    .then(() => removeAllOrdersListItems(ids))
    .then(() => showSuccessToast(`Order price${ids.length > 1 ? 's have' : ' has'} been adjusted.`))
  }

  async function handleExport(id) {
    return reportActions.exportOrder('xlsx', id, { showWarnings: true })
    .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
  }

  async function handleSubmit(ids) {
    return orderActions.editMultipleOrders(
      {
        orderIds: ids,
        status: 'ORDER_SUBMITTED'
      },
      { errorSummary: errorUtils.getErrorSummary({ field: 'orders' }) }
    )
    .then(() => removeAllOrdersListItems(ids))
    .then(() => showSuccessToast(`Order${ids.length > 1 ? 's have' : ' has'} submitted for matching.`))
  }

  async function generateExcel(data) {
    const entityMap = {
      shape: arrayUtils.toMap(shapesList, ({ id }) => id, 'name'),
      cutGrade: arrayUtils.toMap(cutGradesList, ({ id }) => id, 'name'),
      org: arrayUtils.toMap(orgsList, ({ orgId }) => orgId, 'commonName'),
      provenanceTypes: arrayUtils.toMap(provenanceList, ({ id }) => id, 'description'),
      countries: arrayUtils.toMap(countriesList, ({ id }) => id, 'name'),
      mines: arrayUtils.toMap(minesList, ({ id }) => id, 'name')
    }
    function getEntityName(entity, ids = []) {
      if (Array.isArray(ids)) {
        const map = ids.map(id => getEntityName(entity, id)).filter(x => x)
        return map.length ? map.join(', ') : 'Accept All'
      } else {
        return entityMap?.[entity]?.[ids]
      }
    }
    try {
      const workbook = new Excel.Workbook()
      const bold = { font: { bold: true } }

      workbook.creator = 'Clara'
      workbook.created = new Date()
      const sheet = workbook.addWorksheet('Orders')
      const columns = [
        { header: 'Order ID', key: 'id', width: 12 },
        ...(hasAdmin(organizationActions.getOrganizationList) ? [
          { header: 'Owner', key: 'buyerId', width: 12, transform: val => getEntityName('org', val) }
        ] : []),
        { header: 'Order Name', key: 'name', width: 12 },
        { header: 'Shape', key: 'shapeId', width: 12, transform: val => getEntityName('shape', val) },
        { header: 'Cut Grade', key: 'cutGradeId', width: 12, transform: val => getEntityName('cutGrade', val) },
        { header: 'Provenance Types', key: 'provenanceTypeIds', width: 12, transform: (_, item) => getEntityName('provenanceTypes', item.additionalFilters?.provenanceTypeIds) },
        { header: 'Countries', key: 'countryIds', width: 12, transform: (_, item) => getEntityName('countries', item.additionalFilters?.countryIds) },
        { header: 'Mines', key: 'mineIds', width: 12, transform: (_, item) => getEntityName('mines', item.additionalFilters?.mineIds) },
        { header: 'Qty', key: 'totalQty', width: 12 },
        { header: 'Filled', key: 'filledQty', width: 12 },
        { header: 'Accept Windowed Stones', key: 'acceptWindowedStones', width: 12, transform: (_, item) => item.additionalFilters?.acceptWindowedStones },
        { header: 'Accept Blocked Stones', key: 'acceptBlockedStones', width: 12, transform: (_, item) => item.additionalFilters?.acceptBlockedStones },
        { header: 'Status', key: 'status', width: 12, transform: val => orderStatusesMap?.[val] ?? '' },
        { header: 'Condition', key: 'condition', width: 12 },
        { header: 'Created At', key: 'createdAt', width: 12, transform: val => textUtils.formatDate(val) },
        { header: 'Last Updated', key: 'updatedAt', width: 12, transform: val => textUtils.formatDate(val) }
      ]
      const rows = fileUtils.getExcelRows(data, columns)
      sheet.columns = columns
      sheet.getRow(1).style = bold
      sheet.addRows(rows)
      return workbook.xlsx.writeBuffer().then(buffer => fileUtils.saveBufferExcel(buffer, `Orders - ${textUtils.formatDate(new Date(), COMMON.DATE_FMT.REPORT)}.xlsx`))
    } catch (err) {
      console.error(err)
      showErrorToast(err?.message || 'There was an error writing the excel.')
    }
  }

  const rowActions = [
    {
      actionName: 'archive',
      itemType: 'order',
      callback: (row) => handleArchive([row.id]),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      itemType: 'order',
      callback: (row) => handleUnarchive([row.id]),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      itemType: 'order',
      callback: (row) => handleRemove([row.id]),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition !== 'DELETED'
    },
    {
      iconName: 'download',
      actionName: 'export',
      itemType: 'order',
      callback: (row) => handleExport(row.id),
      shouldConfirm: false,
      shouldDisplay: (row) => row.condition !== 'DELETED'
    }
  ]

  const tableTabs = [
    {
      label: 'Active',
      params: 'ACTIVE'
    },
    {
      label: 'Archived',
      params: 'ARCHIVED'
    }
  ]

  return (
    <>
      <Table
        title='Orders'
        data={ordersList}
        columns={columns}
        tableTabs={tableTabs}
        rowActions={rowActions}
        getDataCallback={refreshOrdersList}
        topBarActions={[
          {
            componentName: 'dropdown',
            enableOnSelect: true,
            options: [
              {
                label: 'Submit Order(s)',
                isDisabled: rows => rows.some(row => ['ORDER_SUBMITTED', 'ORDER_FULFILLED', 'ORDER_EXPIRED'].includes(row.original.status) || row.original.condition !== 'ACTIVE'),
                callback: selectedRows => setOpenModal({ name: 'submit', selectedRows }),
                value: 'submit'
              },
              {
                label: 'Pause Order(s)',
                isDisabled: rows => rows.some(row => row.original.status !== 'ORDER_SUBMITTED' || row.original.condition !== 'ACTIVE'),
                callback: (selectedRows, { refreshTableData }) => setModal({
                  id: 'orderListUnsubmit',
                  title: 'Pause Orders',
                  message: `Pause ${selectedRows.length} order${selectedRows.length > 1 ? 's' : ''} from matching?`,
                  onSubmit: () => handlePause(selectedRows.map(row => row.id)),
                  closeOnFail: false
                }),
                value: 'unsubmit'
              },
              {
                label: 'Polished Price Book',
                // isDisabled: rows => rows.some(row => !['ORDER_INCOMPLETE', 'ORDER_NOT_SUBMITTED'].includes(row.original.status) || row.original.condition !== 'ACTIVE'),
                callback: (selectedRows, { refreshTableData }) => setModal({
                  id: 'polishedPriceBookModal',
                  title: 'Polished Price Book',
                  type: 'form',
                  customMessageRenderer: (messageProps) => <PolishedPriceBookModal orders={selectedRows} {...messageProps}/>,
                  closeOnFail: false,
                  className: 'order-list__polished-price-book-modal'
                }),
                value: 'polishedPriceBook'
              },
              {
                label: 'Adjust Price(s)',
                isDisabled: rows => rows.some(row => !['ORDER_INCOMPLETE', 'ORDER_NOT_SUBMITTED'].includes(row.original.status) || row.original.condition !== 'ACTIVE'),
                callback: (selectedRows, { refreshTableData }) => setModal({
                  id: 'orderListPriceAdjust',
                  title: 'Adjust Order Prices',
                  type: 'form',
                  customMessageRenderer: (messageProps) => <PriceAdjustOrderModal orders={selectedRows} {...messageProps}/>,
                  onSubmit: (res) => handlePriceAdjust(selectedRows.map(row => row.id), res?.[0]),
                  closeOnFail: false
                }),
                value: 'priceAdjust'
              },
              {
                label: 'Archive',
                isDisabled: rows => rows.some(row => row.original.status === 'ORDER_SUBMITTED' || row.original.condition !== 'ACTIVE'),
                callback: (selectedRows, { refreshTableData }) => setModal({
                  id: 'orderListArchive',
                  title: 'Archive Orders',
                  message: `Archive ${selectedRows.length} order${selectedRows.length > 1 ? 's' : ''}?`,
                  onSubmit: () => handleArchive(selectedRows.map(row => row.id)),
                  closeOnFail: false
                }),
                value: 'archive'
              },
              {
                label: 'Unarchive',
                isDisabled: rows => rows.some(row => row.original.condition !== 'ARCHIVED'),
                callback: (selectedRows, { refreshTableData }) => setModal({
                  id: 'orderListUnarchive',
                  title: 'Unarchive Orders',
                  message: `Unarchive ${selectedRows.length} order${selectedRows.length > 1 ? 's' : ''}?`,
                  onSubmit: () => handleUnarchive(selectedRows.map(row => row.id)),
                  closeOnFail: false
                }),
                value: 'unarchive'
              },
              {
                label: 'Delete',
                isDisabled: rows => rows.some(row => row.original.status === 'ORDER_SUBMITTED' || row.original.filledQty > 0),
                callback: (selectedRows, { refreshTableData }) => setModal({
                  id: 'orderListDelete',
                  title: 'Delete Orders',
                  message: `Permanently delete ${selectedRows.length} order${selectedRows.length > 1 ? 's' : ''}?`,
                  onSubmit: () => handleRemove(selectedRows.map(row => row.id)),
                  closeOnFail: false
                }),
                value: 'delete'
              },
              {
                label: 'Export',
                isDisabled: false,
                callback: generateExcel,
                value: 'export'
              }

            ]
          },
          {
            callback: () => navigateTo('/orders/create'),
            label: 'Create Order',
            enableOnSelect: false
          },
          {
            label: 'Generate Pricing Template',
            enableOnSelect: false,
            callback: () => setModal({
              id: 'orderPricingExportOrder',
              title: 'Generate Excel Pricing Template',
              customMessageRenderer: ({ handleSubmit, handleCancel }) => (
                <ExportPriceTemplate
                  initialGridSize={initialGridSize}
                  showCosts={showCosts}
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              ),
              customButtonsRenderer: () => null,
              closeable: false
            })
          }
        ]}
      />
      <SubmitOrderConfirmationModal
        open={openModal.name === 'submit'}
        customTitle='Submit Orders'
        customMessage={`Submit ${openModal.selectedRows.length} order${openModal.selectedRows.length > 1 ? 's' : ''} for matching?`}
        onClose={() => setOpenModal({ name: false, selectedRows: [] })}
        onSubmit={() => handleSubmit(openModal.selectedRows.map(row => row.id))
          .then(() => refreshOrdersList())
          .then(() => setOpenModal({ name: false, selectedRows: [] }))
        }
        closeOnFail={false}
      />
    </>
  )
}

export default OrderList
