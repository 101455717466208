import React, { useEffect, useMemo, useState } from 'react'
import { planningActions, saleActions } from '@actions'
import { FormComponents } from '@organisms'
import { useGlobalsStore, useOrderStore, useRoughStoneStore } from '@stores'
import { useToast } from '@hooks'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { arrayUtils, objectUtils } from '@utils'
import clone from 'just-clone'

function CreateSpsRequests() {
  const { SimpleForm, Dropdown, Button, Checkbox, SingleCheckbox, TextInput } = FormComponents
  const appSettingsParams = { groupKey: 'SPS_CONSTANTS', key: 'PLAN_ALL_SHAPES_WEIGHT' }

  const history = useHistory()
  const { showSuccessToast, showErrorToast } = useToast()
  const [allSales, setAllSales] = useState([])
  const {
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    appSettings: { [JSON.stringify(appSettingsParams)]: appSettings },
    getOrgsList,
    getAppSettings,
    spsPlanningSpeeds, getSpsPlanningSpeeds
  } = useGlobalsStore()
  const genericOrg = useMemo(() => orgsList?.find(o => o.orgType === 'GENERIC')?.commonName, [orgsList])

  function handleSanitize(formData = {}) {
    let spsData = clone(formData)
    // Replace empty arrays with null
    spsData = objectUtils.removeEmptyArrays(spsData)
    // Filter null fields
    spsData = objectUtils.filterNullish(spsData)

    for (const buyer of (spsData.buyerIds || [])) {
      spsData.orderIds = (spsData.orderIds || []).concat(...allOrdersByOwner[buyer].map(x => x.id))
    }
    for (const seller of (spsData.sellerIds || [])) {
      spsData.stoneIds = (spsData.stoneIds || []).concat(...allStonesByOwner[seller].map(x => x.id))
    }
    for (const assortment of (spsData.assortmentIds || [])) {
      spsData.stoneIds = (spsData.stoneIds || []).concat(...allStonesByAssortment[assortment].map(x => x.id))
    }

    if (spsData.orderIds) spsData.orderIds = Array.from(new Set(spsData.orderIds))
    if (spsData.roughIds) spsData.roughIds = Array.from(new Set(spsData.roughIds))

    spsData.planAllShapes = spsData.planAllShapesBool || spsData.planAllShapes

    return spsData
  }

  function handleSubmit(formData) {
    const spsData = handleSanitize(formData)
    if (!spsData.saleId && !(spsData.orderIds && spsData.stoneIds)) {
      if (spsData.orderIds || spsData.stoneIds) showErrorToast('You must select at least one option from Buyers or Orders and at least one option from Sellers, Assortments, or Rough Stones.')
      else showErrorToast('You must select at least one option.')
      return
    }

    if (!spsData.saleId && (!spsData.buyerIds || spsData.buyerIds.includes(genericOrg)) && !spsData.planAllShapesBool) {
      showErrorToast('Generic org cannot be included without "Plan All Shapes".')
      return
    }

    if (spsData.buyerIds && spsData.buyerIds.includes(genericOrg) && spsData.buyerIds.some(b => b !== genericOrg)) {
      showErrorToast('Generic org cannot be combined with other buyers.')
      return
    }

    return planningActions.sendSpsRequests(objectUtils.pick(spsData, ['saleId', 'stoneIds', 'orderIds', 'spsSpeed', 'highPriority', 'priceOverride', 'planAllShapes', 'spsVersion']))
    .then(res => {
      const sale = allSales?.find(({ id }) => id === spsData.saleId)
      const successMessage = {
        saleName: sale?.name,
        stoneCount: spsData.stoneIds?.length || sale?.stoneIds?.length || 0,
        orderCount: spsData.orderIds?.length || sale?.orderIds?.length || 0
      }
      showSuccessToast(`${successMessage.saleName ? `${successMessage.saleName}: ` : ''}${successMessage.stoneCount} stones and ${successMessage.orderCount} orders have been submitted for planning.`)
      history.push('/planning')
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
    getAppSettings(appSettingsParams)
    getSpsPlanningSpeeds()

    saleActions.getSales()
    .then(result => {
      setAllSales(result.data.data.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt)))
    })
    .catch(console.error)
  }, [])

  // TODO: These params are the same as the params from create_sale and sale_details
  // although we are not using order condition in this component, it will allow us
  // to capitalize on a previously fetched/cached orderList from one of the two other
  // components if we keep the ordersParams the same. This is probably not a good way to
  // accomplish reusing caching because a small change to ordersParams in one file will have to
  // be accounted for in the other files.
  // Maybe there is a better way to handle situations like this. Possibly creating some ordersParams
  // presets that can be used by multiple components and can be managed in one file
  const ordersParams = { condition: 'ACTIVE', status: 'ORDER_SUBMITTED', columns: '[id, name, buyerId, condition]' }
  const {
    ordersList: { [ordersParams ? JSON.stringify(ordersParams) : 'all']: storeOrdersList },
    getOrdersList
  } = useOrderStore(store => store)

  const roughStoneParams = { condition: 'ACTIVE', status: 'ROUGH_SUBMITTED', columns: ['id', 'sellerId', 'sellerStoneName', 'assortmentId', 'Assortment'] }
  const {
    roughStonesList: { [roughStoneParams ? JSON.stringify(roughStoneParams) : 'all']: storeRoughStonesList },
    getRoughStonesList
  } = useRoughStoneStore(store => store)

  useEffect(() => {
    if (orgsList?.length) {
      getRoughStonesList(roughStoneParams)
      getOrdersList(ordersParams)
    }
  }, [orgsList])

  const [allSubmittedOrders, allOrdersByOwner] = useMemo(() => {
    if (!storeOrdersList || !storeOrdersList.data) return [[], {}]
    const submittedOrders = storeOrdersList.data.map(o => ({ ...o, buyerName: orgsList?.find(org => org.orgId === o.buyerId)?.commonName }))
    const ordersByOwner = arrayUtils.groupBy(submittedOrders, (o) => o.buyerName)
    return [submittedOrders, ordersByOwner]
  }, [storeOrdersList])

  const [allSubmittedRoughs, allStonesByOwner, allStonesByAssortment] = useMemo(() => {
    if (!storeRoughStonesList || !storeRoughStonesList.data) return [[], {}, {}]
    const submittedRoughs = storeRoughStonesList.data.map(r => ({ ...r, sellerName: orgsList?.find(org => org.orgId === r.sellerId)?.commonName }))
    const stonesByOwner = arrayUtils.groupBy(submittedRoughs, (s) => s.sellerName)
    const stonesByAssortment = arrayUtils.groupBy(submittedRoughs, (s) => s.assortmentId)
    return [submittedRoughs, stonesByOwner, stonesByAssortment]
  }, [storeRoughStonesList])

  const [isPlanAllShapesDisabled, setIsPlanAllShapesDisabled] = useState(false)

  function handlePlanAllShapesBoolChanged(_, e) {
    const target = e.currentTarget ?? e.target
    setIsPlanAllShapesDisabled(target.checked)
  }

  return (
    <div className='create-sps-request center'>
      <SimpleForm
        title='Submit Requests for Planning'
        name='Submit Requests for Planning'
        initialValues={{ highPriority: false, priceOverride: false, requestOverride: false, planAllShapes: appSettings?.[0]?.value, planAllShapesBool: false }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        <Dropdown
          name='saleId'
          label='Select Sale to Plan'
          options={allSales.map(s => ({ label: s.name, value: s.id }))}
          required={false}
        />
        <Dropdown
          name='buyerIds'
          label='Select Buyers'
          options={
            Object.keys(allOrdersByOwner)
            .sort((a, b) => { return a.toUpperCase() < b.toUpperCase() ? -1 : 1 })
            .map(o => ({ label: o, value: o }))
          }
          isMulti={true}
          required={false}
          canAddAll={true}
        />
        <Dropdown
          name='orderIds'
          label='Select Orders'
          options={allSubmittedOrders.map(o => ({
            label: `${o.buyerName} | ${o.id} | ${o.name}`,
            value: o.id
          }))}
          isMulti={true}
          required={false}
          canAddAll={true}
        />
        <Dropdown
          name='sellerIds'
          label='Select Sellers'
          options={Object.keys(allStonesByOwner).map(o => ({
            label: o,
            value: o
          }))}
          isMulti={true}
          required={false}
          canAddAll={true}
        />
        <Dropdown
          name='assortmentIds'
          label='Select Assortments'
          options={Object.keys(allStonesByAssortment).map(a => ({
            label: `${allStonesByAssortment[a][0].sellerName} | ${allStonesByAssortment[a][0].Assortment.name} | ${a}`,
            value: a
          }))}
          isMulti={true}
          required={false}
          canAddAll={true}
        />
        <Dropdown
          name='stoneIds'
          label='Select Rough Stones'
          options={allSubmittedRoughs.map(s => ({
            label: `${s.sellerName} | ${s.id} | ${s.sellerStoneName}`,
            value: s.id
          }))}
          isMulti={true}
          required={false}
          canAddAll={true}
        />
        <Dropdown
          name='spsSpeed'
          label='Select Planning Accuracy'
          options={spsPlanningSpeeds?.map(s => ({ label: s.description, value: s.value }))}
          isMulti={false}
          required={true}
          canAddAll={false}
        />
        <Checkbox
          name="highPriority"
          label="Use High Priority Queue?"
        />
        <Checkbox
          name="priceOverride"
          label="Override Previously saved prices?"
        />
        <Checkbox
          name="requestOverride"
          label="Override Previously planned requests?"
        />
        <TextInput
          name='planAllShapes'
          label='Max Weight for Shape Mapping'
          type='number'
          disabled={isPlanAllShapesDisabled}
        />
        <SingleCheckbox
          key='planAllShapesBool'
          name='planAllShapesBool'
          label='Plan All Shapes'
          extraOnChange={handlePlanAllShapesBoolChanged}
        />
        <TextInput
          required={false}
          name='spsVersion'
          label='SPS Version'
        />
        <Button typeVariant="action" onClick={() => history.goBack()}>
            Cancel
        </Button>
        <Button type='submit' size='sm'>
            Send requests
        </Button>
      </SimpleForm>
    </div>
  )
}

export default CreateSpsRequests
