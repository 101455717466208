import { objectUtils } from '@utils'
import create from 'zustand'

const usePageSettingStore = create((set, get) => ({
  pages: {},
  setPageConfig: (pageId, pageConfig) => {
    if (!pageId) return
    set({ pages: { ...get().pages, [pageId]: pageConfig } })
  },
  getPageConfig: (pageId) => {
    if (!pageId) return
    return get().pages[pageId]
  },
  addPageSettingValue: (pageId, value) => {
    if (!pageId) return
    const pageConfig = get().getPageConfig(pageId)
    set({ pages: { ...get().pages, [pageId]: { ...pageConfig, ...value } } })
  },
  getPageSettingValue: (pageId, key) => {
    if (!pageId || !key) return
    const pageConfig = get().getPageConfig(pageId)
    if (!pageConfig) return
    return pageConfig[key]
  },
  removePageSetting: (pageId) => {
    if (!pageId) return
    set({ pages: objectUtils.omit(get().pages, [pageId]) })
  },
  resetPageSettings: () => {
    set({ pages: {} })
  }
}))

export { usePageSettingStore }
