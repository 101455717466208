import { textUtils } from '@utils'

function required(field) {
  return textUtils.substituteConstantTemplateStr('{{field}} is a required field.', { field })
}

function requiredToSubmit(field) {
  return textUtils.substituteConstantTemplateStr('{{field}} will be required to submit.', { field })
}

function invalid(field) {
  return textUtils.substituteConstantTemplateStr('{{field}} is invalid.', { field })
}

function moreThanInclusive(field, field2) {
  const prefix = field ? '{{field}} must' : 'Must'
  return textUtils.substituteConstantTemplateStr(`${prefix} be greater than or equal to {{field2}}.`, { field, field2 })
}

function uuid(field) {
  return textUtils.substituteConstantTemplateStr('{{field}} must be of type uuid.', { field })
}

function number(field) {
  return textUtils.substituteConstantTemplateStr('{{field}} must be of type number.', { field })
}

function negative(field) {
  const prefix = field ? '{{field}} should' : 'Should'
  return textUtils.substituteConstantTemplateStr(`${prefix} be negative.`, { field })
}

function positive(field) {
  const prefix = field ? '{{field}} should' : 'Should'
  return textUtils.substituteConstantTemplateStr(`${prefix} be positive.`, { field })
}

function range(field, field2, field3) {
  const prefix = field ? '{{field}} must' : 'Must'
  return textUtils.substituteConstantTemplateStr(`${prefix} be {{field2}} to {{field3}}.`, { field, field2, field3 })
}

function oneOf(field) {
  const prefix = field ? '{{field}} must' : 'Must'
  return textUtils.substituteConstantTemplateStr(`${prefix} be one of the values available in the dropdown.`, { field })
}

function missing() {
  return 'Missing value.'
}

function expected(field, expectedValue) {
  const prefix = field ? 'Expected value for {{field}} is' : 'Expected value is'
  return textUtils.substituteConstantTemplateStr(`${prefix} {{expectedValue}}.`, { field, expectedValue })
}

export { required, requiredToSubmit, invalid, moreThanInclusive, uuid, number, missing, negative, positive, range, oneOf, expected }
