import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ErrorText } from '@molecules'
import { arrayUtils, objectUtils } from '@utils'
import { COMMON } from '@constants'

function Fieldset({
  legend,
  visible,
  canEdit,
  onChange,
  children,
  required,
  componentName,
  validationText,
  disabled,
  classNames,
  ...props
}) {
  const validHTMLElementProps = useMemo(() => {
    const propKeys = Object.keys(props)
    const validKeys = arrayUtils.intersectBy(propKeys, COMMON.HTML_ATTRIBUTES.GLOBAL.concat(COMMON.HTML_ATTRIBUTES.FIELDSET), (x) => x.toLowerCase())
    return objectUtils.pick(props, validKeys)
  }, [props])
  const className = useMemo(() => {
    const classNamesArr = ['inline', 'split', 'span'].reduce((accum, curr) => props[curr] ? accum.concat(`fieldset--${curr}`) : accum, [])
    if (!classNamesArr.length) classNamesArr.push('fieldset')
    if (classNames?.length) classNamesArr.push(...classNames)
    return classNamesArr.join(' ')
  }, [props])
  if (!visible) return null
  return (
    <div className='fieldset__container'>
      <div className='fieldset__header-container'>
        <label className={'fieldset__header'}>
          {legend}
        </label>
        {!required
          && <small className="text-input__optional-label">
            (optional)
          </small>
        }
      </div>
      <fieldset
        className={className}
        {...validHTMLElementProps}
      >
        {children}
        <ErrorText {...validationText?.parse()} />
      </fieldset>
    </div>
  )
}

Fieldset.propTypes = {
  name: PropTypes.string,
  span: PropTypes.bool,
  canEdit: PropTypes.bool,
  legend: PropTypes.string,
  visible: PropTypes.bool,
  inline: PropTypes.bool,
  split: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  componentName: PropTypes.string,
  validationText: PropTypes.object,
  disabled: PropTypes.bool,
  classNames: PropTypes.array
}

Fieldset.defaultProps = {
  legend: '',
  required: true,
  inline: false,
  split: false,
  visible: true,
  disabled: false,
  classNames: []
}

export default Fieldset
