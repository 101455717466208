import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import clone from 'just-clone'
import { useHistory } from 'react-router-dom'
import { roughStoneActions, weightCategoryActions, pricePointActions } from '@actions'
import { DetailsPage, Modal } from '@templates'
import { usePageTitle, useToast } from '@hooks'
import { Icon } from '@atoms'
import { FileUpload } from '@organisms'
import MeasurementsModal from './create_rough_stone/measurementsModal'
import RoughBlockedBuyers from './roughBlockedBuyers'
import CloneRoughStonesModal from './cloneRoughStonesModal'
import { ErrorText, InfoTip, RoughCommentModal } from '@molecules'
import { SELLER } from '@constants'
import { roughSchema } from '@schemas'
import { fileUtils, arrayUtils, objectUtils, validationUtils, textUtils } from '@utils'
import {
  useGlobalsStore,
  useAuthStore,
  useRoughStoneStore,
  usePlanningStore,
  useModalStore
} from '@stores'

const { ROUGH_LOCATIONS } = SELLER

function RoughStoneDetails({ match, title }) {
  const roughStoneId = match?.params?.roughStoneId
  const [roughStone, setRoughStone] = useState(null)
  const [filePending, setFilePending] = useState(false)
  const [openCommentsModal, setOpenCommentModal] = useState({ open: false, rough: null })
  const [openCloneStonesModal, setOpenCloneStonesModal] = useState({ open: false, rough: null })
  const { hasAdmin, permissionsAdminCache, orgId: userOrgId } = useAuthStore(state => state)
  const { removeAllRoughStonesListItems } = useRoughStoneStore(state => state)
  const { removeAllPlannedStonesListItems } = usePlanningStore(store => store)
  usePageTitle(title, roughStoneId, roughStone?.sellerStoneName)
  const measurementValidationCache = new Map()
  const sellerParam = { sellerId: roughStone?.sellerId }
  const appSettingsParams = { groupKey: 'ROUGH_CONSTANTS' }
  const sellerActiveParam = { sellerId: roughStone?.sellerId, condition: 'ACTIVE' }
  const activeOnlyParam = { condition: 'ACTIVE' }
  const [blockedBuyers, setBlockedBuyers] = useState()

  const {
    setModal
  } = useModalStore(state => state)
  const {
    getRoughStatuses,
    getRoughColours,
    getRoughTinges,
    getPolishedFluorescences,
    getRoughScanTypes,
    getRoughTensions,
    getOrgById,
    getCountriesList,
    getMinesList,
    getPipesList,
    getBatchesList,
    getInclusionTypes,
    getInclusionReductions,
    getRoughQCStatuses,
    getProvenanceTypesList,
    getAllowedSellerChanges,
    getRoughTypes,
    getAppSettings,
    roughStatuses,
    roughColours,
    roughColoursMap,
    roughTinges,
    roughTingesMap,
    roughFluorescences,
    polishedFluorescences,
    roughScanTypes: scanTypes,
    roughTensions,
    countriesList: { [JSON.stringify(sellerParam)]: countriesList },
    minesList: { [JSON.stringify(sellerActiveParam)]: minesList },
    pipesList: { [JSON.stringify(activeOnlyParam)]: pipesList },
    batchesList: { [JSON.stringify(sellerActiveParam)]: batchesList },
    inclusionTypes,
    inclusionReductions,
    roughQCStatusesMap,
    provenanceTypesList: { all: provenanceTypesList },
    allowedSellerChanges,
    roughTypes,
    appSettings: { [JSON.stringify(appSettingsParams)]: appSettings }
  } = useGlobalsStore(store => store)
  const [weightCategories, setWeightCategories] = useState()
  const [pricePoints, setPricePoints] = useState()
  const provenanceType = useMemo(() => {
    return provenanceTypesList?.find(pt => pt.id === roughStone?.Assortment?.provenanceTypeId)
  }, [provenanceTypesList, roughStone])

  function getRoughDetails() {
    const columns = '[Assortment, Mine, Batch, Pipe, Country, InclusionsType, File, Photos]'
    roughStoneActions.getRoughStoneById(roughStoneId, columns)
    .then(async response => {
      const rough = response.data.data[0]
      const seller = await getOrgById(rough.sellerId)
      rough.sellerName = seller.commonName
      rough.sellerReserveRequired = seller.profitSplit !== 0
      rough.galaxyFile = rough.File
      setRoughStone(rough)
    })
    .catch(err => console.error(err))
  }
  useEffect(() => {
    getRoughDetails()
  }, [roughStoneId])

  function getBlockedBuyers() {
    if (hasAdmin(roughStoneActions.getBlockedBuyers)) {
      roughStoneActions.getBlockedBuyers(roughStoneId)
      .then(async response => {
        const blocks = response.data.data
        setBlockedBuyers({ blockedIds: blocks.map(block => block.buyerId), blockedRows: blocks })
      })
      .catch(console.error)
    }
  }
  useEffect(() => {
    getBlockedBuyers()
  }, [permissionsAdminCache])

  useEffect(() => {
    if (!roughStone?.sellerId) return

    getCountriesList(sellerParam)
    .catch(console.error)

    getMinesList(sellerActiveParam)
    .catch(console.error)

    getPipesList(activeOnlyParam)
    .catch(console.error)

    getBatchesList(sellerActiveParam)
    .catch(console.error)

    weightCategoryActions.getWeightCategories(roughStone.sellerId)
    .then(response => setWeightCategories(response.data.data))
    .catch(console.error)

    pricePointActions.getPricePoints(roughStone.sellerId)
    .then(response => setPricePoints(response.data.data))
    .catch(console.error)

    getAppSettings(appSettingsParams)
    .catch(console.error)
  }, [roughStone?.sellerId])

  useEffect(() => {
    getRoughStatuses()
    getRoughColours()
    getRoughTinges()
    getPolishedFluorescences()
    getRoughScanTypes()
    getRoughTensions()
    getInclusionTypes()
    getInclusionReductions()
    getRoughQCStatuses()
    getProvenanceTypesList()
    getAllowedSellerChanges()
    getRoughTypes()
  }, [])

  const eyeMeasurementColours = useMemo(() => (roughColours ?? []).filter(c => !c.eyeNotAllowed), [roughColours])
  const eyeMeasurementFluorescences = useMemo(() => (polishedFluorescences ?? []).filter(f => !f.eyeNotAllowed), [polishedFluorescences])
  const eyeMeasurementTinges = useMemo(() => (roughTinges ?? []).filter(t => !t.eyeNotAllowed), [roughTinges])
  const filteredInclusionTypes = useMemo(() => (inclusionTypes ?? []).filter(it => hasAdmin(roughStoneActions.getInclusionTypes) || it.autoInclusions), [inclusionTypes, roughStone, permissionsAdminCache])
  const defaultInclusionReduction = useMemo(() => (inclusionReductions ?? []).find(ir => ir.default === 'DEFAULT')?.id, [inclusionReductions])
  const { MEASUREMENTS_TO_SUBMIT, TENSION_REQUIRED_ABOVE_WEIGHT, EYE_COLOUR_ABOVE_PRIMARY_SOURCE_WEIGHT } = useMemo(() => arrayUtils.toMap(appSettings || [], (x) => x.key, 'value'), [appSettings])

  // Constants
  const { SUBMITTED_STONE_ALLOWED_CHANGES = [], MATCHED_STONE_ALLOWED_CHANGES = [] } = allowedSellerChanges || {}
  const {
    deletable: DELETEABLE_STATUSES,
    archivable: ARCHIVEABLE_STATUSES,
    sold: SOLD_STATUSES,
    submitted: SUBMITTED_STATUSES
  } = useMemo(() => (roughStatuses ?? []).reduce(({ deletable, archivable, sold, ...rest }, status) => ({
    ...rest,
    deletable: [...deletable, ...(status.deletable ? [status.value] : [])],
    archivable: [...archivable, ...(status.archivable ? [status.value] : [])],
    sold: [...sold, ...(sold.length || status.value === 'ROUGH_MATCHED' ? [status.value] : [])] // Add ROUGH_MATCHED all statuses that occur after in the enum array
  }), { deletable: [], archivable: [], sold: [], submitted: ['ROUGH_SUBMITTED'] }), [roughStatuses])
  const ALLOWED_CHANGES = useMemo(() => ({
    ...SUBMITTED_STATUSES.reduce((allowed, status) => ({ ...allowed, [status]: SUBMITTED_STONE_ALLOWED_CHANGES }), {}),
    ...SOLD_STATUSES.reduce((allowed, status) => ({ ...allowed, [status]: MATCHED_STONE_ALLOWED_CHANGES }), {})
  }), [allowedSellerChanges, SUBMITTED_STATUSES, SOLD_STATUSES])

  function MeasurementsComponent(props) {
    /******
     * Measurement box & modal functions
     *****/
    const [measurementsModalOpen, setMeasurementsModalOpen] = useState(false)
    const [measurementBoxList, setMeasurementBoxList] = useState([
      { colour: '', fluorescence: '', tinge: '' },
      { colour: '', fluorescence: '', tinge: '' },
      { colour: '', fluorescence: '', tinge: '' },
      { colour: '', fluorescence: '', tinge: '' },
      { colour: '', fluorescence: '', tinge: '' }
    ])

    function updateMeasurements(measurements) {
      setMeasurementBoxList(measurements)
    }

    useEffect(() => {
      updateMeasurements(roughStone.measurements)
    }, [roughStone])

    return (<div className="edit-rough-stone__measurement-box">
      <div className="create-rough-stone__measurement-box-header--view">
        <span>Measurements</span>
        {
          (props.isEditToggled && props.canEdit)
            ? <div className='cursor-pointer measurements-edit-button-div'
              onClick={() => {
                setMeasurementsModalOpen(true)
              }}
            >
              <Icon size='lg' name="edit" />
            </div>
            : null
        }
      </div>
      <div className="create-rough-stone__measurement-list">
        <span></span>
        <span className='overline'>Color</span>
        <span className='overline'>Fluorescence</span>
        <span className='overline'>Tinge</span>
        {
          (props.isEditToggled && props.canEdit)
            ? <>
              {measurementBoxList.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <span>Measurement {index + 1}</span>
                    <span>{roughColoursMap?.[item.colour] ?? '—'}</span>
                    <span>{item.fluorescence ?? '—'}</span>
                    <span>{roughTingesMap?.[item.tinge] ?? '—'}</span>
                  </React.Fragment>
                )
              })}
              <ErrorText {...props?.validationText?.parse()} />
            </>
            : <>
              <span>Average Measurements</span>
              <span>
                {roughColoursMap?.[roughStone?.averageColour] ?? '—'}
                {roughStone?.measurements?.some(m => 'colour' in m)
                && <InfoTip name="allColours">
                  {roughStone?.measurements?.map((m, index) => roughColoursMap?.[m.colour] + (index < roughStone.measurements.length - 1 ? ', ' : ''))}
                </InfoTip>
                }
              </span>
              <span>
                {roughStone?.averageFluorescence ?? '—'}
                {roughStone?.measurements?.some(m => 'fluorescence' in m)
                && <InfoTip name="allFlus">
                  {roughStone?.measurements?.map((m, index) => m.fluorescence + (index < roughStone.measurements.length - 1 ? ', ' : ''))}
                </InfoTip>
                }
              </span>
              <span>
                {roughTingesMap?.[roughStone?.averageTinge] ?? '—'}
                {roughStone?.measurements?.some(m => 'tinge' in m)
                && <InfoTip name="allTinges">
                  {roughStone?.measurements?.map((m, index) => roughTingesMap?.[m.tinge] + (index < roughStone.measurements.length - 1 ? ', ' : ''))}
                </InfoTip>
                }
              </span>
              <span>Final Measurements</span>
              <span>
                {roughColoursMap?.[roughStone?.colour] ?? <InfoTip name="allColoursWarning" icon="warning" iconClass="warning" />}
              </span>
              <span>
                {roughStone?.fluorescence ?? <InfoTip name="allFlusWarning" icon="warning" iconClass="warning"/>}
              </span>
              <span>
                {roughTingesMap?.[roughStone?.tinge] ?? <InfoTip name="allTingesWarning" icon="warning" iconClass="warning"/>}
              </span>
            </>
        }
      </div>
      <Modal
        open={measurementsModalOpen}
        title="Rough Stone Measurements"
        position="right center"
        onClose={() => setMeasurementsModalOpen(false)}
      >
        <MeasurementsModal
          measurementBoxList={measurementBoxList}
          onSaveMeasurements={(values) => {
            updateMeasurements(values)
            setMeasurementsModalOpen(false)
          }}
          onCancel={() => setMeasurementsModalOpen(false)}
          {...props}
        />
      </Modal>
    </div>)
  }

  /******
   * File upload functions
   *****/
  async function handleSarineFileChange(file, onChangeCb, handleFileUploadProgress) {
    if (!file) {
      onChangeCb({ currentTarget: { name: 'galaxyFileId', value: null } })
    } else {
      const md5Hash = await fileUtils.getFileHash(file)
      const galaxyFileExists = await roughStoneActions.galaxyFileExists(md5Hash, roughStone.sellerId)

      if (galaxyFileExists.data.data?.exists && !galaxyFileExists.data.data?.files?.some(f => f.rough_id === roughStoneId)) {
        const roughId = galaxyFileExists.data.data.files[0].rough_id
        await new Promise((resolve, reject) => {
          setModal({
            id: 'advFileExists',
            title: 'ADV File Exists',
            message: <>
              This ADV file has been uploaded to another rough stone ({roughId}).
              <br/>
              <strong>Are you sure you want to use this file?</strong>
            </>,
            buttonOptions: {
              submitText: 'Yes',
              cancelText: 'No'
            },
            onSubmit: resolve,
            onCancel: reject
          })
        })
      }

      return uploadAdvFile(file, onChangeCb, handleFileUploadProgress)
    }
  }

  function uploadAdvFile(file, onChangeCb, handleFileUploadProgress) {
    setFilePending(true)
    return roughStoneActions.getUploadUrl({ orgId: roughStone.sellerId })
      .then(response => {
        return {
          url: response.data.data.url.url,
          uploadId: response.data.data.uploadId
        }
      })
      .then(tempUpload => {
        return roughStoneActions.uploadFile(tempUpload.url, file, { updateProgress: handleFileUploadProgress })
          .then(response => {
            const galaxyFile = {
              tempFile: tempUpload.uploadId,
              fileName: response.config.data.name,
              lastUpdated: textUtils.formatDate(response.config.data.lastModifiedDate, true)
            }

            onChangeCb({ currentTarget: { name: 'galaxyFileId', value: galaxyFile } })
          })
      })
      .catch(err => {
        console.error(err)
        throw err
      })
      .finally(() => {
        setFilePending(false)
      })
  }

  function AdvFileUpload({ value, onChange, validationText, canEdit }) {
    return (
      <div className="create-rough-stone__upload-file">
        <FileUpload
          name='advFile'
          label="Sarine galaxy file"
          fileNameCharLimit={15}
          onChange={(file, handleFileUploadProgress) => handleSarineFileChange(file, onChange, handleFileUploadProgress)}
          acceptTypes={['.adv']}
          expectedFileName={[`${roughStone.sellerStoneName}.adv`, `${roughStone.id}.adv`]}
          fileName={value?.fileName || value?.downloadName || undefined}
          validationText={validationText}
          disabled={!canEdit}
        />
      </div>
    )
  }

  const fields = useMemo(() => {
    if (!roughStone) return []
    const allColumns = [
      {
        label: 'Stone Name',
        name: 'sellerStoneName',
        value: roughStone.sellerStoneName,
        componentName: 'textInput',
        canEdit: true
      },
      {
        label: 'Status',
        name: 'status',
        value: roughStone.status,
        componentName: 'dropdown',
        options: roughStatuses?.map(
          status => ({
            value: status.value,
            label: status.description
          })),
        canEdit: false
      },
      {
        label: 'Assortment',
        name: 'assortment',
        value: roughStone.Assortment.name,
        componentName: 'textInput',
        canEdit: false
      },
      {
        label: 'Seller',
        value: roughStone.sellerName,
        name: 'sellerName',
        componentName: 'textInput',
        canEdit: false
      },
      {
        label: 'Weight',
        name: 'weight',
        value: roughStone.weight,
        componentName: 'textinput',
        canEdit: true
      },
      {
        label: 'Weight Category',
        name: 'weightCategory',
        value: roughStone.weightCategory,
        componentName: 'dropdown',
        options: weightCategories?.map(i => ({ label: i, value: i })),
        creatable: true,
        canEdit: true
      },
      {
        label: 'Price Point',
        name: 'pricePoint',
        value: roughStone.pricePoint,
        componentName: 'dropdown',
        options: pricePoints?.map(i => ({ label: i, value: i })),
        creatable: true,
        canEdit: true
      },
      {
        label: 'Country',
        name: 'countryId',
        componentName: 'dropdown',
        options: countriesList?.map(i => ({ label: i.name, value: i.id })),
        value: roughStone.Country?.id,
        canEdit: true
      },
      {
        label: 'Mine',
        value: roughStone.Mine?.id,
        componentName: 'dropdown',
        options: minesList?.map(i => ({ label: i.name, value: i.id })),
        name: 'mineId',
        canEdit: true,
        handleChange: async (_, doChange) => {
          doChange()
          doChange('pipeId', null)
          doChange('batchId', null)
        }
      },
      {
        label: 'Mine Pipe',
        value: roughStone.Pipe?.id,
        componentName: 'dropdown',
        // options: pipesList?.filter(pipe => pipe.mineId === currentMineId && (pipe.sellerId === null || pipe.sellerId === roughStone.sellerId))
        // .map(pipe => ({ label: pipe.name, value: pipe.id })),
        options: pipesList?.map(pipe => ({ label: pipe.name, value: pipe.id })),
        name: 'pipeId',
        canEdit: true,
        handleChange: async (_, doChange) => {
          doChange()
          doChange('batchId', null)
        },
        renderOverrides: values => {
          const mineId = values.find(item => item.name === 'mineId')?.value
          return {
            options: pipesList?.filter(pipe => pipe.mineId === mineId && (pipe.sellerId === null || pipe.sellerId === roughStone.sellerId))
                .map(pipe => ({ label: pipe.name, value: pipe.id }))
          }
        }
      },
      {
        label: 'Mine Batch',
        value: roughStone.Batch?.id,
        componentName: 'dropdown',
        options: batchesList?.map(i => ({ label: i.name, value: i.id })),
        name: 'batchId',
        canEdit: true,
        renderOverrides: values => {
          const pipeId = values.find(item => item.name === 'pipeId')?.value
          return {
            options: batchesList?.filter(batch => batch.pipeId === pipeId && batch.sellerId === roughStone.sellerId)
                .map(batch => ({ label: batch.name, value: batch.id }))
          }
        }
      },
      {
        label: 'Original Price ($/ct)',
        name: 'reservePpcOriginal',
        type: 'currency',
        componentName: 'textInput',
        value: roughStone.reservePpcOriginal,
        strike: !!roughStone.reservePpcOverride,
        canEdit: true
      },
      {
        label: 'Override Price ($/ct)',
        name: 'reservePpcOverride',
        value: roughStone.reservePpcOverride,
        type: 'currency',
        componentName: 'textInput',
        canEdit: true
      },
      {
        label: 'Total Price',
        componentName: 'textInput',
        type: 'currency',
        value: roughStone.reservePpcOverride != null
          ? Number(roughStone.reservePpcOverride) * Number(roughStone.weight)
          : (roughStone.reservePpcOriginal != null ? Number(roughStone.reservePpcOriginal) * Number(roughStone.weight) : null),
        renderOverrides: values => {
          const originalReserve = values.find(item => item.name === 'reservePpcOriginal')?.value
          const overrideReserve = values.find(item => item.name === 'reservePpcOverride')?.value
          const weight = values.find(item => item.name === 'weight')?.value
          return {
            value: overrideReserve != null
              ? Number(overrideReserve) * Number(weight)
              : (originalReserve != null ? Number(originalReserve) * Number(weight) : null)
          }
        },
        decimalScale: 2,
        fixedDecimalScale: true
      },
      {
        label: 'Yehuda Measurements',
        customComponent: MeasurementsComponent,
        name: 'measurements',
        span: true,
        canEdit: true,
        value: roughStone.measurements // adds to form state for validation, but the values used in the component are wired differently
      },
      {
        legend: 'Eye Measurements',
        componentName: 'fieldset',
        name: 'eyeMeas',
        canEdit: true,
        children: [
          {
            label: 'Color',
            componentName: 'dropdown',
            name: 'eyeMeasurement.colour',
            canEdit: true,
            value: roughStone.eyeMeasurement?.colour,
            options: eyeMeasurementColours?.map(i => ({ label: i.description, value: i.value }))
          },
          {
            label: 'Fluorescence',
            componentName: 'dropdown',
            name: 'eyeMeasurement.fluorescence',
            canEdit: true,
            value: roughStone.eyeMeasurement?.fluorescence,
            options: eyeMeasurementFluorescences?.map(i => ({ label: i.description, value: i.value }))
          },
          {
            label: 'Tinge',
            componentName: 'dropdown',
            name: 'eyeMeasurement.tinge',
            canEdit: true,
            value: roughStone.eyeMeasurement?.tinge,
            options: eyeMeasurementTinges?.map(i => ({ label: i.description, value: i.value }))
          }
        ]
      },
      {
        legend: 'Override Measurements',
        componentName: 'fieldset',
        name: 'overrideMeasurement',
        canEdit: hasAdmin(roughStoneActions.editRoughStone),
        children: [
          {
            label: 'Color',
            componentName: 'dropdown',
            name: 'overrideMeasurement.colour',
            canEdit: true,
            value: roughStone.overrideMeasurement?.colour,
            options: eyeMeasurementColours?.map(i => ({ label: i.description, value: i.value }))
          },
          {
            label: 'Fluorescence',
            componentName: 'dropdown',
            name: 'overrideMeasurement.fluorescence',
            canEdit: true,
            value: roughStone.overrideMeasurement?.fluorescence,
            options: eyeMeasurementFluorescences?.map(i => ({ label: i.description, value: i.value }))
          },
          {
            label: 'Tinge',
            componentName: 'dropdown',
            name: 'overrideMeasurement.tinge',
            canEdit: true,
            value: roughStone.overrideMeasurement?.tinge,
            options: eyeMeasurementTinges?.map(i => ({ label: i.description, value: i.value }))
          }
        ]
      },
      {
        legend: 'Inclusion Details',
        componentName: 'fieldset',
        name: 'inclusions',
        canEdit: true,
        children: [
          {
            label: 'Inclusion Type',
            componentName: 'dropdown',
            name: 'inclusionsTypeId',
            value: roughStone.inclusionsTypeId,
            canEdit: true,
            options: filteredInclusionTypes.map(i => ({ label: i.description, value: i.id })),
            infoTip: true
          },
          {
            label: 'Reduction Table',
            componentName: 'dropdown',
            name: 'inclusionReductionsId',
            canEdit: true,
            visible: hasAdmin(roughStoneActions.getRoughStoneList),
            value: roughStone.inclusionReductionsId,
            options: inclusionReductions?.map(i => ({ label: i.description, value: i.id }))
          }
        ],
        visible: false,
        renderOverrides: (values) => {
          const inclusionsTypeId = values.find(({ name }) => name === 'inclusions').children.find(({ name }) => name === 'inclusionsTypeId')
          const galaxyFileField = values.find(({ name }) => name === 'adv').children.find(({ name }) => name === 'galaxyFileId')
          const galaxyFileChanged = !!galaxyFileField?.value?.tempFile || (galaxyFileField?.value == null && roughStone.galaxyFileId != null)
          return {
            visible: hasAdmin(roughStoneActions.editRoughStone) || !inclusionTypes?.some(i => i.autoInclusions === false && i.id === inclusionsTypeId.value) || galaxyFileChanged
          }
        }
      },
      {
        legend: 'Other Attributes',
        componentName: 'fieldset',
        canEdit: true,
        span: false,
        name: 'otherAttr',
        children: [
          {
            label: 'Yellow UV',
            name: 'otherAttributes.yellowFluorescence',
            componentName: 'checkbox',
            value: roughStone.otherAttributes?.yellowFluorescence,
            canEdit: true
          },
          {
            label: 'Tension',
            name: 'otherAttributes.tension',
            componentName: 'dropdown',
            value: roughStone.otherAttributes?.tension,
            options: roughTensions?.map(t => ({ label: t.description, value: t.value })),
            canEdit: true
          },
          {
            label: 'Type',
            name: 'type',
            componentName: 'dropdown',
            value: roughStone.type,
            options: roughTypes?.map(t => ({ label: t.description, value: t.value })),
            canEdit: true,
            isClearable: true
          }
        ]
      },
      {
        legend: 'ADV File',
        componentName: 'fieldset',
        canEdit: true,
        span: true,
        name: 'adv',
        children: [
          {
            label: '',
            name: 'noAdvFilePlaceHolder',
            customComponent: () => <span>No ADV file uploaded.</span>,
            canEdit: false,
            renderOverrides: (values) => ({
              shouldDisplay: !values.find(({ name }) => name === 'galaxyFileId').value && !roughStone.File
            })
          },
          {
            label: 'Scan Type',
            name: 'scanType',
            componentName: 'dropdown',
            value: roughStone.scanType,
            options: scanTypes?.map(i => ({ label: i.description, value: i.value })),
            canEdit: true
          },
          {
            label: 'Original Name',
            name: 'advOriginalName',
            componentName: 'textInput',
            canEdit: false,
            renderOverrides: (values) => {
              const galaxyFileField = values.find(({ name }) => name === 'galaxyFileId')
              return {
                value: galaxyFileField?.value?.originalName,
                shouldDisplay: !!galaxyFileField?.value?.originalName
              }
            }
          },
          {
            label: 'Last Updated',
            name: 'advLastUpdated',
            componentName: 'textInput',
            canEdit: false,
            renderOverrides: (values) => {
              const galaxyFileField = values.find(({ name }) => name === 'galaxyFileId')
              return {
                value: galaxyFileField?.value?.lastUpdated || textUtils.formatDate(galaxyFileField?.value?.updatedAt, true),
                shouldDisplay: !!(galaxyFileField?.value?.lastUpdated || galaxyFileField?.value?.updatedAt)
              }
            }
          },
          {
            label: 'Download',
            text: fileUtils.getFileName(roughStone.File, { shorten: true }),
            componentName: 'downloadLink',
            fileName: roughStone.sellerId === userOrgId ? roughStone.File?.originalName : roughStone.File?.downloadName,
            addToProgressList: true,
            name: 'fileUrl',
            url: roughStone.File?.url,
            shouldDisplay: roughStone.File,
            canEdit: false
          },
          {
            label: 'Upload ADV File',
            customComponent: AdvFileUpload,
            name: 'galaxyFileId',
            value: roughStone.galaxyFile,
            fileName: roughStone.File?.downloadName || undefined,
            shouldDisplay: 'editOnly',
            canEdit: true,
            handleChange: async (_, doChange) => {
              doChange() // update galaxyFileId
              if (inclusionTypes?.some(i => i.autoInclusions === false && i.id === roughStone.inclusionsTypeId)) doChange('inclusionsTypeId', null) // update inclusionsTypeId
              if (hasAdmin(roughStoneActions.editRoughStone)) doChange('inclusionReductionsId', defaultInclusionReduction)
            }
          }
        ]
      },
      // {
      //   legend: 'Photo Files',

      //   componentName: 'fieldset',
      //   name: 'photoFiles',
      //   children:
      //     roughStone.Photos?.length
      //       ? roughStone.Photos?.map((p, i) =>
      //         ({
      //           label: `Photo ${i}`,
      //           componentName: 'downloadLink',
      //           url: p.url
      //         })
      //       )
      //       : [{
      //         label: 'No photos available',
      //         componentName: 'text'
      //       }]
      // },
      {
        label: 'Location',
        name: 'location',
        value: roughStone.location,
        componentName: 'dropdown',
        options: ROUGH_LOCATIONS?.map(i => ({ label: i, value: i })),
        shouldDisplay: hasAdmin(roughStoneActions.getRoughStoneList),
        canEdit: true
      },
      {
        label: 'QC Status',
        name: 'qcStatus',
        value: roughQCStatusesMap?.[roughStone.qcStatus] || '',
        shouldDisplay: hasAdmin(roughStoneActions.getRoughStoneList),
        canEdit: false
      },
      {
        label: 'Blocked Buyers',
        name: 'blockedBuyers',
        value: blockedBuyers?.blockedIds || [],
        shouldDisplay: hasAdmin(roughStoneActions.getBlockedBuyers),
        canEdit: hasAdmin(roughStoneActions.setBlockedBuyers),
        span: true,
        customComponent: RoughBlockedBuyers,
        blockedRows: blockedBuyers?.blockedRows
      },
      { label: 'Created', name: 'createdAt', value: textUtils.formatDate(roughStone.createdAt) },
      { label: 'Last Updated', name: 'updatedAt', value: textUtils.formatDate(roughStone.updatedAt) }
    ]
    // Get the difference between the columns and allowed changes
    if (ALLOWED_CHANGES[roughStone.status]) {
      const parseColName = (col) => {
        if (typeof col === 'string') return col
        const name = col?.name
        // A '.' in the name of the field implies it is a property of a SQL attribute that is type JSONB
        return name?.split('.')?.length ? name.split('.')[0] : name
      }
      // Do an in place mutation of cols if they are not in allowed changes
      // Also ensure we are not missing nested children fields
      const allColumnsAndNested = allColumns.reduce((cols, col) =>
        // Only handling single level of children at the moment
        cols.concat('children' in col ? col.children : col)
      , [])
      const allowedChanges = ALLOWED_CHANGES[roughStone.status].reduce((cols, col) =>
        hasAdmin(roughStoneActions.editRoughStone) || !col.adminOnly ? cols.concat(col.value) : cols
      , [])

      arrayUtils.differenceBy(allColumnsAndNested, allowedChanges, parseColName).forEach(col => {
        col.canEdit = false
      })
    }
    return allColumns
    // Set each of those fields canEdit to false
  }, [roughStone, countriesList, minesList, pipesList, batchesList, weightCategories, pricePoints, roughTensions, scanTypes, inclusionTypes, inclusionReductions, roughQCStatusesMap, permissionsAdminCache, roughTypes, blockedBuyers])

  const { showSuccessToast, showErrorToast } = useToast()
  function handleOnSubmit(values, preSanitizedValues) {
    // Filter out all unchanged values
    const {
      eyeMeasurement = {},
      overrideMeasurement = {},
      galaxyFileId: galaxyFile,
      otherAttributes = {},
      ...editedValues
    } = Object.entries(values).reduce((edited, [key, value]) => ({ ...edited, ...(!(value === roughStone[key]) && { [key]: value }) }), {})
    if (eyeMeasurement.colour !== roughStone.eyeMeasurement?.colour || eyeMeasurement.fluorescence !== roughStone.eyeMeasurement?.fluorescence || eyeMeasurement.tinge !== roughStone.eyeMeasurement?.tinge) {
      editedValues.eyeMeasurement = {
        colour: eyeMeasurement.colour === undefined ? (roughStone.eyeMeasurement?.colour || null) : eyeMeasurement.colour,
        fluorescence: eyeMeasurement.fluorescence === undefined ? (roughStone.eyeMeasurement?.fluorescence || null) : eyeMeasurement.fluorescence,
        tinge: eyeMeasurement.tinge === undefined ? (roughStone.eyeMeasurement?.tinge || null) : eyeMeasurement.tinge
      }
    }
    if (overrideMeasurement.colour !== roughStone.overrideMeasurement?.colour || overrideMeasurement.fluorescence !== roughStone.overrideMeasurement?.fluorescence || overrideMeasurement.tinge !== roughStone.overrideMeasurement?.tinge) {
      editedValues.overrideMeasurement = {
        colour: overrideMeasurement.colour === undefined ? (roughStone.overrideMeasurement?.colour || null) : overrideMeasurement.colour,
        fluorescence: overrideMeasurement.fluorescence === undefined ? (roughStone.overrideMeasurement?.fluorescence || null) : overrideMeasurement.fluorescence,
        tinge: overrideMeasurement.tinge === undefined ? (roughStone.overrideMeasurement?.tinge || null) : overrideMeasurement.tinge
      }
    }

    if (otherAttributes.tension !== roughStone.otherAttributes?.tension || otherAttributes.yellowFluorescence !== roughStone.otherAttributes?.yellowFluorescence) {
      editedValues.otherAttributes = {
        tension: otherAttributes?.tension === undefined ? (roughStone.otherAttributes?.tension ?? null) : otherAttributes?.tension,
        yellowFluorescence: otherAttributes.yellowFluorescence === undefined ? (roughStone.otherAttributes?.yellowFluorescence ?? null) : otherAttributes.yellowFluorescence
      }
    }

    if (galaxyFile !== roughStone.File) {
      editedValues.galaxyFile = galaxyFile != null ? { ...galaxyFile } : galaxyFile
      if (editedValues?.galaxyFile?.lastUpdated) {
        delete editedValues.galaxyFile.lastUpdated
      }
    }

    // Given the rule that any change in galaxy file should clear inclusionsTypeId, it is possible that
    // we may want to set the inclusionsTypeId back to it's current value and this change should not be
    // filtered out as a no change
    if ('inclusionsTypeId' in preSanitizedValues) editedValues.inclusionsTypeId = values.inclusionsTypeId
    if ('inclusionReductionsId' in preSanitizedValues) editedValues.inclusionReductionsId = values.inclusionReductionsId

    const newBlockedBuyers = ('blockedBuyers' in preSanitizedValues) ? editedValues.blockedBuyers : undefined
    delete editedValues.blockedBuyers

    if (editedValues.measurements) {
      editedValues.measurements = [
        ...editedValues.measurements
          .filter(measurement => !!measurement.colour)
          .map(measurement => {
            delete measurement.fluorRange
            return measurement
          })
      ]
    }

    return (Object.keys(editedValues).length ? roughStoneActions.editRoughStone(roughStone.id, editedValues) : Promise.resolve())
    .then(() => {
      if (newBlockedBuyers !== undefined) return roughStoneActions.setBlockedBuyers(roughStone.id, { buyerIds: newBlockedBuyers })
    })
    .then(() => removeAllRoughStonesListItems([roughStoneId]))
    .then(() => removeAllPlannedStonesListItems([roughStoneId], (plannedStone) => plannedStone?.Rough?.id))
    .then(() => {
      showSuccessToast('Rough stone updated!')
      getRoughDetails()
      getBlockedBuyers()
    })
  }

  function handleOnSanitize(formValues) {
    try {
      const nestedFields = ['eyeMeasurement', 'overrideMeasurement', 'otherAttributes']
      const flatFields = arrayUtils.differenceBy(roughSchema.editRoughWarningSchemaFields, nestedFields, (x) => x)
      return {
        ...objectUtils.pick(roughStone, [...flatFields, ...roughSchema.editRoughErrorSchemaFields], false),
        galaxyFileId: roughStone.galaxyFile,
        ...formValues,
        eyeMeasurement: objectUtils.deepMerge({ ...roughStone.eyeMeasurement }, formValues.eyeMeasurement || roughStone.eyeMeasurement || {}),
        overrideMeasurement: objectUtils.deepMerge({ ...roughStone.overrideMeasurement }, formValues.overrideMeasurement || roughStone.overrideMeasurement || {}),
        otherAttributes: objectUtils.deepMerge(clone(roughStone.otherAttributes), formValues.otherAttributes || roughStone.otherAttributes || {})
      }
    } catch (err) {
      console.error(err)
      showErrorToast('Error occured while validating order data')
      return undefined
    }
  }

  function handleHasValuesChanged(editedValues, originalValues) {
    const hasChanged = {}
    const galaxyField = originalValues?.find(v => v.name === 'galaxyFileId')

    if (galaxyField) {
      const galaxyFields = ['id', 'extension', 'ownerId', 'bucket', 'key', 'url', 'originalName', 'downloadName', 'hash', 'condition', 'createdAt', 'updatedAt', 'fileName', 'lastUpdated', 'tempFile']
      const galaxyValues = galaxyField?.value
      galaxyFields.forEach(field => {
        hasChanged[`galaxyFileId.${field}`] = validationUtils.diffValues(galaxyValues?.[field], editedValues[`galaxyFileId.${field}`])
      })
    }

    const yehudaMeasurements = originalValues?.find(v => v.name === 'measurements')
    if (yehudaMeasurements) {
      const editedYehudaMeasurements = objectUtils.filterNullish(editedValues.measurements, true)
      hasChanged.measurements = validationUtils.diffValues(yehudaMeasurements.value, Object.values(editedYehudaMeasurements).map(g => g))
    }
    const skipFields = ['measurements']
    Object.keys(editedValues).forEach(fieldName => {
      const originalValue = originalValues.find(v => v.name === fieldName)
      if (originalValue && !skipFields.includes(fieldName)) {
        hasChanged[fieldName] = validationUtils.diffValues(originalValue?.value, editedValues[fieldName])
      }
    })

    return Object.values(hasChanged).some(v => v)
  }

  function unsubmitRoughStone(values, editedValues) {
    roughStoneActions.editRoughStone(roughStoneId, { status: 'ROUGH_NOT_SUBMITTED' })
    .then(() => removeAllRoughStonesListItems([roughStoneId]))
    .then(() => removeAllPlannedStonesListItems([roughStoneId], (plannedStone) => plannedStone?.Rough?.id))
    .then(() => {
      showSuccessToast('Rough stone has been suspended from matching.')
      getRoughDetails()
    })
  }

  function submitRoughStone(values, editedValues) {
    roughStoneActions.editRoughStone(roughStoneId, { status: 'ROUGH_SUBMITTED' })
    .then(() => removeAllRoughStonesListItems([roughStoneId]))
    .then(() => removeAllPlannedStonesListItems([roughStoneId], (plannedStone) => plannedStone?.Rough?.id))
    .then(() => {
      showSuccessToast('Rough stone has been submitted for matching.')
      getRoughDetails()
    })
  }

  const history = useHistory()
  function handleArchive() {
    roughStoneActions.setRoughCondition({ roughIds: [roughStoneId], condition: 'ARCHIVED' })
    .then(() => removeAllRoughStonesListItems([roughStoneId]))
    .then(() => removeAllPlannedStonesListItems([roughStoneId], (plannedStone) => plannedStone?.Rough?.id))
    .then(() => {
      showSuccessToast('Rough stone has been archived.')
      history.push('/roughstones')
    })
  }

  function handleUnarchive() {
    roughStoneActions.setRoughCondition({ roughIds: [roughStoneId], condition: 'ACTIVE' })
    .then(() => removeAllRoughStonesListItems([roughStoneId]))
    .then(() => removeAllPlannedStonesListItems([roughStoneId], (plannedStone) => plannedStone?.Rough?.id))
    .then(() => {
      showSuccessToast('Rough stone has been unarchived.')
      history.push('/roughstones')
    })
  }

  function handleRemove() {
    roughStoneActions.setRoughCondition({ roughIds: [roughStoneId], condition: 'DELETED' })
    .then(() => removeAllRoughStonesListItems([roughStoneId]))
    .then(() => removeAllPlannedStonesListItems([roughStoneId], (plannedStone) => plannedStone?.Rough?.id))
    .then(() => {
      showSuccessToast('Rough stone has been removed.')
      history.push('/roughstones')
    })
  }

  const extraButtons = [
    {
      onClick: submitRoughStone,
      caption: 'Submit for Matching',
      viewOnly: true,
      shouldDisplay: (['ROUGH_NOT_SUBMITTED', 'ROUGH_INCOMPLETE', 'ROUGH_NO_ADV'].includes(roughStone?.status))
    },
    {
      onClick: unsubmitRoughStone,
      caption: 'Suspend Matching',
      viewOnly: true,
      shouldDisplay: (roughStone?.status === 'ROUGH_SUBMITTED')
    },
    {
      onClick: () => setOpenCommentModal({ open: true, rough: roughStone }),
      caption: 'Comments (' + (roughStone?.comments?.length || 0) + ')',
      viewOnly: true,
      shouldDisplay: true
    },
    {
      onClick: () => setOpenCloneStonesModal({ open: true, rough: roughStone }),
      caption: 'Clone Stone',
      viewOnly: true,
      shouldDisplay: hasAdmin(roughStoneActions.cloneRoughStones)
    }
  ]

  const isArchiveable = ARCHIVEABLE_STATUSES.some(status => roughStone?.status === status)
  && roughStone.condition !== 'ARCHIVED' && roughStone.condition !== 'DELETED'

  const isDeleteable = DELETEABLE_STATUSES.some(status => roughStone?.status === status)
  && roughStone.condition !== 'DELETED'

  const canUnarchive = roughStone?.condition === 'ARCHIVED'
  const canEdit = roughStone && roughStone.condition === 'ACTIVE'
  const canSubmit = !filePending
  const schemaFields = arrayUtils.differenceBy(roughSchema.editRoughWarningSchemaFields, [...roughStone?.sellerReserveRequired ? [] : ['reservePpcOriginal']], (x) => x)

  return (
    <div className="center">
      <DetailsPage
        canEdit={canEdit}
        canSubmit={canSubmit}
        isEdit={false}
        fields={fields}
        onArchive={isArchiveable ? handleArchive : null}
        onUnarchive={canUnarchive ? handleUnarchive : null}
        onRemove={isDeleteable ? handleRemove : null}
        onSanitize={handleOnSanitize}
        onHasValuesChanged={handleHasValuesChanged}
        onSubmit={handleOnSubmit}
        extraButtons={extraButtons}
        validationProps={{
          validationSchema: {
            warning: roughSchema.editRoughWarningSchema(
              {
                provenanceType,
                minesList: arrayUtils.pickBy(minesList ?? [], 'id'),
                pricePoints,
                eyeMeasurementColours: arrayUtils.pickBy(eyeMeasurementColours ?? [], 'value'),
                eyeMeasurementFluorescences: arrayUtils.pickBy(eyeMeasurementFluorescences ?? [], 'value'),
                eyeMeasurementTinges: arrayUtils.pickBy(eyeMeasurementTinges ?? [], 'value'),
                inclusionTypes: arrayUtils.pickBy(filteredInclusionTypes ?? [], 'id'),
                inclusionReductions: arrayUtils.pickBy(inclusionReductions ?? [], 'id'),
                roughScanTypes: arrayUtils.pickBy(scanTypes ?? [], 'value'),
                roughTensions: arrayUtils.pickBy(roughTensions ?? [], 'value'),
                roughTypes: arrayUtils.pickBy(roughTypes ?? [], 'value'),
                minMeasurements: MEASUREMENTS_TO_SUBMIT,
                tensionRequiredAboveWeight: TENSION_REQUIRED_ABOVE_WEIGHT,
                eyeColourAbovePrimarySourceWeight: EYE_COLOUR_ABOVE_PRIMARY_SOURCE_WEIGHT,
                // For calculateRoughMeasurements
                roughColours: roughColours ?? [],
                roughFluorescences: roughFluorescences ?? [],
                polishedFluorescences: polishedFluorescences ?? [],
                roughTinges: roughTinges ?? []
              },
              schemaFields
            ),
            error: roughSchema.editRoughErrorSchema
          },
          schemaContext: { cache: measurementValidationCache }
        }}
        title={{
          id: roughStone?.id,
          label: 'Clara ID',
          value: `${roughStone?.id || ''} ${roughStone?.sellerStoneName ? '(' + roughStone?.sellerStoneName + ')' : ''}`
        }}
      />
      <RoughCommentModal
        open={openCommentsModal.open}
        onClose={() => {
          getRoughDetails()
          setOpenCommentModal({ open: false, rough: null })
        }}
        rough={openCommentsModal.rough}
      />
      <CloneRoughStonesModal
        open={openCloneStonesModal.open}
        redirect={true}
        onClose={() => {
          setOpenCloneStonesModal({ open: false, rough: null })
        }}
        roughStones={openCloneStonesModal?.rough ? [openCloneStonesModal?.rough] : []}
      />
    </div>
  )
}

RoughStoneDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default RoughStoneDetails
