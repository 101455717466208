import React, { useState, useEffect } from 'react'
import { Table } from '@organisms'
import { useAuthStore, useGlobalsStore } from '@stores'
import { useHistory, NavLink } from 'react-router-dom'
import { provenanceActions } from '@actions'
import { useToast } from '@hooks'

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    dataType: 'string',
    Cell: cellInfo => {
      return (
        <NavLink
          className="link"
          to={`/seller-settings/batches/${cellInfo.row.original.id}`}
          id={cellInfo.row.original.id}
        >
          {cellInfo.value}
        </NavLink>
      )
    }
  },
  {
    Header: 'Mine',
    accessor: 'mineName',
    dataType: 'string',
    filterType: 'checkbox'
  },
  {
    Header: 'Pipe',
    accessor: 'pipeName',
    dataType: 'string',
    filterType: 'checkbox'
  },
  {
    Header: 'Seller',
    accessor: 'sellerName',
    dataType: 'string'
  }
]

function BatchList() {
  const {
    getMinesList,
    getPipesList,
    getBatchesList,
    getOrgsList,
    resetBatchesList
  } = useGlobalsStore(store => store)
  const history = useHistory()
  const { showSuccessToast } = useToast()
  const [batches, setBatches] = useState([])
  const { orgId: userOrgId, hasAdmin, permissionsAdminCache } = useAuthStore(state => state)

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    getData('ACTIVE')
  }, [permissionsAdminCache])

  function getData(condition = 'ACTIVE') {
    Promise.all([
      getBatchesList({ condition }),
      getOrgsList(),
      getMinesList(getMinesParams()),
      getPipesList()
    ])
    .then(([batches, orgs, mines, pipes]) => {
      setBatches(batches.map(batch => {
        const pipe = pipes?.find(pipe => pipe.id === batch.pipeId)
        return {
          ...batch,
          mineName: mines?.find(mine => mine.id === pipe?.mineId)?.name,
          pipeName: pipe?.name,
          sellerName: orgs?.find(org => org.orgId === batch.sellerId)?.commonName
        }
      }))
    })
  }

  function getMinesParams() {
    if (hasAdmin(provenanceActions.getMines)) return null
    return {
      sellerId: userOrgId
    }
  }

  function handleArchive(row) {
    return provenanceActions.setBatchCondition(row.id, 'ARCHIVED')
    .then(() => {
      showSuccessToast('Batch has been archived.')
    })
  }

  function handleUnarchive(row) {
    return provenanceActions.setBatchCondition(row.id, 'ACTIVE')
    .then(() => {
      showSuccessToast('Batch has been unarchived.')
    })
  }

  function handleRemove(row) {
    return provenanceActions.setBatchCondition(row.id, 'DELETED')
    .then(() => {
      showSuccessToast('Batch has been removed.')
    })
  }

  const rowActions = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      callback: handleRemove,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition !== 'DELETED'
    }
  ]

  const tableTabs = [{
    label: 'Active',
    params: 'ACTIVE'
  },
  {
    label: 'Archived',
    params: 'ARCHIVED'
  }]

  const topBarActions = [{
    label: 'Create Batch',
    callback: () => history.push('/seller-settings/batches/create')
  }]

  return (
    <Table
      title='Batches'
      data={batches}
      columns={columns}
      tableTabs={tableTabs}
      getDataCallback={getData}
      resetDataCallback={resetBatchesList}
      rowActions={rowActions}
      topBarActions={topBarActions}
      isMultiSelect={false}
    />
  )
}

export default BatchList
