import React from 'react'
import { TextInput } from '@molecules'
import { Icon } from '@atoms'

function CostItem({ name, value, onChange, fieldName, fieldLabel, onDelete, disabled, ...props }) {
  function handleChange(e) {
    const target = e?.target || e?.currentTarget
    const fieldName = target?.name.slice(target?.name.lastIndexOf('.') + 1)
    return onChange({ currentTarget: { name, value: { ...(value || {}), [fieldName]: target?.value } } })
  }

  return (
    <div className='cost-item__row'>
      <TextInput
        name={`${name}.minWeight`}
        value={value?.minWeight ?? ''}
        onChange={handleChange}
        label='Min Weight'
        type='number'
        disabled={disabled}
      />
      <TextInput
        name={`${name}.maxWeight`}
        value={value?.maxWeight ?? ''}
        onChange={handleChange}
        label='Max Weight'
        type='number'
        disabled={disabled}
      />
      <TextInput
        name={`${name}.${fieldName}`}
        value={value?.[fieldName] ?? ''}
        onChange={handleChange}
        label={fieldLabel}
        type='number'
        disabled={disabled}
      />
      {!disabled && <div className='cost-item__remove-icon' onClick={onDelete}>
        <Icon
          name='closeCircle'
          size='md'
        />
      </div>}
    </div>
  )
}

function MfgCostItem(props) {
  return <CostItem { ...props} fieldName='costPerRoughCarat' fieldLabel='Cost per Rough Carat' />
}

function CertCostItem(props) {
  return <CostItem { ...props} fieldName='costPerStone' fieldLabel='Cost Per Stone' />
}

export { CertCostItem, MfgCostItem }
