import React, { useMemo, useEffect, useState } from 'react'
import { DetailsPage } from '@templates'
import { logisticsActions, reportActions, saleActions } from '@actions'
import { useGlobalsStore } from '@stores'
import moment from 'moment'
import { textUtils, validationUtils } from '@utils'
import { useToast } from '@hooks'
import InvoiceFiles from './invoiceFiles'
import dottie from 'dottie'
import clone from 'just-clone'
import PaymentList from './paymentList'
import FileSaver from 'file-saver'

const PAYMENT_LIST_CONDITION_KEYS = ['allInvoice']

function InvoiceDetails({ match }) {
  const invoiceId = match?.params?.invoiceId
  const { showSuccessToast } = useToast()
  const [invoice, setInvoice] = useState(null)
  const [sales, setSales] = useState([])
  const [reportUrl, setReportUrl] = useState(null)
  const [isEditToggled, setIsEditToggled] = useState(false)

  const {
    orgsMap: { all: orgsMap },
    getOrgsList
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    saleActions.getSales({ columns: '[id, name]' })
    .then(result => {
      setSales(result.data.data)
    })
  }, [])

  useEffect(() => {
    if (invoiceId) {
      getInvoice(invoiceId)
    }
  }, [invoiceId])

  const fields = useMemo(() => ([
    {
      label: 'Organization',
      name: 'orgId',
      value: orgsMap?.[invoice?.orgId],
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Sale',
      name: 'sale',
      value: invoice?.saleId,
      componentName: 'dropdown',
      options: sales?.map(s => ({ label: s.name, value: s.id })),
      canEdit: false
    },
    {
      label: 'Invoice #',
      name: 'invoiceNumber',
      value: invoice?.invoiceNumber,
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Invoice Date',
      name: 'invoiceDate',
      value: textUtils.formatDate(invoice?.invoiceDate, true),
      componentName: 'textInput',
      type: 'datetime-local',
      canEdit: true
    },
    ...(
      invoice?.otherParameters?.shipToAddress
        ? [
          {
            label: 'Ship To',
            name: 'otherParameters.shipToAddress',
            value: `${invoice?.otherParameters?.shipToAddress?.name} - ${invoice?.otherParameters?.shipToAddress?.line1} - ${invoice?.otherParameters?.shipToAddress?.city} - ${invoice?.otherParameters?.shipToAddress?.country}`,
            componentName: 'textInput',
            canEdit: false
          }
        ] : []
    ),
    ...(
      invoice?.otherParameters?.toAddress
        ? [
          {
            label: 'Bill To',
            name: 'otherParameters.toAddress',
            value: `${invoice?.otherParameters?.toAddress?.name} - ${invoice?.otherParameters?.toAddress?.line1} - ${invoice?.otherParameters?.toAddress?.city} - ${invoice?.otherParameters?.toAddress?.country}`,
            componentName: 'textInput',
            canEdit: false
          }
        ] : []
    ),
    {
      label: 'Expenses',
      name: 'expenses',
      value: invoice?.expenses,
      componentName: 'textInput',
      type: 'currency',
      canEdit: true
    },
    {
      label: 'Condition',
      name: 'condition',
      value: textUtils.capitalize(invoice?.condition),
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Invoice File',
      componentName: 'downloadLink',
      fileName: reportUrl?.downloadName ?? reportUrl?.originalName,
      url: '#',
      target: '_blank',
      onClick: () => downloadOriginalReportFile(reportUrl?.url, reportUrl?.downloadName),
      text: reportUrl?.downloadName ?? reportUrl?.originalName,
      span: true
    },
    ...(
      invoice?.otherParameters?.stoneCount
        ? [
          {
            label: 'Stones Count',
            name: 'otherParameters.stoneCount',
            value: invoice?.otherParameters?.stoneCount,
            componentName: 'textInput',
            type: 'number',
            canEdit: false
          }
        ] : []
    ),
    ...(
      invoice?.otherParameters?.weight
        ? [
          {
            label: 'Weight',
            name: 'otherParameters.weight',
            value: invoice?.otherParameters?.weight,
            componentName: 'textInput',
            type: 'number',
            canEdit: false
          }
        ] : []
    ),
    ...(
      invoice?.otherParameters?.stonesTotal
        ? [
          {
            label: 'Stones Total',
            name: 'otherParameters.stonesTotal',
            value: invoice?.otherParameters?.stonesTotal,
            componentName: 'textInput',
            type: 'currency',
            canEdit: false
          }
        ] : []
    ),
    ...(
      invoice?.otherParameters?.expenses
        ? [
          {
            label: 'Expenses',
            name: 'otherParameters.expenses',
            value: invoice?.otherParameters?.expenses,
            componentName: 'textInput',
            type: 'currency',
            canEdit: false
          }
        ] : []
    ),
    ...(
      invoice?.otherParameters?.galaxyFees
        ? [
          {
            label: 'Galaxy Fees',
            name: 'otherParameters.galaxyFees',
            value: invoice?.otherParameters?.galaxyFees,
            componentName: 'textInput',
            type: 'currency',
            canEdit: false
          }
        ] : []
    ),
    ...(
      invoice?.otherParameters?.tax
        ? [
          {
            label: 'Tax',
            name: 'otherParameters.tax',
            value: invoice?.otherParameters?.tax,
            componentName: 'textInput',
            type: 'currency',
            canEdit: false
          }
        ] : []
    ),
    {
      label: 'Invoice Total',
      name: 'invoiceTotal',
      value: (Number(invoice?.otherParameters?.galaxyFees) || 0) + (Number(invoice?.otherParameters?.expenses) || 0) + (Number(invoice?.otherParameters?.tax) || 0) + (Number(invoice?.otherParameters?.stonesTotal) || 0),
      componentName: 'textInput',
      type: 'currency',
      canEdit: false
    },
    ...(
      invoice?.otherParameters?.preferredShipping
        ? [
          {
            label: 'Preferred Shipping',
            name: 'otherParameters.preferredShipping',
            value: invoice?.otherParameters?.preferredShipping,
            componentName: 'textInput',
            canEdit: false
          }
        ] : []
    ),
    ...(
      invoice?.otherParameters?.notes
        ? [
          {
            label: 'Notes',
            name: 'otherParameters.notes',
            value: invoice?.otherParameters?.notes,
            componentName: 'textArea',
            canEdit: false,
            span: true
          }
        ] : []
    ),
    {
      label: 'Created At',
      name: 'createdAt',
      value: textUtils.formatDate(invoice?.createdAt, true),
      componentName: 'textInput',
      type: 'datetime-local',
      canEdit: false
    },
    {
      label: 'Last Updated',
      name: 'updatedAt',
      value: textUtils.formatDate(invoice?.updatedAt, true),
      componentName: 'textInput',
      type: 'datetime-local',
      canEdit: false
    }

  ]), [invoice, orgsMap, reportUrl])

  function getInvoice(id) {
    logisticsActions.getInvoices({ id, columns: '[SavedReport,Files]' })
    .then(response => {
      setInvoice(response.data.data[0])
      const savedReport = response.data.data[0]?.SavedReport
      if (savedReport) {
        reportActions.getDownloadUrl([savedReport.fileId], { savedReportId: savedReport.id })
        .then(resp2 => setReportUrl(resp2.data.data[0]))
      }
    })
  }

  async function handleOnSubmit(values) {
    if (values.invoiceDate === '') values.invoiceDate = null
    if (values.invoiceDate) values.invoiceDate = moment(values.invoiceDate).toISOString()

    return logisticsActions.editInvoice(invoiceId, { ...values })
    .then(() => {
      showSuccessToast('Invoice saved.')
      getInvoice(invoiceId)
    })
  }

  function handleHasValuesChanged(editedValues, originalValues) {
    const hasChanged = {}
    const values = dottie.transform(clone(editedValues))

    Object.keys(values).forEach(fieldName => {
      const originalValue = originalValues.find(v => v.name === fieldName)
      if (originalValue) {
        hasChanged[fieldName] = validationUtils.diffValues(originalValue?.value, values[fieldName])
      }
    })

    return Object.values(hasChanged).some(v => v)
  }

  async function downloadOriginalReportFile(url, fileName) {
    if (url && fileName) {
      const downloaded = await reportActions.getBinaryFile(url)
      FileSaver.saveAs(downloaded?.data, fileName)
    }
  }

  function handleOnRegenerateInvoice() {
    logisticsActions.generateFullInvoice({ buyerId: invoice.orgId, saleId: invoice.saleId, replacementInvoice: true })
    .then(() => showSuccessToast('Invoice has been regenerated.'))
    .then(() => getInvoice(invoiceId))
  }

  const extraButtons = [
    {
      onClick: () => handleOnRegenerateInvoice(),
      caption: 'Regenerate Invoice',
      viewOnly: true,
      shouldDisplay: invoice?.condition === 'ACTIVE'
    }
  ]

  return (
    <div className="invoice-details">
      <div className='invoice-details__container'>
        <DetailsPage
          canEdit={true}
          fields={fields}
          extraButtons={extraButtons}
          onSubmit={handleOnSubmit}
          onHasValuesChanged={handleHasValuesChanged}
          onRemove={null}
          onEditToggled={(edit) => {
            setIsEditToggled(edit)
          }}
          title ={{
            label: 'Invoice ID',
            value: invoice?.id || ''
          }}
        />
        <InvoiceFiles
          invoiceId={invoiceId}
          invoice={invoice}
          refreshInvoice={() => getInvoice(invoiceId)}
          isEditToggled={isEditToggled}
        />
        <PaymentList
          invoice={invoice}
          conditions={PAYMENT_LIST_CONDITION_KEYS}
        />
      </div>
    </div>
  )
}

export default InvoiceDetails
