import React, { useMemo, useEffect, useState } from 'react'
import { Table } from '@organisms'
import { useGlobalsStore } from '@stores'

function RoughBlockedBuyers({
  value,
  onChange,
  name,
  isEditToggled,
  blockedRows,
  ...props
}) {
  const { getOrgById } = useGlobalsStore(store => store)

  const deleteBuyerRow = (row) => {
    const newArr = value.filter(buyerId => buyerId !== row.orgId)
    onChange({ target: { name, value: newArr } })
  }

  const columns = useMemo(() => ([
    { Header: 'Buyer', accessor: 'legalName', dataType: 'string' },
    { Header: 'Last Updated', accessor: 'updatedAt', dataType: 'date', filterType: 'date' }
  ]), [])

  const [tableValue, setTableValue] = useState([])
  useEffect(() => {
    if (!value?.length) setTableValue([])
    else {
      Promise.all(value.map(async buyerId => ({ ...(await getOrgById(buyerId)), updatedAt: blockedRows?.find(row => row.buyerId === buyerId)?.updatedAt })))
      .then(setTableValue)
    }
  }, [value])

  const rowActions = [
    {
      actionName: 'remove',
      shouldConfirm: true,
      shouldDisplay: () => isEditToggled,
      callback: deleteBuyerRow
    }
  ]

  return <div className='w-100'>
    <Table
      title="Blocked Buyers"
      columns={columns}
      data={tableValue}
      rowActions={rowActions}
      isMultiSelect={false}
    />
  </div>
}

export default RoughBlockedBuyers
