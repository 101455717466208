import React, { useEffect, useState } from 'react'
import { useGlobalsStore, useAuthStore } from '@stores'
import { Checkbox, Icon } from '@atoms'
import { Dropdown, ErrorText } from '@molecules'
import { Form, Fieldset } from '@organisms'
import { Modal } from '@templates'
import CreateRoughStoneBtn from '../inc/createRoughStoneBtn'
import MeasurementsModal from './measurementsModal'
import { roughStoneActions } from '@actions'

function MeasurementStep({
  assortment,
  inclusionTypes,
  inclusionReductions,
  eyeMeasurementColours,
  eyeMeasurementTinges,
  eyeMeasurementFluorescences,
  roughScanTypes,
  roughTensions,
  roughTypes,
  ...props
}) {
  const { validationSchemaError } = props.useMultiSteps(props)

  const [measurementsModalOpen, setMeasurementsModalOpen] = useState(false)
  const [measurementBoxList, setMeasurementBoxList] = useState([
    { colour: '', fluorescence: '', tinge: '' },
    { colour: '', fluorescence: '', tinge: '' },
    { colour: '', fluorescence: '', tinge: '' },
    { colour: '', fluorescence: '', tinge: '' },
    { colour: '', fluorescence: '', tinge: '' }
  ])

  const {
    getRoughColours,
    getRoughTinges,
    roughColoursMap: coloursMap,
    roughTingesMap: tingesMap
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getRoughColours()
    getRoughTinges()
    setMeasurements(props.getState('measurements'))
  }, [])

  const { hasAdmin } = useAuthStore()

  function setMeasurements(measurements) {
    if (!measurements) return

    setMeasurementBoxList(measurements)
    props.setState('measurements', measurements)
  }

  return (
    <Form title={`New rough stone for assortment ${assortment.name}`} name="New rough stone">
      <div className="create-rough-stone__measurement-step">
        <Fieldset
          legend='Eye measurement'
          required={true}
        >
          <div className="create-rough-stone__eye-measurement">
            <Dropdown
              size="lg"
              name="eyeMeasurement.colour"
              label="Color"
              value={props.getState('eyeMeasurement')?.colour}
              onChange={e =>
                props.setState('eyeMeasurement', { ...props.getState('eyeMeasurement'), colour: e.currentTarget.value })
              }
              options={eyeMeasurementColours?.map(col => ({
                value: col.value,
                label: col.description
              }))}
              validationText={validationSchemaError?.parseStep(['eyeMeasurement', 'colour'])}
            />
            <Dropdown
              size="lg"
              name="eyeMeasurement.fluorescence"
              label="Fluorescence"
              value={props.getState('eyeMeasurement')?.fluorescence}
              onChange={e =>
                props.setState('eyeMeasurement', { ...props.getState('eyeMeasurement'), fluorescence: e.currentTarget.value })
              }
              options={eyeMeasurementFluorescences?.map(f => ({
                value: f.value,
                label: f.description
              }))}
              validationText={validationSchemaError?.parseStep('eyeMeasurement.fluorescence')}
            />
            <Dropdown
              size="lg"
              name="eyeMeasurement.tinge"
              label="Tinge"
              value={props.getState('eyeMeasurement')?.tinge}
              onChange={e =>
                props.setState('eyeMeasurement', { ...props.getState('eyeMeasurement'), tinge: e.currentTarget.value })
              }
              options={eyeMeasurementTinges?.map(tinge => {
                return {
                  value: tinge.value,
                  label: tinge.description
                }
              })}
              validationText={validationSchemaError?.parseStep('eyeMeasurement.tinge')}
            />
          </div>
        </Fieldset>
        <Fieldset legend='Inclusion Details'>
          <Dropdown
            size="lg"
            name="inclusionsTypeId"
            label="Inclusion type"
            value={props.getState('inclusionsTypeId')}
            onChange={e =>
              props.setState('inclusionsTypeId', e.currentTarget.value)
            }
            options={inclusionTypes.map(inclusionType => {
              return {
                value: inclusionType.id,
                label: inclusionType.description,
                default: inclusionType.default
              }
            })}
            infoTip={true}
            validationText={validationSchemaError?.parseStep('inclusionsTypeId')}
          />
          {hasAdmin(roughStoneActions.editRoughStone)
            ? <Dropdown
              size="lg"
              name="inclusionReductionsId"
              label="Inclusion reductions"
              value={props.getState('inclusionReductionsId')}
              onChange={e =>
                props.setState('inclusionReductionsId', e.currentTarget.value)
              }
              options={inclusionReductions?.map(inclusionReductions => {
                return {
                  value: inclusionReductions.id,
                  label: inclusionReductions.description,
                  default: inclusionReductions.default
                }
              })}
              validationText={validationSchemaError?.parseStep('inclusionReductionsId')}
            />
            : null}
          <Dropdown
            size="lg"
            name="scanType"
            label="Sarine scan type"
            value={props.getState('scanType')}
            onChange={e => props.setState('scanType', e.currentTarget.value)}
            options={roughScanTypes?.map(s => ({ label: s.description, value: s.value }))}
            validationText={validationSchemaError?.parseStep('scanType')}
          />
        </Fieldset>
        <div className="create-rough-stone__measurement-box">
          <div className="create-rough-stone__measurement-box-header">
            <span>Color, fluorescence and tinge</span>
            <div className='cursor-pointer'
              onClick={() => setMeasurementsModalOpen(true)}
            >
              <Icon size='lg' name="edit" />
            </div>
          </div>
          <div className="create-rough-stone__measurement-list">
            <span></span>
            <span className='overline'>Color</span>
            <span className='overline'>Fluorescence</span>
            <span className='overline'>Tinge</span>
            {measurementBoxList.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <span>{`Measurement ${index + 1}`}</span>
                  <span>{coloursMap?.[item.colour] ?? '-'}</span>
                  <span>{item.fluorescence ?? '-'}</span>
                  <span>{tingesMap?.[item.tinge] ?? '-'}</span>
                </React.Fragment>
              )
            })}
          </div>
          <Modal
            open={measurementsModalOpen}
            title="Rough stone measurements"
            position="right center"
            onClose={() => setMeasurementsModalOpen(false)}
          >
            <MeasurementsModal
              measurementBoxList={measurementBoxList}
              onSaveMeasurements={(values) => {
                setMeasurements(values)
                setMeasurementsModalOpen(false)
              }}
              onCancel={() => setMeasurementsModalOpen(false)}
            />
          </Modal>
          <ErrorText {...validationSchemaError?.parse('measurements')} />
        </div>
        <Fieldset legend='Other Properties'>
          <Checkbox
            name='otherAttributes.yellowFluorescence'
            label='Yellow UV'
            value={props.getState('otherAttributes')?.yellowFluorescence}
            onChange={e =>
              props.setState('otherAttributes', { ...props.getState('otherAttributes'), yellowFluorescence: e.currentTarget.checked })
            }
            validationText={validationSchemaError?.parseStep('otherAttributes.yellowFluorescence')}
          />
          <Dropdown
            size='lg'
            name='otherAttributes.tension'
            label='Tension'
            value={props.getState('otherAttributes')?.tension}
            onChange={e =>
              props.setState('otherAttributes', { ...props.getState('otherAttributes'), tension: e.currentTarget.value })
            }
            options={roughTensions?.map(t => ({ label: t.description, value: t.value }))}
            validationText={validationSchemaError?.parseStep('otherAttributes.tension')}
          />
          <Dropdown
            size='lg'
            name='type'
            label='Type'
            value={props.getState('type')}
            onChange={e => props.setState('type', e.currentTarget.value)}
            options={roughTypes?.map(t => ({ label: t.description, value: t.value }))}
            validationText={validationSchemaError?.parseStep('type')}
          />
        </Fieldset>
        <CreateRoughStoneBtn {...props} />
      </div>
    </Form>
  )
}

export default MeasurementStep
