import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@atoms'
import { Dropdown } from '@molecules'
import { Modal } from '@templates'
import { useToast } from '@hooks'
import { useGlobalsStore } from '@stores'
import { useHistory } from 'react-router-dom'
import { roughStoneActions, assortmentActions } from '@actions'

function CloneRoughStonesModal({
  open,
  onClose,
  redirect,
  roughStones,
  refreshStoneList
}) {
  const { showSuccessToast } = useToast()
  const history = useHistory()
  const [assortmentList, setAssortmentList] = useState([])
  const [selectedAssortmentId, setSelectedAssortmentId] = useState(null)
  const {
    orgsMap: { all: orgsMap },
    getOrgsList
  } = useGlobalsStore(state => state)

  useEffect(() => {
    if (open) {
      getOrgsList()
    }
  }, [open])

  useEffect(() => {
    if (open) {
      assortmentActions.getAssortmentList({ condition: 'ACTIVE' })
      .then(response => setAssortmentList(response.data.data))
    }
  }, [open])

  function handleOnSubmit(roughIds, assortmentId) {
    if (!roughIds?.length) return
    if (!assortmentId) return

    roughStoneActions.cloneRoughStones(roughIds, assortmentId)
    .then(response => {
      showSuccessToast('Rough stones were cloned successfully')
      if (refreshStoneList && typeof refreshStoneList === 'function') refreshStoneList()
      handleOnClose()
      if (redirect) history.push(`/roughstones/${response.data.data}`)
    })
  }

  function handleOnClose() {
    setAssortmentList([])
    setSelectedAssortmentId(null)
    if (onClose && typeof onClose === 'function') onClose()
  }

  function getAssortmentLabel(assortment) {
    return orgsMap?.[assortment.sellerId] ? `${orgsMap?.[assortment.sellerId]} | ${assortment.name}` : assortment.name
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`Clone ${roughStones?.length} Stones`}
    >
      <div className="clone-rough-stones-modal">
        <div className="clone-rough-stones-modal__rough-list">
          <div className="clone-rough-stones-modal__rough-list-header">
            <span><b>Clara ID</b></span>
            <span><b>Seller Stone Name</b></span>
          </div>
          {/* list of rough stones */}
          {roughStones?.map((rough, key) => {
            return (
              <div key={key} className="clone-rough-stones-modal__rough-list-row">
                <span>{rough.id}</span>
                <span>{rough.sellerStoneName}</span>
              </div>
            )
          })}
        </div>
        <div className='clone-rough-stones-modal__assortment-list'>
          {/* assortment dropdown */}
          <Dropdown
            name={'assortment'}
            label='Assortment'
            isClearable={false}
            value={selectedAssortmentId}
            onChange={e => setSelectedAssortmentId(e.currentTarget.value)}
            options={assortmentList?.map(a => ({ value: a.id, label: getAssortmentLabel(a) }))}
          />
          <div className='clone-rough-stones-modal__assortment-list-row'>
            <Button
              onClick={handleOnClose}
              typeVariant='action'
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleOnSubmit(roughStones?.map(r => r.id), selectedAssortmentId)}
              size='sm'
              disabled={!selectedAssortmentId}
            >
              Clone Stones
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

CloneRoughStonesModal.propTypes = {
  open: PropTypes.bool,
  redirect: PropTypes.bool,
  onClose: PropTypes.func,
  refreshStoneList: PropTypes.func,
  roughStones: PropTypes.arrayOf(PropTypes.object)
}

CloneRoughStonesModal.defaultProps = {
  open: false,
  redirect: false,
  roughStones: []
}

export default CloneRoughStonesModal
