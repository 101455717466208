import React from 'react'
import { IconContext } from 'react-icons'
import { FaRegBell, FaDollarSign, FaComment, FaShapes, FaFileCsv } from 'react-icons/fa'
import { VscOrganization, VscListUnordered } from 'react-icons/vsc'
import { CgProfile, CgTemplate } from 'react-icons/cg'
import { BiEditAlt, BiRefresh, BiCalendar, BiDiamond } from 'react-icons/bi'
import { GiCardExchange } from 'react-icons/gi'
import { FiUsers, FiUserPlus, FiPlus, FiSettings } from 'react-icons/fi'
import { CiBoxes } from 'react-icons/ci'
import {
  AiFillFilter,
  AiOutlineCheckCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineWarning,
  AiOutlineFieldNumber,
  AiOutlineFileExcel
} from 'react-icons/ai'
import {
  BsDash,
  BsQuestionDiamond,
  BsSearch,
  BsCalendar,
  BsCalendar3,
  BsClock,
  BsClockHistory,
  BsClipboardCheck,
  BsFileEarmarkText,
  BsFillCircleFill,
  BsCalendarFill,
  BsShieldLock
} from 'react-icons/bs'
import {
  HiChevronUp,
  HiChevronLeft,
  HiChevronDown,
  HiChevronRight,
  HiExclamationCircle,
  HiOutlineClipboardList,
  HiOutlineDocumentReport
} from 'react-icons/hi'
import {
  IoIosClose,
  IoMdArrowDropup,
  IoMdCloudUpload,
  IoMdCloudDownload,
  IoMdArrowDropleft,
  IoMdArrowDropdown,
  IoMdArrowDropright,
  IoMdCloseCircleOutline,
  IoMdFiling
} from 'react-icons/io'
import {
  IoChevronForwardCircleSharp,
  IoArrowUndoCircleOutline,
  IoInformationCircleOutline
} from 'react-icons/io5'
import {
  RiCrop2Line,
  RiTruckLine,
  RiRoadMapLine,
  RiDashboardLine,
  RiDeleteBinLine,
  RiErrorWarningLine,
  RiUserSettingsLine,
  RiInboxUnarchiveFill,
  RiInboxArchiveFill,
  RiFilterOffFill,
  RiFileExcel2Line
} from 'react-icons/ri'
import { ImCopy, ImSpinner2 } from 'react-icons/im'
import { TbNumbers, TbOctahedron } from 'react-icons/tb'
import PropTypes from 'prop-types'

function getIconComponent(name, title) {
  return {
    plus: <FiPlus title={title} />,
    minus: <BsDash title={title} />,
    users: <FiUsers title={title} />,
    clock: <BsClock title={title} />,
    bell: <FaRegBell title={title} />,
    edit: <BiEditAlt title={title} />,
    search: <BsSearch title={title} />,
    close: <IoIosClose title={title} />,
    comment: <FaComment title={title} />,
    refresh: <BiRefresh title={title} />,
    profile: <CgProfile title={title} />,
    view: <AiOutlineEye title={title} />,
    assortment: <CiBoxes title={title} />,
    spinner: <ImSpinner2 title={title} />,
    inventory: <IoMdFiling title={title} />,
    calendar: <BsCalendar title={title} />,
    calendar3: <BsCalendar3 title={title} />,
    menuCalendar: <BiCalendar title={title} />,
    template: <CgTemplate title={title} />,
    dollars: <FaDollarSign title={title} />,
    event: <BsCalendarFill title={title} />,
    shipments: <RiTruckLine title={title} />,
    createUser: <FiUserPlus title={title} />,
    chevronUp: <HiChevronUp title={title} />,
    roughStone: <TbOctahedron title={title} />,
    remove: <RiDeleteBinLine title={title} />,
    upload: <IoMdCloudUpload title={title} />,
    excel: <AiOutlineFileExcel title={title} />,
    shieldLock: <BsShieldLock title={title} />,
    planning: <BiDiamond title={title} />,
    numbers: <TbNumbers title={title} />,
    number: <AiOutlineFieldNumber title={title} />,
    provenance: <RiRoadMapLine title={title} />,
    logList: <VscListUnordered title={title} />,
    dashboard: <RiDashboardLine title={title} />,
    clearFilter: <RiFilterOffFill title={title} />,
    chevronDown: <HiChevronDown title={title} />,
    chevronLeft: <HiChevronLeft title={title} />,
    download: <IoMdCloudDownload title={title} />,
    csvDownload: <FaFileCsv title={title} />,
    xlsxDownload: <RiFileExcel2Line title={title} />,
    assign: <GiCardExchange title={title} />,
    archive: <RiInboxArchiveFill title={title} />,
    check: <AiOutlineCheckCircle title={title} />,
    error: <RiErrorWarningLine title={title} />,
    warning: <AiOutlineWarning title={title} />,
    chevronRight: <HiChevronRight title={title} />,
    arrowDropUp: <IoMdArrowDropup title={title} />,
    shapesCutGrades: <RiCrop2Line title={title} />,
    progressClock: <BsClockHistory title={title} />,
    reportFile: <BsFileEarmarkText title={title} />,
    organizations: <VscOrganization title={title} />,
    undo: <IoArrowUndoCircleOutline title={title} />,
    orders: <HiOutlineClipboardList title={title} />,
    unarchive: <RiInboxUnarchiveFill title={title} />,
    clipboardCheck: <BsClipboardCheck title={title} />,
    report: <HiOutlineDocumentReport title={title} />,
    invisible: <AiOutlineEyeInvisible title={title} />,
    arrowDropDown: <IoMdArrowDropdown title={title} />,
    arrowDropLeft: <IoMdArrowDropleft title={title} />,
    info: <IoInformationCircleOutline title={title} />,
    buyerSettings: <RiUserSettingsLine title={title} />,
    arrowDropRight: <IoMdArrowDropright title={title} />,
    closeCircle: <IoMdCloseCircleOutline title={title} />,
    exclamation: <HiExclamationCircle title={title} />,
    diamondQuestionMark: <BsQuestionDiamond title={title} />,
    chevronForwardCircle: <IoChevronForwardCircleSharp title={title} />,
    copy: <ImCopy title={title} />,
    circle: <BsFillCircleFill title={title}/>,
    filter: <AiFillFilter title={title}/>,
    shapes: <FaShapes title={title} />,
    settings: <FiSettings title={title} />
  }[name]
}

function Icon({
  name,
  size,
  spin,
  title,
  style,
  onClick,
  disabled,
  className,
  tooltipId
}) {
  const sizeSuffix
    = {
      xsm: '--xsm',
      sm: '--sm',
      md: '--md',
      lg: '--lg',
      info: '--info-tip',
      warning: '--warning',
      error: '--error'
    }[size] || '--md'

  return (
    <IconContext.Provider value={{ className }}>
      <div
        onClick={!disabled ? onClick : null}
        className={`icon${sizeSuffix}${spin ? ' spinning' : ''} ${onClick ? ' cursor-pointer' : ''} ${disabled ? ' icon--disabled' : ''}`}
        {...(tooltipId ? { 'data-tip': true, 'data-for': tooltipId } : {})}
        style={style}
      >
        {getIconComponent(name, title)}
      </div>
    </IconContext.Provider>
  )
}

Icon.propTypes = {
  spin: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltipId: PropTypes.string,
  onClick: PropTypes.func
}

Icon.defaultProps = {
  size: '',
  title: '',
  style: {},
  spin: false,
  disabled: false,
  className: 'icon',
  onClick: null,
  // default is a diamond-shape question mark
  // to indicate to the developer that he/she
  // forgot to pass an icon name
  name: 'diamondQuestionMark'
}

export default Icon
