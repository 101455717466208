import React, { useMemo, useState } from 'react'
import { reportActions, roughStoneActions } from '@actions'
import { arrayUtils, errorUtils, fileUtils, textUtils } from '@utils'
import { useAuthStore } from '@stores'
import { COMMON } from '@constants'
import { Button, Icon } from '@atoms'
import { RoughCommentModal, Dropdown } from '@molecules'
import { ConfirmationModal, Modal } from '@templates'
import PriceUploadModal from './inc/priceUploadModal'
import UploadBidsResponsesModal from './inc/uploadBidsResponsesModal'
import MultiRoughsCommentsModal from './inc/multiRoughsCommentsModal'
import PricingAdjustmentModal from './pricingAdjustmentModal'
import CloneRoughStonesModal from './cloneRoughStonesModal'

function RoughStoneListModals({ openModal, setOpenModal, updateSucceeded, isReportsModalOpen, setIsReportsModalOpen, openCommentsModal, setOpenCommentModal, orgsList, orgsMap, orgId, roughSellersList, localRoughList, priceableStatuses, refreshStoneList, refreshBids }) {
  const { hasAdmin } = useAuthStore(state => state)

  const [selectedOrganization, setSelectedOrganization] = useState(null)

  function generateAllStonesReport() {
    const params = {}
    if (hasAdmin(reportActions.getRoughsReport)) {
      if (selectedOrganization) {
        params.sellerIds = [selectedOrganization]
      }
    } else {
      params.sellerIds = [orgId]
    }

    const fileName = getFileName('All Stones')
    generateRoughsReport(params, fileName)
  }

  function generateSoldStonesReport() {
    const params = {}
    if (hasAdmin(reportActions.getRoughsReport)) {
      if (selectedOrganization) {
        params.sellerIds = [selectedOrganization]
      }
    } else {
      params.sellerIds = [orgId]
    }

    params.statuses = [
      'ROUGH_SOLD',
      'ROUGH_SHIPPED',
      'ROUGH_ACCEPTED'
    ]

    const fileName = getFileName('Sold Stones')
    generateRoughsReport(params, fileName)
  }

  function generateCurrentInventoryReport() {
    const params = {}
    if (hasAdmin(reportActions.getRoughsReport)) {
      if (selectedOrganization) {
        params.sellerIds = [selectedOrganization]
      }
    } else {
      params.sellerIds = [orgId]
    }

    params.condition = 'ACTIVE'
    params.statuses = [
      'ROUGH_NO_ADV',
      'ROUGH_MATCHED',
      'ROUGH_SUBMITTED',
      'ROUGH_INCOMPLETE',
      'ROUGH_NOT_SUBMITTED'
    ]

    const fileName = getFileName('Current Inventory')
    generateRoughsReport(params, fileName)
  }

  function generateRoughsReport(params, fn) {
    reportActions.getRoughsReport('xlsx', params)
    .then(response => {
      const serverFileName = fileUtils.getFileName(response.data.data)
      const fnComponents = serverFileName.split(' - ')
      const fileName = fnComponents.length === 2 && fnComponents[0] === 'Rough List' ? [fnComponents[0], fn].join(' - ') : serverFileName
      fileUtils.saveBase64Excel(response.data.data.report, fileName)
    })
    .catch(err => console.error(err))
  }

  function getFileName(prefix) {
    let orgName = ''
    if (hasAdmin(reportActions.getRoughsReport) && selectedOrganization) {
      orgName = ' - ' + orgsMap[selectedOrganization]
    }

    return `${prefix}${orgName} - ${textUtils.formatDate(new Date(), COMMON.DATE_FMT.REPORT)}.xlsx`
  }

  function onBidsUploaded() {
    setOpenModal({ name: false, selectedRows: [] })
    refreshBids()
  }

  async function handleArchive(ids) {
    return roughStoneActions.setRoughCondition({ roughIds: ids, condition: 'ARCHIVED' })
    .then(updateSucceeded(`Stone${ids.length > 1 ? 's have' : ' has'} been archived.`, ids))
  }

  async function handleUnarchive(ids) {
    return roughStoneActions.setRoughCondition({ roughIds: ids, condition: 'ACTIVE' })
    .then(updateSucceeded(`Stone${ids.length > 1 ? 's have' : ' has'} been unarchived.`, ids))
  }

  async function handleRemove(ids) {
    return roughStoneActions.setRoughCondition({ roughIds: ids, condition: 'DELETED' })
    .then(updateSucceeded(`Stone${ids.length > 1 ? 's have' : ' has'} been removed.`, ids))
  }

  const sellerOrgs = useMemo(() => {
    if (!orgsList?.length || !roughSellersList?.length) return []
    return arrayUtils.intersectBy(orgsList, roughSellersList, (org) => org?.orgId || org)
  }, [orgsList, roughSellersList])

  return <>
    <PricingAdjustmentModal openModal={openModal} setOpenModal={setOpenModal} updateSucceeded={updateSucceeded} priceableStatuses={priceableStatuses} />
    <ConfirmationModal
      open={openModal.name === 'submit'}
      title='Submit Stones'
      message={`Submit ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''} to be planned and matched?`}
      onClose={() => setOpenModal({ name: false, selectedRows: [] })}
      onSubmit={async () => {
        return roughStoneActions.updateMultipleRoughs(
          {
            roughIds: openModal.selectedRows.map(row => row.id),
            status: 'ROUGH_SUBMITTED'
          },
          { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
        )
          .then(updateSucceeded('Stones submitted', openModal.selectedRows.map(row => row.id)))
      }}
    />
    <ConfirmationModal
      open={openModal.name === 'unsubmit'}
      title='Pause Stones'
      message={`Pause ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''} from sales until further notice?`}
      onClose={() => setOpenModal({ name: false, selectedRows: [] })}
      onSubmit={async () => {
        return roughStoneActions.updateMultipleRoughs(
          {
            roughIds: openModal.selectedRows.map(row => row.id),
            status: 'ROUGH_NOT_SUBMITTED'
          },
          { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
        )
          .then(updateSucceeded('Stones paused', openModal.selectedRows.map(row => row.id)))
      }}
    />
    <ConfirmationModal
      open={openModal.name === 'archive'}
      title='Archive Stones'
      message={`Archive ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''}?`}
      onClose={() => setOpenModal({ name: false, selectedRows: [] })}
      onSubmit={() => handleArchive(openModal.selectedRows.map(row => row.id))}
      closeOnFail={false}
    />
    <ConfirmationModal
      open={openModal.name === 'unarchive'}
      title='Unarchive Stones'
      message={`Unarchive ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''}?`}
      onClose={() => setOpenModal({ name: false, selectedRows: [] })}
      onSubmit={() => handleUnarchive(openModal.selectedRows.map(row => row.id))}
      closeOnFail={false}
    />
    <ConfirmationModal
      open={openModal.name === 'delete'}
      title='Delete Stones'
      message={`Permanently delete ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''}?`}
      onClose={() => setOpenModal({ name: false, selectedRows: [] })}
      onSubmit={() => handleRemove(openModal.selectedRows.map(row => row.id))}
      closeOnFail={false}
    />
    <PriceUploadModal
      open={openModal.name === 'uploadPrices'}
      onClose={() => setOpenModal({ name: false, selectedRows: [] })}
      onSubmit={async newPrices => {
        return roughStoneActions.updateMultipleRoughs(
          {
            roughIds: Object.keys(newPrices),
            reservePpcOverride: { list: newPrices }
          },
          { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
        )
          .then(updateSucceeded('Prices set', openModal.selectedRows.map(row => row.id)))
      }}
    />
    <Modal
      title='Rough Stones Reports'
      open={isReportsModalOpen}
      onClose={() => setIsReportsModalOpen(false)}
    >
      {
        hasAdmin(reportActions.getRoughsReport)
          ? (
            <div className="rough-stone-list__organization-dropdown">
              <Dropdown
                label='Organization'
                value={selectedOrganization}
                onChange={e => setSelectedOrganization(e.currentTarget.value)}
                options={sellerOrgs?.map(org => {
                  return {
                    label: org.commonName,
                    value: org.orgId
                  }
                })}
              />
            </div>
          )
          : null
      }
      <div className="rough-stone-list__reports-row">
        <Icon
          name='reportFile'
        />
        <span>All Stones</span>
        <Button
          typeVariant='secondary'
          size='sm'
          onClick={() => generateAllStonesReport()}
        >
            Generate Report
        </Button>
      </div>
      <div className="rough-stone-list__reports-row">
        <Icon
          name='reportFile'
        />
        <span>Sold Stones</span>
        <Button
          typeVariant='secondary'
          size='sm'
          onClick={() => generateSoldStonesReport()}
        >
            Generate Report
        </Button>
      </div>
      <div className="rough-stone-list__reports-row">
        <Icon
          name='reportFile'
        />
        <span>Current Inventory</span>
        <Button
          typeVariant='secondary'
          size='sm'
          onClick={() => generateCurrentInventoryReport()}
        >
            Generate Report
        </Button>
      </div>
    </Modal>
    <RoughCommentModal
      open={openCommentsModal.open}
      onClose={() => setOpenCommentModal({ open: false, rough: null })}
      rough={openCommentsModal.rough}
    />
    <UploadBidsResponsesModal open={openModal?.name === 'uploadBidsResponse'} onClose={() => setOpenModal({ name: false, selectedRows: [] })} localRoughList={localRoughList} onBidsUploaded={onBidsUploaded}/>
    <MultiRoughsCommentsModal open={openModal?.name === 'multiRoughsComments'} onClose={() => setOpenModal({ name: false, selectedRows: [] })} dataSet={openModal?.selectedRows} refreshStoneList={refreshStoneList}/>
    <CloneRoughStonesModal open={openModal?.name === 'cloneRoughStones'} onClose={() => setOpenModal({ name: false, selectedRows: [] })} roughStones={openModal?.selectedRows} refreshStoneList={refreshStoneList}/>
  </>
}

export default RoughStoneListModals
