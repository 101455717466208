import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { saleActions, eventActions, reportActions } from '@actions'
import { useGlobalsStore, useOrderStore, useRoughStoneStore, useModalStore } from '@stores'
import { usePageTitle, useToast } from '@hooks'
import { useHistory } from 'react-router-dom'
import { arrayUtils, fileUtils } from '@utils'
import { DetailsPage, Modal } from '@templates'
import { FormComponents } from '@organisms'
import MatchList from '../match/matchList'
import moment from 'moment'
import PlanningDetailsModal from '../inc/planningDetailsModal'

function SaleDetails({ match, title }) {
  const { saleId } = match?.params

  const { SimpleForm, TextArea, Dropdown, Button } = FormComponents

  const { setModal } = useModalStore()

  const [sale, setSale] = useState(null)

  useEffect(() => {
    saleActions.getSales({ id: saleId })
    .then(result => setSale(result.data.data[0]))
  }, [saleId])

  usePageTitle(title, saleId, sale?.name)

  const [allEvents, setAllEvents] = useState([])

  const {
    orgsList: { all: orgsList }, getOrgsList,
    eventTypesList, getEventTypesList,
    saleStatusesMap, getSaleStatuses
  } = useGlobalsStore()
  useEffect(() => {
    getOrgsList()
    getEventTypesList()
    getSaleStatuses()
  }, [])

  useEffect(() => {
    if (eventTypesList?.length) {
      const saleTypeId = eventTypesList.find(t => t.name === 'Sale')?.id
      if (!saleTypeId) return
      eventActions.getEvents({ typeId: saleTypeId })
      .then(res => {
        res.data.data.sort((a, b) => moment(a.startTime) - moment(b.startTime))
        const eventGroups = {}
        for (const event of res.data.data) {
          if (event.groupId) eventGroups[event.groupId] = true
        }
        setAllEvents(Object.keys(eventGroups))
      })
      .catch(console.error)
    }
  }, [eventTypesList])

  const ordersParams = { condition: 'ACTIVE', status: 'ORDER_SUBMITTED', columns: '[id, name, buyerId, condition]' }
  const {
    ordersList: { [ordersParams ? JSON.stringify(ordersParams) : 'all']: ordersList },
    getOrdersList
  } = useOrderStore(store => store)

  const roughStoneParams = { condition: 'ACTIVE', status: 'ROUGH_SUBMITTED', qcApproved: true, columns: ['id', 'sellerId', 'sellerStoneName', 'assortmentId', 'qcStatus', 'Assortment', 'weight'] }
  const {
    roughStonesList: { [roughStoneParams ? JSON.stringify(roughStoneParams) : 'all']: storeRoughStonesList },
    getRoughStonesList
  } = useRoughStoneStore(store => store)

  useEffect(() => {
    if (orgsList?.length) {
      getRoughStonesList(roughStoneParams)
      getOrdersList(ordersParams)
    }
  }, [orgsList])

  const genericOrgId = useMemo(() => orgsList?.find(o => o.orgType === 'GENERIC')?.orgId, [orgsList])

  const [allOrders, allOrdersByOwner] = useMemo(() => {
    if (!ordersList || !ordersList.data) return [new Map(), {}]
    const submittedOrders = ordersList.data.filter(o => o.buyerId !== genericOrgId).map(o => ({ ...o, buyerName: orgsList?.find(org => org.orgId === o.buyerId)?.commonName }))
    const orders = submittedOrders.reduce((m, o) => m.set(o.id, o.name), new Map())
    if (sale && sale.orderIds) {
      for (const oId of sale.orderIds) {
        if (!orders.has(oId)) orders.set(oId, oId)
      }
    }
    const ordersByOwner = arrayUtils.groupBy(submittedOrders, (o) => o.buyerName)
    return [orders, ordersByOwner]
  }, [ordersList, sale?.orderIds, genericOrgId])

  const [allRoughs, allStonesByOwner, allStonesByAssortment] = useMemo(() => {
    if (!storeRoughStonesList || !storeRoughStonesList.data) return [new Map(), {}, {}]
    const submittedRoughs = storeRoughStonesList.data.map(r => ({ ...r, sellerName: orgsList?.find(org => org.orgId === r.sellerId)?.commonName }))
    const roughs = submittedRoughs.reduce((m, o) => m.set(o.id, o.name), new Map())
    if (sale && sale.stoneIds) {
      for (const rId of sale.stoneIds) {
        if (!roughs.has(rId)) roughs.set(rId, true)
      }
    }
    const stonesByOwner = arrayUtils.groupBy(submittedRoughs, (s) => s.sellerName)
    const stonesByAssortment = arrayUtils.groupBy(submittedRoughs, (s) => s.assortmentId)
    return [roughs, stonesByOwner, stonesByAssortment]
  }, [storeRoughStonesList, sale?.stoneIds])

  const [allQCApprovedRoughs, allQCNotRequiredRoughs, allOver25, allOver18, allOver12] = useMemo(() => {
    if (!storeRoughStonesList || !storeRoughStonesList.data) return [[], []]
    return Object.values(storeRoughStonesList.data.reduce((accum, { id: value, qcStatus, weight }) => {
      if (qcStatus === 'APPROVED') accum.qcApproved.push({ value })
      else if (qcStatus === 'NOT_REQUIRED') accum.qcNotRequired.push({ value })
      if (weight >= 2.5) accum['over2.5'].push({ value })
      else if (weight >= 1.8) accum['1.8-2.5'].push({ value })
      else if (weight >= 1.2) accum['1.2-1.8'].push({ value })
      return accum
    }, { qcApproved: [], qcNotRequired: [], 'over2.5': [], '1.8-2.5': [], '1.2-1.8': [] }))
  }, [storeRoughStonesList, sale?.stoneIds])

  const [isOrderStoneModalOpen, setIsOrderStoneModalOpen] = useState(null)
  const [isRemoveOrderStoneModalOpen, setIsRemoveOrderStoneModalOpen] = useState(null)

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (sale) {
      setFields([
        {
          label: 'Name',
          value: sale.name,
          name: 'name',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Status',
          value: sale.status,
          name: 'status',
          componentName: 'dropdown',
          options: Object.entries(saleStatusesMap)?.map(([v, d]) => ({ value: v, label: d })),
          canEdit: true
        },
        {
          label: 'Event Group',
          value: sale.eventGroupId,
          name: 'eventGroupId',
          componentName: 'dropdown',
          options: allEvents.map(e => ({ value: e, label: e })),
          canEdit: true
        },
        {
          label: 'Rough Stones',
          value: sale.stoneIds,
          name: 'stoneIds',
          componentName: 'dropdown',
          options: Array.from(allRoughs, ([rId, _]) => ({ value: rId, label: rId })),
          isMulti: true,
          canEdit: true,
          span: true
        },
        {
          label: 'Orders',
          value: sale.orderIds,
          name: 'orderIds',
          componentName: 'dropdown',
          options: Array.from(allOrders, ([oId, oNm]) => ({ value: oId, label: oNm })),
          isMulti: true,
          canEdit: true,
          span: true
        },
        {
          label: 'Created At',
          value: moment(sale.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          value: moment(sale.updatedAt).toLocaleString()
        }
      ])
    }
  }, [sale, allEvents, allRoughs, allOrders, saleStatusesMap])

  async function downloadPlanningDetails([opts]) {
    return reportActions.getPlanningDetails('xlsx', { ...opts })
    .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
  }
  function downloadBidGrid() {
    reportActions.getBidGrid('xlsx', { saleId })
    .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
  }
  function downloadBidsReport() {
    reportActions.getBidsReport('xlsx', { saleId })
    .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
  }

  const { showSuccessToast, showErrorToast } = useToast()

  const history = useHistory()
  async function handleOnSubmit({ ...saleData }) {
    if (Object.keys(saleData).length) {
      return saleActions.editSale({ id: saleId, ...saleData })
      .then(() => {
        showSuccessToast('Sale updated.')
        history.push('/sales')
      })
    }
  }

  function handleAdd({ orderIdsList, stoneIdsList, buyerIds, orderIds, sellerIds, assortmentIds, stoneIds }) {
    const { handleOnChange, formValues } = isOrderStoneModalOpen?.detailsProps || {}
    if (orderIdsList) {
      orderIds = orderIds ? [...orderIds, ...orderIdsList.trim().split('\n')] : orderIdsList.trim().split('\n')
    }
    if (stoneIdsList) {
      stoneIds = stoneIds ? [...stoneIds, ...stoneIdsList.trim().split('\n')] : stoneIdsList.trim().split('\n')
    }

    if (buyerIds?.length) {
      for (const buyer of buyerIds) {
        orderIds = orderIds ? [...orderIds, ...allOrdersByOwner[buyer].map(x => x.id)] : allOrdersByOwner[buyer].map(x => x.id)
      }
    }

    if (sellerIds?.length) {
      for (const seller of sellerIds) {
        stoneIds = stoneIds ? [...stoneIds, ...allStonesByOwner[seller].map(x => x.id)] : allStonesByOwner[seller].map(x => x.id)
      }
    }

    if (assortmentIds?.length) {
      for (const assortment of assortmentIds) {
        stoneIds = stoneIds ? [...stoneIds, ...allStonesByAssortment[assortment].map(x => x.id)] : allStonesByAssortment[assortment].map(x => x.id)
      }
    }

    if ((!orderIds || orderIds.length === 0) && (!stoneIds || stoneIds.length === 0)) {
      showErrorToast('No new rough stones or orders were selected')
      setIsOrderStoneModalOpen(null)
      return true
    }

    if (stoneIds?.length) handleOnChange()({ currentTarget: { name: 'stoneIds', value: Array.from(new Set((formValues?.stoneIds || sale.stoneIds).concat(stoneIds))) } })
    if (orderIds?.length) handleOnChange()({ currentTarget: { name: 'orderIds', value: Array.from(new Set((formValues?.orderIds || sale.orderIds).concat(orderIds))) } })
    setIsOrderStoneModalOpen(null)
  }

  function handleRemove({ orderIdsList, stoneIdsList, buyerIds, orderIds, sellerIds, assortmentIds, stoneIds }) {
    const { handleOnChange, formValues } = isRemoveOrderStoneModalOpen?.detailsProps || {}
    if (orderIdsList) {
      orderIds = orderIds ? [...orderIds, ...orderIdsList.trim().split('\n')] : orderIdsList.trim().split('\n')
    }
    if (stoneIdsList) {
      stoneIds = stoneIds ? [...stoneIds, ...stoneIdsList.trim().split('\n')] : stoneIdsList.trim().split('\n')
    }

    if (buyerIds?.length) {
      for (const buyer of buyerIds) {
        orderIds = orderIds ? [...orderIds, ...allOrdersByOwner[buyer].map(x => x.id)] : allOrdersByOwner[buyer].map(x => x.id)
      }
    }

    if (sellerIds?.length) {
      for (const seller of sellerIds) {
        stoneIds = stoneIds ? [...stoneIds, ...allStonesByOwner[seller].map(x => x.id)] : allStonesByOwner[seller].map(x => x.id)
      }
    }

    if (assortmentIds?.length) {
      for (const assortment of assortmentIds) {
        stoneIds = stoneIds ? [...stoneIds, ...allStonesByAssortment[assortment].map(x => x.id)] : allStonesByAssortment[assortment].map(x => x.id)
      }
    }

    if ((!orderIds || orderIds.length === 0) && (!stoneIds || stoneIds.length === 0)) {
      showErrorToast('No rough stones or orders were selected')
      setIsRemoveOrderStoneModalOpen(null)
      return true
    }

    if (stoneIds?.length) handleOnChange()({ currentTarget: { name: 'stoneIds', value: Array.from(new Set((formValues?.stoneIds || sale.stoneIds).filter(x => !stoneIds.includes(x)))) } })
    if (orderIds?.length) handleOnChange()({ currentTarget: { name: 'orderIds', value: Array.from(new Set((formValues?.orderIds || sale.orderIds).filter(x => !orderIds.includes(x)))) } })
    setIsRemoveOrderStoneModalOpen(null)
  }

  const roughStoneSelectionActions = [
    {
      label: 'QC Approved',
      onClick: (handleChange) => handleChange(allQCApprovedRoughs, true)
    },
    {
      label: 'QC Not Required',
      onClick: (handleChange) => handleChange(allQCNotRequiredRoughs, true)
    },
    {
      label: '2.5ct+',
      onClick: (handleChange) => handleChange(allOver25, true)
    },
    {
      label: '1.8-2.5ct',
      onClick: (handleChange) => handleChange(allOver18, true)
    },
    {
      label: '1.2-1.8ct',
      onClick: (handleChange) => handleChange(allOver12, true)
    }
  ]

  return <div className='sale-details__container'>
    <DetailsPage
      canEdit={sale?.status && sale?.status !== 'COMPLETED'}
      fields={fields}
      onSubmit={handleOnSubmit}
      title={{
        label: 'Sale ID',
        value: sale?.id || ''
      }}
      extraButtons={[
        {
          onClick: () => setModal({
            id: 'downloadPlanningDetails',
            title: 'Planning Details',
            className: 'planning-details-modal',
            customMessageRenderer: (messageProps) => <PlanningDetailsModal
              initialValues={{ status: 'submitted', saleId, includeTops: true, maxOptsPerBuyer: 2, maxOpts: 5 }}
              {...messageProps}
            />,
            onSubmit: downloadPlanningDetails,
            customButtonsRenderer: () => null,
            closeOnFail: false
          }),
          caption: 'Planning Details'
        },
        {
          caption: 'Bid Grid',
          onClick: downloadBidGrid
        },
        {
          caption: 'Bids & Assortments',
          onClick: downloadBidsReport
        },
        {
          caption: 'Add Orders/Stones',
          onClick: (values, detailsProps) => setIsOrderStoneModalOpen({ isOpen: true, detailsProps: { ...detailsProps, formValues: detailsProps.getValues(values) } }),
          editOnly: true
        },
        {
          caption: 'Remove Orders/Stones',
          onClick: (values, detailsProps) => setIsRemoveOrderStoneModalOpen({ isOpen: true, detailsProps: { ...detailsProps, formValues: detailsProps.getValues(values) } }),
          editOnly: true
        }
      ]}
    />
    <div className="sale-details__match-list">
      <MatchList
        saleId={saleId}
        canEdit={['PLANNING', 'MATCHING', 'PENDING'].includes(sale?.status)}
      />
    </div>
    <Modal
      open={isOrderStoneModalOpen?.isOpen}
      title='Add Orders or Stones to the Sale'
      onClose={() => setIsOrderStoneModalOpen(null)}
    >
      <div>
        <div className='add-order-stone center'>
          <SimpleForm
            onSubmit={handleAdd}
          >
            <TextArea
              rows={5}
              name='orderIdsList'
              label='List of order ids (one per line)'
              required={false}
            >
            </TextArea>
            <TextArea
              name='stoneIdsList'
              rows={5}
              label='List of rough stone ids (one per line)'
              required={false}
            >
            </TextArea>
            <Dropdown
              name='buyerIds'
              label='Select Buyers'
              options={
                Object.keys(allOrdersByOwner)
                .map(o => ({ label: o, value: o }))
                .sort((a, b) => { return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1 })
              }
              isMulti={true}
              canAddAll={true}
              required={false}
            />
            <Dropdown
              name='orderIds'
              label='Select Orders'
              options={
                Object.values(allOrdersByOwner)
                .flat()
                .map(o => ({
                  label: `${o.buyerName} | ${o.id} | ${o.name}`,
                  value: o.id
                }))
                .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
              }
              isMulti={true}
              canAddAll={true}
              required={false}
            />
            <Dropdown
              name='sellerIds'
              label='Select Sellers'
              options={
                Object.keys(allStonesByOwner)
                .map(o => ({
                  label: o,
                  value: o
                }))
                .sort((a, b) => { return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1 })
              }
              isMulti={true}
              canAddAll={true}
              required={false}
            />
            <Dropdown
              name='assortmentIds'
              label='Select Assortments'
              options={
                Object.keys(allStonesByAssortment)
                .map(a => ({
                  label: `${allStonesByAssortment[a][0].sellerName} | ${allStonesByAssortment[a][0].Assortment.name} | ${a}`,
                  value: a
                }))
                .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
              }
              isMulti={true}
              canAddAll={true}
              required={false}
            />
            <Dropdown
              name='stoneIds'
              label='Select Rough Stones'
              options={
                Object.values(allStonesByOwner)
                .flat()
                .map(s => ({
                  label: `${s.sellerName} | ${s.id} | ${s.sellerStoneName}`,
                  value: s.id
                }))
                .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
              }
              isMulti={true}
              canAddAll={true}
              topActions={roughStoneSelectionActions}
              required={false}
            />
            <Button type='submit' size='sm'>
              Add
            </Button>
          </SimpleForm>
        </div>
      </div>
    </Modal>
    <Modal
      open={isRemoveOrderStoneModalOpen?.isOpen}
      title='Remove Orders or Stones from the Sale'
      onClose={() => setIsRemoveOrderStoneModalOpen(null)
      }
    >
      <div>
        <div className='add-order-stone center'>
          <SimpleForm
            onSubmit={handleRemove}
          >
            <TextArea
              rows={5}
              name='orderIdsList'
              label='List of order ids (one per line)'
              required={false}
            >
            </TextArea>
            <TextArea
              name='stoneIdsList'
              rows={5}
              label='List of rough stone ids (one per line)'
              required={false}
            >
            </TextArea>
            <Dropdown
              name='buyerIds'
              label='Select Buyers'
              options={
                Object.keys(allOrdersByOwner)
                .map(o => ({ label: o, value: o }))
                .sort((a, b) => { return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1 })
              }
              isMulti={true}
              canAddAll={true}
              required={false}
            />
            <Dropdown
              name='orderIds'
              label='Select Orders'
              options={
                Object.values(allOrdersByOwner)
                .flat()
                .map(o => ({
                  label: `${o.buyerName} | ${o.id} | ${o.name}`,
                  value: o.id
                }))
                .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
              }
              isMulti={true}
              canAddAll={true}
              required={false}
            />
            <Dropdown
              name='sellerIds'
              label='Select Sellers'
              options={
                Object.keys(allStonesByOwner)
                .map(o => ({
                  label: o,
                  value: o
                }))
                .sort((a, b) => { return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1 })
              }
              isMulti={true}
              canAddAll={true}
              required={false}
            />
            <Dropdown
              name='assortmentIds'
              label='Select Assortments'
              options={
                Object.keys(allStonesByAssortment)
                .map(a => ({
                  label: `${allStonesByAssortment[a][0].sellerName} | ${allStonesByAssortment[a][0].Assortment.name} | ${a}`,
                  value: a
                }))
                .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
              }
              isMulti={true}
              canAddAll={true}
              required={false}
            />
            <Dropdown
              name='stoneIds'
              label='Select Rough Stones'
              options={
                Object.values(allStonesByOwner)
                .flat()
                .map(s => ({
                  label: `${s.sellerName} | ${s.id} | ${s.sellerStoneName}`,
                  value: s.id
                }))
                .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
              }
              isMulti={true}
              canAddAll={true}
              topActions={roughStoneSelectionActions}
              required={false}
            />
            <Button type='submit' size='sm'>
              Remove
            </Button>
          </SimpleForm>
        </div>
      </div>
    </Modal>
  </div>
}

SaleDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default SaleDetails
