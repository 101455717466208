import React from 'react'
import { validationUtils } from '@utils'
import {
  ToggleSwitch,
  Checkbox,
  Button
} from '@atoms'
import {
  TextArea,
  TextInput,
  Dropdown,
  RadioGroup,
  InfoTip
} from '@molecules'
import {
  Fieldset
} from '@organisms'
import {
  NavigationLink,
  DownloadLink
} from '@templates'

function ReadOnly({ name, label, value, infoTip, ...props }) {
  return (
    <>
      <div className='input_label'>
        <span className='label'>{typeof label === 'function' ? label(props) : label}</span>
        {infoTip ? <InfoTip name={name}>{infoTip}</InfoTip> : null}
      </div>
      <span className='details-page__label-value'>{value}</span>
    </>
  )
}

function FormInput({ item, values, onChange, disabled, validationErrors, setValidationErrors, removeValidationErrors, ...props }) {
  if (item?.componentName?.toLocaleLowerCase() === 'text') {
    return (
      <span
        className={item.className}
      >
        {item.label}
      </span>
    )
  }

  if (item?.componentName?.toLocaleLowerCase() === 'button') {
    return (
      <Button
        onClick={item.onClick}
        id={item.id}
        {...item}
      >
        {item.label}
      </Button>
    )
  }

  if (item?.componentName?.toLocaleLowerCase() === 'blank') {
    return (
      <div className={item.className}/>
    )
  }

  const FormComponent = {
    navlink: NavigationLink,
    downloadlink: DownloadLink,
    dropdown: Dropdown,
    fieldset: Fieldset,
    textarea: TextArea,
    checkbox: Checkbox,
    textinput: TextInput,
    radiogroup: RadioGroup,
    toggleswitch: ToggleSwitch,
    readonly: ReadOnly
  }[String(item.componentName).toLocaleLowerCase()] || item.customComponent || TextInput

  function getValidationText() {
    if (!item.name) return validationUtils.validation()
    const itemErrors = validationErrors?.parseStep(item.name)
    // fieldset should not receive its childrens errors
    if (
      !(Array.isArray(itemErrors?.getError()) || Array.isArray(itemErrors?.getWarning()))
      && item.children
      && String(item.componentName).toLocaleLowerCase() === 'fieldset'
    ) {
      return validationUtils.validation()
    }
    return itemErrors
  }
  return (
    <div className="details-page__content-item">
      <FormComponent
        legend={item.label}
        onChange={onChange(item.handleChange)}
        disabled={disabled}
        validationText={getValidationText()}
        setValidationText={setValidationErrors}
        removeValidationText={removeValidationErrors}
        {...item}
        {...props}
      >
        {
          item.children && Array.isArray(item.children)
            ? item.children.reduce((children, child, index) => {
              const overrides = typeof child.renderOverrides === 'function' ? child.renderOverrides(item.children, values, onChange) : null
              const renderItem = overrides ? { ...child, ...overrides } : child

              const hasProperty = Object.prototype.hasOwnProperty.call(renderItem, 'shouldDisplay')
              if (!hasProperty || (renderItem.shouldDisplay === 'editOnly' ? props.isEditToggled : renderItem.shouldDisplay)) {
                children.push(<FormInput
                  key={index}
                  item={renderItem}
                  onChange={onChange}
                  disabled={disabled || !renderItem.canEdit}
                  props={props}
                  validationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                  removeValidationErrors={removeValidationErrors}
                />)
              }
              return children
            }, [])
            : null
        }
      </FormComponent>
    </div>
  )
}

export default FormInput
