import React from 'react'
import PropTypes from 'prop-types'
import { useAuthStore } from '@stores'
import { NavLink } from 'react-router-dom'
import { Icon } from '@atoms'

function SidebarSection({
  isCollapsed,
  isFooter,
  routes,
  label
}) {
  const collapsedClassName = isCollapsed ? '--collapsed' : ''
  const {
    hasPermission,
    hasAdmin
  } = useAuthStore(state => state)

  if (!routes?.length) return null

  return (
    <div className={isFooter ? 'sidebar__footer' : 'sidebar__menu-section-container' }>
      <div className="sidebar__menu-section">
        <span className={`sidebar__menu-section-title${collapsedClassName}`}>
          {label}
        </span>
        <hr className="sidebar__divider" />
      </div>
      <div className="sidebar__menu">
        <div className="sidebar__menu-items">
          {
            routes
            .filter(r => r.displayFor?.some(endpoint => hasPermission(endpoint)) || r.displayForAdmin?.some(endpoint => hasAdmin(endpoint)))
            .map((route, index) => {
              return <NavLink
                key={index}
                to={route.path}
                activeClassName="sidebar__item--active"
                className="sidebar__item">
                <div className="sidebar__icon">
                  {route.iconName && <Icon name={route.iconName} className={route.iconClassName || ''}/>}
                </div>
                <div className={`sidebar__label${collapsedClassName}`}>
                  <span>
                    {route.label}
                  </span>
                </div>
              </NavLink>
            })}
        </div>
      </div>
    </div>
  )
}

SidebarSection.propTypes = {
  isCollapsed: PropTypes.bool,
  isFooter: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  id: PropTypes.string
}

SidebarSection.defaultProps = {
  isCollapsed: false,
  isFooter: false,
  routes: []
}

export default SidebarSection
