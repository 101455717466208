import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@atoms'
import { Table, FileUpload } from '@organisms'
import { Dropdown } from '@molecules'
import { Modal } from '@templates'
import { logisticsActions, reportActions } from '@actions'
import { useToast } from '@hooks'
import { fileUtils } from '@utils'
import { useGlobalsStore } from '@stores'
import FileSaver from 'file-saver'
import ConsignmentPrices from './modal/consignmentPrices'
import ReturnInvoiceModal from './modal/returnInvoiceModal'

const TABS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived'
}

function ShipmentFiles({
  shipmentId,
  shipment,
  refreshShipment,
  isEditToggled,
  getShipment
}) {
  const shipmentFiles = shipment?.Files

  const [files, setFiles] = useState([])
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [activeTab, setActiveTab] = useState({ id: TABS.ACTIVE, label: 'Active', params: 'ACTIVE' })
  const [fileDownloadLinks, setFileDownloadLinks] = useState({})
  const [type, setType] = useState(null)
  const [priceModalOpen, setPriceModalOpen] = useState(false)
  const [reInvoiceModalOpen, setReInvoiceModalOpen] = useState(false)
  const { showSuccessToast } = useToast()

  const {
    shipmentFileTypes,
    shipmentFileTypesMap,
    getShipmentTypes
  } = useGlobalsStore(state => state)

  useEffect(() => {
    getShipmentTypes()
  }, [])

  useEffect(() => {
    setFiles(getShipmentFiles())
  }, [shipmentFiles])

  function getShipmentFiles(condition = activeTab.params) {
    if (!shipmentFiles) return []
    return shipmentFiles
      .filter(file => file.ShipmentFiles.condition === condition)
      .map(file => (
        {
          name: file.originalName,
          downloadName: file.downloadName,
          type: file.ShipmentFiles.type,
          fileId: file.id,
          condition: file.ShipmentFiles.condition,
          createdAt: file.createdAt,
          updatedAt: file.updatedAt
        }
      ))
  }

  async function downloadFile(url, name) {
    const file = await reportActions.getBinaryFile(url)
    FileSaver.saveAs(file.data, name)
  }

  const columns = useMemo(() => ([
    {
      Header: 'Name',
      id: 'name',
      accessor: row => {
        return (
          <a
            onClick={() => downloadFile(getFileUrl(row), row?.downloadName)}
          >
            {row?.downloadName}
          </a>
        )
      }
    },
    {
      Header: 'Type',
      id: 'type',
      accessor: row => shipmentFileTypesMap[row?.type],
      dataType: 'string',
      filterType: 'checkbox',
      enums: Object.values(shipmentFileTypesMap)
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      dataType: 'date',
      filterType: 'date'
    },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
      dataType: 'date',
      filterType: 'date'
    }
  ]), [shipmentFileTypesMap, files, fileDownloadLinks])

  useEffect(() => {
    const fileIds = files?.map(file => file.fileId)
    if (!fileIds?.length) return
    reportActions.getDownloadUrl(fileIds, { shipmentId })
      .then(response => {
        setFileDownloadLinks([...response.data.data.map(d => ({
          id: d.id,
          url: d.url
        }))])
      })
  }, [files])

  async function handleOnFileChange(file) {
    const base64File = await fileUtils.promiseReadBase64File(file)
    setSelectedFile({ file: base64File, name: file.name })
  }

  function handleOnFileUpload() {
    if (selectedFile) {
      logisticsActions.createShipmentFiles(shipmentId, {
        type,
        file: selectedFile.file,
        origFileName: selectedFile.name
      })
      .then(() => {
        showSuccessToast('Shipment document created.')
        setAddModalOpen(false)
        refreshShipment()
      })
    }
  }

  async function downloadFiles(selectedRows) {
    selectedRows.reduce((links, row) => {
      links.push({ url: getFileUrl(row), name: row.downloadName })
      return links
    }, []).forEach(({ url, name }) => downloadFile(url, name))
  }

  function getFileUrl(row) {
    if (!fileDownloadLinks?.length) return ''
    return fileDownloadLinks?.find(fd => fd.id === row.fileId)?.url
  }

  function createConsignmentMemo(roughPrices) {
    reportActions.createConsignmentMemo({ format: 'xlsx', shipmentId, roughPrices })
    .then(() => {
      showSuccessToast('Consignment Memo created.')
      setPriceModalOpen(false)
      refreshShipment()
    })
  }

  function createSellerReturnInvoice(returningInvoiceNo) {
    reportActions.createReturnInvoice({ format: 'xlsx', shipmentId, returningInvoiceNo })
        .then(() => {
          showSuccessToast('Return Invoice created.')
          setReInvoiceModalOpen(false)
          refreshShipment()
        })
  }

  const topBarActions = [
    {
      componentName: 'dropdown',
      enableOnSelect: true,
      options: [{
        label: 'Download Files',
        value: 'downloadFiles',
        callback: selectedRows => downloadFiles(selectedRows),
        isDisabled: isEditToggled
      }]
    },
    ...(activeTab?.id === TABS.ACTIVE && shipment?.type === 'CONSIGNMENT' ? [{
      label: 'Create Consignment Memo',
      callback: () => {
        setPriceModalOpen(true)
      },
      disabled: isEditToggled
    }] : []),
    ...(activeTab?.id === TABS.ACTIVE && shipment?.type === 'FROM_SELLER' ? [{
      label: 'Create Pro Forma Invoice',
      callback: () => {
        reportActions.createProForma({ format: 'xlsx', shipmentId })
        .then(() => {
          showSuccessToast('Pro Forma Invoice created.')
          refreshShipment()
        })
      },
      disabled: isEditToggled
    }] : []),
    ...(activeTab?.id === TABS.ACTIVE && shipment?.type === 'TO_BUYER' ? [{
      label: 'Create Consolidated Invoice',
      callback: () => {
        reportActions.createConsolidatedInvoice({ format: 'xlsx', shipmentId })
        .then(() => {
          showSuccessToast('Consolidated Invoice created.')
          refreshShipment()
        })
      },
      disabled: isEditToggled
    }] : []),
    ...(activeTab?.id === TABS.ACTIVE && shipment?.type === 'SELLER_RETURN' ? [{
      label: 'Create Return Invoice',
      callback: () => {
        setReInvoiceModalOpen(true)
      },
      disabled: isEditToggled
    }] : []),
    ...(activeTab?.id === TABS.ACTIVE ? [{
      label: 'Upload Document',
      callback: () => setAddModalOpen(true)
    }] : [])
  ]

  const tableTabs = [
    {
      id: TABS.ACTIVE,
      label: 'Active',
      params: 'ACTIVE'
    },
    {
      id: TABS.ARCHIVED,
      label: 'Archived',
      params: 'ARCHIVED'
    }
  ]

  const rowActions = [
    {
      actionName: 'archive',
      itemType: 'shipment file',
      callback: (row) => handleSetCondition(row.fileId, 'ARCHIVED', 'archived'),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      itemType: 'shipment file',
      callback: (row) => handleSetCondition(row.fileId, 'ACTIVE', 'unarchived'),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      itemType: 'shipment file',
      callback: (row) => handleSetCondition(row.fileId, 'DELETED', 'removed'),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition !== 'DELETED'
    }
  ]

  function handleSetCondition(fileId, condition, actionText) {
    logisticsActions.setShipmentFileCondition(shipmentId, fileId, { condition })
    .then(() => showSuccessToast(`Shipment file has been ${actionText}.`))
    .then(() => refreshShipment())
  }

  function handleOnModalClose() {
    setAddModalOpen(false)
    setType(null)
    setSelectedFile(null)
  }

  return (
    <>
      <Table
        title='Shipment Documents'
        data={files}
        tableTabs={tableTabs}
        columns={columns}
        rowActions={rowActions}
        onTabChange={setActiveTab}
        topBarActions={topBarActions}
        getDataCallback={() => getShipment(shipmentId)}
      />
      <Modal
        title='Upload Document'
        open={addModalOpen}
        onClose={handleOnModalClose}
      >
        <div className="shipment-files-modal">
          <Dropdown
            label='Type'
            name='type'
            value={type}
            options={shipmentFileTypes?.map(type => ({
              value: type.value,
              label: type.description
            })) || []}
            onChange={e => setType(e.currentTarget.value)}
          />
          <FileUpload
            name='selectFile'
            label='Select File'
            onChange={handleOnFileChange}
            acceptTypes={['.xlsx', '.docx', '.pdf', '.png']}
          />
          <Button
            size='sm'
            disabled={!selectedFile || !type}
            onClick={handleOnFileUpload}
          >
          Upload
          </Button>
        </div>
      </Modal>
      <ConsignmentPrices
        shipment={shipment}
        open={priceModalOpen}
        onClose={() => setPriceModalOpen(false)}
        onSubmit={createConsignmentMemo}
      />
      <ReturnInvoiceModal
        open={reInvoiceModalOpen}
        onClose={() => setReInvoiceModalOpen(false)}
        onSubmit={createSellerReturnInvoice}
      />
    </>
  )
}

ShipmentFiles.propTypes = {
  shipment: PropTypes.object,
  isEditToggled: PropTypes.bool,
  getShipment: PropTypes.func.isRequired,
  shipmentId: PropTypes.string.isRequired,
  refreshShipment: PropTypes.func.isRequired
}

ShipmentFiles.defaultProps = {
  shipmentFiles: [],
  isEditToggled: false
}

export default ShipmentFiles
