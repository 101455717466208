import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'
import { useToast } from '@hooks'

function CopyToClipboard({
  value,
  title
}) {
  const { showInfoToast } = useToast()
  function handleOnClick() {
    navigator.clipboard.writeText(value)
    showInfoToast('Copied to clipboard')
  }

  return (
    <Icon
      name='copy'
      title={title}
      onClick={handleOnClick}
    />
  )
}

CopyToClipboard.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string
}

CopyToClipboard.defaultProps = {
  value: '',
  title: 'Copy to clipboard'
}

export default CopyToClipboard
