import React, { useState, useEffect } from 'react'
import { useGlobalsStore, useAuthStore } from '@stores'
import { Table } from '@organisms'
import { organizationActions, tingeMapActions } from '@actions'
import { useHistory, NavLink } from 'react-router-dom'
import { useToast } from '@hooks'

function TingeMapList() {
  const [tingeMapList, setTingeMapList] = useState([])
  const { hasAdmin, orgId, permissionsAdminCache } = useAuthStore(state => state)
  const { showSuccessToast } = useToast()
  const { orgsList: { all: orgsList }, getOrgsList } = useGlobalsStore()
  const [columns, setColumns] = useState([])
  const history = useHistory()

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    getTingeMaps('ACTIVE')
  }, [permissionsAdminCache])

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    setColumns([
      {
        Header: 'Name',
        accessor: 'name',
        dataType: 'string',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/buyer-settings/tingemaps/${cellInfo.row.original.id}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value}
            </NavLink>
          )
        }
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      },
      ...(hasAdmin(organizationActions.getOrganizationList) ? [{
        Header: 'Owner',
        accessor: (row) => row.buyerId == null ? '* Preloaded *' : (orgsList?.find(org => org.orgId === row.buyerId)?.commonName || ''),
        dataType: 'string',
        id: 'owner'
      }] : [])
    ])
  }, [orgsList, permissionsAdminCache])

  function getTingeMaps(condition) {
    tingeMapActions.getTingeMapList(!hasAdmin(tingeMapActions.getTingeMapList) ? { buyerId: orgId, condition } : { condition })
    .then(response => setTingeMapList(response.data.data))
    .catch(err => console.error(err))
  }

  function handleArchive(row) {
    return tingeMapActions.setTingeMapCondition([row.id], 'ARCHIVED')
    .then(() => {
      showSuccessToast('Tinge map has been archived.')
    })
  }

  function handleUnarchive(row) {
    return tingeMapActions.setTingeMapCondition([row.id], 'ACTIVE')
    .then(() => {
      showSuccessToast('Tinge map has been unarchived.')
    })
  }

  function handleRemove(row) {
    return tingeMapActions.setTingeMapCondition([row.id], 'DELETED')
    .then(() => {
      showSuccessToast('Tinge map has been removed.')
    })
  }

  const rowActions = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      callback: handleRemove,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition !== 'DELETED'
    }
  ]

  const topBarActions = [{
    label: 'Create Tinge Map',
    callback: () => history.push('/buyer-settings/tingemaps/create')
  }]

  const tableTabs = [{
    label: 'Active',
    params: 'ACTIVE'
  },
  {
    label: 'Archived',
    params: 'ARCHIVED'
  }]

  const initialSort = [{
    id: 'updatedAt',
    desc: true
  }
  // {
  //   id: 'owner',
  //   desc: false
  // },
  // {
  //   id: 'name',
  //   desc: false
  // }
  ]

  return (
    <Table
      title='Tinge Maps'
      data={tingeMapList}
      columns={columns}
      tableTabs={tableTabs}
      rowActions={rowActions}
      topBarActions={topBarActions}
      getDataCallback={getTingeMaps}
      initialSort={initialSort}
      isMultiSelect={false}
    />
  )
}

export default TingeMapList
