import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { costActions, organizationActions } from '@actions'
import moment from 'moment'
import { usePageTitle, useToast } from '@hooks'
import { useHistory } from 'react-router-dom'
import { useGlobalsStore, useAuthStore } from '@stores'
import { Button } from '@atoms'
import { MfgCostItem, CertCostItem } from './costItem'
import { BUYER } from '@constants'
import { textUtils } from '@utils'
import BuyerSettingsDetailsPage from './buyerSettingsDetailsPage'

const COST_DICT = BUYER.COST

function CostDetails({ match, costType, canEdit: canEditProp, ...props }) {
  const costDict = COST_DICT[costType.toUpperCase()]
  const costTypeLower = costType.toLowerCase()
  const costTypeCap = costTypeLower.charAt(0).toUpperCase() + costTypeLower.slice(1)
  const CostItem = costTypeLower === 'mfg' ? MfgCostItem : CertCostItem
  const { costId, operation } = match?.params
  const appSettingsParams = { groupKey: 'BUYER_SETTINGS_CONSTANTS', key: `${costDict.KEY}_COST_LIMIT` }
  const { hasAdmin, permissionsAdminCache } = useAuthStore(store => store)
  const { showSuccessToast, showErrorToast } = useToast()
  const history = useHistory()
  const [cost, setCost] = useState({})

  usePageTitle(props.title, costId, cost?.name)
  useEffect(() => {
    if (costId) {
      costActions[costDict.ACTION.GET]({ id: costId, columns: `[${costTypeCap}CostItems,Orders]` })
      .then(response => {
        response.data.data[0][`${costTypeCap}CostItems`].sort((a, b) => a.minWeight - b.minWeight)
        setCost(response.data.data[0])
      })
      .catch(err => console.error(err))
    } else setCost({})
  }, [costId])

  const {
    getOrgsList,
    getAppSettings,
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    appSettings: { [JSON.stringify(appSettingsParams)]: appSettings }
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
    getAppSettings(appSettingsParams)
  }, [])

  function CostItems(props) {
    function handleChange({ currentTarget: { name, value } }) {
      const idx = props.value?.findIndex(item => item.id === name || item.tempId === name)
      if (idx < 0) {
        console.error("can't find item to update")
        return
      }
      const newValue = [...props.value]
      newValue.splice(idx, 1, value)
      props.onChange({ currentTarget: { name: props.name, value: newValue } })
    }

    function addCostItem() {
      const maxId = props.value.reduce((max, it) => it.tempId ? Math.max(max, it.tempId) : max, 0)
      props.onChange({ currentTarget: { name: props.name, value: [...props.value, { tempId: maxId + 1 }] } })
    }

    function deleteCostItem(id) {
      props.onChange({ currentTarget: { name: props.name, value: props.value.filter(item => item.id !== id && item.tempId !== id) } })
    }

    return <>
      {(props.value ?? []).map(item => {
        const id = item.id || item.tempId
        return <CostItem {...props} key={id} value={item} name={id} onChange={handleChange} onDelete={() => deleteCostItem(id)} />
      })}
      {!props.disabled && <div className='w-100 mt-3'>
        <Button size='sm' onClick={() => addCostItem()}>Add a Cost Row</Button>
      </div>}
    </>
  }

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (cost) {
      setFields([
        {
          label: 'Name',
          value: cost.name,
          name: 'name',
          componentName: 'textInput',
          canEdit: true
        },
        ...(hasAdmin(organizationActions.getOrganizationList) || costTypeLower === 'cert'
          ? [{
            label: 'Owner',
            value: cost.buyerId === null ? '-' : cost.buyerId,
            name: 'buyerId',
            componentName: 'dropdown',
            options: orgsList?.map(org => ({ value: org.orgId, label: org.commonName })).concat({ value: '-', label: 'Preloaded' }),
            canEdit: false
          }] : []
        ),
        {
          label: 'Items',
          name: 'items',
          customComponent: CostItems,
          value: cost[`${costTypeCap}CostItems`],
          canEdit: true,
          span: true
        },
        {
          label: 'Created At',
          shouldDisplay: (hasAdmin(costActions.getCertCostList) && hasAdmin(costActions.getMfgCostList)) || cost.buyerId !== null,
          value: moment(cost.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          shouldDisplay: (hasAdmin(costActions.getCertCostList) && hasAdmin(costActions.getMfgCostList)) || cost.buyerId !== null,
          value: moment(cost.updatedAt).toLocaleString()
        }
      ])
    }
  }, [cost, orgsList, permissionsAdminCache])

  async function handleOnValidate(editedValues) {
    const { items = cost[costDict.ITEMS_KEY] } = editedValues
    const errors = []

    if (!items.some((item) => item.maxWeight >= appSettings?.[0]?.value)) {
      errors.push(textUtils.substituteConstantTemplateStr(BUYER.COST.ERROR.WEIGHT_COVERAGE, { name: costDict.NAME, maxWeight: appSettings?.[0]?.value }))
    }
    if (errors.length) {
      errors.forEach(error => showErrorToast(error))
      throw new Error(errors)
    }
  }

  async function handleOnSubmit(editedValues) {
    const { condition, ...costData } = editedValues

    if (costData.items) {
      costData.items = costData.items.map(({ id, minWeight, maxWeight, costPerRoughCarat, costPerStone, additionalFilters }) => ({
        id,
        minWeight,
        maxWeight,
        costPerRoughCarat,
        costPerStone,
        additionalFilters
      }))
    }

    const conditionPromise = condition ? costActions[costDict.ACTION.PUT]([costId], condition) : Promise.resolve()
    return conditionPromise.then(() => {
      if (Object.keys(costData).length) {
        return costActions[costDict.ACTION.PATCH](costId, costData)
      }
    })
    .then(() => {
      showSuccessToast('Cost updated.')
      history.push('/buyer-settings')
    })
  }

  function handleConfirmModal(editedValues) {
    const ignoreKeys = ['name']
    const editedKeys = Object.keys(editedValues)
    return editedKeys.some(key => !ignoreKeys.includes(key)) ? costDict.CONFIRM_MODAL_KEY : null
  }

  function handleArchive() {
    costActions[costDict.ACTION.PUT]([cost?.id], 'ARCHIVED')
    .then(() => {
      showSuccessToast('Cost has been archived.')
      history.push('/buyer-settings')
    })
  }

  function handleUnarchive() {
    costActions[costDict.ACTION.PUT]([cost?.id], 'ACTIVE')
    .then(() => {
      showSuccessToast('Cost has been unarchived.')
      history.push('/buyer-settings')
    })
  }

  function handleRemove() {
    costActions[costDict.ACTION.PUT]([cost?.id], 'DELETED')
    .then(() => {
      showSuccessToast('Cost has been removed.')
      history.push('/buyer-settings')
    })
  }

  const canArchive = canEditProp && cost?.buyerId && cost?.condition !== 'DELETED' && cost?.condition !== 'ARCHIVED'
  const canUnarchive = canEditProp && cost?.buyerId && cost?.condition === 'ARCHIVED'
  const canRemove = canEditProp && cost?.buyerId && cost?.condition !== 'DELETED'
  const canEdit = canEditProp && cost?.condition === 'ACTIVE' && (cost?.buyerId != null || hasAdmin(costActions[costDict.ACTION.PATCH]))

  return (
    <BuyerSettingsDetailsPage
      canEdit={canEdit}
      isEdit={operation === 'edit'}
      onArchive={ canArchive ? handleArchive : null}
      onUnarchive={ canUnarchive ? handleUnarchive : null}
      onRemove={canRemove ? handleRemove : null}
      fields={fields}
      onValidate={handleOnValidate}
      onSubmit={handleOnSubmit}
      confirmModal={handleConfirmModal}
      title={{
        label: 'Cost ID',
        value: cost?.id || ''
      }}
      value={cost}
    />
  )
}

CostDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string,
  costType: PropTypes.string.isRequired,
  canEdit: PropTypes.bool
}

CostDetails.defaultProps = {
  canEdit: true
}

export default CostDetails
