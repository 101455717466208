import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  TextInput,
  Collapsible,
  TextArea,
  Dropdown
} from '@molecules'
import { Fieldset } from '@organisms'
import { useGlobalsStore } from '@stores'
import clone from 'just-clone'
import dottie from 'dottie'
import { ORG } from '@constants'

function ShipmentAddress({
  title,
  name: formName,
  formData,
  disabled,
  onChange
}) {
  const [address, setAddress] = useState(null)

  const appSettingsParams = { groupKey: 'INVOICE_PAYMENT_DETAILS' }

  const {
    appSettings: { [JSON.stringify(appSettingsParams)]: invoicePaymentDetails },
    getAppSettings
  } = useGlobalsStore()
  useEffect(() => {
    getAppSettings(appSettingsParams)
  }, [])

  useEffect(() => {
    if (disabled && formData) setAddress(formData)
  }, [formData])

  function handleOnChange(name, value) {
    const newAddress = clone(address)
    dottie.set(newAddress, name, value)
    setAddress(newAddress)
    return onChange({ target: { name: formName, value: newAddress } })
  }

  return (
    <Collapsible
      title={title + (address?.name ? ` | ${address?.name} | ${address?.line1} | ${address?.city} | ${address?.country}` : ' ')}
    >
      <div className='shipment-details__address-form'>
        <TextInput
          name='name'
          label='Entity Name'
          value={address?.name}
          onChange= {(e) => handleOnChange('name', e.target.value)}
          disabled={disabled}
        />
        <TextInput
          name="line1"
          label="Address line 1"
          value={address?.line1}
          onChange= {(e) => handleOnChange('line1', e.target.value)}
          disabled={disabled}
        />
        <TextInput
          name="line2"
          label="Address line 2"
          value={address?.line2}
          onChange= {(e) => handleOnChange('line2', e.target.value)}
          required={false}
          disabled={disabled}
        />
        <TextInput
          name="line3"
          label="Address line 3"
          value={address?.line3}
          onChange= {(e) => handleOnChange('line3', e.target.value)}
          required={false}
          disabled={disabled}
        />
        <TextInput
          name="city"
          label="City"
          value={address?.city}
          onChange= {(e) => handleOnChange('city', e.target.value)}
          required
          disabled={disabled}
        />
        <TextInput
          name="province"
          label="Province / State"
          value={address?.province}
          required={false}
          onChange= {(e) => handleOnChange('province', e.target.value)}
          disabled={disabled}
        />
        <TextInput
          name="country"
          label="Country"
          onChange= {(e) => handleOnChange('country', e.target.value)}
          value={address?.country}
          required
          disabled={disabled}
        />
        <TextInput
          name="postalCode"
          label="Postal code"
          value={address?.postalCode}
          required={false}
          onChange= {(e) => handleOnChange('postalCode', e.target.value)}
          disabled={disabled}
        />
        <TextInput
          name="email"
          label="Email"
          type='email'
          value={address?.email}
          onChange= {(e) => handleOnChange('email', e.target.value)}
          required={false}
          disabled={disabled}
        />
        <TextInput
          name="phoneNumber"
          label="Phone Number"
          value={address?.phoneNumber}
          onChange= {(e) => handleOnChange('phoneNumber', e.target.value)}
          required={false}
          disabled={disabled}
        />
        <TextInput
          name='taxNumber'
          label='Tax Number'
          value={address?.taxNumber}
          required={false}
          onChange= {(e) => handleOnChange('taxNumber', e.target.value)}
          disabled={disabled}
        />
        <Dropdown
          name='preferredShipping'
          label='Preferred Shipping'
          value={address?.preferredShipping}
          options={ORG.PREFERRED_SHIPPING}
          required={false}
          onChange= {(e) => handleOnChange('preferredShipping', e.currentTarget.value)}
          disabled={disabled}
        />
        <TextArea
          name='invoiceNotes'
          label='Notes'
          value={address?.invoiceNotes}
          required={false}
          onChange={(e) => handleOnChange('invoiceNotes', e.target.value)}
          disabled={disabled}
        />
        <Fieldset legend='Payment Details' key='invoicePayment' disabled={disabled}>
          <Dropdown
            name='invoicePayment.clara'
            label='Clara Invoice'
            value={address?.invoicePayment?.clara}
            options={invoicePaymentDetails?.map(({ key }) => ({ label: key, value: key }))}
            required={true}
            onChange={(e) => handleOnChange('invoicePayment.clara', e.currentTarget.value)}
            disabled={disabled}
            isClearable={false}
          />
          <Dropdown
            name='invoicePayment.lucara'
            label='Lucara Invoice'
            value={address?.invoicePayment?.lucara}
            options={invoicePaymentDetails?.map(({ key }) => ({ label: key, value: key }))}
            required={true}
            onChange={(e) => handleOnChange('invoicePayment.lucara', e.currentTarget.value)}
            disabled={disabled}
            isClearable={false}
          />
        </Fieldset>
      </div>
    </Collapsible>
  )
}

ShipmentAddress.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  formData: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

ShipmentAddress.defaultProps = {
  title: '',
  disabled: true
}

export default ShipmentAddress
