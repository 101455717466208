import create from 'zustand'
import { organizationActions, userActions, constantsActions, provenanceActions, roughStoneActions, eventActions, orderActions, settingsActions, roleAndPermissionAction } from '@actions'
import { arrayUtils, numberUtils } from '@utils'
import { createMap } from './storeUtils'
import { objectUtils } from 'utils/utils'

let sellerConstantsPromise
let buyerConstantsPromise
let cutGradeConstantsPromise
let licenceTypesPromise
let complianceConstantsPromise
let reportConstantsPromise
let shipmentConstantsPromise
let spsConstantsPromise
let usersListPromise
let userRolesListPromise
let inclusionTypesPromise
let inclusionReductionsPromise
let eventTypesPromise
let permissionsPromise
let rolesPromise

const orgsListPromise = {}
const provTypesListPromise = {}
const countriesListPromise = {}
const minesListPromise = {}
const pipesListPromise = {}
const batchesListPromise = {}
const roughSellersListPromise = {}
const orderBuyersListPromise = {}
const appSettingsPromise = {}

const storeFunction = (set, get) => ({
  orgsList: {
    limited: {}
  },
  orgsMap: {
    limited: {}
  },
  getOrgsList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().orgsList[paramString]) await get().setOrgsList(params)
    return get().orgsList[paramString] || []
  },
  getLimitedOrgsList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().orgsList.limited[paramString]) await get().setLimitedOrgsList(params)
    return get().orgsList.limited[paramString] || []
  },
  resetOrgsList: () => {
    set({
      orgsList: {
        limited: {}
      }
    })
  },
  setOrgsList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!orgsListPromise[paramString]) {
      orgsListPromise[paramString] = organizationActions.getOrganizationList(params).finally(() => {
        orgsListPromise[paramString] = null
      })
    }
    const queryResults = await orgsListPromise[paramString]
    if (!queryResults) return
    set({
      orgsList: { ...get().orgsList, [paramString]: queryResults.data.data },
      orgsMap: { ...get().orgsMap, [paramString]: arrayUtils.toMap(queryResults.data.data, (x) => x.orgId, 'commonName') }
    })
  },
  setLimitedOrgsList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!orgsListPromise[paramString]) {
      orgsListPromise[paramString] = organizationActions.getLimitedOrganizationList(params).finally(() => {
        orgsListPromise[paramString] = null
      })
    }
    const queryResults = await orgsListPromise[paramString]
    if (!queryResults) return
    const orgsList = get().orgsList
    const orgsMap = get().orgsMap
    set({
      orgsList: {
        ...orgsList,
        limited: { ...orgsList.limited, [paramString]: queryResults.data.data }
      },
      orgsMap: {
        ...orgsMap,
        limited: { ...orgsMap.limited, [paramString]: arrayUtils.toMap(queryResults.data.data, (x) => x.orgId, 'commonName') }
      }
    })
  },
  getOrgById: async orgId => {
    if (!get().orgsList?.all) await get().setOrgsList()
    return get().orgsList?.all.find(org => org.orgId === orgId)
  },
  usersList: [],
  getUsersList: async () => {
    if (!get().usersList.length) await get().setUsersList()
    return get().usersList
  },
  resetUsersList: () => {
    set({ usersList: [] })
  },
  setUsersList: async () => {
    if (!usersListPromise) {
      usersListPromise = userActions.getUsersList().finally(() => {
        usersListPromise = null
      })
    }
    const queryResults = await usersListPromise
    if (!queryResults) return
    set({ usersList: queryResults.data.data })
  },
  getUserById: async uId => {
    if (!get().usersList.length) await get().setUsersList()
    return get().usersList.find(user => user.userId === uId)
  },
  userRolesList: [],
  getUserRolesList: async () => {
    if (!get().userRolesList.length) await get().setUserRolesList()
    return get().userRolesList
  },
  setUserRolesList: async () => {
    if (!userRolesListPromise) {
      userRolesListPromise = userActions.getUserRoles().finally(() => {
        userRolesListPromise = null
      })
    }

    const queryResults = await userRolesListPromise
    if (!queryResults) return
    set({ userRolesList: queryResults.data.data })
  },
  roughSellersList: [],
  getRoughSellersList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().roughSellersList[paramString]) await get().setRoughSellersList(params)
    return get().roughSellersList[paramString]
  },
  setRoughSellersList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!roughSellersListPromise[paramString]) {
      roughSellersListPromise[paramString] = roughStoneActions.getRoughSellers(params).finally(() => {
        roughSellersListPromise[paramString] = null
      })
    }
    const queryResults = await roughSellersListPromise[paramString]
    if (!queryResults) return
    set({ roughSellersList: { ...get().roughSellersList, [paramString]: queryResults.data.data } })
  },
  orderBuyersList: [],
  getOrderBuyersList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().orderBuyersList[paramString]) await get().setOrderBuyersList(params)
    return get().orderBuyersList[paramString]
  },
  setOrderBuyersList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!orderBuyersListPromise[paramString]) {
      orderBuyersListPromise[paramString] = orderActions.getOrderBuyers(params).finally(() => {
        orderBuyersListPromise[paramString] = null
      })
    }
    const queryResults = await orderBuyersListPromise[paramString]
    if (!queryResults) return
    set({ orderBuyersList: { ...get().orderBuyersList, [paramString]: queryResults.data.data } })
  },
  eventTypesList: [],
  getEventTypesList: async () => {
    if (!get().eventTypesList.length) await get().setEventTypesList()
    return get().eventTypesList
  },
  setEventTypesList: async () => {
    if (!eventTypesPromise) {
      eventTypesPromise = eventActions.getEventTypes().finally(() => {
        eventTypesPromise = null
      })
    }
    const queryResults = await eventTypesPromise
    if (!queryResults) return
    set({ eventTypesList: queryResults.data.data })
  },

  reserveMethods: null,
  reserveMethodsMap: {},
  getReserveMethods: async () => {
    if (!get().reserveMethods) await get().setSellerConstants()
    return get().reserveMethods
  },
  roughStatuses: undefined,
  roughStatusesMap: {},
  getRoughStatuses: async () => {
    if (!get().roughStatuses) await get().setSellerConstants()
    return get().roughStatuses
  },
  roughScanTypes: null,
  roughScanTypesMap: null,
  getRoughScanTypes: async () => {
    if (!get().roughScanTypes) await get().setSellerConstants()
    return get().roughScanTypes
  },
  roughColours: null,
  roughColoursMap: {},
  getRoughColours: async () => {
    if (!get().roughColours) await get().setSellerConstants()
    return get().roughColours
  },
  roughFluorescences: null,
  roughFluorescencesMap: {},
  getRoughFluorescences: async () => {
    if (!get().roughFluorescences) await get().setSellerConstants()
    return get().roughFluorescences
  },
  roughTinges: null,
  roughTingesMap: {},
  getRoughTinges: async () => {
    if (!get().roughTinges) await get().setSellerConstants()
    return get().roughTinges
  },
  roughTensions: null,
  roughTensionsMap: {},
  getRoughTensions: async () => {
    if (!get().roughTensions) await get().setSellerConstants()
    return get().roughTensions
  },
  roughQCStatuses: null,
  roughQCStatusesMap: {},
  getRoughQCStatuses: async () => {
    if (!get().roughQCStatuses) await get().setSellerConstants()
    return get().roughQCStatuses
  },
  roughTypes: null,
  roughTypesMap: {},
  getRoughTypes: async () => {
    if (!get().roughTypes) await get().setSellerConstants()
    return get().roughTypes
  },
  allowedSellerChanges: null,
  getAllowedSellerChanges: async () => {
    if (!get().allowedSellerChanges) await get().setSellerConstants()
    return get().allowedSellerChanges
  },
  bidStatuses: null,
  bidStatusesMap: {},
  getBidStatuses: async () => {
    if (!get().bidStatuses) await get().setSellerConstants()
    return get().bidStatuses
  },

  setSellerConstants: async () => {
    if (!sellerConstantsPromise) {
      sellerConstantsPromise = constantsActions.getSellerConstants().finally(() => {
        sellerConstantsPromise = null
      })
    }
    const queryResults = await sellerConstantsPromise
    if (!queryResults) return
    const sellerConstants = queryResults.data.data
    set({
      reserveMethods: sellerConstants.RESERVE_METHODS,
      reserveMethodsMap: createMap(sellerConstants.RESERVE_METHODS),
      roughStatuses: sellerConstants.ROUGH_STATUSES,
      roughStatusesMap: createMap(sellerConstants.ROUGH_STATUSES),
      roughScanTypes: sellerConstants.ROUGH_SCAN_TYPES,
      roughScanTypesMap: createMap(sellerConstants.ROUGH_SCAN_TYPES),
      roughColours: sellerConstants.ROUGH_COLOURS,
      roughColoursMap: createMap(sellerConstants.ROUGH_COLOURS),
      roughFluorescences: sellerConstants.ROUGH_FLUORESCENCES,
      roughFluorescencesMap: createMap(sellerConstants.ROUGH_FLUORESCENCES),
      roughTinges: sellerConstants.ROUGH_TINGES,
      roughTingesMap: createMap(sellerConstants.ROUGH_TINGES),
      roughTensions: sellerConstants.ROUGH_TENSIONS,
      roughTensionsMap: createMap(sellerConstants.ROUGH_TENSIONS),
      roughQCStatuses: sellerConstants.ROUGH_QC_STATUSES,
      roughQCStatusesMap: createMap(sellerConstants.ROUGH_QC_STATUSES),
      roughQCApprovedMap: arrayUtils.toMap(sellerConstants.ROUGH_QC_STATUSES, x => x.value, 'qcApproved'),
      roughTypes: sellerConstants.ROUGH_TYPES,
      roughTypesMap: createMap(sellerConstants.ROUGH_TYPES),
      allowedSellerChanges: objectUtils.pick(sellerConstants, ['LOCKED_ASSORTMENT_ALLOWED_CHANGES', 'SUBMITTED_STONE_ALLOWED_CHANGES', 'MATCHED_STONE_ALLOWED_CHANGES']),
      bidStatuses: sellerConstants.BID_STATUSES,
      bidStatusesMap: createMap(sellerConstants.BID_STATUSES)
    })
  },

  provenanceTypesList: {},
  setProvenanceTypesList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!provTypesListPromise[paramString]) {
      provTypesListPromise[paramString] = provenanceActions.getProvenance(params).finally(() => {
        provTypesListPromise[paramString] = null
      })
    }
    const queryResults = await provTypesListPromise[paramString]
    if (!queryResults) return
    const data = queryResults.data.data.map(item => {
      // Secondary Market
      if (item.id === 4) item.secondaryMarket = true
      else item.secondaryMarket = false
      return item
    })
    set({ provenanceTypesList: { ...get().provenanceTypesList, [paramString]: data } })
  },
  getProvenanceTypesList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().provenanceTypesList[paramString]) await get().setProvenanceTypesList(params)
    return get().provenanceTypesList[paramString]
  },
  resetProvenanceTypesList: (params) => {
    let provenanceTypesList = {}
    if (params === 'all') {
      provenanceTypesList = objectUtils.omit(get().provenanceTypesList, ['all'], false)
    } else if (params && Object.keys(params).length) {
      provenanceTypesList = objectUtils.omit(get().provenanceTypesList, [JSON.stringify(params)], false)
    }
    set({ provenanceTypesList })
  },
  countriesList: {},
  setCountriesList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!countriesListPromise[paramString]) {
      countriesListPromise[paramString] = provenanceActions.getCountries(params).finally(() => {
        countriesListPromise[paramString] = null
      })
    }
    const queryResults = await countriesListPromise[paramString]
    if (!queryResults) return
    set({ countriesList: { ...get().countriesList, [paramString]: queryResults.data.data } })
  },
  getCountriesList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().countriesList[paramString]) await get().setCountriesList(params)
    return get().countriesList[paramString]
  },
  resetCountriesList: (params) => {
    let countriesList = {}
    if (params === 'all') {
      countriesList = objectUtils.omit(get().countriesList, ['all'], false)
    } else if (params && Object.keys(params).length) {
      countriesList = objectUtils.omit(get().countriesList, [JSON.stringify(params)], false)
    }
    set({ countriesList })
  },
  minesList: {},
  setMinesList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!minesListPromise[paramString]) {
      minesListPromise[paramString] = provenanceActions.getMines(params).finally(() => {
        minesListPromise[paramString] = null
      })
    }
    const queryResults = await minesListPromise[paramString]
    if (!queryResults) return
    set({ minesList: { ...get().minesList, [paramString]: queryResults.data.data } })
  },
  getMinesList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().minesList[paramString]) await get().setMinesList(params)
    return get().minesList[paramString]
  },
  resetMinesList: (params) => {
    let minesList = {}
    if (params === 'all') {
      minesList = objectUtils.omit(get().minesList, ['all'], false)
    } else if (params && Object.keys(params).length) {
      minesList = objectUtils.omit(get().minesList, [JSON.stringify(params)], false)
    }
    set({ minesList })
  },
  pipesList: {},
  setPipesList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!pipesListPromise[paramString]) {
      pipesListPromise[paramString] = provenanceActions.getPipes(params).finally(() => {
        pipesListPromise[paramString] = null
      })
    }
    const queryResults = await pipesListPromise[paramString]
    if (!queryResults) return
    set({ pipesList: { ...get().pipesList, [paramString]: queryResults.data.data } })
  },
  getPipesList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().pipesList[paramString]) await get().setPipesList(params)
    return get().pipesList[paramString]
  },
  resetPipesList: (params) => {
    let pipesList = {}
    if (params === 'all') {
      pipesList = objectUtils.omit(get().pipesList, ['all'], false)
    } else if (params && Object.keys(params).length) {
      pipesList = objectUtils.omit(get().pipesList, [JSON.stringify(params)], false)
    }
    set({ pipesList })
  },
  batchesList: {},
  setBatchesList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!batchesListPromise[paramString]) {
      batchesListPromise[paramString] = provenanceActions.getBatches(params).finally(() => {
        batchesListPromise[paramString] = null
      })
    }
    const queryResults = await batchesListPromise[paramString]
    if (!queryResults) return
    set({ batchesList: { ...get().batchesList, [paramString]: queryResults.data.data } })
  },
  getBatchesList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().batchesList[paramString]) await get().setBatchesList(params)
    return get().batchesList[paramString]
  },
  resetBatchesList: (params) => {
    let batchesList = {}
    if (params === 'all') {
      batchesList = objectUtils.omit(get().batchesList, ['all'], false)
    } else if (params && Object.keys(params).length) {
      batchesList = objectUtils.omit(get().batchesList, [JSON.stringify(params)], false)
    }
    set({ batchesList })
  },
  inclusionTypes: null,
  inclusionTypesMap: {},
  getInclusionTypes: async () => {
    if (!get().inclusionTypes) await get().setInclusionTypes()
    return get().inclusionTypes
  },
  setInclusionTypes: async () => {
    if (!inclusionTypesPromise) {
      inclusionTypesPromise = roughStoneActions.getInclusionTypes().finally(() => {
        inclusionTypesPromise = null
      })
    }
    const queryResults = await inclusionTypesPromise
    if (!queryResults) return
    set({
      inclusionTypes: queryResults.data.data,
      inclusionTypesMap: arrayUtils.toMap(queryResults.data.data, (x) => x.id, 'description')
    })
  },

  inclusionReductions: null,
  inclusionReductionsMap: {},
  getInclusionReductions: async () => {
    if (!get().inclusionReductions) await get().setInclusionReductions()
    return get().inclusionReductions
  },
  setInclusionReductions: async () => {
    if (!inclusionReductionsPromise) {
      inclusionReductionsPromise = roughStoneActions.getInclusionReductions().finally(() => {
        inclusionReductionsPromise = null
      })
    }
    const queryResults = await inclusionReductionsPromise
    if (!queryResults) return
    set({
      inclusionReductions: queryResults.data.data,
      inclusionReductionsMap: arrayUtils.toMap(queryResults.data.data, (x) => x.id, 'description')
    })
  },

  orderStatuses: null,
  orderStatusesMap: {},
  getOrderStatuses: async () => {
    if (!get().orderStatuses) await get().setBuyerConstants()
    return get().orderStatuses
  },
  quantityTypes: null,
  getQuantityTypes: async () => {
    if (!get().quantityTypes) await get().setBuyerConstants()
    return get().quantityTypes
  },
  polishedColours: null,
  polishedColoursMap: {},
  getPolishedColours: async () => {
    if (!get().polishedColours) await get().setBuyerConstants()
    return get().polishedColours
  },
  clarities: null,
  claritiesMap: {},
  getClarities: async () => {
    if (!get().clarities) await get().setBuyerConstants()
    return get().clarities
  },
  polishedFluorescences: null,
  polishedFluorescencesMap: {},
  getPolishedFluorescences: async () => {
    if (!get().polishedFluorescences) await get().setBuyerConstants()
    return get().polishedFluorescences
  },
  polishedTinges: null,
  polishedTingesMap: {},
  getPolishedTinges: async () => {
    if (!get().polishedTinges) await get().setBuyerConstants()
    return get().polishedTinges
  },
  baselineShapeTypes: null,
  baselineShapeTypesMap: {},
  getBaselineShapeTypes: async () => {
    if (!get().shapeTypes) await get().setBuyerConstants()
    return get().baselineShapeTypes
  },
  setBuyerConstants: async () => {
    if (!buyerConstantsPromise) {
      buyerConstantsPromise = constantsActions.getBuyerConstants().finally(() => {
        buyerConstantsPromise = null
      })
    }
    const queryResults = await buyerConstantsPromise
    if (!queryResults) return
    const buyerConstants = queryResults.data.data
    set({
      orderStatuses: buyerConstants.ORDER_STATUSES,
      orderStatusesMap: createMap(buyerConstants.ORDER_STATUSES),
      quantityTypes: buyerConstants.QUANTITY_TYPES,
      polishedColours: buyerConstants.POLISHED_COLOURS,
      polishedColoursMap: createMap(buyerConstants.POLISHED_COLOURS),
      clarities: buyerConstants.CLARITIES,
      claritiesMap: createMap(buyerConstants.CLARITIES),
      polishedFluorescences: buyerConstants.POLISHED_FLUORESCENCES,
      polishedFluorescencesMap: createMap(buyerConstants.POLISHED_FLUORESCENCES),
      polishedTinges: buyerConstants.POLISHED_TINGES,
      polishedTingesMap: createMap(buyerConstants.POLISHED_TINGES),
      baselineShapeTypes: buyerConstants.BASELINE_SHAPE_TYPES,
      baselineShapeTypesMap: createMap(buyerConstants.BASELINE_SHAPE_TYPES)
    })
  },

  shapeTypes: null,
  shapeTypesMap: {},
  getShapeTypes: async () => {
    if (!get().shapeTypes) await get().setCutGradeConstants()
    return get().shapeTypes
  },
  fixedIds: null,
  getFixedIds: async () => {
    if (!get().fixedIds) await get().setCutGradeConstants()
    return get().fixedIds
  },
  proportionDisplayTypes: null,
  getProportionDisplayTypes: async () => {
    if (!get().proportionDisplayTypes) await get().setCutGradeConstants()
    return get().proportionDisplayTypes
  },

  setCutGradeConstants: async () => {
    if (!cutGradeConstantsPromise) {
      cutGradeConstantsPromise = constantsActions.getCutGradeConstants().finally(() => {
        cutGradeConstantsPromise = null
      })
    }
    const queryResults = await cutGradeConstantsPromise
    if (!queryResults) return
    const cutGradeConstants = queryResults.data.data
    set({
      shapeTypes: cutGradeConstants.SHAPE_TYPES,
      shapeTypesMap: createMap(cutGradeConstants.SHAPE_TYPES),
      fixedIds: cutGradeConstants.FIXED_IDS,
      proportionDisplayTypes: cutGradeConstants.PROPORTION_DISPLAY_TYPES
    })
  },
  pricingRangeWeightIncrement: 0.010, // Equivalent to WEIGHT_INCREMENT in abstract.js file in backend
  // If an argument is passed into this function, the pricingRangeWeightIncrement will be added to it
  // use the mult variable to modify the increment
  getPricingRangeWeightIncrement: (x = 0, mult = 1) => {
    return numberUtils.floatAddition(get().pricingRangeWeightIncrement * mult, x, 3)
  },
  pricingRangeWeightGap: 0.0101, // Equivalent to MAX_WEIGHT_GAP in abstract.js file in backend
  getPricingRangeWeightGap: (x = 0, mult = 1) => {
    return numberUtils.floatAddition(get().pricingRangeWeightGap * mult, x, 3)
  },
  sawPlane: {
    thickness: 150,
    units: 'microns'
  },
  getSawPlane: () => {
    return get().sawPlane
  },

  licenceTypesList: null,
  setLicenceTypesList: async (params) => {
    if (!licenceTypesPromise) {
      licenceTypesPromise = provenanceActions.getLicenceTypes(params).finally(() => {
        licenceTypesPromise = null
      })
    }
    const queryResults = await licenceTypesPromise
    if (!queryResults) return
    set({ licenceTypesList: queryResults.data.data })
  },
  getLicenceTypesList: async (params) => {
    if (!get().licenceTypesList) await get().setLicenceTypesList(params)
    return get().licenceTypesList
  },
  resetLicenceTypesList: () => {
    set({ licenceTypesList: null })
  },
  complianceStatuses: null,
  complianceStatusesMap: {},
  getComplianceStatuses: async () => {
    if (!get().complianceStatuses) await get().setComplianceConstants()
    return get().complianceStatuses
  },
  licenceStatuses: null,
  licenceStatusesMap: {},
  getLicenceStatuses: async () => {
    if (!get().licenceStatuses) await get().setComplianceConstants()
    return get().licenceStatuses
  },
  setComplianceConstants: async () => {
    if (!complianceConstantsPromise) {
      complianceConstantsPromise = constantsActions.getComplianceConstants().finally(() => {
        complianceConstantsPromise = null
      })
    }
    const queryResults = await complianceConstantsPromise
    if (!queryResults) return
    const complianceConstants = queryResults.data.data
    set({
      complianceStatuses: complianceConstants.COMPLIANCE_STATUSES,
      complianceStatusesMap: createMap(complianceConstants.COMPLIANCE_STATUSES),
      licenceStatuses: complianceConstants.LICENCE_STATUSES,
      licenceStatusesMap: createMap(complianceConstants.LICENCE_STATUSES)
    })
  },
  reportTypes: null,
  reportTypesMap: {},
  getReportTypes: async () => {
    if (!get().reportTypes) await get().setReportConstants()
    return get().reportTypes
  },
  setReportConstants: async () => {
    if (!reportConstantsPromise) {
      reportConstantsPromise = constantsActions.getReportConstants().finally(() => {
        reportConstantsPromise = null
      })
    }
    const queryResults = await reportConstantsPromise
    if (!queryResults) return
    const reportConstants = queryResults.data.data
    set({
      reportTypes: reportConstants.REPORT_TYPES,
      reportTypesMap: createMap(reportConstants.REPORT_TYPES)
    })
  },
  shipmentTypes: null,
  shipmentTypesMap: {},
  shipmentStatuses: null,
  shipmentStatusesMap: {},
  shipmentFileTypes: null,
  shipmentFileTypesMap: {},
  getShipmentTypes: async () => {
    if (!get().shipmentTypes) await get().setShipmentConstants()
    return get().shipmentTypes
  },
  getShipmentStatuses: async () => {
    if (!get().shipmentStatuses) await get().setShipmentConstants()
    return get().shipmentStatuses
  },
  getShipmentFileTypes: async () => {
    if (!get().shipmentFileTypes) await get().setShipmentConstants()
    return get().shipmentFileTypes
  },
  setShipmentConstants: async () => {
    if (!shipmentConstantsPromise) {
      shipmentConstantsPromise = constantsActions.getShipmentConstants().finally(() => {
        shipmentConstantsPromise = null
      })
    }

    const queryResults = await shipmentConstantsPromise
    if (!queryResults) return
    const shipmentConstants = queryResults.data.data
    set({
      shipmentTypes: shipmentConstants.SHIPMENT_TYPES,
      shipmentTypesMap: createMap(shipmentConstants.SHIPMENT_TYPES),
      shipmentStatuses: shipmentConstants.SHIPMENT_STATUSES,
      shipmentStatusesMap: createMap(shipmentConstants.SHIPMENT_STATUSES),
      shipmentFileTypes: shipmentConstants.SHIPMENT_FILE_TYPES,
      shipmentFileTypesMap: createMap(shipmentConstants.SHIPMENT_FILE_TYPES)
    })
  },
  saleStatuses: null,
  saleStatusesMap: {},
  spsRequestStatuses: null,
  spsRequestStatusesMap: {},
  spsPlanningSpeeds: null,
  spsPlanningSpeedsMap: {},
  qcProgressStatuses: null,
  qcProgressStatusesMap: {},
  getSaleStatuses: async () => {
    if (!get().saleStatuses) await get().setSpsConstants()
    return get().saleStatuses
  },
  getSpsRequestStatuses: async () => {
    if (!get().spsRequestStatuses) await get().setSpsConstants()
    return get().spsRequestStatuses
  },
  getSpsPlanningSpeeds: async () => {
    if (!get().PlanningSpeeds) await get().setSpsConstants()
    return get().PlanningSpeeds
  },
  getQCProgressStatuses: async () => {
    if (!get().qcProgressStatuses) await get().setSpsConstants()
    return get().qcProgressStatuses
  },
  setSpsConstants: async () => {
    if (!spsConstantsPromise) {
      spsConstantsPromise = constantsActions.getSpsConstants().finally(() => {
        spsConstantsPromise = null
      })
    }

    const queryResults = await spsConstantsPromise
    if (!queryResults) return
    const spsConstants = queryResults.data.data
    set({
      saleStatuses: spsConstants.SALE_STATUSES,
      saleStatusesMap: createMap(spsConstants.SALE_STATUSES),
      spsRequestStatuses: spsConstants.SPS_REQUEST_STATUSES,
      spsRequestStatusesMap: createMap(spsConstants.SPS_REQUEST_STATUSES),
      spsPlanningSpeeds: spsConstants.SPS_PLANNING_SPEEDS,
      spsPlanningSpeedsMap: createMap(spsConstants.SPS_PLANNING_SPEEDS),
      qcProgressStatuses: spsConstants.QC_PROGRESS_STATUSES,
      qcProgressStatusesMap: createMap(spsConstants.QC_PROGRESS_STATUSES)
    })
  },
  appSettings: {},
  appSettingsByKey: {},
  appSettingsByGroup: {},
  getAppSettings: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().appSettings[paramString]) await get().setAppSettings(params)
    return get().appSettings[paramString]
  },
  resetAppSettings: () => {
    set({ appSettings: {} })
  },
  setAppSettings: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!appSettingsPromise[paramString]) {
      appSettingsPromise[paramString] = settingsActions.getSettings(params).finally(() => {
        appSettingsPromise[paramString] = null
      })
    }
    const queryResults = await appSettingsPromise[paramString]
    if (!queryResults) return
    set({
      appSettings: { ...get().appSettings, [paramString]: queryResults.data.data },
      appSettingsByKey: { ...get().appSettingsByKey, [paramString]: arrayUtils.toMap(queryResults.data.data, (x) => x.key) },
      appSettingsByGroup: { ...get().appSettingsByGroup, [paramString]: arrayUtils.groupBy(queryResults.data.data, (x) => x.groupKey) }
    })
  },
  rolesList: [],
  getRolesList: async () => {
    if (!get().rolesList.length) await get().setRolesList()
    return get().rolesList
  },
  setRolesList: async () => {
    if (!rolesPromise) {
      rolesPromise = roleAndPermissionAction.getRoles().finally(() => {
        rolesPromise = null
      })
    }

    const queryResults = await rolesPromise
    if (!queryResults) return
    set({ rolesList: queryResults.data.data })
  },
  permissionsList: [],
  getPermissionsList: async () => {
    if (!get().permissionsList.length) await get().setPermissionsList()
    return get().permissionsList
  },
  setPermissionsList: async () => {
    if (!permissionsPromise) {
      permissionsPromise = roleAndPermissionAction.getPermissions().finally(() => {
        permissionsPromise = null
      })
    }

    const queryResults = await permissionsPromise
    if (!queryResults) return
    set({ permissionsList: queryResults.data.data })
  }
})

let useGlobalsStore = create(storeFunction)

const resetGlobalsStore = () => {
  useGlobalsStore = create(storeFunction)
}

export { useGlobalsStore, resetGlobalsStore }
