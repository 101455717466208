import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { cutGradeActions } from '@actions'
import moment from 'moment'
import ProportionInput from '../proportionInput'
import { usePageTitle, useToast } from '@hooks'
import { useHistory } from 'react-router-dom'
import { useGlobalsStore, useAuthStore, useModalStore } from '@stores'
import dottie from 'dottie'
import clone from 'just-clone'
import BuyerSettingsDetailsPage from './buyerSettingsDetailsPage'
import { textUtils } from '@utils'
import { exportCutGrades } from '../utils'

function CutGradeDetails({ match, canEdit: canEditProp, title }) {
  const { cutGradeId, operation } = match?.params
  const { hasAdmin, permissionsAdminCache } = useAuthStore(state => state)

  const [cutGrade, setCutGrade] = useState({})

  const {
    setModal
  } = useModalStore(state => state)

  const { showSuccessToast, showErrorToast } = useToast()
  const history = useHistory()

  usePageTitle(title, cutGradeId, cutGrade?.name)
  useEffect(() => {
    refreshCutGrade(cutGradeId)
  }, [cutGradeId])

  function refreshCutGrade(id) {
    if (id) {
      cutGradeActions.getCutGradeList({ id, columns: '[Shape,Orders,Proportions]' })
      .then(response => {
        const cutGrade = response.data.data[0]
        setCutGrade({
          ...cutGrade,
          Proportions: cutGrade.Proportions.sort((x, y) => x.sortOrder - y.sortOrder)
        })
      })
      .catch(err => console.error(err))
    } else setCutGrade({})
  }

  const canEdit = canEditProp && cutGrade?.buyerId != null && cutGrade?.condition === 'ACTIVE'

  const [proportionStatus, setProportionStatus] = useState('unchanged')

  const {
    orgsList: { all: orgsList },
    getOrgsList,
    fixedIds,
    getFixedIds
  } = useGlobalsStore()
  useEffect(() => {
    getOrgsList()
    getFixedIds()
  }, [])

  const fixedIdOptions = fixedIds?.filter(id => id.sarineShapeReplacements[cutGrade?.Shape?.sarineGenericShapeId])

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (cutGrade && orgsList && fixedIds) {
      setFields([
        {
          label: 'Name',
          value: cutGrade.name,
          name: 'name',
          componentName: 'textInput',
          canEdit
        },
        {
          label: 'Shape',
          value: cutGrade.Shape && `${cutGrade.Shape?.name} (${textUtils.shortenUUID(cutGrade.Shape?.id)})`,
          name: 'shape',
          componentName: 'textInput',
          canEdit: false
        },
        {
          label: 'Institute',
          value: cutGrade.institute,
          name: 'institute',
          componentName: 'textInput',
          canEdit
        },
        {
          label: 'Owner',
          value: cutGrade.buyerId === null ? '-' : cutGrade.buyerId,
          name: 'buyerId',
          componentName: 'dropdown',
          options: orgsList?.map(org => ({ value: org.orgId, label: org.commonName })).concat({ value: '-', label: 'Preloaded' }),
          canEdit: false
        },
        {
          label: 'Special Rules',
          name: 'sarineNonLinearGrade.fixedId',
          value: String(cutGrade.sarineNonLinearGrade?.fixedId ?? ''),
          componentName: 'dropdown',
          options: [{ value: '', label: 'None' }].concat(fixedIdOptions?.map(id => ({ value: String(id.value), label: id.description })) || []),
          canEdit
        },
        {
          label: 'Cut Grade for Rules',
          name: 'sarineNonLinearGrade.cutGradeId',
          value: String(cutGrade.sarineNonLinearGrade?.cutGradeId ?? ''),
          componentName: 'dropdown',
          options: [{ value: '', label: 'None' }],
          renderOverrides: values => {
            const fixedIdValue = values.find(v => v.name === 'sarineNonLinearGrade.fixedId')
            const selectedFixedId = fixedIds?.find(id => String(id.value) === fixedIdValue?.value)
            return { options: [{ value: '', label: 'None' }].concat(selectedFixedId?.cutGrades?.map(g => ({ value: String(g.value), label: g.description })) || []) }
          },
          canEdit
        },
        {
          label: 'Validation Status',
          name: 'sarineValidProportions',
          value: textUtils.capitalize(cutGrade.sarineValidProportions, true),
          componentName: 'textInput',
          canEdit: false
        },

        {
          label: 'Proportions',
          name: 'proportions',
          span: true,
          canEdit,
          value: cutGrade.proportions,
          fixedId: cutGrade.sarineNonLinearGrade?.fixedId,
          renderOverrides: values => {
            const over = {}
            // if (values.proportions) over.value = values.proportions
            if (values.sarineNonLinearGrade && 'fixedId' in values.sarineNonLinearGrade) over.fixedId = values.sarineNonLinearGrade.fixedId
          },
          customComponent: ProportionDetails,
          propMeta: cutGrade.Proportions
        },
        {
          label: 'Created At',
          shouldDisplay: hasAdmin(cutGradeActions.getCutGradeList) || cutGrade.buyerId !== null,
          value: moment(cutGrade.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          shouldDisplay: hasAdmin(cutGradeActions.getCutGradeList) || cutGrade.buyerId !== null,
          value: moment(cutGrade.updatedAt).toLocaleString()
        }
      ])
    }
  }, [cutGrade, orgsList, fixedIds, permissionsAdminCache])

  const ProportionDetails = (props) => {
    function handleProportionChange(e) {
      const proportions = { proportions: clone(props.value) }
      const target = 'currentTarget' in e ? 'currentTarget' : 'target'
      dottie.set(proportions, e?.[target]?.name, e?.[target]?.value)
      if (e?.[target]?.name === 'proportions.MIN_GRDL_FLAG.min') {
        dottie.set(proportions, 'proportions.MIN_GRDL_FLAG.max', e?.[target]?.value)
      }
      props.onChange({ [target]: { name: 'proportions', value: proportions.proportions } })
      setProportionStatus('changed')
    }

    return <>
      <div className='proportion-input__container'>
        <div className='proportion-input__min'>
          <label>
            <div className="input__label">
              <span>Min</span>
            </div>
          </label>
        </div>
        <div className='proportion-input__max'>
          <label>
            <div className="input__label">
              <span>Max</span>
            </div>
          </label>
        </div>
      </div>
      {props?.propMeta?.map(prop => (
        <ProportionInput
          {...prop}
          key={prop.proportion}
          minValue={props.value[prop.proportion]?.min}
          maxValue={props.value[prop.proportion]?.max}
          onChange={handleProportionChange}
          disabled={props.disabled}
        />
      ))}
    </>
  }

  async function triggerInvalidProportionsModal() {
    return new Promise((resolve, reject) => {
      setModal({
        id: 'cutGradeDetailsProportionsInvalid',
        title: 'Proportions Invalid',
        onCancel: reject,
        message: 'The proportions are not valid and may fail to plan. Are you sure you want to save?',
        onSubmit: resolve
      })
    })
  }

  async function handleOnValidate(editedValues) {
    try {
      if (proportionStatus === 'changed' || proportionStatus === 'unchanged') {
        await cutGradeActions.validateCutGrade({ shapeId: cutGrade.shapeId, proportions: editedValues.proportions || cutGrade.proportions })
        setProportionStatus('valid')
      } else if (proportionStatus === 'invalid') {
        throw new Error('Invalid proportion status')
      }
    } catch (err) {
      setProportionStatus('invalid')
      await triggerInvalidProportionsModal()
    }
  }

  async function handleOnSubmit(editedValues) {
    const { condition, ...cutGradeValues } = editedValues
    const cutGradeData = { ...cutGradeValues }
    const conditionPromise = condition ? cutGradeActions.setCutGradeCondition(cutGradeId, condition) : Promise.resolve()
    return conditionPromise
      .then(() => {
        if (Object.keys(cutGradeData).length) {
          return cutGradeActions.editCutGrade({ id: cutGradeId, ...cutGradeData })
        }
      })
      .then(() => {
        showSuccessToast('Cut Grade updated.')
      })
      .then(() => refreshCutGrade(cutGradeId))
  }

  function handleConfirmModal(editedValues) {
    const ignoreKeys = ['name', 'institute']
    const editedKeys = Object.keys(editedValues)
    return editedKeys.some(key => !ignoreKeys.includes(key)) ? 'cutGradeDetailsSaveConfirmation' : null
  }

  function handleArchive() {
    cutGradeActions.setCutGradeCondition(cutGrade?.id, 'ARCHIVED')
    .then(() => {
      showSuccessToast('Cut Grade has been archived.')
      history.push('/buyer-settings')
    })
  }

  function handleUnarchive() {
    cutGradeActions.setCutGradeCondition(cutGrade?.id, 'ACTIVE')
    .then(() => {
      showSuccessToast('Cut Grade has been unarchived.')
      history.push('/buyer-settings')
    })
  }

  function handleRemove() {
    cutGradeActions.setCutGradeCondition(cutGrade?.id, 'DELETED')
    .then(() => {
      showSuccessToast('Cut Grade has been removed.')
      history.push('/buyer-settings')
    })
  }

  async function handleExport() {
    try {
      return await exportCutGrades([cutGrade], { fixedIds, orgsList })
    } catch (err) {
      console.error(err)
      showErrorToast(err?.message || 'There was an error writing the excel.')
    }
  }

  const canArchive = canEditProp && cutGrade?.condition === 'ACTIVE' && cutGrade?.buyerId != null
  const canUnarchive = canEditProp && cutGrade?.condition === 'ARCHIVED' && cutGrade?.buyerId != null
  const canRemove = canEditProp && cutGrade?.condition !== 'DELETED' && cutGrade?.buyerId != null

  return (
    <BuyerSettingsDetailsPage
      canEdit={canEdit}
      isEdit={operation === 'edit'}
      fields={fields}
      onArchive={canArchive ? handleArchive : null}
      onUnarchive={canUnarchive ? handleUnarchive : null}
      onRemove={canRemove ? handleRemove : null}
      onValidate={handleOnValidate}
      onSubmit={handleOnSubmit}
      title={{
        label: 'Cut Grade ID',
        value: cutGradeId
      }}
      extraButtons={[...(canEdit ? [{
        caption: 'Validate',
        onClick: (values) => {
          const proportions = values.find(v => v.name === 'proportions')
          cutGradeActions.validateCutGrade({ shapeId: cutGrade.shapeId, proportions: proportions.value })
              .then(() => {
                showSuccessToast('Proportions are valid')
                setProportionStatus('valid')
              })
              .catch(() => {
                setProportionStatus('invalid')
              })
        },
        disabled: (!hasAdmin(cutGradeActions.editCutGrade) && cutGrade.buyerId === null)
      }] : [])].concat({
        caption: 'Download Excel',
        onClick: handleExport
      })}
      confirmModal={handleConfirmModal}
      value={cutGrade}
    />
  )
}

CutGradeDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string,
  canEdit: PropTypes.bool
}

CutGradeDetails.defaultProps = {
  canEdit: true
}

export default CutGradeDetails
