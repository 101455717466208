import { usePageSettingStore } from '@stores'

function usePageSettings(pageId) {
  const {
    addPageSettingValue,
    getPageSettingValue,
    setPageConfig,
    getPageConfig,
    removePageSetting,
    resetPageSetting
  } = usePageSettingStore(state => state)

  return {
    setPageConfig: (pageConfig) => setPageConfig(pageId, pageConfig),
    getPageConfig: () => getPageConfig(pageId),
    addPageSettingValue: (value) => addPageSettingValue(pageId, value),
    getPageSettingValue: (key) => getPageSettingValue(pageId, key),
    removePageSetting: () => removePageSetting(pageId),
    resetPageSetting: () => resetPageSetting()
  }
}

export { usePageSettings }
