import { COMMON } from '@constants'
import { fileUtils, textUtils } from '@utils'
import ProportionInput from './proportionInput'
import Excel from 'exceljs/dist/es5/exceljs.browser'

async function exportCutGrades(cutGrades, { fixedIds, orgsList }) {
  const workbook = new Excel.Workbook()
  workbook.creator = 'Clara'
  workbook.created = new Date()
  const bold = { font: { bold: true } }

  function buildCutGrade(cutGrade) {
    const filteredFixedIds = fixedIds?.filter(id => id.sarineShapeReplacements[cutGrade?.Shape?.sarineGenericShapeId])
    const specialRules = filteredFixedIds?.find(fixedId => fixedId.value == cutGrade.sarineNonLinearGrade?.fixedId)
    return {
      ...cutGrade,
      shapeName: cutGrade.Shape.name || '',
      buyerName: cutGrade.buyerId == null ? '* Preloaded *' : (orgsList?.find(org => org.orgId === cutGrade.buyerId)?.commonName || ''),
      validationStatus: textUtils.capitalize(cutGrade.sarineValidProportions, true),
      specialRules: specialRules?.description || 'None',
      cutGradeForRules: specialRules?.cutGrades?.find(cG => cG.value == cutGrade.sarineNonLinearGrade?.cutGradeId)?.description || 'None',
      Proportions: cutGrade.Proportions.sort((x, y) => x.sortOrder - y.sortOrder)
    }
  }

  const getRows = (cG) => [
    [{ value: `Export of Cut Grade ${cG.id}`, ...bold }, null, null, null, null, { value: 'Proportions', ...bold }],
    [],
    [{ value: 'Name', ...bold }, cG.name],
    [{ value: 'Shape', ...bold }, cG?.shapeName],
    [{ value: 'Owner', ...bold }, cG?.buyerName],
    [{ value: 'Institute', ...bold }, cG?.institute],
    [{ value: 'Validation Status', ...bold }, cG?.validationStatus],
    [],
    [],
    [{ value: 'Special Rules', ...bold }, cG?.specialRules],
    [{ value: 'Cut Grade for Rules', ...bold }, cG?.cutGradeForRules],
    [],
    [{ value: 'Condition', ...bold }, cG?.condition],
    [{ value: 'Created At', ...bold }, cG?.createdAt],
    [{ value: 'Last Updated', ...bold }, cG?.updatedAt]
  ]

  const getSheetName = (cG) => {
    const invalidCharsRegex = /[*?:/\\[\]]/g
    const maxLength = 28 // 31 but subtracted 3 chars for ' - '

    const shapeName = cG.shapeName.replaceAll(invalidCharsRegex, '#')
    const cutGradeName = cG.name.replaceAll(invalidCharsRegex, '#')
    const shapeLength = Math.min(maxLength / 2, shapeName.length)
    const cutGradeLength = Math.max(maxLength / 2, maxLength - shapeLength)
    return `${textUtils.shorten(shapeName, shapeLength)} - ${textUtils.shorten(cutGradeName, cutGradeLength)}`
  }

  for (const cG of cutGrades) {
    const cutGrade = buildCutGrade(cG)

    const sheet = workbook.addWorksheet(getSheetName(cutGrade))
    sheet.getColumn(1).width = 22.5
    sheet.getColumn(6).width = 17.5
    let row = 4

    const rows = getRows(cutGrade)
    for (const row of rows) fileUtils.addRow(sheet, row)
    fileUtils.setRowCells(sheet.getRow(3), ['Min', 'Max'].map(v => ({ value: v, ...bold })), 6)

    for (const proportion of cutGrade.Proportions) {
      const cells = []
      const dispTp = ProportionInput.displayTypes[proportion.displayType]
      if (proportion.displayType === 'HIDDEN') continue

      cells.push(proportion.description)
      const minValue = cutGrade.proportions[proportion.proportion]?.min
      const maxValue = cutGrade.proportions[proportion.proportion]?.max
      if (proportion.displayType === 'GIRDLE_BOOLEAN') {
        const value = (minValue == null || minValue === '') ? '' : dispTp.toDisplay(minValue)
        cells.push(ProportionInput.girdleBooleanOptions.find(opt => opt.value === value)?.label)
        sheet.mergeCells(row, 7, row, 8)
      } else {
        cells.push((minValue == null || minValue === '') ? '' : dispTp.toDisplay(minValue))
        cells.push((maxValue == null || maxValue === '') ? '' : dispTp.toDisplay(maxValue))
        cells.push(dispTp.units)
      }
      fileUtils.setRowCells(sheet.getRow(row++), cells, 6)
    }
  }

  return workbook.xlsx.writeBuffer().then(buffer => fileUtils.saveBufferExcel(buffer, `Export Cut Grades - ${textUtils.formatDate(new Date(), COMMON.DATE_FMT.REPORT)}.xlsx`))
}

export { exportCutGrades }
