import Excel from 'exceljs/dist/es5/exceljs.browser'
import { arrayUtils, fileUtils } from '@utils'
import { SELLER } from '@constants'
import { roughStoneActions } from '@actions'
const { ROUGH_LOCATIONS } = SELLER

const idOpts = ['clara id', 'id']
const nameOpts = ['seller stone name']
const colourOpts = ['colour', 'color']
const fluOpts = ['flu', 'fluor', 'fluorescence']
const tingeOpts = ['tinge']
const yellowFluOpts = ['yellow uv', 'yellow fluorescence']
const pricePointOpts = ['price point name', 'price point']
const reserveOpts = ['price per carat', 'original reserve ($/ct)', 'reserve ($/ct)']
const reserveOverrideOpts = ['override price per carat', 'override reserve ($/ct)']
const qcStatusOpts = ['qc status', 'qc approved']
const typeOpts = ['type', 'stone type']
const parseRoughColumnMap = {
  ...arrayUtils.toMap(idOpts, x => x, () => 'id'),
  ...arrayUtils.toMap(nameOpts, x => x, () => 'sellerStoneName'),
  ...arrayUtils.toMap([...colourOpts, ...colourOpts.map(opt => 'yehuda ' + opt)], x => x, () => 'colour'),
  ...arrayUtils.toMap([...fluOpts, ...fluOpts.map(opt => 'yehuda ' + opt)], x => x, () => 'fluorescence'),
  ...arrayUtils.toMap([...tingeOpts, ...tingeOpts.map(opt => 'yehuda ' + opt)], x => x, () => 'tinge'),
  ...arrayUtils.toMap(colourOpts.map(opt => 'eye ' + opt), x => x, () => 'eye_colour'),
  ...arrayUtils.toMap(fluOpts.map(opt => 'eye ' + opt), x => x, () => 'eye_fluorescence'),
  ...arrayUtils.toMap(tingeOpts.map(opt => 'eye ' + opt), x => x, () => 'eye_tinge'),
  ...arrayUtils.toMap(colourOpts.map(opt => 'override ' + opt), x => x, () => 'override_colour'),
  ...arrayUtils.toMap(fluOpts.map(opt => 'override ' + opt), x => x, () => 'override_fluorescence'),
  ...arrayUtils.toMap(tingeOpts.map(opt => 'override ' + opt), x => x, () => 'override_tinge'),
  ...arrayUtils.toMap(yellowFluOpts, x => x, () => 'otherAttributes.yellowFluorescence'),
  ...arrayUtils.toMap(pricePointOpts, x => x, () => 'pricePoint'),
  ...arrayUtils.toMap(reserveOpts, x => x, () => 'reservePpcOriginal'),
  ...arrayUtils.toMap(reserveOverrideOpts, x => x, () => 'reservePpcOverride'),
  ...arrayUtils.toMap(qcStatusOpts, x => x, () => 'qcStatus'),
  ...arrayUtils.toMap(typeOpts, x => x, () => 'type'),
  weight: 'weight',
  country: 'countryId',
  mine: 'mineId',
  pipe: 'pipeId',
  batch: 'batchId',
  'weight category': 'weightCategory',
  'scan type': 'scanType',
  'inclusion type': 'inclusionsTypeId',
  'inclusion reductions': 'inclusionReductionsId',
  tension: 'otherAttributes.tension',
  location: 'location'
}

const pagePermissions = [
  {
    key: 'assortments',
    edit: true,
    qcEdit: false,
    create: true
  },
  {
    key: 'roughstones',
    edit: true,
    qcEdit: false,
    create: false
  },
  {
    key: 'qc',
    edit: false,
    qcEdit: true,
    create: false
  }
]

const uploadFieldFilters = [
  {
    key: 'id',
    edit: { allow: true },
    qcEdit: { allow: true },
    create: { allow: false }
  }, {
    key: 'assortmentId',
    edit: { allow: false },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'sellerId',
    edit: { allow: false },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'sellerStoneName',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'measurements',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'eyeMeasurement',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'overrideMeasurement',
    edit: {
      allow: true,
      hasAdmin: roughStoneActions.editRoughStone
    },
    qcEdit: { allow: false },
    create: { allow: false }
  }, {
    key: 'weight',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'countryId',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'mineId',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'pipeId',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'batchId',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'weightCategory',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'pricePoint',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'reservePpcOriginal',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'reservePpcOverride',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'scanType',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'inclusionsTypeId',
    edit: { allow: true },
    qcEdit: { allow: true },
    create: { allow: true }
  }, {
    key: 'inclusionReductionsId',
    edit: {
      allow: true,
      hasAdmin: roughStoneActions.editRoughStone
    },
    qcEdit: { allow: true },
    create: {
      allow: true,
      hasAdmin: roughStoneActions.editRoughStone,
      default: (_, { inclusionReductions }) => inclusionReductions.find(x => x.default === 'DEFAULT')?.id
    }
  }, {
    key: 'otherAttributes',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }, {
    key: 'status',
    edit: { allow: false },
    qcEdit: { allow: false },
    create: {
      allow: false,
      default: 'ROUGH_NOT_SUBMITTED'
    }
  }, {
    key: 'location',
    edit: {
      allow: true,
      hasAdmin: roughStoneActions.editRoughStone
    },
    qcEdit: { allow: false },
    create: {
      allow: false,
      default: 'Seller'
    }
  }, {
    key: 'qcStatus',
    edit: { allow: false },
    qcEdit: {
      allow: true,
      hasAdmin: roughStoneActions.editQcRoughStone
    },
    create: { allow: false }
  }, {
    key: 'type',
    edit: { allow: true },
    qcEdit: { allow: false },
    create: { allow: true }
  }
]

const defaultSecondaryMarketSettings = {
  country: 'Mixed Origin',
  mine: 'Mixed Mining'
}

const parseExcelFile = async ({
  pageKey,
  file,
  assortment,
  countries,
  mines,
  pipes,
  batches,
  pricePoints,
  weightCategories,
  roughScanTypes,
  roughColours,
  roughColoursMap,
  roughFluorescences,
  roughFluorescencesMap,
  tinges,
  roughTingesMap,
  polishedFluorescences,
  inclusionTypes,
  inclusionReductions,
  roughTensions,
  roughTypes,
  provenanceList,
  inclusionReductionsMap,
  roughScanTypesMap,
  roughTensionsMap,
  roughStatusesMap,
  roughQCStatuses,
  roughQCStatusesMap,
  roughTypesMap
}, getDBRoughs) => {
  const res = {
    roughs: [],
    mode: 'create',
    validation: {
      errors: [],
      warnings: []
    }
  }
  const buffer = await fileUtils.promiseReadBinaryFile(file)
  const book = new Excel.Workbook()
  await book.xlsx.load(buffer)
  const sheet = book.worksheets[0]
  const roughList = []
  let dbRoughsMap = {}

  const headerRowIdx = Array.from({ length: sheet.rowCount + 1 }, (_, i) => i).findIndex(idx => {
    const row = sheet.getRow(idx)
    // One of these two keys are required for multistone upload
    const headerRow = !!arrayUtils.intersectBy(row.values, [...idOpts, ...nameOpts], x => String(x)?.toLowerCase()?.trim()).length
    if (!headerRow) row.claraPreHeader = true
    if (headerRow) row.claraHeader = true
    return headerRow
  })
  if (headerRowIdx < 0 || headerRowIdx === sheet.rowCount) throw new Error('Invalid file. Please update file or try again with a different file.')
  const headerRow = sheet.getRow(headerRowIdx)
  // Go through the columns of the header row and determine what kind of upload this is
  for (const [idx, headVal] of headerRow.values.entries()) {
    const head = headVal?.toLowerCase()?.trim()
    const column = sheet.getColumn(idx)
    if (!head || !column) continue
    const isEyeMeasurement = !!(head.match(/eye (colou?r|flu(or|orescence)?|tinge)/))
    const isOverrideMeasurement = !!(head.match(/override (colou?r|flu(or|orescence)?|tinge)/))
    const isMeasurement = !isEyeMeasurement && !isOverrideMeasurement && !!(head.match(/(yehuda )?(colou?r|flu(or|orescence)?|tinge)/))

    column.key = head
    // If this current header column is an id type field, try and determine what type of edit the upload is
    if (idOpts.includes(head)) {
      // normalize the key
      column.key = parseRoughColumnMap[head]
      const idColVals = sheet.getColumn(column.key).values.slice(headerRowIdx + 1)
      const isIdColEmpty = !idColVals.length || idColVals.every(id => id == null)
      // if the id column is fully empty then consider the upload as a create mode upload
      if (isIdColEmpty) {
        res.mode = 'create'
        res.validation.warnings.push('No values entered in the id column. If you wish to edit existing stones please ensure cells in this column are populated.')
      } else {
        res.mode = pageKey === 'qc' ? 'qcEdit' : 'edit'
        // Retrieve db roughs to compare against the uploaded roughs
        const dbRoughsList = await getDBRoughs()
        dbRoughsMap = arrayUtils.toMap(dbRoughsList?.data, x => x.id,
          (r) => ({
            ...r,
            assortmentId: r?.Assortment?.id,
            assortmentName: r?.Assortment?.name,
            batchId: r?.Batch?.id,
            batchName: r?.Batch?.name,
            countryId: r?.Country?.id,
            countryName: r?.Country?.name,
            mineId: r?.Mine?.id,
            mineName: r?.Mine?.name,
            pipeId: r?.Pipe?.id,
            pipeName: r?.Pipe?.name,
            inclusionsTypeId: r?.InclusionsType?.id,
            inclusionsTypeName: r?.InclusionsType?.description,
            inclusionReductionsId: r?.inclusionReductionsId,
            inclusionReductionsName: inclusionReductionsMap?.[r?.inclusionReductionsId],
            scanTypeName: roughScanTypesMap?.[r?.scanType],
            tensionName: roughTensionsMap?.[r?.otherAttributes?.tension],
            qcStatusName: roughQCStatusesMap?.[r?.qcStatus],
            typeName: roughTypesMap?.[r?.type],
            statusName: roughStatusesMap?.[r?.status],
            locationName: r?.location,
            colourName: roughColoursMap?.[r?.colour],
            fluorescenceName: roughFluorescencesMap?.[r?.fluorescence],
            tingeName: roughTingesMap?.[r?.tinge]
          }))
      }
    }
    if (isMeasurement) {
      const parsed = head.match(/^((?:yehuda )?[A-Za-z]+)\s([1-5])$/)
      if (!parsed) {
        res.validation.warnings.push(`Invalid column for rough stone upload. Skipping measurement column ${headVal}.\n`)
        continue
      }
      column.claraKey = { isMeasurement, key: parseRoughColumnMap[parsed[1]], measurementIndex: Number(parsed[2]) - 1 }
    } else {
      column.claraKey = { isMeasurement: false, isEyeMeasurement, isOverrideMeasurement, key: parseRoughColumnMap[head] }
    }
    if (column.claraKey.key == null) res.validation.warnings.push(`Invalid column for rough stone upload. Skipping column: ${headVal}.\n`)
  }

  // Upload mode has been determined. Now determine if there are any errors with the page key and mode.
  const pagePermission = pagePermissions.find(({ key }) => key === pageKey)
  if (!pagePermission?.[res.mode]) {
    if (res.mode === 'create') throw new Error('Invalid operation. Clara ID field must be present.')
    else throw new Error('Invalid operation. Please review infotip and template instructions for correct fields.')
  }

  const roughIdSet = new Set()
  sheet.eachRow((row, idx) => {
    if (row.claraPreHeader || row.claraHeader) return
    let rowRoughId
    const totalRoughs = roughIdSet.size
    try {
      rowRoughId = row.getCell('id').value
    } catch (err) {
      // do nothing
    }
    if (['edit', 'qcEdit'].includes(res.mode)) {
      if (!rowRoughId) return res.validation.warnings.push(`Empty value at row ${idx} in the id column. If you wish to edit this row, please enter in a valid id.`)
      if (totalRoughs === roughIdSet.add(rowRoughId).size) return res.validation.warnings.push(`Duplicate value at row ${idx} in the id column. This row will be skipped.`)
    }

    const dbRough = dbRoughsMap?.[rowRoughId]
    const rough = { existing: dbRough }
    if (res.mode === 'create') rough.assortment = assortment
    else if (dbRough) rough.assortment = dbRough.Assortment
    else return res.validation.errors.push(`Invalid rough at row ${idx}.`)
    if (!rough.assortment) throw new Error('Invalid assortment.')
    const isAssortmentSecMarket = !!provenanceList?.find(({ id }) => id === rough.assortment?.provenanceTypeId)?.secondaryMarket
    row.eachCell({ includeEmpty: true }, (cell, cellNum) => {
      const column = sheet.getColumn(cellNum).claraKey
      if (!column) return
      let value = cell.text.trim()
      if (column.key === 'mineId') {
        let mine = mines?.find(mine => mine.name.toLowerCase() === value.toLowerCase())
        if (!mine) mine = mines?.find(mine => mine.id.toLowerCase() === value.toLowerCase())
        if (!value && isAssortmentSecMarket) mine = mines?.find(mine => mine.name.toLowerCase() === defaultSecondaryMarketSettings.mine.toLowerCase() && mine.secondaryMarket)
        rough.mineName = mine?.name ?? value
        value = mine?.id ?? null
      }
      if (column.key === 'pipeId') {
        let pipe = pipes?.find(pipe => pipe.name.toLowerCase() === value.toLowerCase() && (!rough.mineId || pipe.mineId === rough.mineId))
        if (!pipe) pipe = pipes?.find(pipe => pipe.id.toLowerCase() === value.toLowerCase() && (!rough.mineId || pipe.mineId === rough.mineId))
        rough.pipeName = pipe?.name ?? value
        value = pipe?.id ?? null
      }
      if (column.key === 'batchId') {
        let batch = batches?.find(batch => batch.name.toLowerCase() === value.toLowerCase() && (!rough.pipeId || batch.pipeId === rough.pipeId))
        if (!batch) batch = batches?.find(batch => batch.id.toLowerCase() === value.toLowerCase() && (!rough.pipeId || batch.pipeId === rough.pipeId))
        rough.batchName = batch?.name ?? value
        value = batch?.id ?? null
      }
      if (column.key === 'countryId') {
        let country = countries?.find(country => country.name.toLowerCase() === value.toLowerCase())
        if (!country) country = countries?.find(country => country.id.toLowerCase() === value.toLowerCase())
        if (!value && isAssortmentSecMarket) country = countries?.find(country => country.name.toLowerCase() === defaultSecondaryMarketSettings.country.toLowerCase() && country.secondaryMarket)
        rough.countryName = country?.name ?? value
        value = country?.id ?? null
      }
      if (column.key === 'weightCategory') {
        value = weightCategories?.find(w => w.trim().toLowerCase() === value.toLowerCase()) || value
      }
      if (column.key === 'pricePoint') {
        value = pricePoints?.find(p => p.trim().toLowerCase() === value.toLowerCase()) || value
      }
      if (column.key === 'scanType') {
        let scanType = roughScanTypes?.find(s => s.description.toLowerCase() === value.toLowerCase())
        if (!scanType) scanType = roughScanTypes?.find(s => s.value.toLowerCase() === value.toLowerCase())
        rough.scanTypeName = scanType?.description ?? value
        value = scanType?.value ?? null
      }
      if (column.key === 'colour' || column.key === 'eye_colour' || column.key === 'override_colour') {
        const col = roughColours?.find(c => c.description.toLowerCase() === value.toLowerCase() || c.value.toLowerCase() === value.toLowerCase())
        if (col) value = col.value
      }
      if (column.key === 'fluorescence') {
        let flu = roughFluorescences?.find(f => f.description === value.toLowerCase())
        if (!flu) flu = roughFluorescences?.find(f => String(f.value) === value)
        if (flu) value = String(flu.value)
      }
      if (column.key === 'eye_fluorescence' || column.key === 'override_fluorescence') {
        const pFlu = polishedFluorescences?.find(f => f.description.toLowerCase() === value.toLowerCase() || f.value.toLowerCase() === value.toLowerCase())
        if (pFlu) value = pFlu.value
      }
      if (column.key === 'tinge' || column.key === 'eye_tinge' || column.key === 'override_tinge') {
        const tinge = tinges?.find(t => t.description.toLowerCase() === value.toLowerCase())
        if (tinge) value = tinge.value
      }
      if (column.key === 'inclusionsTypeId') {
        let incTp = inclusionTypes?.find(it => it.description.toLowerCase() === value.toLowerCase())
        if (!incTp) incTp = inclusionTypes?.find(it => it.id == value.toLowerCase())
        rough.inclusionsTypeName = incTp?.description ?? value
        value = incTp?.id ?? null
      }
      if (column.key === 'inclusionReductionsId') {
        let incRd = inclusionReductions?.find(it => it.description.toLowerCase() === value.toLowerCase())
        if (!incRd) incRd = inclusionReductions?.find(it => it.id == value.toLowerCase())
        rough.inclusionReductionsName = incRd?.description ?? value
        value = incRd?.id ?? null
      }
      if (column.key === 'otherAttributes.tension') {
        const tension = roughTensions?.find(t => t.description.toLowerCase() === value.toLowerCase())
        rough.tensionName = tension?.description ?? value
        value = tension?.value ?? null
      }
      if (column.key === 'otherAttributes.yellowFluorescence') {
        if (['y', 'yes', 'true', 'yellow', '1'].includes(value.toLowerCase())) value = true
        else if (['n', 'no', 'none', 'false'].includes(value.toLowerCase())) value = false
        // Is this dangerous to convert from '' to null?
        else value = null
      }
      if (column.key === 'type') {
        let type = roughTypes?.find(t => t.description.toLowerCase() === value.toLowerCase())
        if (!type) type = roughTypes?.find(t => t.value.toLowerCase() === value.toLowerCase())
        rough.typeName = type?.description ?? value
        value = type?.value ?? null
      }
      if (column.key === 'location') {
        const location = ROUGH_LOCATIONS?.find(l => l.toLowerCase() === value.toLowerCase())
        rough.locationName = location ?? value
        value = location ?? null
      }
      if (column.key === 'qcStatus') {
        let qcStatus = roughQCStatuses?.find(q => q.description.toLowerCase() === value.toLowerCase())
        if (!qcStatus) qcStatus = roughQCStatuses?.find(q => q.value.toLowerCase() === value.toLowerCase())
        if (!qcStatus) qcStatus = roughQCStatuses?.find(q => q.qcApproved.toLowerCase() === value.toLowerCase())
        rough.qcStatusName = qcStatus?.qcApproved ?? value
        value = qcStatus?.value ?? null
      }
      if (column.isMeasurement) {
        if (!rough.measurements) rough.measurements = []
        if (value) {
          if (!rough.measurements[column.measurementIndex]) rough.measurements[column.measurementIndex] = {}
          rough.measurements[column.measurementIndex][column.key] = value
        }
      } else if (column.isEyeMeasurement) {
        if (value) {
          if (!rough.eyeMeasurement) rough.eyeMeasurement = {}
          rough.eyeMeasurement[column.key.slice(4)] = value
        }
      } else if (column.isOverrideMeasurement) {
        if (value) {
          if (!rough.overrideMeasurement) rough.overrideMeasurement = {}
          rough.overrideMeasurement[column.key.slice(9)] = value
        }
      } else if (value === null || value === '') {
        // do nothing
      } else if (column.key?.startsWith('otherAttributes.')) {
        if (!rough.otherAttributes) rough.otherAttributes = {}
        rough.otherAttributes[column.key.slice(16)] = value
      } else if (column.key) {
        rough[column.key] = value
      }
    })
    roughList.push(rough)
  })
  if (!res.validation.errors.length) {
    const roughListMap = arrayUtils.groupBy(roughList, r => r.id)
    const newRoughs = [...(roughListMap[undefined] || [])]
    delete roughListMap[undefined]
    res.roughs = (
      newRoughs?.length === roughList.length
        ? roughList
        : roughList.filter(({ id }) => id)
    )
    .filter(r => r.sellerStoneName || r.id)
    .map(({ assortment = {}, ...rough }) => ({
      assortmentId: assortment.id,
      assortmentName: assortment.name,
      sellerId: assortment.sellerId,
      ...rough
    }))
  }
  return res
}

export { parseExcelFile, uploadFieldFilters }
