import React, { useEffect, useMemo, useState } from 'react'
import { saleActions, eventActions } from '@actions'
import { FormComponents } from '@organisms'
// import { Button as PlainButton, Icon } from '@atoms'
import { useGlobalsStore, useOrderStore, useRoughStoneStore } from '@stores'
import { useToast } from '@hooks'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { arrayUtils } from '@utils'

function CreateSale() {
  const { SimpleForm, TextInput, Dropdown, Button } = FormComponents

  const history = useHistory()
  const { showSuccessToast, showErrorToast } = useToast()

  function handleSubmit({ saleName, eventGroupId, buyerIds, orderIds, sellerIds, assortmentIds, stoneIds }) {
    if (!buyerIds && !orderIds) {
      orderIds = allSubmittedOrders.map(x => x.id)
    }

    if (!stoneIds && !assortmentIds && !sellerIds) {
      stoneIds = allSubmittedRoughs.map(x => x.id)
    }

    if (buyerIds?.length) {
      for (const buyer of buyerIds) {
        orderIds = orderIds ? [...orderIds, ...allOrdersByOwner[buyer].map(x => x.id)] : allOrdersByOwner[buyer].map(x => x.id)
      }
    }

    if (sellerIds?.length) {
      for (const seller of sellerIds) {
        stoneIds = stoneIds ? [...stoneIds, ...allStonesByOwner[seller].map(x => x.id)] : allStonesByOwner[seller].map(x => x.id)
      }
    }

    if (assortmentIds?.length) {
      for (const assortment of assortmentIds) {
        stoneIds = stoneIds ? [...stoneIds, ...allStonesByAssortment[assortment].map(x => x.id)] : allStonesByAssortment[assortment].map(x => x.id)
      }
    }

    if (!orderIds || orderIds.length === 0) {
      showErrorToast('No submitted orders were selected')
      return
    }
    if (!stoneIds || stoneIds.length === 0) {
      showErrorToast('No submitted roughs stones were selected')
      return
    }

    saleActions.createSales({
      name: saleName,
      eventGroupId: eventGroupId,
      orderIds: Array.from(new Set(orderIds)),
      stoneIds: Array.from(new Set(stoneIds))
    })
    .then(res => {
      showSuccessToast(`Sale ${saleName} has been created.`)
      history.push('/sales')
    })
    .catch(err => console.log(err))
  }

  const [allEvents, setAllEvents] = useState([])

  const {
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList }, getOrgsList,
    eventTypesList, getEventTypesList
  } = useGlobalsStore()
  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
    getEventTypesList()
  }, [])

  const genericOrgId = useMemo(() => orgsList?.find(o => o.orgType === 'GENERIC')?.orgId, [orgsList])

  useEffect(() => {
    if (eventTypesList?.length) {
      const saleTypeId = eventTypesList.find(t => t.name === 'Sale')?.id
      if (!saleTypeId) return
      eventActions.getEvents({ typeId: saleTypeId })
      .then(res => {
        res.data.data.sort((a, b) => moment(a.startTime) - moment(b.startTime))
        const eventGroups = {}
        for (const event of res.data.data) {
          if (event.groupId) eventGroups[event.groupId] = true
        }
        setAllEvents(Object.keys(eventGroups))
      })
      .catch(console.error)
    }
  }, [eventTypesList])

  // TODO: These params are the same as the params from create_sps_requests and sale_details
  // although we are not using order condition in this component, it will allow us
  // to capitalize on a previously fetched/cached orderList from one of the two other
  // components if we keep the ordersParams the same. This is probably not a good way to
  // accomplish reusing caching because a small change to ordersParams in one file will have to
  // be accounted for in the other files.
  // Maybe there is a better way to handle situations like this. Possibly creating some ordersParams
  // presets that can be used by multiple components and can be managed in one file
  const ordersParams = { condition: 'ACTIVE', status: 'ORDER_SUBMITTED', columns: '[id, name, buyerId, condition]' }
  const {
    ordersList: { [ordersParams ? JSON.stringify(ordersParams) : 'all']: ordersList },
    getOrdersList
  } = useOrderStore(store => store)

  const roughStoneParams = { condition: 'ACTIVE', status: 'ROUGH_SUBMITTED', qcApproved: true, columns: ['id', 'sellerId', 'sellerStoneName', 'assortmentId', 'qcStatus', 'Assortment'] }
  const {
    roughStonesList: { [roughStoneParams ? JSON.stringify(roughStoneParams) : 'all']: storeRoughStonesList },
    getRoughStonesList
  } = useRoughStoneStore(store => store)

  useEffect(() => {
    if (orgsList?.length) {
      getRoughStonesList(roughStoneParams)
      getOrdersList(ordersParams)
    }
  }, [orgsList])

  const [allSubmittedOrders, allOrdersByOwner] = useMemo(() => {
    if (!ordersList || !ordersList.data) return [[], {}]
    const submittedOrders = ordersList.data.filter(o => o.buyerId !== genericOrgId).map(o => ({ ...o, buyerName: orgsList?.find(org => org.orgId === o.buyerId)?.commonName }))
    const ordersByOwner = arrayUtils.groupBy(submittedOrders, (o) => o.buyerName)
    return [submittedOrders, ordersByOwner]
  }, [ordersList, genericOrgId])

  const [allSubmittedRoughs, allStonesByOwner, allStonesByAssortment] = useMemo(() => {
    if (!storeRoughStonesList || !storeRoughStonesList.data) return [[], {}, {}]
    const submittedRoughs = storeRoughStonesList.data.map(r => ({ ...r, sellerName: orgsList?.find(org => org.orgId === r.sellerId)?.commonName }))
    const stonesByOwner = arrayUtils.groupBy(submittedRoughs, (s) => s.sellerName)
    const stonesByAssortment = arrayUtils.groupBy(submittedRoughs, (s) => s.assortmentId)
    return [submittedRoughs, stonesByOwner, stonesByAssortment]
  }, [storeRoughStonesList])

  const [allQCApprovedRoughs, allQCNotRequiredRoughs] = useMemo(() => {
    if (!storeRoughStonesList || !storeRoughStonesList.data) return [[], []]
    return Object.values(storeRoughStonesList.data.reduce((accum, { id: value, qcStatus }) => {
      if (qcStatus === 'APPROVED') accum.qcApproved.push({ value })
      else if (qcStatus === 'NOT_REQUIRED') accum.qcNotRequired.push({ value })
      return accum
    }, { qcApproved: [], qcNotRequired: [] }))
  }, [storeRoughStonesList])

  const topActions = [
    {
      label: 'QC Approved',
      onClick: (handleChange) => handleChange(allQCApprovedRoughs, true)
    },
    {
      label: 'QC Not Required',
      onClick: (handleChange) => handleChange(allQCNotRequiredRoughs, true)
    }
  ]

  return (
    <div className='create-sale center'>
      <SimpleForm
        title='Create a New Sale'
        name='Create a new sale'
        onSubmit={handleSubmit}
      >
        <TextInput
          name='saleName'
          label='Sale Name'
        />
        <Dropdown
          name='eventGroupId'
          label='Select Event Group'
          options={allEvents.map(x => ({ label: x, value: x }))}
          isMulti={false}
          required={false}
        />
        <Dropdown
          name='buyerIds'
          label='Select Buyers'
          options={
            Object.keys(allOrdersByOwner)
            .map(o => ({ label: o, value: o }))
            .sort((a, b) => { return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1 })
          }
          isMulti={true}
          canAddAll={true}
          required={false}
        />
        <Dropdown
          name='orderIds'
          label='Select Orders'
          options={
            allSubmittedOrders
            .map(o => ({
              label: `${o.buyerName} | ${o.id} | ${o.name}`,
              value: o.id
            }))
            .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
          }
          isMulti={true}
          canAddAll={true}
          required={false}
        />
        <Dropdown
          name='sellerIds'
          label='Select Sellers'
          options={
            Object.keys(allStonesByOwner)
            .map(o => ({
              label: o,
              value: o
            }))
            .sort((a, b) => { return a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1 })
          }
          isMulti={true}
          canAddAll={true}
          required={false}
        />
        <Dropdown
          name='assortmentIds'
          label='Select Assortments'
          options={
            Object.keys(allStonesByAssortment)
            .map(a => ({
              label: `${allStonesByAssortment[a][0].sellerName} | ${allStonesByAssortment[a][0].Assortment.name} | ${a}`,
              value: a
            }))
            .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
          }
          isMulti={true}
          canAddAll={true}
          required={false}
        />
        <Dropdown
          name='stoneIds'
          label='Select Rough Stones'
          options={
            allSubmittedRoughs
            .map(s => ({
              label: `${s.sellerName} | ${s.id} | ${s.sellerStoneName}`,
              value: s.id
            }))
            .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
          }
          isMulti={true}
          canAddAll={true}
          required={false}
          topActions={topActions}
        />
        <Button typeVariant="action" onClick={() => history.goBack()}>
            Cancel
        </Button>
        <Button type='submit' size='sm'>
            Create Sale
        </Button>
      </SimpleForm>
    </div>
  )
}

export default CreateSale
