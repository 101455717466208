import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { roughStoneActions, planningActions, reportActions, organizationActions, orderActions } from '@actions'
import { useAuthStore, useGlobalsStore, useModalStore, useOrderStore } from '@stores'
import { usePageTitle, useToast } from '@hooks'
import { DetailsPage, Modal } from '@templates'
import PolishedStonesList from './polished_stone_list'
import { fileUtils, numberUtils, textUtils } from '@utils'
import { FormComponents, FileUpload } from '@organisms'
import clone from 'just-clone'

const PAGE_KEYS = {
  PLANNING: 'planning',
  QC: 'qc'
}

function PlanningDetails({ match, title }) {
  const { requestId } = match?.params
  const pageKey = match.path.split('/')[1]

  const {
    inclusionTypes,
    inclusionTypesMap,
    inclusionReductions,
    inclusionReductionsMap,
    roughColoursMap,
    roughTingesMap,
    claritiesMap,
    roughQCStatusesMap,
    roughStatusesMap,
    spsRequestStatusesMap,
    getInclusionTypes,
    getInclusionReductions,
    orgsList: { all: orgsList },
    getOrgsList,
    getRoughColours,
    getRoughTinges,
    getClarities,
    getRoughQCStatuses,
    getRoughStatuses,
    getSpsRequestStatuses,
    spsPlanningSpeedsMap, getSpsPlanningSpeeds
  } = useGlobalsStore(store => store)
  const { hasAdmin, hasPermission, permissionsAdminCache } = useAuthStore(state => state)
  const { setModal } = useModalStore(state => state)

  const [spsRequest, setSpsRequest] = useState()
  const [roughStone, setRoughStone] = useState()

  usePageTitle(title, spsRequest?.id)

  function fetchData() {
    (hasAdmin(planningActions.getPlannedStones)
      ? planningActions.getPlannedStones({ id: requestId, columns: '[PolishedStones]' })
      : planningActions.getQcPlannedStones({ id: requestId, columns: '[PolishedStones]' }))
    .then(res => {
      const spsReq = res.data.data[0]
      if (spsReq) {
        setSpsRequest(spsReq)
        if (hasPermission(reportActions.getDownloadUrl) || hasPermission(reportActions.getQcDownloadUrl)) getFileLinks(spsReq)
      }
    })
  }

  useEffect(() => {
    getOrgsList()
    getInclusionTypes()
    getInclusionReductions()
    getRoughColours()
    getRoughTinges()
    getClarities()
    getRoughQCStatuses()
    getRoughStatuses()
    getSpsRequestStatuses()
    getSpsPlanningSpeeds()
  }, [])
  useEffect(() => {
    if (!permissionsAdminCache?.size) return
    fetchData()
  }, [permissionsAdminCache])

  const ordersParams = {
    buyerId: spsRequest?.buyerId,
    columns: '[id, name, OrderLines]'
  }
  const {
    ordersList: { [ordersParams ? JSON.stringify(ordersParams) : 'all']: ordersList },
    getOrdersList
  } = useOrderStore(store => store)
  const orders = ordersList?.data

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    if (spsRequest?.roughId) {
      let request
      if (pageKey === PAGE_KEYS.PLANNING) {
        request = roughStoneActions.getRoughStoneById(spsRequest.roughId, '[Country,Mine,Batch,status,sellerStoneName,createdAt,sellerId,weight,colour,fluorescence,tinge,reservePpc,inclusionsTypeId,inclusionReductionsId,qcStatus]')
      } else if (hasAdmin(roughStoneActions.getQcRoughStoneList)) {
        request = roughStoneActions.getQcRoughStoneById(spsRequest.roughId, '[Country,Mine,Batch,status,sellerStoneName,createdAt,sellerId,weight,colour,fluorescence,tinge,reservePpc,inclusionsTypeId,inclusionReductionsId,qcStatus]')
      } else {
        request = roughStoneActions.getQcRoughStoneById(spsRequest.roughId)
      }
      request.then(res => setRoughStone(res.data.data[0]))
    }
    if (spsRequest?.buyerId && hasPermission(orderActions.getOrderList)) {
      getOrdersList(ordersParams)
    }
  }, [spsRequest, permissionsAdminCache])

  const [fileLinks, setFileLinks] = useState([])
  async function getFileLinks(spsReq) {
    if (!spsReq) setFileLinks([])
    try {
      const fileIds = [
        spsReq.plannedAdvId,
        spsReq.plannedAxpId,
        spsReq.allInstitutesId,
        spsReq.priceListsId,
        spsReq.allocationProgramsId,
        spsReq.gemCadShapeIdsId
      ].filter(x => x)
      const res = (await (pageKey === PAGE_KEYS.PLANNING ? reportActions.getDownloadUrl : reportActions.getQcDownloadUrl)(fileIds, { spsRequestId: spsReq.id })).data.data
      setFileLinks([
        spsReq.plannedAdvId ? { label: 'Planned Adv File', ...res.find(f => f.id === spsReq.plannedAdvId) } : null,
        spsReq.plannedAxpId ? { label: 'Planned Axp File', ...res.find(f => f.id === spsReq.plannedAxpId) } : null,
        spsReq.allInstitutesId ? { label: 'AllInstitutes File', ...res.find(f => f.id === spsReq.allInstitutesId) } : null,
        spsReq.priceListsId ? { label: 'Price Lists File', ...res.find(f => f.id === spsReq.priceListsId) } : null,
        spsReq.allocationProgramsId ? { label: 'Allocation Programs File', ...res.find(f => f.id === spsReq.allocationProgramsId) } : null,
        spsReq.gemCadShapeIdsId ? { label: 'Gemcad Shapes File', ...res.find(f => f.id === spsReq.gemCadShapeIdsId) } : null
      ].filter(f => f))
    } catch (err) {
      console.error('Couldn\'t get download links for sps files.', err)
    }
  }

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (spsRequest) {
      setFields([
        {
          label: 'Status',
          value: spsRequestStatusesMap?.[spsRequest.status] ?? '',
          name: 'status',
          componentName: 'textInput'
        },
        {
          label: 'Condition',
          value: textUtils.formatDescription(spsRequest.condition),
          name: 'condition',
          componentName: 'textInput'
        },
        {
          label: 'SPS Start Time',
          value: textUtils.formatDate(spsRequest.spsStartTime, true) ?? '',
          name: 'spsStartTime',
          componentName: 'textInput'
        },
        {
          label: 'SPS End Time',
          value: textUtils.formatDate(spsRequest.spsEndTime, true) ?? '',
          name: 'spsEndTime',
          componentName: 'textInput'
        },
        {
          label: 'SPS Version',
          value: spsRequest.version,
          name: 'version',
          componentName: 'textInput'
        },
        {
          label: 'Planning Accuracy',
          value: spsPlanningSpeedsMap?.[spsRequest.speed],
          name: 'spsSpeed',
          componentName: 'textInput'
        },
        {
          label: 'Queue',
          value: spsRequest.priorityQueue ? 'Priority Queue' : 'Normal',
          name: 'queue',
          componentName: 'textInput'
        },
        {
          label: 'SPS Message',
          value: spsRequest.spsMessage,
          name: 'spsMessage',
          componentName: spsRequest.spsMessage?.length > 100 ? 'textArea' : 'textInput',
          span: true
        },
        {
          label: 'Created At',
          value: textUtils.formatDate(spsRequest.createdAt, true)
        },
        {
          label: 'Last Updated',
          value: textUtils.formatDate(spsRequest.updatedAt, true)
        },
        ...fileLinks.map(file => ({
          label: file.label,
          componentName: 'downloadLink',
          fileName: null, // Setting this to null, so the default FileSaver download method is used
          url: file.url,
          text: file.label,
          target: '_blank'
        })),
        {
          legend: 'Rough Stone',
          componentName: 'fieldset',
          name: 'roughStone',
          span: !hasPermission(roughStoneActions.getRoughStoneList),
          split: !hasPermission(roughStoneActions.getRoughStoneList),
          children: [
            {
              label: 'Rough ID',
              value: spsRequest.roughId,
              name: 'roughId',
              componentName: 'textInput'
            },
            {
              label: 'Status',
              value: roughStatusesMap?.[roughStone?.status] ?? '',
              name: 'status',
              componentName: 'textInput'
            },
            {
              label: 'QC Status',
              value: roughQCStatusesMap?.[roughStone?.qcStatus] ?? '',
              name: 'qcStatus',
              componentName: 'textInput'
            },
            {
              label: 'Color',
              value: roughColoursMap?.[roughStone?.colour] ?? '',
              name: 'colour',
              componentName: 'textInput'
            },
            {
              label: 'Fluorescence',
              value: roughStone?.fluorescence,
              name: 'fluorescence',
              componentName: 'textInput'
            },
            {
              label: 'Tinge',
              value: roughTingesMap?.[roughStone?.tinge] ?? '',
              name: 'tinge',
              componentName: 'textInput'
            },
            {
              label: 'Weight',
              value: roughStone?.weight,
              name: 'weight',
              componentName: 'textInput'
            },
            ...(hasPermission(roughStoneActions.getRoughStoneList) ? [{
              label: 'Reserve Price',
              value: roughStone?.reservePpc != null ? numberUtils.numFmt(roughStone?.weight * roughStone?.reservePpc, 2) : 'N/A',
              name: 'reservePrice',
              componentName: 'textInput',
              type: 'currency',
              decimalScale: 2,
              fixedDecimalScale: true
            }] : []),
            {
              label: 'Seller Stone Name',
              value: roughStone?.sellerStoneName,
              name: 'sellerStoneName',
              componentName: 'textInput'
            },
            ...(hasAdmin(organizationActions.getOrganizationList) ? [{
              label: 'Seller',
              value: orgsList?.find(org => org.orgId === roughStone?.sellerId)?.commonName,
              name: 'status',
              componentName: 'textInput'
            }] : []),
            {
              label: 'Inclusions Type',
              value: inclusionTypesMap?.[roughStone?.inclusionsTypeId] ?? '',
              name: 'inclusionsType',
              componentName: 'textInput'
            },
            {
              label: 'Inclusion Reductions',
              value: inclusionReductionsMap?.[roughStone?.inclusionReductionsId] ?? '',
              name: 'inclusionReductions',
              componentName: 'textInput'
            }
          ]
        },
        ...(hasPermission(orderActions.getOrderList) ? [{
          legend: 'Orders',
          componentName: 'fieldset',
          name: 'orders',
          span: false,
          children: [
            {
              label: 'Buyer',
              value: orgsList?.find(org => org.orgId === spsRequest.buyerId)?.commonName,
              name: 'buyerId',
              componentName: 'textInput'
            },
            {
              label: 'Orders',
              value: orders?.filter(x => x.OrderLines?.find(y => spsRequest?.orderLineIds?.includes(y.id))).map(x => x.name).join('\n'),
              name: 'status',
              componentName: 'textArea'
            }
          ]
        }] : []),
        {
          legend: 'Planned Results',
          name: 'PolishedStonesList',
          customComponent: PolishedStonesList,
          polishedStones: spsRequest?.PolishedStones,
          value: spsRequest?.PolishedStones,
          orders: orders,
          claritiesMap,
          span: true
        }
      ])
    }
  }, [spsRequest, roughStone, orders, fileLinks, claritiesMap, roughTingesMap, roughColoursMap, inclusionTypesMap, inclusionReductionsMap, roughQCStatusesMap, roughStatusesMap, spsRequestStatusesMap, orgsList, permissionsAdminCache])

  const [isEditRoughModalOpen, setIsEditRoughModalOpen] = useState(false)
  const { SimpleForm, Dropdown, Button } = FormComponents
  const [galaxyFile, setGalaxyFile] = useState(false)
  const [filePending, setFilePending] = useState(false)
  const { showSuccessToast, showInfoToast } = useToast()
  function handleOnSubmit(vals) {
    const editedValues = clone(vals)
    if ('inclusionsTypeId' in editedValues && editedValues.inclusionsTypeId === roughStone.inclusionsTypeId) {
      delete editedValues.inclusionsTypeId
    }
    if ('inclusionReductionsId' in editedValues && editedValues.inclusionReductionsId === roughStone.inclusionReductionsId) {
      delete editedValues.inclusionReductionsId
    }
    if ((!editedValues || !Object.keys(editedValues).length) && !galaxyFile) {
      setGalaxyFile(false)
      setIsEditRoughModalOpen(false)
      return showInfoToast('Nothing was changed')
    }

    if (galaxyFile) {
      editedValues.galaxyFile = galaxyFile
    }

    return (pageKey === PAGE_KEYS.PLANNING ? roughStoneActions.editRoughStone : roughStoneActions.editQcRoughStone)(spsRequest.roughId, editedValues)
    .then(() => {
      setGalaxyFile(false)
      setIsEditRoughModalOpen(false)
      showSuccessToast('Rough stone updated')
      fetchData()
    })
  }

  async function handleSarineFileChange(file, handleFileUploadProgress) {
    if (!file) return setGalaxyFile(null)

    const md5Hash = await fileUtils.getFileHash(file)
    const galaxyFileExists = await roughStoneActions.galaxyFileExists(md5Hash, roughStone.sellerId)
    if (galaxyFileExists.data.data?.exists && !galaxyFileExists.data.data?.files?.some(f => f.rough_id === spsRequest.roughId)) {
      const roughId = galaxyFileExists.data.data.files[0].rough_id
      await new Promise((resolve, reject) => {
        setModal({
          id: 'advFileExists',
          title: 'ADV File Exists',
          message: <>
              This ADV file has been uploaded to another rough stone ({roughId}).
            <br/>
            <strong>Are you sure you want to use this file?</strong>
          </>,
          buttonOptions: {
            submitText: 'Yes',
            cancelText: 'No'
          },
          onSubmit: resolve,
          onCancel: reject
        })
      })
    }

    return uploadAdvFile(file, handleFileUploadProgress)
  }

  function uploadAdvFile(file, handleFileUploadProgress) {
    setFilePending(true)
    return (pageKey === PAGE_KEYS.PLANNING ? roughStoneActions.getUploadUrl : roughStoneActions.getQcUploadUrl)({ orgId: roughStone.sellerId, roughId: spsRequest.roughId })
        .then(response => {
          return {
            url: response.data.data.url.url,
            uploadId: response.data.data.uploadId
          }
        })
        .then(tempUpload => {
          return roughStoneActions.uploadFile(tempUpload.url, file, { updateProgress: handleFileUploadProgress })
            .then(response => {
              const galaxyFile = {
                tempFile: tempUpload.uploadId,
                fileName: response.config.data.name
              }
              setGalaxyFile(galaxyFile)
            })
        })
        .catch(err => {
          console.error(err)
          throw err
        })
        .finally(() => {
          setFilePending(false)
        })
  }

  return (
    <>
      <DetailsPage
        canEdit={false}
        fields={fields}
        title={{
          label: 'SPS Request ID',
          value: spsRequest?.id || ''
        }}
        extraButtons={[
          {
            caption: 'Update Inclusion Details',
            onClick: () => setIsEditRoughModalOpen(true)
          },
          {
            caption: 'Refresh',
            onClick: () => fetchData()
          }
        ]}
      />
      <Modal
        open={isEditRoughModalOpen}
        title='Edit ADV or planning options'
        onClose={() => setIsEditRoughModalOpen(false)}
      >
        <div>
          <div className='add-order-stone center'>
            <SimpleForm
              onSubmit={handleOnSubmit}
              initialValues={{
                inclusionsTypeId: roughStone?.inclusionsTypeId,
                inclusionReductionsId: roughStone?.inclusionReductionsId
              }}
            >
              <div className="create-rough-stone__upload-file">
                <FileUpload
                  name='advFile'
                  label="Upload a new ADV File"
                  required={false}
                  onChange={(file, handleFileUploadProgress) => handleSarineFileChange(file, handleFileUploadProgress)}
                  acceptTypes={['.adv']}
                  expectedFileName={`${roughStone?.sellerStoneName?.toLowerCase()}.adv`}
                  fileName={galaxyFile?.fileName || galaxyFile?.downloadName || undefined}
                />
              </div>
              <Dropdown
                name='inclusionsTypeId'
                label='Inclusion Type'
                options={
                  inclusionTypes?.map(i => ({ label: i.description, value: i.id }))
                }
                isMulti={false}
                required={false}
              />
              <Dropdown
                name='inclusionReductionsId'
                label='Reduction Table'
                options={
                  inclusionReductions?.map(i => ({ label: i.description, value: i.id }))
                }
                isMulti={false}
                required={false}
              />
              <Button type='submit' size='sm' loading={filePending}>Save</Button>
            </SimpleForm>
          </div>
        </div>
      </Modal>
    </>
  )
}

PlanningDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default PlanningDetails
