import React, { useEffect, useMemo } from 'react'
import { Button } from '@atoms'
import { numberUtils } from '@utils'
import { Fieldset } from '@organisms'
import { TextInput } from '@molecules'
import { useOrderStore, useGlobalsStore } from '@stores'
import clone from 'just-clone'

function FluorPainter({ priceSchema, selectedPricingRange, onChange, onRangeChange }) {
  const {
    activeRange,
    setActiveRange,
    painterValue,
    painterFluorValue = { F: null, M: null, S: null, VS: null },
    gridSize,
    setPainterValue,
    setPainterFluorValue,
    isPainterEnabled,
    setIsPainterEnabled,
    fluorGridValues,
    isFluorMultiPainterEnabled,
    setIsFluorMultiPainterEnabled
  } = useOrderStore(state => state)

  const { maxFluorescence } = gridSize
  const { polishedFluorescences, getPolishedFluorescences } = useGlobalsStore(state => state)

  useEffect(() => {
    getPolishedFluorescences()
  }, [])

  function onSetAllLocalPrices() {
    const _activeRange = { ...activeRange }
    const {
      priceRange,
      colours,
      clarities
    } = _activeRange

    const maxColour = colours[0].value
    const minColour = colours[colours.length - 1].value
    const maxClarity = clarities[0].value
    const minClarity = clarities[clarities.length - 1].value

    priceRange.additionalDiscounts = {}
    priceRange.additionalDiscounts.fluorescence = []
    priceRange.additionalDiscounts.fluorescence.push({
      discounts: { ...getDiscounts() },
      maxClarity,
      maxColour,
      minClarity,
      minColour
    })

    setActiveRange({ ..._activeRange, priceRange })
  }

  function onSetAllPrices() {
    const {
      colours,
      clarities
    } = activeRange

    const maxColour = colours[0].value
    const minColour = colours[colours.length - 1].value
    const maxClarity = clarities[0].value
    const minClarity = clarities[clarities.length - 1].value

    if (priceSchema) {
      onChange([
        ...priceSchema.map(range => ({
          ...range,
          additionalDiscounts: {
            fluorescence: [{
              discounts: { ...getDiscounts() },
              maxClarity,
              maxColour,
              minClarity,
              minColour
            }]
          }
        }))
      ])
    }
  }

  function onFillLocalBlanks() {
    const _activeRange = { ...activeRange }
    const { priceRange } = _activeRange
    updatePriceRange(priceRange)
    setActiveRange({ ..._activeRange })
  }

  function onFillAllBlanks() {
    const _priceSchema = clone(priceSchema)
    if (_priceSchema?.length) {
      for (const priceRange of _priceSchema) {
        updatePriceRange(priceRange)
      }

      onChange(_priceSchema)
    }
  }

  function updatePriceRange(priceRange) {
    priceRange.additionalDiscounts = {}
    priceRange.additionalDiscounts.fluorescence = []
    const formattedFluorDiscounts = formatFluorDiscounts()
    for (const colClarity of Object.keys(formattedFluorDiscounts)) {
      const [colour, clarity] = String(colClarity).split('/')
      priceRange.additionalDiscounts.fluorescence.push({
        discounts: {
          ...polishedFluorescences.reduce((discounts, fluor) => {
            if (formattedFluorDiscounts[colClarity][fluor.value] != null || painterFluorValue[fluor.value] != null) {
              discounts[fluor.value] = formattedFluorDiscounts[colClarity][fluor.value] ?? painterFluorValue[fluor.value]
            }

            return discounts
          }, {})
        },
        maxClarity: clarity,
        maxColour: colour,
        minClarity: clarity,
        minColour: colour
      })
    }
  }

  function formatFluorDiscounts() {
    const formattedFluorDiscounts = Object.keys(fluorGridValues).reduce((discounts, value) => {
      const [colour, clarity, fluor] = String(value).split('/')
      discounts[colour + '/' + clarity] = {
        ...discounts[colour + '/' + clarity],
        [fluor]: fluorGridValues[value]
      }

      return discounts
    }, {})
    return formattedFluorDiscounts
  }

  function getDiscounts() {
    return polishedFluorescences.reduce((discounts, fluor) => {
      if (painterFluorValue[fluor.value] != null) {
        discounts[fluor.value] = painterFluorValue[fluor.value]
      }
      return discounts
    }, {})
  }

  const fluorInputs = useMemo(() => {
    if (polishedFluorescences.length) {
      return polishedFluorescences
      .filter(fluor => fluor.value !== 'N')
      .map((fluor, index) => {
        return {
          order: index + 1,
          value: painterFluorValue[fluor.value],
          key: fluor.value,
          name: fluor.description,
          onChange: e => {
            setPainterFluorValue({
              ...painterFluorValue,
              [fluor.value]: e.currentTarget.value
            })
          }
        }
      })
    }
  }, [polishedFluorescences, painterFluorValue])

  const referenceFluorRange = useMemo(() => {
    return polishedFluorescences?.map((fluor, index) => {
      return {
        order: index,
        key: fluor.value,
        description: fluor.description
      }
    })
  }, [polishedFluorescences])

  const maxFluorOrderNum = referenceFluorRange.find(rf => rf.key === maxFluorescence).order
  const minWeight = numberUtils.numFmt(activeRange?.priceRange?.minWeight, 2)
  const maxWeight = numberUtils.numFmt(activeRange?.priceRange?.maxWeight, 2)

  return (
    <div>
      <span className="p2">
        Set a new value to all discounts or blanks.
      </span>
      <div className='price-painter__new-price'>
        <div>
          {
            fluorInputs.filter(fluorInput => fluorInput.order <= maxFluorOrderNum).map((fluorInput, key) => {
              return (
                <TextInput
                  key={key}
                  type='number'
                  label={referenceFluorRange.find(fr => fr.key === fluorInput.key).description}
                  name={fluorInput.name}
                  value={fluorInput.value}
                  onChange={fluorInput.onChange}
                />
              )
            })
          }
        </div>
      </div>
      <div >
        {
          maxFluorOrderNum > 0
            ? (
              <>
                <Fieldset
                  legend={`${minWeight} - ${maxWeight}`}
                >
                  <div className="price-painter__new-price-buttons">
                    <Button
                      typeVariant='secondary'
                      size='sm'
                      disabled={maxFluorOrderNum === 0}
                      onClick={() => {
                        if (isPainterEnabled) {
                          setPainterValue(null)
                          setIsPainterEnabled(false)
                        }
                        setIsFluorMultiPainterEnabled(!isFluorMultiPainterEnabled)
                      }}
                    >
                      {`${isFluorMultiPainterEnabled ? 'Disable Fluorescence Painter' : 'Enable Fluorescence Painter'}`}
                    </Button>
                    <Button
                      typeVariant='secondary'
                      size='sm'
                      onClick={() => {
                        if (!fluorGridValues) {
                          onSetAllLocalPrices()
                        } else {
                          onFillLocalBlanks()
                        }
                      }}
                    >
                      Fill Blanks
                    </Button>
                    <Button
                      typeVariant='secondary'
                      size='sm'
                      onClick={() => onSetAllLocalPrices()}
                    >
                      Set all
                    </Button>
                  </div>
                </Fieldset>
                <Fieldset
                  legend='All Existing Weight Ranges'
                >
                  <div className="price-painter__new-price-buttons">
                    <Button
                      typeVariant='secondary'
                      size='sm'
                      onClick={() => {
                        if (!fluorGridValues) {
                          onSetAllPrices()
                        } else {
                          onFillAllBlanks()
                        }
                      }}
                    >
                      Fill Blanks
                    </Button>
                    <Button
                      typeVariant='secondary'
                      size='sm'
                      onClick={() => onSetAllPrices()}
                    >
                      Set all
                    </Button>
                  </div>
                </Fieldset>
              </>
            )
            : null
        }

      </div>
      <span className="p2">
        Enable painter to manually set a new value.
      </span>
      <div className='price-painter__paint-value'>
        <div>
          <TextInput
            type="number"
            label='Paint Value'
            name='paintvalue'
            value={painterValue}
            disabled={!isPainterEnabled}
            onChange={e => setPainterValue(e.currentTarget.value)}
          />
        </div>
        <Button
          typeVariant='secondary'
          size='sm'
          disabled={maxFluorOrderNum === 0}
          onClick={() => {
            if (isPainterEnabled) {
              setPainterValue(null)
            }
            if (isFluorMultiPainterEnabled) {
              setIsFluorMultiPainterEnabled(false)
            }
            setIsPainterEnabled(!isPainterEnabled)
          }}
        >
          {`${isPainterEnabled ? 'Disable Single Value Painter' : 'Enable Single Value Painter'}`}
        </Button>
      </div>
    </div>
  )
}

export default FluorPainter
