import create from 'zustand'
import clone from 'just-clone'

const useProgressListStore = create((set, get) => ({
  lists: {},
  setLists: (lists) => {
    set({ lists })
  },
  addList: (list) => {
    set({
      lists: {
        ...get().lists,
        [list.id]: { ...list }
      }
    })
  },
  removeList: (listId) => {
    const _lists = clone(get().lists)
    if (!_lists[listId]) return
    delete _lists[listId]
    set({
      lists: _lists
    })
  },
  getList: (id) => {
    return get().lists[id]
  },
  getTask: (listId, taskId) => {
    const list = get().getList(listId)
    if (!list) return
    return list.tasks[taskId] || null
  },
  addToQueue: (listId, taskId) => {
    const list = get().getList(listId)
    if (!list) return
    return list.queue.add(async () => {
      try {
        const task = list.tasks[taskId]
        if (!task?.status?.isRemoved) {
          get().setIsInProgress(list.id, task)
          await task.callback(task)
          get().setIsSucceeded(list.id, task)
        }
      } catch (err) {
        const task = list.tasks[taskId]
        if (task) {
          get().setIsFailed(list.id, task, err)
          console.error(err)
        }
      } finally {
        if (list.onAllTasksComplete && list.isAllTasksComplete()) {
          list.onAllTasksComplete(list)
        }
      }
    }, { priority: list.tasks[taskId]?.priority || 0 })
  },
  startList: () => {
    get().queue.start()
  },
  pauseList: () => {
    get().queue.pause()
  },
  setIsSucceeded: (listId, task) => {
    const list = get().getList(listId)
    if (!list) return
    task.status.isSucceeded = true
    task.status.isInProgress = false
    get().updateList(list)
  },
  setIsInProgress: (listId, task) => {
    const list = get().getList(listId)
    if (!list) return
    task.status.isInProgress = true
    get().updateList(list)
  },
  setIsFailed: (listId, task, error) => {
    const list = get().getList(listId)
    if (!list) return
    task.error = error?.response?.data?.message || error?.message || ''
    task.status.isFailed = true
    task.status.isInProgress = false
    list.tasks = { ...list.tasks, [task.id]: { ...task } }
    get().updateList(list)
  },
  setIsRemoved: (listId, task) => {
    const list = get().getList(listId)
    if (!list) return
    task.status.isInProgress = false
    task.status.isSucceeded = false
    task.status.isFailed = false
    task.status.isRemoved = true
    list.tasks = { ...list.tasks, [task.id]: { ...task } }
    get().updateList(list)
  },
  updateList: (list) => {
    set({ lists: { ...get().lists, [list.id]: { ...list } } })
  },
  resetProgressListStore: () => {
    Object.keys(get().lists).forEach(list => {
      get().lists[list].reset()
    })
    set({ lists: {} })
  }
}))

export {
  useProgressListStore
}
