import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'
import images from 'assets/images'

function SidebarLogo({
  isCollapsed,
  setIsCollapsed
}) {
  const collapsedClassName = isCollapsed ? '--collapsed' : ''

  return (
    <>
      <div
        className={`sidebar__collapse-button${collapsedClassName}`}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Icon
          name='chevronRight'
        />
      </div>
      {
        !isCollapsed ? (
          <div className={'sidebar__logo'}>
            <img className="sidebar__img"
              src={images.claraLogo}
              alt=""
            />
          </div>
        ) : null
      }
      {
        isCollapsed ? (
          <div className={`sidebar__logo${collapsedClassName}`}>
            <img className="sidebar__img"
              src={ images.claraLogoSmall }
              alt=""
            />
          </div>
        ) : null
      }
    </>
  )
}

SidebarLogo.propTypes = {
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func
}

SidebarLogo.defaultProps = {
  isCollapsed: false
}

export default SidebarLogo
