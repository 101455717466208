import React, { useState, useEffect } from 'react'
import { useGlobalsStore, useAuthStore } from '@stores'
import { Table } from '@organisms'
import { baselineActions, organizationActions, reportActions } from '@actions'
import { useToast } from '@hooks'
import { useHistory, NavLink } from 'react-router-dom'
import { fileUtils, textUtils } from '@utils'
import { COMMON } from '@constants'

function BaselineList() {
  const {
    orgsList: { all: orgsList },
    getOrgsList,
    getOrgById,
    baselineShapeTypesMap,
    baselineShapeTypes,
    getBaselineShapeTypes
  } = useGlobalsStore()
  const { showSuccessToast } = useToast()
  const { currentAuthenticatedUser, hasAdmin, permissionsAdminCache } = useAuthStore(state => state)
  const [baselineList, setBaselineList] = useState([])
  const [showComputedBaselines, setShowComputedBaselines] = useState()
  const [columns, setColumns] = useState([])
  const history = useHistory()

  useEffect(() => {
    getOrgsList()
    getBaselineShapeTypes()
  }, [])

  useEffect(() => {
    if (!permissionsAdminCache) return
    getBaselines('ACTIVE')
  }, [permissionsAdminCache])

  useEffect(() => {
    setColumns([
      {
        Header: 'Name',
        accessor: 'name',
        dataType: 'string',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/buyer-settings/baselines/${cellInfo.row.original.id}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value}
            </NavLink>
          )
        }
      },
      {
        Header: 'Shape Type',
        accessor: (row) => baselineShapeTypesMap?.[row.shapeType] ?? '',
        dataType: 'string',
        filterType: 'checkbox',
        enums: baselineShapeTypes?.map(bst => bst.description)
      },
      {
        Header: 'Type',
        id: 'baselineType',
        dataType: 'string',
        accessor: row => row.parentBaselineId ? 'Computed' : 'Uploaded',
        filterType: 'checkbox',
        enums: ['Computed', 'Uploaded']
      },
      {
        Header: 'Price Date',
        accessor: (row) => row.date || row?.ParentBaseline?.date,
        dataType: 'date',
        filterType: 'date'
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      },
      ...(hasAdmin(organizationActions.getOrganizationList) ? [{
        Header: 'Owner',
        accessor: (row) => row.buyerId == null ? '* Preloaded *' : (orgsList?.find(org => org.orgId === row.buyerId)?.commonName || ''),
        dataType: 'string',
        id: 'owner'
      }] : [])
    ])
  }, [orgsList, baselineShapeTypesMap, baselineShapeTypes, permissionsAdminCache])

  function getBaselines(condition) {
    getOrgById(currentAuthenticatedUser?.attributes?.['custom:org_id'])
    .then(buyer => {
      const advancedPricing = (hasAdmin(baselineActions.getBaselines) || buyer?.showComputedBaselines)
      setShowComputedBaselines(advancedPricing)
      baselineActions.getBaselines({ condition, columns: '[ParentBaseline]' })
      .then(response => {
        if (advancedPricing) setBaselineList(response.data.data)
        else setBaselineList(response.data.data.filter(x => x.colourMix === null))
      })
      .catch(err => console.error(err))
    })
  }

  function handleExport(row, format) {
    return reportActions.exportBaseline(format, row.id)
    .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
    .then(() => {
      showSuccessToast('Baseline has been exported.')
    })
  }

  function handleArchive(row) {
    return baselineActions.setBaselineCondition([row.id], 'ARCHIVED')
    .then(() => {
      showSuccessToast('Baseline has been archived.')
    })
  }

  function handleUnarchive(row) {
    return baselineActions.setBaselineCondition([row.id], 'ACTIVE')
    .then(() => {
      showSuccessToast('Baseline has been unarchived.')
    })
  }

  function handleRemove(row) {
    return baselineActions.setBaselineCondition([row.id], 'DELETED')
    .then(() => {
      showSuccessToast('Baseline has been removed.')
    })
  }

  const rowAction = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      callback: handleRemove,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition !== 'DELETED'
    },
    {
      actionName: 'xlsxDownload',
      title: 'Download Excel',
      callback: (row) => handleExport(row, 'xlsx'),
      shouldConfirm: false,
      showDisplay: (row) => row.condition !== 'DELETED'
    },
    {
      actionName: 'csvDownload',
      title: 'Download CSV',
      callback: (row) => handleExport(row, 'csv'),
      shouldConfirm: false,
      showDisplay: (row) => row.condition !== 'DELETED'
    }
  ]

  const topBarActions = [{
    label: 'Upload Baseline',
    callback: () => history.push('/buyer-settings/baselines/upload')
  },
  ...(showComputedBaselines
    ? [{
      label: 'Create Color Calc Baseline',
      callback: () => history.push('/buyer-settings/baselines/create')
    }] : [])]

  const tableTabs = [{
    label: 'Active',
    params: 'ACTIVE'
  },
  {
    label: 'Archived',
    params: 'ARCHIVED'
  }]

  const initialSort = [{
    id: 'updatedAt',
    desc: true
  }
  // {
  //   id: 'owner',
  //   desc: true
  // },
  // {
  //   id: 'name',
  //   desc: false
  // }
  ]

  return (
    <Table
      title='Baselines'
      data={baselineList}
      columns={columns}
      tableTabs={tableTabs}
      rowActions={rowAction}
      initialSort={initialSort}
      getDataCallback={getBaselines}
      topBarActions={topBarActions}
      isMultiSelect={false}
    />
  )
}

export default BaselineList
