import React, { useEffect, useState } from 'react'
import { useAuthStore, useGlobalsStore } from '@stores'
import { provenanceActions } from '@actions'
import { useToast } from '@hooks'
import { Table } from '@organisms'
import { useHistory, NavLink } from 'react-router-dom'

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    dataType: 'string',
    Cell: cellInfo => {
      return (
        <NavLink
          className="link"
          to={`/seller-settings/pipes/${cellInfo.row.original.id}`}
          id={cellInfo.row.original.id}
        >
          {cellInfo.value}
        </NavLink>
      )
    }
  },
  {
    Header: 'Mine',
    accessor: 'mineName',
    dataType: 'string',
    filterType: 'checkbox'
  },
  {
    Header: 'Seller',
    accessor: 'sellerName',
    dataType: 'string'
  }
]

function PipeList() {
  const history = useHistory()
  const { showSuccessToast } = useToast()
  const [pipes, setPipes] = useState([])
  const { orgId: userOrgId, hasAdmin, hasPermission, permissionsAdminCache } = useAuthStore(state => state)
  const {
    getMinesList,
    getPipesList,
    getOrgsList,
    resetPipesList
  } = useGlobalsStore(store => store)

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    getData('ACTIVE')
  }, [permissionsAdminCache])

  function getData(condition) {
    Promise.all([
      getPipesList(getPipeParams(condition)),
      getMinesList(getMineParams()),
      getOrgsList()
    ])
    .then(([pipes, mines, orgs]) => {
      setPipes(pipes.map(pipe => ({
        ...pipe,
        mineName: mines?.find(mine => mine.id === pipe.mineId)?.name,
        sellerName: orgs?.find(org => org.orgId === pipe.sellerId)?.commonName
      })))
    })
  }

  function getPipeParams (condition) {
    if (hasAdmin(provenanceActions.getPipes)) return { condition }
    return { condition: 'ACTIVE' }
  }

  function getMineParams() {
    if (hasAdmin(provenanceActions.getMines)) return null
    return {
      sellerId: userOrgId
    }
  }

  function handleArchive(row) {
    return provenanceActions.setPipeCondition(row.id, 'ARCHIVED')
    .then(() => {
      showSuccessToast('Pipe has been archived.')
    })
  }

  function handleUnarchive(row) {
    return provenanceActions.setPipeCondition(row.id, 'ACTIVE')
    .then(() => {
      showSuccessToast('Pipe has been unarchived.')
    })
  }

  const rowActions = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE' && hasPermission(provenanceActions.setPipeCondition)
    },
    {
      actionName: 'unarchive',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED' && hasPermission(provenanceActions.setPipeCondition)
    }
  ]

  const tableTabs = !hasAdmin(provenanceActions.getPipes)
    ? null
    : [{
      label: 'Active',
      params: 'ACTIVE'
    },
    {
      label: 'Archived',
      params: 'ARCHIVED'
    }]

  const topBarActions = hasPermission(provenanceActions.createPipe)
    ? [{
      label: 'Create Pipe',
      callback: () => history.push('/seller-settings/pipes/create')
    }]
    : []

  return (
    <Table
      title='Pipes'
      data={pipes}
      columns={columns}
      tableTabs={tableTabs}
      rowActions={rowActions}
      getDataCallback={getData}
      topBarActions={topBarActions}
      resetDataCallback={resetPipesList}
      isMultiSelect={false}
    />
  )
}

export default PipeList
