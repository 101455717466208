import React, { useState, useEffect } from 'react'
import './styles/main.scss'
import {
  Sidebar,
  TopBar,
  CookiesBanner,
  NotificationList,
  LoadingSpinnerModal,
  ProgressLists,
  TermsAndConditionsModal,
  ActionConfirmationModal,
  CustomIdleTimer,
  MultiErrorModal,
  Route
} from '@templates'
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom'
import routes from './routes/routes'
import { Config } from './cognito_config'
import Amplify from 'aws-amplify'
import { eventActions } from '@actions'
import { useAuth, useAxiosInterceptors, useWindowActivity, useAnalytics } from '@hooks'
import { PersistGate } from 'zustand-persist'

Amplify.configure(Config)
function App() {
  const { isAuthenticated } = useAuth()
  const windowIsActive = useWindowActivity()

  useAxiosInterceptors()
  useAnalytics()

  const [isCollapsed, setIsCollapsed] = useState(false)
  const [lastWakeUpTime, setLastWakeUpTime] = useState(false)

  useEffect(() => {
    // Try to wake up the db when window becomes active
    // Only do this once every 15 mins
    const timeDiff = Math.abs(new Date() - lastWakeUpTime)
    if (timeDiff > 900000 && windowIsActive) {
      eventActions.wakeUpDb()
      setLastWakeUpTime(new Date())
    }
  }, [windowIsActive])

  return (
    <div id="App" className="App">
      <PersistGate>
        <LoadingSpinnerModal />
        {isAuthenticated && <CustomIdleTimer />}
        {isAuthenticated && <ProgressLists />}
        <Router>
          <CookiesBanner />
          <MultiErrorModal />
          <TermsAndConditionsModal />
          <ActionConfirmationModal />
          {!isAuthenticated && <Redirect to={'/signin'} />}
          <Sidebar
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
          <div className={`App__main-area${!isAuthenticated ? '--full-width' : isCollapsed ? '--collapsed' : ''}`}>
            {isAuthenticated && (
              <>
                <TopBar />
                <NotificationList />
              </>
            )}
            <div className="container-fluid overflow-y-auto App__outer-container">
              <div className={`App__inner-container${!isAuthenticated ? '--no-padding' : ''}`}>
                <Switch>
                  {[
                    ...routes.external,
                    ...routes.navigation.overview,
                    ...routes.navigation.account,
                    ...routes.navigation.admin,
                    ...routes.navigation.main
                  ].map((route, index) => <Route key={index} {...route}/>)}
                  <Redirect to={isAuthenticated ? '/dashboard' : '/signin'} />
                </Switch>
              </div>
            </div>
          </div>
        </Router>
      </PersistGate>
      <div id="react-select-portal" className="react-select"></div>
    </div>
  )
}

export default App
