import React from 'react'
import PropTypes from 'prop-types'
import { ConfirmationModal } from '@templates'
import { SELLER } from '@constants'
import { TextInput, Dropdown } from '@molecules'
import { transactionActions } from '@actions'
import { Checkbox } from '@atoms'
const { ROUGH_LOCATIONS } = SELLER

function UpdateTransactionsModal({
  openModal,
  updateSucceeded,
  setOpenModal
}) {
  async function handleOnSubmit() {
    const params = openModal.updateKeys.reduce(
      (obj, key) => ({ ...obj, [key]: openModal[key] }),
      { transactionIds: openModal.selectedRows.map(row => row.id), ...(openModal.newLocation ? { location: openModal.newLocation } : {}) }
    )

    return transactionActions.editTransactions(params)
      .then(updateSucceeded(`${openModal.selectedRows.length} transaction${openModal.selectedRows.length > 1 ? 's' : ''} have been updated.`))
      .then(() => {
        if (openModal.successCallback) openModal.successCallback(params)
      })
      .catch(setOpenModal({ updateKeys: [], selectedRows: [], newLocation: '' }))
  }

  return (
    <ConfirmationModal
      open={openModal?.updateKeys?.length > 0}
      title='Update Transactions'
      message={`Mark ${openModal.selectedRows.length} transaction${openModal.selectedRows.length > 1 ? 's' : ''} as ${openModal[openModal.updateKeys[0]] ? '' : 'not '} ${openModal.updateKeys[0]?.endsWith('Date') ? openModal.updateKeys[0].slice(0, -4) : openModal.updateKeys[0]}?`}
      onClose={() => setOpenModal({ updateKeys: [], selectedRows: [], newLocation: '' })}
      onSubmit={handleOnSubmit}
    >
      <div className='update-transactions-modal'>
        {openModal?.updateKeys?.includes('cancelledReason')
          ? (
            <TextInput
              name='cancelledReason'
              label='Reason for Cancellation'
              value={openModal.cancelledReason}
              onChange={e => setOpenModal({ ...openModal, cancelledReason: e.currentTarget.value })}
            />
          )
          : null}
        <div className='change-location-modal__content'>
          <Dropdown
            name='newLocation'
            label='Change Stone Location?'
            value={openModal.newLocation}
            placeholder='No Change'
            onChange={e => setOpenModal({ ...openModal, newLocation: e.currentTarget.value })}
            options={ROUGH_LOCATIONS.map(i => ({ value: i, label: i }))}
          />
        </div>
      </div>
      <div className="update-transactions-modal__checkboxes">
        {openModal?.updateKeys?.includes('cancelForSeller')
          ? (
            <Checkbox
              name='cancelForSeller'
              label='Return to Seller'
              value={openModal.cancelForSeller}
              onChange={e => setOpenModal({ ...openModal, cancelForSeller: e.currentTarget.checked })}
            />
          )
          : null}
        {openModal?.updateKeys?.includes('blockBuyer')
          ? (
            <Checkbox
              name='blockBuyer'
              label='Block Buyer from Re-purchasing'
              value={openModal.blockBuyer}
              onChange={e => setOpenModal({ ...openModal, blockBuyer: e.currentTarget.checked })}
            />
          )
          : null}
      </div>
    </ConfirmationModal>
  )
}

UpdateTransactionsModal.propTypes = {
  openModal: PropTypes.object,
  updateSucceeded: PropTypes.func,
  setOpenModal: PropTypes.func
}

export default UpdateTransactionsModal
